import HelperError from "./helperError.js";

export default class extends HelperError {
  /**
   * 教务系统验证码错误
   */
  constructor() {
    super({
      code: "aaw_check_code_error",
      tip: "教务系统验证码错误，再试一次吧~",
      httpCode: 401,
    });
  }
}
