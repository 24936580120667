import HelperError from "./helperError.js";

export default class extends HelperError {
  /** 内部服务器错误 */
  constructor(details) {
    super({
      code: "internal_server_error",
      httpCode: 500,
      details: details,
    });
  }
}
