import HelperError from "./helperError.js";

export default class extends HelperError {
  /** 找不到 */
  constructor(details) {
    super({
      code: "not_found",
      httpCode: 404,
      details: details,
    });
  }
}
