export default {
  ImageJPEG: { Name: "ImageJPEG", Value: "image/jpeg" },
  ImagePNG: { Name: "ImagePNG", Value: "image/png" },
  ApplicationJSON: { Name: "ApplicationJSON", Value: "application/json" },
  XWWWFormURLEncoded: {
    Name: "XWWWFormURLEncoded",
    Value: "application/x-www-form-urlencoded",
  },
  TextPlainUTF8: { Name: "TextPlainUTF8", Value: "text/plain;charset=utf-8" },
  ApplicationOctetStream: {
    Name: "ApplicationOctetStream",
    Value: "application/octet-stream",
  },
};
