import HelperError from "./helperError.js";

export default class extends HelperError {
  /** 需要认证 */
  constructor(details) {
    super({
      code: "unauthorized",
      tip: undefined,
      httpCode: 401,
      details: details,
    });
  }
}
