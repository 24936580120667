import Util from "@/js/util";
export default class Plan {
  // NonNull
  plan_course_id; //计划课程代码
  plan_course_name; //计划课程名称
  plan_term; //计划应修学期
  credit_hour; //计划学分

  // Nullable
  sterm; //选修学期，如果为 null 则说明无成绩入选
  s_score; //选修成绩，数值类型，未经过五级制转换
  s_score_type; //选修成绩的类型，1 代表五级制，其他代表不转换

  // Nullable
  final_term; //计入哪个学期，如果为 null 则表示不计入学分绩计算
  final_score; //最终计入学分绩计算的分数，数值类型，未经过五级制转换
  final_score_type; //最终计入学分绩计算的分数的类型，1 代表五级制，其他代表不转换
  final_level_score; //最终经过五级制转换的计入学分绩计算的分数
  final_weighted_grade_point; //最终计入学分绩计算的加权后的绩点

  /**
   * @param {string} plan_course_id 计划课程代码
   * @param {string} plan_course_name 计划课程名称
   * @param {string} plan_term 计划应修学期
   * @param {number} credit_hour 计划学分
   * @param {string} sterm （可空）选修学期，如果为 null 则说明无成绩入选
   * @param {number} s_score 选修成绩，未经过五级制转换
   * @param {number} s_score_type 选修成绩的类型，1 代表五级制，其他代表不转换
   */
  constructor(
    plan_course_id,
    plan_course_name,
    plan_term,
    credit_hour,
    sterm,
    s_score,
    s_score_type
  ) {
    if (typeof plan_course_id !== "string" || Util.isNullOrNaN(plan_course_id))
      throw TypeError("Plan 构造器传入的参数 plan_course_id 类型错误");
    if (
      typeof plan_course_name !== "string" ||
      Util.isNullOrNaN(plan_course_name)
    )
      throw TypeError("Plan 构造器传入的参数 plan_course_name 类型错误");
    if (typeof plan_term !== "string" || Util.isNullOrNaN(plan_term))
      throw TypeError("Plan 构造器传入的参数 plan_term 类型错误");
    if (typeof credit_hour !== "number" || Util.isNullOrNaN(credit_hour))
      throw TypeError("Plan 构造器传入的参数 credit_hour 类型错误");
    if (typeof sterm !== "string" && !Util.isNullOrNaN(sterm))
      throw TypeError("Plan 构造器传入的参数 sterm 类型错误");
    if (typeof s_score !== "number" && !Util.isNullOrNaN(s_score))
      throw TypeError("Plan 构造器传入的参数 s_score 类型错误");
    if (typeof s_score_type !== "number" && !Util.isNullOrNaN(s_score_type))
      throw TypeError("Plan 构造器传入的参数 s_score_type 类型错误");

    this.plan_course_id = plan_course_id;
    this.plan_course_name = plan_course_name;
    this.plan_term = plan_term;
    this.credit_hour = credit_hour;
    this.sterm = sterm;
    this.s_score = s_score;
    this.s_score_type = s_score_type;
  }

  toString() {
    return (
      "Plan{" +
      "plan_course_id='" +
      this.plan_course_id +
      "'" +
      ", plan_course_name='" +
      this.plan_course_name +
      "'" +
      ", plan_term='" +
      this.plan_term +
      "'" +
      ", credit_hour=" +
      this.credit_hour +
      ", sterm='" +
      this.sterm +
      "'" +
      ", s_score=" +
      this.s_score +
      ", s_score_type=" +
      this.s_score_type +
      ", final_term='" +
      this.final_term +
      "'" +
      ", final_score=" +
      this.final_score +
      ", final_score_type=" +
      this.final_score_type +
      ", final_level_score=" +
      this.final_level_score +
      ", final_weighted_grade_point=" +
      this.final_weighted_grade_point +
      "}"
    );
  }

  toJSON() {
    return {
      plan_course_id: this.plan_course_id,
      plan_course_name: this.plan_course_name,
      plan_term: this.plan_term,
      credit_hour: this.credit_hour,
      sterm: this.sterm,
      s_score: this.s_score,
      s_score_type: this.s_score_type,
      final_term: this.final_term,
      final_score: this.final_score,
      final_score_type: this.final_score_type,
      final_level_score: this.final_level_score,
      final_weighted_grade_point: this.final_weighted_grade_point,
    };
  }

  genFinalLevelScore() {
    let ls;
    let fs = this.final_score;
    if (this.final_score_type === 1) {
      if (fs < 60) {
        ls = 40;
      } else if (fs >= 60 && fs < 70) {
        ls = 65;
      } else if (fs >= 70 && fs < 80) {
        ls = 75;
      } else if (fs >= 80 && fs < 90) {
        ls = 85;
      } else {
        ls = 95;
      }
    } else {
      ls = fs;
    }
    this.final_level_score = ls;
  }

  genGradePoint(total_credit_hour) {
    this.genFinalLevelScore();
    if (!(this.credit_hour > 0 && total_credit_hour > 0)) {
      return 0;
    }
    this.final_weighted_grade_point =
      (this.final_level_score * this.credit_hour) / total_credit_hour;
    return this.final_weighted_grade_point;
  }

  getPlan_course_id() {
    return this.plan_course_id;
  }

  setPlan_course_id(plan_course_id) {
    this.plan_course_id = plan_course_id;
  }

  getPlan_course_name() {
    return this.plan_course_name;
  }

  setPlan_course_name(plan_course_name) {
    this.plan_course_name = plan_course_name;
  }

  getPlan_term() {
    return this.plan_term;
  }

  setPlan_term(plan_term) {
    this.plan_term = plan_term;
  }

  getCredit_hour() {
    return this.credit_hour;
  }

  setCredit_hour(credit_hour) {
    this.credit_hour = credit_hour;
  }

  getSterm() {
    return this.sterm;
  }

  setSterm(sterm) {
    this.sterm = sterm;
  }

  getS_score() {
    return this.s_score;
  }

  setS_score(s_score) {
    this.s_score = s_score;
  }

  getS_score_type() {
    return this.s_score_type;
  }

  setS_score_type(s_score_type) {
    this.s_score_type = s_score_type;
  }

  getFinal_term() {
    return this.final_term;
  }

  setFinal_term(final_term) {
    this.final_term = final_term;
  }

  getFinal_score() {
    return this.final_score;
  }

  setFinal_score(final_score) {
    this.final_score = final_score;
  }

  getFinal_score_type() {
    return this.final_score_type;
  }

  setFinal_score_type(final_score_type) {
    this.final_score_type = final_score_type;
  }

  getFinal_level_score() {
    return this.final_level_score;
  }

  setFinal_level_score(final_level_score) {
    this.final_level_score = final_level_score;
  }

  getFinal_weighted_grade_point() {
    return this.final_weighted_grade_point;
  }

  setFinal_weighted_grade_point(final_weighted_grade_point) {
    this.final_weighted_grade_point = final_weighted_grade_point;
  }
}
