<template>
  <div>
    <div :style="{width: widthString, minWidth: widthString, height: heightString, minHeight: heightString}"></div>
  </div>
</template>

<script>
export default {
  name: "Space",
  props: {
    widthString: {
      type: String,
      default() {
        return "0"
      },
    },
    heightString: {
      type: String,
      default() {
        return "0"
      },
    },
  },
}
</script>

<style scoped>

</style>