import HelperError from "./helperError.js";

export default class extends HelperError {
  constructor() {
    super({
      code: "user-canceled",
      tip: `已取消`,
      httpCode: 400
    });
  }
}
