import HelperError from "./helperError.js";

export default class extends HelperError {
  /**
   * CAS: 需要二次验证
   */
  constructor() {
    super({
      code: "cas_need_re_auth",
      tip: "需要二次验证",
      httpCode: 401,
    });
  }
}
