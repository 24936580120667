import Util from "@/js/util";

export default {
    Developer: {
        Telephone: {
            Name: 'Telephone',
            AliPayURL: 'https://qr.alipay.com/fkx1448620n40aomi6fng7a',
        },
        Eric: {
            Name: 'Eric',
            AliPayURL: 'https://qr.alipay.com/fkx10716ctdfy51t9tuvue6',
        },
    },
    selectOne() {
        const developerList = [
            this.Developer.Telephone,
            this.Developer.Eric,
        ]
        return developerList[Util.randomInt(0, developerList.length)]
    },
}