// noinspection HttpUrlsUsage

import LANAndWAN from "../types/lanWAN.js";

const This = {
  Server: "https://guetcob.com",
  CAS: new LANAndWAN(
    "https://cas.guet.edu.cn",
    "https://v.guet.edu.cn/https/77726476706e69737468656265737421f3f652d220256d44300d8db9d6562d",
  ),
  VPN: new LANAndWAN(
    "https://v.guet.edu.cn",
    "https://v.guet.edu.cn/https/77726476706e69737468656265737421e6b94689222426557a1dc7af96",
  ),
  System: new LANAndWAN(
    "https://bkjw.guet.edu.cn",
    "https://v.guet.edu.cn/https/77726476706e69737468656265737421f2fc4b8b69377d556a468ca88d1b203b",
  ),
  SystemSupWisdom: new LANAndWAN(
    "https://bkjwtest.guet.edu.cn",
    "https://v.guet.edu.cn/https/77726476706e69737468656265737421f2fc4b8b33357b44300f9ca98c1b2631e4350e37",
  ),
  ICampus: new LANAndWAN(
    "https://pcportal.guet.edu.cn",
    "https://v.guet.edu.cn/https/77726476706e69737468656265737421e0f451933524695c300f9ca98c1b2631c0c8746e",
  ),
  LiJiangSchool: new LANAndWAN(
    "http://www.gxljxt.cn",
    "https://v.guet.edu.cn/http/77726476706e69737468656265737421e7e056d22028645a661cc7af96",
  ),
};

This.WebVPNCookie = This.VPN.Get(true);

export default This;
