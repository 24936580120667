import Util from '@/js/util';
import Plan from '@/js/type/Plan';
import Score from '@/js/type/Score';
import Require from "@/js/require";

export default {
    CalculateSchoolYearCreditPoint(aaData) {
        let gradeList = aaData.gradeList
        let gradeMap = new Map()
        for (const grade of Util.getEmptyArrayFromNull(gradeList)) {
            let term = Require.string(grade.term)
            let credit = Require.number(grade.xf)
            let courseID = Require.string(grade.courseid)
            let score = Require.number(grade.score)
            //
            let year = 0
            if (term.length > 4) {
                year = Require.number(term.substring(0, 4))
            }
            //
            if (year <= 0 || credit <= 0 || !courseID) {
                continue
            }
            if (!gradeMap.has(year)) {
                gradeMap.set(year, [])
            }
            gradeMap.get(year).push({credit, courseID, score})
        }
        let yearCreditPointList = []
        for (const [year, courseList] of gradeMap) {
            let check = course => {
                let id = course.courseID.toLowerCase()
                return id.startsWith("b")
                    || (id.startsWith("x") && course.score >= 60)
            }
            let totalCredit = 0
            for (const course of courseList) {
                if (check(course)) {
                    totalCredit += course.credit
                }
            }
            if (!totalCredit) {
                continue
            }
            let totalCreditPoint = 0
            for (const course of courseList) {
                if (!check(course)) {
                    continue
                }
                if (course.courseID.toLowerCase().startsWith("bs")) {
                    if (course.score < 60) {
                        course.score = 40;
                    } else if (course.score >= 60 && course.score < 70) {
                        course.score = 65;
                    } else if (course.score >= 70 && course.score < 80) {
                        course.score = 75;
                    } else if (course.score >= 80 && course.score < 90) {
                        course.score = 85;
                    } else {
                        course.score = 95;
                    }
                }
                totalCreditPoint += course.credit / totalCredit * course.score
            }
            totalCreditPoint = Math.round(totalCreditPoint * 100) / 100
            yearCreditPointList.push([year, Require.string(totalCreditPoint)])
        }
        yearCreditPointList.sort(([year1], [year2]) => year1 - year2)
        for (const yearCreditPoint of yearCreditPointList) {
            let year = yearCreditPoint[0]
            yearCreditPoint[0] = Require.string(year) + " - " + Require.string(year + 1) + " 学年"
        }
        if (aaData.changingMajorInformation) {
            let xfjStr = Require.string(aaData.changingMajorInformation.xfj)
            if (xfjStr) {
                yearCreditPointList.unshift(["转专业计算的学分绩", xfjStr])
            }
        }
        if (aaData.graduationInformation) {
            let xfjStr = Require.string(aaData.graduationInformation.xfj)
            if (xfjStr) {
                yearCreditPointList.unshift(["毕业计算的学分绩", xfjStr])
            }
        }
        return yearCreditPointList
    },
    /**
     * 解析并计算学分绩
     * @param aaData 教务数据。提示：传入 `GuetcobHelper.fetch()` 的结果
     * @param {boolean} use_plan_term 对于每一门课，`true` 代表按照培养计划标明的学期计算学分绩，`false` 代表按照实际选课、出成绩的学期计算学分绩
     * @return {Array} 计算结果
     */
    getCreditPoint(aaData, use_plan_term = false) {
        let graduationScoreList = aaData.planCourseGradeList.filter(
            score => score.courseid.toLowerCase().startsWith("b"));
        let gradeList = aaData.gradeList.filter(
            grade => grade.courseid.toLowerCase().startsWith("b")
                || grade.courseid.toLowerCase().startsWith("x"));
        let termInfoList = aaData.termList;
        let plans = [];
        for (let g of graduationScoreList) {
            plans.push(new Plan(
                g.courseid,
                g.cname,
                g.term,
                !Util.isNullOrNaN(g.credithour) ? parseFloat(g.credithour) : null,
                Util.getEmptyStringFromNull(g.sterm),
                !Util.isNullOrNaN(g.score) ? parseFloat(g.score) : null,
                (g.courseid.toLowerCase().startsWith("bs") ? 1 : 0) //实践环节为五级制，其余正常
            ));
        }

        // <限选课程代码, 此限选课的所有成绩记录列表>
        let xz_map = {};
        let scores = [];
        for (let grade of gradeList) {
            scores.push(new Score(
                grade.courseid,
                grade.courseno,
                grade.term,
                grade.score,
                grade.cjlx
            ));

            if (grade.courseid.toLowerCase().startsWith("xz")) {
                // eslint-disable-next-line no-prototype-builtins
                if (xz_map.hasOwnProperty(grade.courseid)) {
                    xz_map[grade.courseid].push(grade);
                } else {
                    xz_map[grade.courseid] = [grade]
                }
            }
        }

        for (let xz_course_id in xz_map) {  //对于每一门限选课
            let xz_grade_list = xz_map[xz_course_id];  //这门限选课的所有成绩记录

            // 有无过了的？
            let pass = false;
            for (let g of xz_grade_list) {
                if (g.score >= 60) {
                    pass = true;
                    break;
                }
            }

            if (pass) {  //如果这门限选课过了，那么将它添加到毕业计划课程
                //取这门限选课所有成绩记录中最早的选修学期
                xz_grade_list = xz_grade_list.sort(
                    (o1, o2) => o1.term.localeCompare(o2.term));
                let term = xz_grade_list[0].term;
                //取这门限选课所有成绩记录中最好的分数和相应的选修学期
                xz_grade_list = xz_grade_list.sort( // 倒序
                    (o1, o2) => o2.score - o1.score);
                let best_score = xz_grade_list[0].score;
                let best_score_term = xz_grade_list[0].term;
                plans.push(new Plan(
                    xz_course_id,           //这门限选课的课程代码
                    xz_grade_list[0].cname, //随便取一个课程名称
                    term,                   //取最早的选修学期作为计划应修学期
                    !Util.isNullOrNaN(xz_grade_list[0].xf) ? parseFloat(xz_grade_list[0].xf) : null,    //随便取一个学分
                    best_score_term,        //取最好分数的选修学期作为入选成绩的选修学期
                    !Util.isNullOrNaN(best_score) ? parseFloat(best_score) : null,             //取最好分数作为入选成绩
                    !Util.isNullOrNaN(xz_grade_list[0].cjlx) ? parseInt(xz_grade_list[0].cjlx) : null   //随便取一个成绩类型
                ));
            }
        }
        let term_ids = termInfoList.map((t => t.term));

        return this.calculate(plans, scores, term_ids, aaData.isInternational, aaData.changingMajorInformation ? aaData.changingMajorInformation.xfj : null, aaData.graduationInformation.xfj, use_plan_term);
    },

    /**
     * 计算学分绩
     * @param {[Plan]} plan_nodes 计划课程列表
     * @param {[Score]} score_nodes 课程成绩列表
     * @param {[string]} term_id_list 学期列表，如["2022-2023_1", "2021-2022_2"]
     * @param isInternational 是否是国际学院
     * @param changingMajorCredit 转专业计算的学分绩
     * @param graduationCredit 毕业计算的学分绩
     * @param {bool} use_plan_term
     * @return {Array} 计算结果
     */
    calculate(plan_nodes, score_nodes, term_id_list, isInternational, changingMajorCredit, graduationCredit, use_plan_term = false) {
        for (let plan_node of plan_nodes) {
            //确定计划课程列表中每一门计划课程计入学分绩计算的学期，选修过一门计划课程相关的课，这门计划课程才计入学分绩计算，并且计入最早的选修记录所在的那个学期
            let score_nodes_with_the_same_cid = []
            for (let score of score_nodes) {
                //从成绩列表中选出课程代码相同的成绩
                if (plan_node.plan_course_id === score.course_id) {
                    score_nodes_with_the_same_cid.push(score);
                }
            }
            let final_term = null;
            if (score_nodes_with_the_same_cid.length === 0) {
                //没有此计划课程代码的成绩记录
                if (plan_node.sterm && plan_node.sterm.length > 0) {
                    //如果有入选成绩，则取入选成绩的选修学期作为最终计入学分绩计算的学期
                    final_term = plan_node.sterm;
                } //没有入选成绩，此计划课程不计入学分绩计算
            } else {
                //存在此计划课程代码的成绩记录，取其中最早的成绩记录的选修学期作为最终计入学分绩计算的学期
                score_nodes_with_the_same_cid.sort(
                    (o1, o2) => o1.term.localeCompare(o2.term));
                final_term = score_nodes_with_the_same_cid[0].term;
            }
            plan_node.final_term = final_term;
        }

        //移除不计入学分绩计算的计划课程
        plan_nodes = plan_nodes.filter((plan) => !Util.isNullOrNaN(plan.final_term));

        if (use_plan_term) {
            //如果指定按计划应修学期来计算学分绩，则把计入学分绩计算的每一门计划课程的计入学期都设置为计划学期
            for (let plan of plan_nodes) {
                plan.final_term = plan.plan_term;
            }
        }

        //对于某些情况，例如入伍，可能会存在早于当前年级的选修学期/计划应修学期，因此应当生成一个新的学期列表，把这些不存在于参数传入的学期列表中的学期也添加到这个列表中
        let new_term_id_list = [];
        for (let plan of plan_nodes) {
            if (plan.final_term && !new_term_id_list.includes(plan.final_term)) {
                new_term_id_list.push(plan.final_term);
            }
        }

        //基于补充后的学期列表生成一个学年列表，后续将基于这个学年列表构造用于构建返回值的 <学年, 计划课程列表> Map
        let year_list = [];
        for (let term_id of new_term_id_list) {
            let year_code = "";
            try {
                year_code = term_id.slice(0, isInternational ? 4 : 9);
            } catch (e) {
                // console.error(e, e.stack, "Xfj", "Exception caught. Roll back to 4");
                year_code = term_id.slice(0, 4);
            }
            if (!year_list.includes(year_code)) {
                year_list.push(year_code);
            }
        }

        //构造用于构建返回值的 <学年, 计划课程列表> Map 的雏形，此时每一门计划课程尚未归入此 Map
        let year_plans_map = {};
        for (let year of year_list) {
            year_plans_map[year] = []
        }
        //将计入学分绩计算的每一门计划课程归入此 Map，注意：所有计入学分绩计算的计划课程归入完毕后，仍然可能会存在不包含任何计划课程的学年，那么这个学年最后的学分绩应当取默认值（例如 100 ）
        for (let plan of plan_nodes) {
            let year_code_of_plan = "";
            try {
                year_code_of_plan = plan.final_term.slice(0, isInternational ? 4 : 9);
            } catch (e) {
                // console.error(e, e.stack, "Xfj", "Exception caught. Roll back to 4");
                year_code_of_plan = plan.final_term.slice(0, 4);
            }
            year_plans_map[year_code_of_plan].push(plan);
        }

        //构建返回值雏形：<学年, 学年学分绩> 列表
        let res = [];

        //将 Map 中的每一个学年，都计算出它相应的学年学分绩，然后添加到返回值列表中
        //在计算每一个学年的学分绩的时候，如果这个学年包含计划课程，则顺便将这个学年的所有计划课程的总学分加到入学至今总学分中，方便后续计算入学至今学分绩
        let all_total_credit = 0.0;  //入学至今总学分
        for (let year_code in year_plans_map) {
            //取出此学年的计划课程列表
            let plans_list_of_year = year_plans_map[year_code];
            if (plans_list_of_year.length === 0) {
                //如果此学年不包含任何计划课程，则此学年学分绩直接设为 100
                res.push([year_code, 100.0]);
            } else {
                //如果此学年包含计划课程，则计算此学年的学年学分绩
                let total_credit_hour = 0.0;

                //生成学年总学分
                for (let p of plans_list_of_year) {
                    total_credit_hour += p.credit_hour;
                }
                //打印学年总学分
                // console.log("total_credit_hour", year_code + ": " + total_credit_hour);
                //添加到入学至今总学分
                all_total_credit += total_credit_hour;

                //填写每一门计划课程的计入学分绩计算的分数及其类型
                for (let p of plans_list_of_year) {
                    if (p.sterm && p.sterm.length > 0) {
                        //如果有入选成绩，照抄入选成绩
                        p.final_score = p.s_score;
                        p.final_score_type = p.s_score_type;
                    } else {
                        //没有入选成绩，取最好的成绩记录
                        let my_scores = score_nodes.filter(
                            score => score.course_id === p.plan_course_id
                        ).sort((o1, o2) => {
                            //成绩高的排前面
                            o2.score - o1.score
                        });
                        let selected = my_scores[0];
                        p.final_score = selected.score;
                        p.final_score_type = selected.score_type;
                    }
                }

                //生成每一门计划课程的学年加权绩点，并累加到初始值为0的学年学分绩上
                let year_grade_point = 0.0;  //学年学分绩
                for (let p of plans_list_of_year) {
                    year_grade_point += p.genGradePoint(total_credit_hour);
                }

                if (total_credit_hour <= 0) {
                    //此学年有课程但无学分，此学年学分绩直接设为 100
                    res.push([year_code, 100.0]);
                } else {
                    res.push([year_code, year_grade_point]);
                }
            }
        }

        //打印出 基于各学年总学分计算学分绩的 Map
        // let map_detail_base_year = JSON.stringify(year_plans_map, null, 2);
        // map_detail_base_year = this.beauty(map_detail_base_year, "学年");
        // console.log("XFJ-map-base-year", map_detail_base_year);

        let all_xfj = 100.0;  //入学至今学分绩
        if (all_total_credit > 0) {
            //如果入学至今有任何计划课程计入学分绩计算，那么将入学至今学分绩设为0，
            //重新生成每一门计划课程的入学至今加权绩点，并累加到入学至今学分绩上
            all_xfj = 0.0;
            for (let p of plan_nodes) {
                all_xfj += p.genGradePoint(all_total_credit);
            }
            // 打印入学至今总学分
            // console.log("total_credit_hour", "since_enrollment: " + all_total_credit);
        }
        //对返回值列表排序
        res.sort(([yearCode1], [yearCode2]) => yearCode1.localeCompare(yearCode2) * -1);
        // 换成亲和的名字
        for (let i = 0; i < res.length; i++) {
            const r = res[i]
            r[0] = [
                "大一",
                "大二",
                "大三",
                "大四",
                "大五",
                "大六",
                "大七",
                "大八",
                "大九",
                "大十",
                "大十一",
                "大十二",
            ][res.length - 1 - i]
        }
        //在返回值列表的首位添加入学至今学分绩
        res.unshift(["入学至今", all_xfj]);
        //不要入学至今学分绩
        res.splice(0, 1)
        // 加上转专业计算的学分绩
        res.unshift(["转专业计算的学分绩", changingMajorCredit])
        // 加上毕业计算的学分绩
        res.unshift(["毕业计算的学分绩", graduationCredit])
        // 四舍五入
        for (const r of res) {
            if (r[1] != null) {
                r[1] = Math.round(r[1] * 100) / 100
            }
        }
        //打印出基于入学至今总学分计算学分绩的 Map
        // let map_detail_base_enrollment = JSON.stringify(year_plans_map, null, 2)
        // map_detail_base_enrollment = this.beauty(map_detail_base_enrollment, "入学至今");
        // console.log("XFJ-map-base-enrollment", map_detail_base_enrollment);
        //打印出成绩单
        // let scores_detail = JSON.stringify(score_nodes, null, 2);
        // console.log("XFJ-scores_nodes", scores_detail);
        return res;
    },

    /**
     * 将类方法中用到的 JSON 字符串美化后返回
     * @param {string} s 需要美化的 JSON 字符串
     * @param {string} type “入学至今”或“学年”
     * @returns {string} 美化后的 JSON 字符串
     */
    beauty(s, type) {
        s = s.replace("credit_hour", "学分");
        s = s.replace("final_level_score", "计算学分绩的分数");
        s = s.replace("final_score_type", "成绩类型");
        s = s.replace("final_score", "成绩");
        s = s.replace("final_term", "计入哪个学期的学分绩计算");
        s = s.replace("final_weighted_grade_point", type + "加权学分");
        s = s.replace("plan_course_id", "课程代码");
        s = s.replace("plan_course_name", "课程名称");
        s = s.replace("plan_term", "计划应修学期");
        s = s.replace("s_score_type", "选修成绩类型");
        s = s.replace("s_score", "选修成绩");
        s = s.replace("sterm", "选修学期");
        return s;
    },
}
