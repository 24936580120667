// noinspection HttpUrlsUsage

export default {
  WebVPN: {
    URL: "https://v.guet.edu.cn/login?cas_login=true",
    Test: (str) => str.includes("资源站点"),
  },
  ICampus: {
    URL: "http://icampus.guet.edu.cn/GuetAccount/CasLogin",
    Test: (str) => str.includes("_sui_webpack_remote_path"),
  },
  AAS: {
    URL: "https://bkjw.guet.edu.cn",
    Test: (str) =>
      str.includes("用户类型：学生") ||
      str.toLowerCase().includes("maindesktop".toLowerCase()),
  },
  AASSupWisdom: {
    URL: "https://bkjwtest.guet.edu.cn/student/sso/login",
    Test: (str) => str.includes("教学周"),
  },
  CASSecurityCenter: {
    URL: "https://cas.guet.edu.cn/personalInfo/personCenter/index.html",
    Test: () => true,
  },
  CAS: {
    URL: "https://cas.guet.edu.cn",
    Test: () => true,
  },
};
