<template>
  <div class="fc">
    <el-progress
        :style="{
          '--override-el-progress-bar-radius-left': radiusLeft,
          '--override-el-progress-bar-radius-right': radiusRight,
          '--override-el-progress-bar-foreground': calForeground,
          '--override-el-progress-bar-background': calBackground,
          '--override-el-progress-bar-height': height,
        }"
        :type="'line'"
        :percentage="percentageComputed"
        :indeterminate="useAnimation"
        :duration="durationS"
        :show-text="false"
        class="flex-shrink-0 flex-grow-1"
    />
  </div>
</template>

<script>
import {inject} from "vue";
import Util from "@/js/util";

const PredefinedType = {
  Progress: 'progress',
  Loading: 'loading',
  Success: 'success',
  Fail: 'fail',
  Warning: 'warning',
}
const allTypes = [
  PredefinedType.Progress,
  PredefinedType.Loading,
  PredefinedType.Success,
  PredefinedType.Fail,
  PredefinedType.Warning,
]
const animationTypes = [
  PredefinedType.Loading
]
const percentageRequiredTypes = [
  PredefinedType.Progress
]
export default {
  name: "ProgressBar",
  PredefinedType,
  setup() {
    return {
      gNumber: inject('gNumber'),
      gColor: inject('gColor'),
    }
  },
  props: {
    type: {
      type: String,
      default() {
        return PredefinedType.Loading
      },
      validator(value) {
        return allTypes.includes(value)
      },
    },
    percentage: Number,
    showLeftRadius: {
      type: Boolean,
      default() {
        return true
      },
    },
    showRightRadius: {
      type: Boolean,
      default() {
        return true
      },
    },
    background: String,
    foreground: String,
    durationS: {
      type: Number,
      default() {
        return 2.2
      },
    },
    height: {
      type: String,
      default() {
        return "var(--vh)"
      },
    },
  },
  computed: {
    useAnimation() {
      return animationTypes.includes(this.type)
    },
    percentageComputed() {
      if (percentageRequiredTypes.includes(this.type)) {
        return this.percentage
      } else {
        return 100
      }
    },
    radiusLeft() {
      return this.showLeftRadius ? `calc(${this.height} / 2)` : "0"
    },
    radiusRight() {
      return this.showRightRadius ? `calc(${this.height} / 2)` : "0"
    },
    calBackground() {
      return Util.empty(this.background) ? this.gColor.ProgressBar.Background : this.background
    },
    calForeground() {
      let res = this.foreground
      if (Util.empty(res)) {
        switch (this.type) {
          default:
          case PredefinedType.Progress:
          case PredefinedType.Loading:
            res = this.gColor.ProgressBar.Loading
            break
          case PredefinedType.Success:
            res = this.gColor.ProgressBar.Success
            break
          case PredefinedType.Fail:
            res = this.gColor.ProgressBar.Fail
            break
          case PredefinedType.Warning:
            res = this.gColor.ProgressBar.Warning
            break
        }
      }
      return res
    },
  },
}
</script>

<style scoped>

</style>