import HelperError from "./helperError.js";

export default class extends HelperError {
  /**
   * 学号或教务系统密码错误
   */
  constructor() {
    super({
      code: "aaw_password_error",
      tip: "教务系统用户名或密码错误，请仔细检查输入~",
      httpCode: 401,
    });
  }
}
