import Util from "@/js/util";
import Schedule from "@/js/schedule";

const PredefinedMode = {
    NONE: -1,
    LIGHT: 0,
    NO_TRANSPARENCY: 1,
}

export default function ColorSelector() {
    this.selectColorClassArray = selectColorClassArray
    this.randomColorCode = randomColorCode

    this.used = new Set
    this.cnoMap = new Map
    this.expIDMap = new Map
    this.unionIDMap = new Map
    this.minColorInclude = 1
    this.maxColorExclude = 9
    this.colorClassPrefix = "colorful-node-"

    this.PredefinedMode = PredefinedMode
}

function randomColorCode() {
    if (this.used.size === this.maxColorExclude - this.minColorInclude) {
        this.used.clear()
    }
    let colorCode
    do {
        colorCode = Util.randomInt(this.minColorInclude, this.maxColorExclude)
    } while (this.used.has(colorCode))
    this.used.add(colorCode)
    return colorCode
}

// 使用事件列表的全部事件判断激活状态，但仅使用第一个事件作为生成新颜色的依据。
//
// 联合 ID 是颜色复用的依据。
function selectColorClassArray(nanoNow, singleEventList, unionID, useActive = true, mode = PredefinedMode.NONE) {
    const res = []

    if (useActive) {
        for (const singleEvent of singleEventList) {
            if (singleEvent.isActive(nanoNow)) {
                res.push("active")
                break
            }
        }
    }
    const singleEvent = singleEventList[0]

    switch (mode) {
        case PredefinedMode.LIGHT:
            res.push("light")
            break
        case PredefinedMode.NO_TRANSPARENCY:
            res.push("no-transparency")
            break
    }

    let colorCode
    if (this.unionIDMap.has(unionID)) {
        colorCode = this.unionIDMap.get(unionID)
    } else {
        if (singleEvent.id.startsWith(Schedule.ID.Prefix.Course)) {// 课程
            const cno = singleEvent.data.courseNo
            if (!this.cnoMap.has(cno)) {
                this.cnoMap.set(cno, this.randomColorCode())
            }
            colorCode = this.cnoMap.get(cno)
        } else if (singleEvent.id.startsWith(Schedule.ID.Prefix.Experiment)) {// 实验
            const expID = singleEvent.data.id
            if (!this.expIDMap.has(expID)) {
                this.expIDMap.set(expID, this.randomColorCode())
            }
            colorCode = this.expIDMap.get(expID)
        } else {
            colorCode = this.randomColorCode()
        }
        this.unionIDMap.set(unionID, colorCode)
    }
    res.push(this.colorClassPrefix + colorCode)

    return res
}