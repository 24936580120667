export default function PromiseSemaphore(available) {
  this.available = available;
  this.waitingConsumers = new Map();
  this.parent = null;
}
PromiseSemaphore.prototype = {
  async Get(n) {
    if (this.available >= n) {
      this.available -= n;
      return null;
    }
    return new Promise((rs) => {
      if (this.available >= n) {
        this.available -= n;
        return rs();
      }
      this.waitingConsumers.set(rs, n);
    });
  },
  Post(n) {
    this.available += n;
    if (this.available <= 0) {
      return;
    }
    for (const [rs, need] of this.waitingConsumers) {
      if (this.available >= need) {
        rs();
        this.available -= need;
        this.waitingConsumers.delete(rs);
      }
    }
  },
  Reduce(n) {
    this.available -= n;
  },
  Then(child) {
    if (child != null) {
      child.parent = this;
    }
    return this;
  },
  async Acquire() {
    let s = this;
    while (s != null) {
      await s.Get(1);
      s = s.parent;
    }
  },
  Release() {
    let s = this;
    while (s != null) {
      s.Post(1);
      s = s.parent;
    }
  },
};
