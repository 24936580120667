<template>
  <div>
    <div v-for="grade in gradeList" :key="grade.termCode + grade.name + grade.gradeType + grade.examType" class="fc">
      <ChildGap/>
      <div class="fr">
        <div class="flex-basis-0 fc function-menu-grade" style="flex: 4 4">
          <div class="margin-top-bottom-auto-in-fc margin-left-right-auto-in-fc function-menu-grade-name"
               :class="{important: grade.important}"
          >
            {{ grade.name }}
          </div>
        </div>
        <div class="flex-basis-0 fc function-menu-grade" style="flex: 1.5 1.5">
          <div class="margin-top-bottom-auto-in-fc function-menu-grade-final-grade"
               :class="[calGradeClass(grade.isGradeSuccessful)]"
          >
            {{ grade.finalGrade }}
          </div>
        </div>
        <div class="flex-basis-0 fc function-menu-grade" style="flex: 1.5 1.5">
          <div class="margin-top-bottom-auto-in-fc">
            {{ grade.dailyPerformance }}
          </div>
        </div>
        <div class="flex-basis-0 fc function-menu-grade" style="flex: 1.5 1.5">
          <div class="margin-top-bottom-auto-in-fc">
            {{ grade.experimentPerformance }}
          </div>
        </div>
        <div class="flex-basis-0 fc function-menu-grade" style="flex: 1.5 1.5">
          <div class="margin-top-bottom-auto-in-fc">
            {{ grade.examPerformance }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChildGap from "@/component/function_menu/element/ChildGap";

export default {
  name: "Grade",
  components: {ChildGap},
  props: {
    gradeList: {
      type: Array,
      default() {
        return []
      },
    },
  },
  methods: {
    calGradeClass(isGradeSuccessful) {
      if (isGradeSuccessful == null) {
        return ""
      } else {
        return isGradeSuccessful ? "successful" : "failure"
      }
    }
  },
}
</script>

<style scoped>

</style>