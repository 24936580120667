export default class extends Error {
  constructor({
    code = "error",
    tip = "出错啦，再试一次吧~ 若仍未解决，请联系开发者~",
    httpCode = 500,
    details = "",
    mayCache = true,
    retryAfter,
    cause,
  } = {}) {
    super();
    this.code = code;
    this.tip = tip;
    this.httpCode = httpCode;
    this.details = details;
    this.mayCache = mayCache;
    this.retryAfter = retryAfter;
    this.cause = cause;
  }
}
