<script>
import Util from "@/js/util";

export default {
  name: "mascot",
  props: {
    text: {
      type: String,
      default() {
        return undefined;
      },
    },
  },
  data() {
    return {
      emptyIconID: this.randomIcon(),
    };
  },
  methods: {
    randomIcon() {
      const iconSet = [
        "alibaba-icon-GULULU-magic-ball",
        "alibaba-icon-GULULU-ice-and-fire",
        "alibaba-icon-GULULU-peach",
        "alibaba-icon-GULULU-ice-milkshake",
        "alibaba-icon-GULULU-rainbow-jar",
        "alibaba-icon-GULULU-lemon",
        "alibaba-icon-GULULU-milk",
        "alibaba-icon-GULULU-come-on",
        "alibaba-icon-GULULU-teacher",
        "alibaba-icon-GULULU-rainbow-baby",
        "alibaba-icon-GULULU-god-of-liberty",
        "alibaba-icon-Gululu-mummy",
      ];
      return iconSet[Util.getRandomInt(0, iconSet.length)];
    },
  },
};
</script>

<template>
  <div>
    <div style="display: flex; flex-direction: column; align-items: center">
      <svg
        class="align-self-center alibaba-icon mascot-icon"
        aria-hidden="true"
        @click.prevent.stop="emptyIconID = randomIcon()"
      >
        <use :xlink:href="'#' + emptyIconID"></use>
      </svg>
      <span
        class="align-self-center mascot-icon-text unselectable-text"
        v-if="text"
        >{{ text }}</span
      >
    </div>
  </div>
</template>

<style scoped></style>
