import Util from '@/js/util';

export default class Score {
    #course_id;  // 课程代码
    #course_no;  // 课号
    #term;  // 选修此课程的学期
    #score;  // 分数，数值类型，未经过五级制转换
    #score_type;  // 分数类型，1 代表五级制，其他代表不转换

    /**
     * @param {string} course_id 课程代码
     * @param {string} course_no 课号
     * @param {string} term 选修此课程的学期
     * @param {double} score 选修此课程的学期
     * @param {number} score_type 分数类型，1 代表五级制，其他代表不转换
     */
    constructor(course_id, course_no, term, score, score_type) {
        if (typeof course_id !== "string" || Util.isNullOrNaN(course_id))
            throw TypeError("Plan 构造器传入的参数 course_id 类型错误");
        if (typeof course_no !== "string" || Util.isNullOrNaN(course_no))
            throw TypeError("Plan 构造器传入的参数 course_no 类型错误");
        if (typeof term !== "string" || Util.isNullOrNaN(term))
            throw TypeError("Plan 构造器传入的参数 term 类型错误");
        if (typeof score !== "number" || Util.isNullOrNaN(score))
            throw TypeError("Plan 构造器传入的参数 score 类型错误");
        if (typeof score_type !== "number" || Util.isNullOrNaN(score_type))
            throw TypeError("Plan 构造器传入的参数 score_type 类型错误");

        this.#course_id = course_id;
        this.#course_no = course_no;
        this.#term = term;
        this.#score = score;
        this.#score_type = score_type;
    }

    toString() {
        return "Score{" +
            "course_id='" + this.#course_id + '\'' +
            ", course_no='" + this.#course_no + '\'' +
            ", term='" + this.#term + '\'' +
            ", score=" + this.#score +
            ", score_type=" + this.#score_type +
            '}';
    }

    toJSON() {
        return {
            course_id: this.#course_id,
            course_no: this.#course_no,
            term: this.#term,
            score: this.#score,
            score_type: this.#score_type
        };
    }

    get course_id() {
        return this.#course_id;
    }

    set course_id(course_id) {
        if (typeof (course_id) !== "string" || isNaN(course_id)) {
            throw TypeError("course_id 应为字符串");
        }
        this.#course_id = course_id
    }

    getCourse_id() {
        return this.course_id;
    }

    setCourse_id(course_id) {
        this.course_id = course_id;
    }

    get course_no() {
        return this.#course_no;
    }

    set course_no(course_no) {
        if (typeof (course_no) !== "string" || isNaN(course_no)) {
            throw TypeError("course_no 应为字符串");
        }
        this.#course_no = course_no
    }

    getCourse_no() {
        return this.course_no;
    }

    setCourse_no(course_no) {
        this.course_no = course_no;
    }

    get term() {
        return this.#term;
    }

    set term(term) {
        if (typeof (term) !== "string" || isNaN(term)) {
            throw TypeError("term 应为字符串");
        }
        this.#term = term
    }

    getTerm() {
        return this.term;
    }

    setTerm(term) {
        this.term = term;
    }

    get score() {
        return this.#score;
    }

    set score(score) {
        if (typeof (score) !== "number" || isNaN(score)) {
            throw TypeError("score 应为数值型");
        }
        this.#score = score
    }

    getScore() {
        return this.score;
    }

    setScore(score) {
        this.score = score;
    }

    get score_type() {
        return this.#score_type;
    }

    set score_type(score_type) {
        if (typeof (score_type) !== "number" || isNaN(score_type)) {
            throw TypeError("score_type 应为数值型");
        }
        this.#score_type = score_type
    }

    getScore_type() {
        return this.score_type;
    }

    setScore_type(score_type) {
        this.score_type = score_type;
    }
}