import Util from "@/js/util";
import JSUtil from "js-util";
import Predefined from "@/js/predefined";
import Schedule from "@/js/schedule";
import Require from "@/js/require";
import Event from "@/js/type/Event";
import SingleEvent from "@/js/type/SingleEvent";
import Credit_point from "@/js/credit_point";
import Json from "@/js/json";
import Main from "@/main";
import LocalStorage from "@/js/LocalStorage";

const StoreKey = "data";
const LeaveKey = "leave";
const LoadKey = "load";

let backupString;

export default {
  // 备份当前 localStorage 数据到内存变量中。此方法不会自动刷新数据缓存
  async backup() {
    backupString = await LocalStorage.getItem(StoreKey);
  },
  // 从内存变量中恢复 localStorage 数据。此方法不会自动刷新数据缓存
  async restore() {
    return LocalStorage.setItem(StoreKey, backupString);
  },
  leave() {
    localStorage.setItem(LeaveKey, Json.toJSON(Date.now()));
  },
  lastLeaveTimestampMS() {
    const msStr = localStorage.getItem(LeaveKey);
    if (msStr == null) {
      return null;
    } else {
      return Json.fromJSON(msStr);
    }
  },
  async load() {
    return LocalStorage.setItem(LoadKey, Json.toJSON(Date.now()));
  },
  async lastLoadTimestampMS() {
    const msStr = await LocalStorage.getItem(LoadKey);
    if (msStr == null) {
      return null;
    } else {
      return Json.fromJSON(msStr);
    }
  },
  // 此方法用于：登出所有用户。如果传入的用户名是有效的，那么这个用户名所对应的用户的所有数据会被删除。此方法不会自动刷新数据缓存
  async logout(username) {
    await this.deactivateAllUser();
    const mapString = await LocalStorage.getItem(StoreKey);
    if (mapString) {
      const mapObj = Json.fromJSON(mapString);
      if (mapObj.has(username)) {
        mapObj.delete(username);
        return LocalStorage.setItem(StoreKey, Json.toJSON(mapObj));
      }
    }
  },
  // 此方法用于：用户登录成功后存入一些用户信息和教务数据。不会自动刷新数据缓存
  async loginStore(username, vpnPassword, aawPassword, active, aaData) {
    // 计算学分绩
    {
      aaData.creditPointList =
        Credit_point.CalculateSchoolYearCreditPoint(aaData);
    }
    const mapString = await LocalStorage.getItem(StoreKey);
    const mapObj = mapString ? Json.fromJSON(mapString) : new Map();
    if (!mapObj.has(username)) {
      mapObj.set(username, {
        nonAAData: {
          eventList: [],
        },
        aaData: {},
      });
    }
    const userdata = mapObj.get(username);
    userdata.nonAAData.active = active;
    userdata.nonAAData.vpnPassword = vpnPassword;
    userdata.nonAAData.aawPassword = aawPassword;
    userdata.aaData = aaData;
    return LocalStorage.setItem(StoreKey, Json.toJSON(mapObj));
  },
  // 激活用户。不会自动刷新数据缓存
  async activateUser(username) {
    const mapString = await LocalStorage.getItem(StoreKey);
    if (mapString) {
      const mapObj = Json.fromJSON(mapString);
      if (mapObj.has(username)) {
        mapObj.get(username).nonAAData.active = true;
        return LocalStorage.setItem(StoreKey, Json.toJSON(mapObj));
      }
    }
  },
  // 取消激活所有用户。不会自动刷新数据缓存
  async deactivateAllUser() {
    const mapString = await LocalStorage.getItem(StoreKey);
    if (mapString) {
      const mapObj = Json.fromJSON(mapString);
      for (const [, userdata] of mapObj) {
        userdata.nonAAData.active = false;
      }
      return LocalStorage.setItem(StoreKey, Json.toJSON(mapObj));
    }
  },
  // 取消激活某个用户。不会自动刷新数据缓存
  async deactivateUser(username) {
    const mapString = await LocalStorage.getItem(StoreKey);
    if (mapString) {
      const mapObj = Json.fromJSON(mapString);
      if (mapObj.has(username)) {
        let ud = mapObj.get(username);
        if (ud == null) {
          ud = {};
          mapObj.set(username, ud);
        }
        if (ud.nonAAData == null) {
          ud.nonAAData = {};
        }
        ud.nonAAData.active = false;
        return LocalStorage.setItem(StoreKey, Json.toJSON(mapObj));
      }
    }
  },
  // 设置用户的国际学院状态。不会自动刷新数据缓存
  async setUserIsInternational(username, isInternational) {
    const mapString = await LocalStorage.getItem(StoreKey);
    if (mapString) {
      const mapObj = Json.fromJSON(mapString);
      if (mapObj.has(username)) {
        mapObj.get(username).aaData.isInternational = isInternational;
        return LocalStorage.setItem(StoreKey, Json.toJSON(mapObj));
      }
    }
  },
  // 记录用户选择的颜色模式
  async setUserPreferredColorMode(username, userSelectedDarkMode) {
    const mapString = await LocalStorage.getItem(StoreKey);
    if (mapString) {
      const mapObj = Json.fromJSON(mapString);
      if (mapObj.has(username)) {
        let mode;
        switch (userSelectedDarkMode) {
          default:
          case undefined:
          case null:
            mode = Predefined.UserPreferredColorMode.System;
            break;
          case true:
            mode = Predefined.UserPreferredColorMode.Dark;
            break;
          case false:
            mode = Predefined.UserPreferredColorMode.Light;
            break;
        }
        mapObj.get(username).nonAAData.userPreferredColorMode = mode;
        return LocalStorage.setItem(StoreKey, Json.toJSON(mapObj));
      }
    }
  },
  // 读取当前用户喜爱的启动页名称。如果不存在当前用户，返回 null；如果当前用户没有设置喜爱的启动页名称，也会返回 null
  async getCurrentUserFavoriteStartPageName() {
    const ud = await this.getActiveUserAndHisData();
    if (ud == null) {
      return null;
    } else {
      const [, d] = ud;
      if (d.favoriteStartPageName == null) {
        return null;
      } else {
        return d.favoriteStartPageName;
      }
    }
  },
  // 设置用户喜爱的启动页名称。不会自动刷新数据缓存，但是会自动刷新路由器
  async setUserFavoriteStartPageName(username, favoriteStartPageName) {
    const mapString = await LocalStorage.getItem(StoreKey);
    if (mapString) {
      const mapObj = Json.fromJSON(mapString);
      if (mapObj.has(username)) {
        mapObj.get(username).nonAAData.favoriteStartPageName =
          favoriteStartPageName;
        await LocalStorage.setItem(StoreKey, Json.toJSON(mapObj));
        return Main.refreshRouter();
      }
    }
  },
  // 从本地存储中读取数据并反序列化，然后返回数据对象。
  // 返回类型：Map，键是用户名，值是用户数据对象。
  // 注意：此函数返回的用户数据对象已经融合了（不再区分）教务数据和非教务数据。
  async readAll() {
    const data = await LocalStorage.getItem(StoreKey);
    if (Util.empty(data)) {
      return LocalStorage.removeItem(StoreKey).then(() => undefined);
    } else {
      const dataMap = Json.fromJSON(data);
      const res = new Map();
      for (const [username, userdata] of dataMap) {
        const aaData = userdata.aaData;
        const nonAAData = userdata.nonAAData;
        res.set(username, {
          active: nonAAData.active,
          vpnPassword: nonAAData.vpnPassword,
          aawPassword: nonAAData.aawPassword,
          userPreferredColorMode: nonAAData.userPreferredColorMode,
          eventList: nonAAData.eventList,
          favoriteStartPageName: nonAAData.favoriteStartPageName,
          lastUpdateUnixNanoTimestamp: aaData.lastUpdateUnixNanoTimestamp,
          isInternational: aaData.isInternational,
          role:
            aaData.role == null
              ? aaData.isInternational
                ? Predefined.Role.InternationalStudent
                : Predefined.Role.Student
              : aaData.role,
          creditPointList: aaData.creditPointList,
          termList: aaData.termList,
          periodList: aaData.periodList,
          courseList: aaData.courseList,
          expList: aaData.expList,
          examList: aaData.examList,
          personalInfoPerson: aaData.personalInfoPerson,
          personalInfoStu: aaData.personalInfoStu,
          validCreditList: aaData.validCreditList,
          planCourseGradeList: aaData.planCourseGradeList,
          gradeList: aaData.gradeList,
          cetList: aaData.cetList,
          selectedCourseList: aaData.selectedCourseList,
          makeUpExamList: aaData.makeUpExamList,
          expGradeList: aaData.expGradeList,
          graduationInformation: aaData.graduationInformation,
          graduationRequirementList: aaData.graduationRequirementList,
          innovationPointInformation: aaData.innovationPointInformation,
          changingMajorInformation: aaData.changingMajorInformation,
        });
      }
      return res;
    }
  },
  // 获取当前用户的数据
  async getActiveUserAndHisData() {
    const d = await this.readAll();
    if (d) {
      for (const [k, v] of d) {
        if (v.active) {
          return [k, v];
        }
      }
      return null;
    } else {
      return null;
    }
  },
  // 获取解析后的当前用户数据
  async parseData() {
    const data = await this.getActiveUserAndHisData();
    if (!data) {
      return null;
    }
    const [username, d] = data;
    let res = {};
    // 解析用户名和密码
    {
      res.username = username;
      res.vpnPassword = d.vpnPassword;
      res.aawPassword = d.aawPassword;
    }
    // 解析国际学院
    {
      res.isInternational = d.isInternational;
    }
    // 解析身份
    {
      res.role = d.role;
    }
    // 解析最喜爱的启动页
    {
      res.favoriteStartPageName = d.favoriteStartPageName;
    }
    // 解析深色模式
    {
      res.userPreferredColorMode = d.userPreferredColorMode;
    }
    // 解析学分绩
    {
      res.creditPointList = d.creditPointList;
    }
    // 解析学期列表
    {
      res.termList = JSUtil.ParseTermList(d.termList);
    }
    // 解析时间段列表
    {
      res.periodList = [];
      const periodList = Util.getEmptyArrayFromNull(d.periodList);
      periodList.unshift({
        name: "before",
        description: "未定义",
        start: {
          hour: 0,
          minute: 0,
        },
        end: {
          hour: 0,
          minute: 0,
        },
      });
      periodList.forEach((p) => {
        const startNanoOffset = Require.number(
          p.start.hour * Predefined.Hour.Nano +
            p.start.minute * Predefined.Minute.Nano
        );
        const endNanoOffset = Require.number(
          p.end.hour * Predefined.Hour.Nano +
            p.end.minute * Predefined.Minute.Nano
        );
        res.periodList.push({
          name: Require.string(p.name),
          description: Require.string(p.description),
          start: Require.object({
            hour: Require.number(p.start.hour),
            minute: Require.number(p.start.minute),
          }),
          end: Require.object({
            hour: Require.number(p.end.hour),
            minute: Require.number(p.end.minute),
          }),
          startNanoOffset: startNanoOffset,
          endNanoOffset: endNanoOffset,
          durationNano: Require.number(endNanoOffset - startNanoOffset),
        });
      });
    }
    // 解析事件列表
    {
      res.eventList = [];
      Util.getEmptyArrayFromNull(d.eventList).forEach((e) => {
        const unixNanoList = [];
        for (const unixNano of Require.array(e.unixNanoList)) {
          unixNanoList.push(
            Require.object({
              start: Require.number(Require.object(unixNano).start),
              end: Require.number(Require.object(unixNano).end),
            })
          );
        }
        const participantList = [];
        for (const participant of Require.array(e.participantList)) {
          participantList.push(Require.string(participant));
        }
        res.eventList.push({
          id: Require.string(e.id),
          unixNanoList: Require.array(unixNanoList),
          location: Require.string(e.location),
          name: Require.string(e.name),
          participantList: Require.array(participantList),
          singleCharMark: Require.string(e.singleCharMark),
          notes: Require.string(e.notes),
        });
      });
    }
    // 解析已选课程列表
    {
      res.selectedCourseList = [];
      Util.getEmptyArrayFromNull(d.selectedCourseList).forEach((sc) => {
        res.selectedCourseList.push({
          name: Require.string(sc.cname),
          credit: Require.number(sc.xf),
          school: Require.string(sc.dpt),
          schoolNo: Require.string(sc.dptno),
          majorNo: Require.string(sc.spno),
          majorName: Require.string(sc.spname),
          courseCode: Require.string(sc.courseid),
          courseType: Require.string(
            Util.concatOrMergeTwoString(
              Require.string(sc.tname),
              ",",
              Require.string(sc.tname1)
            )
          ),
          courseNo: Require.string(sc.courseno),
          grade: Require.number(sc.grade),
          termCode: Require.string(sc.term),
          studyType: Require.string(sc.stype),
          selectionTime: Require.string(sc.xksj),
          selectionIP: Require.string(sc.ip),
          notes: Require.string(
            Util.concatOrMergeTwoString(
              Require.string(sc.comm),
              "，",
              Require.string(sc.demo)
            )
          ),
          courseNotes: Require.string(sc.demo),
        });
      });
    }
    // 解析课程列表
    {
      res.courseList = [];
      Util.getEmptyArrayFromNull(d.courseList).forEach((c) => {
        let cno = Require.string(c.courseno);
        let scList = res.selectedCourseList.filter((e) => e.courseNo === cno);
        let scNotes;
        if (scList.length > 0) {
          scNotes = scList[0].courseNotes;
        }
        res.courseList.push({
          id: Require.string(c.id),
          name: Require.string(c.cname),
          courseNo: Require.string(cno),
          courseCode: Require.string(c.courseid),
          courseType: Require.string(c.tname),
          assessmentType: Require.string(c.examt),
          schoolNo: Require.string(c.dptno),
          schoolName: Require.string(c.dptname),
          majorNo: Require.string(c.spno),
          majorName: Require.string(c.spname),
          grade: Require.number(c.grade),
          teacherName: Require.string(c.name),
          location: Require.string(c.croomno),
          termCode: Require.string(c.term),
          startWeek: Require.number(c.startweek),
          endWeek: Require.number(c.endweek),
          oddWeekOnly: Require.boolean(c.oddweek),
          weekday: Require.number(c.week),
          period: Require.number(c.seq),
          credit: Require.number(c.xf),
          notes: Util.concatOrMergeTwoString(
            Require.string(c.comm),
            ",",
            Require.string(scNotes)
          ),
        });
      });
    }
    // 解析实验列表
    {
      res.expList = [];
      Util.getEmptyArrayFromNull(d.expList).forEach((exp) => {
        res.expList.push({
          id: Require.string(exp.labid),
          name: Require.string(exp.itemname),
          courseName: Require.string(exp.cname),
          courseCode: Require.string(exp.courseid),
          courseNo: Require.string(exp.courseno),
          majorNo: Require.string(exp.spno),
          majorName: Require.string(exp.spname),
          grade: Require.number(exp.grade),
          termCode: Require.string(exp.term),
          week: Require.number(exp.zc),
          weekday: Require.number(exp.xq),
          period: Require.number(exp.jc),
          teacherName: Require.string(exp.name),
          location: Require.string(exp.srdd),
          batchNo: Require.string(exp.bno),
          notes: Require.string(exp.comm),
        });
      });
    }
    // 解析考试列表
    {
      res.examList = [];
      Util.getEmptyArrayFromNull(d.examList).forEach((exam) => {
        // 我已经不记得为什么要过滤掉 examstate 是 2 的记录，但是这是安卓 APP 的做法
        if (Number(exam.examstate) !== 2) {
          res.examList.push({
            name: Require.string(exam.cname),
            courseCode: Require.string(exam.courseid),
            courseNo: Require.string(exam.courseno),
            type: Require.number(exam.examstate),
            teacherName: Require.string(exam.name),
            schoolNo: Require.string(exam.dptno),
            school: Require.string(exam.dpt),
            majorNo: Require.string(exam.spno),
            majorName: Require.string(exam.spname),
            grade: Require.number(exam.grade),
            termCode: Require.string(exam.term),
            week: Require.number(exam.zc),
            weekday: Require.number(exam.xq),
            period: Require.number(exam.ksjc),
            date: Require.string(exam.examdate),
            time: Require.string(exam.kssj),
            location: Require.string(exam.croomno),
            notes: Require.string(exam.comm),
          });
        }
      });
      // 合并相同考试的位置
      {
        res.examList.sort((a, b) => {
          let res = b.termCode.localeCompare(a.termCode);
          if (res === 0) {
            res = b.courseNo.localeCompare(a.courseNo);
          }
          if (res === 0) {
            res = b.type - a.type;
          }
          if (res === 0) {
            res = b.date.localeCompare(a.date);
          }
          if (res === 0) {
            res = b.time.localeCompare(a.time);
          }
          if (res === 0) {
            res = b.week - a.week;
          }
          if (res === 0) {
            res = b.weekday - a.weekday;
          }
          if (res === 0) {
            res = b.period - a.period;
          }
          return res;
        });
        const combinedList = [];
        let exam = null;
        for (let i = 0; i < res.examList.length; i++) {
          const e = res.examList[i];
          if (exam == null) {
            exam = e;
          } else if (
            e.termCode !== exam.termCode ||
            e.courseNo !== exam.courseNo ||
            e.type !== exam.type ||
            e.date !== exam.date ||
            e.time !== exam.time ||
            e.week !== exam.week ||
            e.weekday !== exam.weekday ||
            e.period !== exam.period
          ) {
            combinedList.push(exam);
            exam = e;
          } else {
            exam.location += `，${e.location}`;
          }
        }
        if (exam) {
          combinedList.push(exam);
        }
        res.examList = combinedList;
      }
    }
    // 解析补考缓考列表
    {
      res.makeUpExamList = [];
      Util.getEmptyArrayFromNull(d.makeUpExamList).forEach((mue) => {
        res.makeUpExamList.push({
          name: Require.string(mue.cname),
          schoolNo: Require.string(mue.dptno),
          schoolName: Require.string(mue.dptname),
          majorNo: Require.string(mue.spno),
          majorName: Require.string(mue.spname),
          courseCode: Require.string(mue.courseid),
          courseNo: Require.string(mue.courseno),
          location: Require.string(
            Util.concatOrMergeTwoString(
              Require.string(mue.croomno),
              ":",
              Require.string(mue.croomname)
            )
          ),
          grade: Require.number(mue.grade),
          termCode: Require.string(mue.term),
          date: Require.string(mue.examdate),
          time: Require.string(mue.kssj),
          type: Require.string(mue.lb),
          notes: Require.string(mue.comm),
        });
      });
      // 合并相同考试的位置
      {
        res.makeUpExamList.sort((a, b) => {
          let res = b.termCode.localeCompare(a.termCode);
          if (res === 0) {
            res = b.courseNo.localeCompare(a.courseNo);
          }
          if (res === 0) {
            res = b.type.localeCompare(a.type);
          }
          if (res === 0) {
            res = b.date.localeCompare(a.date);
          }
          if (res === 0) {
            res = b.time.localeCompare(a.time);
          }
          return res;
        });
        const combinedList = [];
        let me = null;
        for (let i = 0; i < res.makeUpExamList.length; i++) {
          const m = res.makeUpExamList[i];
          if (me == null) {
            me = m;
          } else if (
            m.termCode !== me.termCode ||
            m.courseNo !== me.courseNo ||
            m.type !== me.type ||
            m.date !== me.date ||
            m.time !== me.time
          ) {
            combinedList.push(me);
            me = m;
          } else {
            me.location = Util.concatOrMergeTwoString(
              me.location,
              "，",
              m.location
            );
          }
        }
        if (me) {
          combinedList.push(me);
        }
        res.makeUpExamList = combinedList;
      }
    }
    // 汇总事件列表
    {
      res.eventMap = new Map();
      res.eventList.forEach((e) => {
        res.eventMap.set(
          Require.string(Schedule.ID.Prefix.Event + e.id),
          new Event(
            e.unixNanoList,
            e.location,
            e.name,
            e.participantList,
            e.singleCharMark,
            e.notes,
            e
          )
        );
      });
      res.courseList.forEach((c, i) => {
        res.eventMap.set(
          Require.string(Schedule.ID.Prefix.Course + i),
          new Event(
            Util.calCourseUnixNanoList(
              res.termList,
              res.periodList,
              c.termCode,
              c.startWeek,
              c.endWeek,
              c.oddWeekOnly,
              c.weekday,
              c.period
            ),
            c.location,
            c.name,
            [c.teacherName],
            Util.periodToName(res.periodList, c.period),
            c.notes,
            c
          )
        );
      });
      res.expList.forEach((exp, i) => {
        res.eventMap.set(
          Require.string(Schedule.ID.Prefix.Experiment + i),
          new Event(
            Util.calCourseUnixNanoList(
              res.termList,
              res.periodList,
              exp.termCode,
              exp.week,
              exp.week,
              false,
              exp.weekday,
              exp.period
            ),
            exp.location,
            exp.name,
            [exp.teacherName],
            Util.periodToName(res.periodList, exp.period),
            exp.notes,
            exp
          )
        );
      });
      res.examList.forEach((exam, i) => {
        res.eventMap.set(
          Require.string(Schedule.ID.Prefix.Exam + i),
          new Event(
            Util.calExamUnixNanoList(
              res.termList,
              res.periodList,
              exam.termCode,
              exam.date,
              exam.time,
              exam.week,
              exam.weekday,
              exam.period
            ),
            exam.location,
            "考试-" + exam.name,
            [exam.teacherName],
            "考",
            exam.notes,
            exam
          )
        );
      });
      res.makeUpExamList.forEach((makeUpExam, i) => {
        res.eventMap.set(
          Require.string(Schedule.ID.Prefix.MakeUpExam + i),
          new Event(
            Util.calExamUnixNanoList(
              res.termList,
              res.periodList,
              makeUpExam.termCode,
              makeUpExam.date,
              makeUpExam.time,
              null,
              null,
              null
            ),
            makeUpExam.location,
            ((type) => {
              if (Util.empty(type)) {
                return "考试";
              } else if (!type.endsWith("考") && !type.endsWith("试")) {
                return type + "考";
              } else {
                return type;
              }
            })(makeUpExam.type) +
              "-" +
              makeUpExam.name,
            null,
            ((type) => {
              if (Util.notEmpty(type)) {
                if (type === "补考") {
                  return "补";
                } else if (type === "缓考") {
                  return "缓";
                }
              }
              return "考";
            })(makeUpExam.type),
            makeUpExam.notes,
            makeUpExam
          )
        );
      });
    }
    // 展开事件列表
    {
      res.singleEventList = [];
      for (const [id, e] of res.eventMap) {
        for (const unixNanoObj of e.unixNanoList) {
          res.singleEventList.push(
            new SingleEvent(
              id,
              unixNanoObj.start,
              unixNanoObj.end,
              e.location,
              e.name,
              e.participantList,
              e.singleCharMark,
              e.notes,
              e.data
            )
          );
        }
      }
    }
    // 解析上次同步时间
    {
      // 返回
      res.lastUpdateUnixNanoTimestamp = d.lastUpdateUnixNanoTimestamp;
    }
    // 解析个人信息
    {
      res.personalInfo = {
        // 学号
        sid: Require.string(d.personalInfoPerson.stid),
        // 姓名
        name: Require.string(d.personalInfoPerson.name),
        // 曾用名
        formerName: Require.string(d.personalInfoPerson.name1),
        // 英文名
        englishName: Require.string(d.personalInfoPerson.engname),
        // 性别
        sex: Require.string(d.personalInfoPerson.sex),
        // 年级
        grade: Require.number(d.personalInfoPerson.grade),
        // 学院号
        schoolNo: Require.string(d.personalInfoStu.dptno),
        // 学院名称
        schoolName: Require.string(d.personalInfoStu.dptname),
        // 班级
        classNo: Require.string(d.personalInfoPerson.classno),
        // 专业号
        majorNo: Require.string(d.personalInfoPerson.spno),
        // 专业名称
        majorName: Require.string(d.personalInfoStu.spname),
        // 第二专业号
        secondMajorNo: Require.string(d.personalInfoPerson.secspno),
        // 状态
        status: Require.string(d.personalInfoPerson.changetype),
        // 身份证号
        idNumber: Require.string(d.personalInfoPerson.idcard),
        // 学生类型
        studentType: Require.string(d.personalInfoPerson.stype),
        // 民族
        nationality: Require.string(d.personalInfoPerson.nation),
        // 政治面貌
        politicalProfile: Require.string(d.personalInfoPerson.political),
        // 籍贯
        hometown: Require.string(d.personalInfoPerson.nativeplace),
        // 高考考生号
        collegeEntranceExaminationCandidateNumber: Require.string(
          d.personalInfoPerson.testnum
        ),
        // 入学日期
        enrollmentDate: Require.string(d.personalInfoPerson.enrolldate),
        // 离校日期
        leavingSchoolDate: Require.string(d.personalInfoPerson.leavedate),
        // 宿舍
        dormitory: Require.string(d.personalInfoPerson.hostel),
        // 联系电话
        telephoneNumber: Require.string(d.personalInfoPerson.hostelphone),
        // 家庭邮编
        familyPostCode: Require.string(d.personalInfoPerson.postcode),
        // 家庭住址
        familyAddress: Require.string(d.personalInfoPerson.address),
        // 家庭联系电话
        familyPhoneNumber: Require.string(d.personalInfoPerson.phoneno),
        // 家庭户主
        familyHead: Require.string(d.personalInfoPerson.familyheader),
        // 高考总分
        ceeTotal: Require.number(d.personalInfoPerson.total),
        // 高考语文(或英语)
        ceeChinese: Require.number(d.personalInfoPerson.english),
        // 高考数学
        ceeMath: Require.number(d.personalInfoPerson.maths),
        // 高考英语(或语文)
        ceeEnglish: Require.number(d.personalInfoPerson.chinese),
        // 高考综合
        ceeComprehensiveTest: Require.number(d.personalInfoPerson.addscore1),
        // 高考其他
        ceeOther: Require.number(d.personalInfoPerson.addscore2),
        // 备注
        notes: Require.string(d.personalInfoPerson.comment),
      };
    }
    // 解析毕业计划课程列表
    {
      res.graduationPlanCourseList = [];
      res.graduationPlanRequiredCredit = 0;
      res.graduationPlanXZCredit = undefined;
      res.graduationPlanRZCredit = undefined;
      res.graduationPlanRequiredCreditTaken = 0;
      res.graduationPlanXZCreditTaken = 0;
      res.graduationPlanRZCreditTaken = 0;
      for (const planCourseGrade of Util.getEmptyArrayFromNull(
        d.planCourseGradeList
      )) {
        const planCredit = Require.number(planCourseGrade.credithour);
        const completed = Require.boolean(
          Util.getEmptyStringFromNull(planCourseGrade.chk).trim() === "1"
        );
        res.graduationPlanCourseList.push({
          name: Require.string(planCourseGrade.cname),
          credit: planCredit,
          courseCode: Require.string(planCourseGrade.courseid),
          courseNo: Require.string(planCourseGrade.courseno),
          // [注意] 这里之所以是字符串, 是因为可能会有文字描述类型的成绩
          grade: Require.string(planCourseGrade.zpxs),
          completed: completed,
          type: Require.string(planCourseGrade.tname),
        });
        if (
          Util.getEmptyStringFromNull(planCourseGrade.courseid)
            .trim()
            .toLowerCase()
            .startsWith("b")
        ) {
          res.graduationPlanRequiredCredit += planCredit;
          if (completed) {
            res.graduationPlanRequiredCreditTaken += planCredit;
          }
        } else if (
          Util.getEmptyStringFromNull(planCourseGrade.courseid)
            .trim()
            .toLowerCase()
            .startsWith("xz")
        ) {
          // res.graduationPlanXZCredit += planCredit
          if (completed) {
            res.graduationPlanXZCreditTaken += planCredit;
          }
        } else if (
          Util.getEmptyStringFromNull(planCourseGrade.courseid)
            .trim()
            .toLowerCase()
            .startsWith("rz")
        ) {
          // res.graduationPlanRZCredit += planCredit
          if (completed) {
            res.graduationPlanRZCreditTaken += planCredit;
          }
        }
      }
      // 排列毕业计划课程列表
      // 完成的排前面
      // 成绩非空的排前面
      res.graduationPlanCourseList.sort((a, b) => {
        const preOrderedCCArray = ["BJ", "BG", "BT", "BS", "XZ", "RZ"];
        if (b.completed && !a.completed) {
          return 1;
        } else if (a.completed && !b.completed) {
          return -1;
        } else if (Util.notEmpty(b.grade) && Util.empty(a.grade)) {
          return 1;
        } else if (Util.notEmpty(a.grade) && Util.empty(b.grade)) {
          return -1;
        } else {
          const acc = a.courseCode;
          const bcc = b.courseCode;
          let ai = 9999;
          let bi = 9999;
          for (let i = 0; i < preOrderedCCArray.length; i++) {
            const p = preOrderedCCArray[i];
            if (acc.toLowerCase().startsWith(p.toLowerCase())) {
              ai = i;
            }
            if (bcc.toLowerCase().startsWith(p.toLowerCase())) {
              bi = i;
            }
          }
          return ai === bi ? acc.localeCompare(bcc) : ai - bi;
        }
      });
    }
    // 解析成绩单
    {
      res.gradeList = [];
      for (const grade of Util.getEmptyArrayFromNull(d.gradeList)) {
        res.gradeList.push({
          name: Require.string(grade.cname),
          dailyPerformance: Require.number(grade.pscj),
          experimentPerformance: Require.number(grade.sycj),
          examPerformance: Require.number(grade.khcj),
          // [注意] 这里之所以是字符串, 是因为可能会有文字描述类型的成绩
          finalGrade: Require.string(grade.zpxs),
          termCode: Require.string(grade.term),
          gradeType: Require.string(grade.cjlb),
          examType: Require.string(grade.kslb),
        });
      }
    }
    // 解析等级考试成绩列表
    {
      res.cetList = [];
      Util.getEmptyArrayFromNull(d.cetList).forEach((cet) => {
        res.cetList.push({
          name: Require.string(cet.code).trim(),
          termCode: Require.string(cet.term),
          // 可能会有文字描述类型的成绩
          grade: Require.string(cet.stage),
          convertedGrade: Require.string(cet.score),
          cardNo: Require.string(cet.card).trim(),
        });
      });
      // 排列等级考试成绩列表
      // 学期大的排前面
      res.cetList.sort((a, b) => {
        return b.termCode.localeCompare(a.termCode);
      });
    }
    // 解析实验成绩列表
    {
      res.expGradeList = [];
      Util.getEmptyArrayFromNull(d.expGradeList).forEach((eg) => {
        res.expGradeList.push({
          name: Require.string(eg.cname),
          teacherName: Require.string(eg.tname),
          termCode: Require.string(eg.term),
          dailyPerformance: Require.number(eg.pscj),
          examPerformance: Require.number(eg.khcj),
          // 假定实验成绩不会出现文字描述类型的成绩
          finalGrade: Require.number(eg.zpcj),
          gradeType: Require.string(eg.cjlb),
          examType: Require.string(eg.kslb),
        });
      });
    }
    // 解析毕业信息
    {
      const gi = d.graduationInformation;
      res.graduationInformation = {
        studentID: Require.string(gi.stid),
        studentName: Require.string(gi.name),
        schoolNo: Require.string(gi.dptno),
        schoolName: Require.string(gi.dptname),
        majorNo: Require.string(gi.spno),
        majorName: Require.string(gi.spname),
        majorEnglishName: Require.string(gi.engname),
        grade: Require.number(gi.grade),
        classNo: Require.string(gi.class),
        cetGrade: Require.string(gi.cetshow),
        cetConvertedGrade: Require.string(
          Util.concatOrMergeTwoString(
            Require.string(gi.cet),
            ": ",
            Require.string(gi.cetcj)
          )
        ),
        credit: Require.number(gi.xfj),
        foreignLanguageAverageGrade: Require.number(gi.fpjf),
        notes: Require.string(gi.comm),
      };
    }
    // 解析毕业条件列表
    {
      res.graduationRequirementList = [];
      Util.getEmptyArrayFromNull(d.graduationRequirementList).forEach((r) => {
        res.graduationRequirementList.push({
          description: Require.string(r.comm),
        });
      });
    }
    // 解析创新积分信息
    {
      const ii = d.innovationPointInformation;
      res.innovationPointInformation =
        ii == null
          ? null
          : {
              basicQuality: Require.object({
                name: Require.string("基本素质"),
                benchmark: Require.number(3),
                earned: Require.number(ii.lb1),
              }),
              basicSkill: Require.object({
                name: Require.string("基本技能"),
                benchmark: Require.number(3),
                course: Require.object({
                  name: Require.string("课程"),
                  benchmark: Require.number(1),
                  earned: Require.number(ii.lb21),
                }),
                training: Require.object({
                  name: Require.string("训练"),
                  benchmark: Require.number(1),
                  earned: Require.number(ii.lb22),
                }),
              }),
              innovativePractice: Require.object({
                name: Require.string("创新实践"),
                benchmark: Require.number(2),
                earned: Require.number(ii.lb3),
              }),
              isTargetReached: Require.boolean(ii.pass),
              pointsLacked: Require.number(ii.lack),
            };
    }
    // 解析转专业信息
    {
      const ci = d.changingMajorInformation;
      res.changingMajorInformation =
        ci == null
          ? null
          : {
              studentID: Require.string(ci.stid),
              studentName: Require.string(ci.stname),
              schoolNo: Require.string(ci.dptno),
              majorNo: Require.string(ci.spno),
              majorName: Require.string(ci.spname),
              grade: Require.number(ci.grade),
              credit: Require.number(ci.xfj),
              totalGrade: Require.number(ci.zfs),
              countedCourseNum: Require.number(ci.jskcs),
              excellentCourseNum: Require.number(ci.kcs),
              failedCourseNum: Require.number(ci.nopass),
              studentNum: Require.number(ci.zrs),
              rank: Require.number(ci.wz),
            };
    }
    return res;
  },
};
