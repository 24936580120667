import Util from "js-util";
import ErrorMultipleBlockerResponse from "./error/ErrorMultipleBlockerResponse.js";

export default function Blocker(blockerType, challenge) {
  this.Type = blockerType;
  this.Challenge = challenge;
  this.resolved = false;
}
Blocker.prototype = {
  async generatePromise() {
    ({ Promise: this.promise, Resolve: this.resolve } =
      await Util.NewPromiseAndResolve());
  },
  async Block() {
    if (this.promise == null) {
      await this.generatePromise();
    }
    return this.promise;
  },
  async Resolve(response) {
    if (this.resolve == null) {
      await this.generatePromise();
    }
    if (this.resolved) {
      if (this.response !== response) {
        throw new ErrorMultipleBlockerResponse();
      }
    } else {
      this.response = response;
      this.resolve(this.response);
      this.resolved = true;
    }
  },
  IsBlocking() {
    return !this.resolved;
  }
};
