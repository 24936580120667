import HelperError from "./helperError.js";

export default class extends HelperError {
  /** 未知错误 */
  constructor(details, { tip, cause } = {}) {
    super({
      code: "unknown",
      tip: tip,
      httpCode: 500,
      details: details,
      cause: cause,
    });
  }
}
