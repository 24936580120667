export default function (
  studentID,
  vpnPassword,
  aasPassword,
  isInternational,
  isVPNPasswordPlain
) {
  this.StudentID = studentID;
  this.VPNPassword = vpnPassword;
  this.AASPassword = aasPassword;
  this.IsInternational = isInternational;
  this.IsVPNPasswordPlain = isVPNPasswordPlain;
}
