<template>
  <!--  时间线必须溢出-->
  <div class="fc overflow-visible-important">
    <!--    适应内容-->
    <!--    子节点可能为绝对定位-->
    <!--    时间线必须溢出-->
    <div class="flex-shrink-0 position-relative overflow-visible-important">
      <WeekNode
          :color="{node: weekday.color, timeline: timelineColor}"
          :content="{course: weekday.name}"
          :timeline-height="timelineHeight"
          :show-time-line="weekday.showTimeLine"/>
    </div>
    <!--    占满可用空间-->
    <!--    子节点可能为绝对定位-->
    <!--    外部指定的定位方式最重要-->
    <!--    时间线必须溢出-->
    <div class="flex-grow-1 fc position-relative overflow-visible-important">
      <WeekNode v-for="node in nodeList" :key="node.id"
                :style="{position: node.position + '!important', top: node.top, width: node.width, height: node.height}"
                :single-event-list="node.singleEventList"
                :color="{node: node.color, timeline: timelineColor}"
                :content="node.content"
                :time="node.time"
                :timeline-height="timelineHeight"
                :show-time-line="node.showTimeLine"
                :has-dot="node.hasDot"/>
    </div>
  </div>
</template>

<script>
import WeekNode from "@/component/week/element/WeekNode";

export default {
  name: "WeekColumn",
  components: {WeekNode},
  props: {
    weekday: {
      type: Object,
      default() {
        return {
          name: "",
          color: [],
          showTimeLine: false,
        }
      }
    },
    timelineColor: {
      type: Array,
      default() {
        return []
      }
    },
    timelineHeight: String,
    // [
    //   {
    //     id: "",
    //     color: [
    //       ""
    //     ],
    //     content: {
    //       classroom: "",
    //       course: "",
    //       teacher: ""
    //     },
    //     time: {
    //       start: "",
    //       end: "",
    //     },
    //     showTimeLine: true,
    //     position: "relative",
    //     top: "5%",
    //     width: "50%",
    //     height: "50%",
    //     singleEventList: [],
    //   }
    // ],
    nodeList: {
      type: Array,
      default() {
        return []
      }
    },
  }
}
</script>

<style scoped>

</style>

