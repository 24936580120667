import HelperError from "./helperError.js";

export default class extends HelperError {
  /**
   * CAS: 账号权限不足，请重试~
   */
  constructor() {
    super({
      code: "cas_user_permissions_insufficient",
      tip: "账号权限不足，请重试~",
      httpCode: 403,
    });
  }
}
