import HelperError from "./helperError.js";

export default class extends HelperError {
  constructor(waitFor) {
    super({
      code: "sms-code-rate-limit-reached",
      tip: `短信验证码发送速率达到上限，${waitFor == null ? "稍" : waitFor + " 秒"}后再试吧~`,
      httpCode: 429,
      retryAfter: waitFor
    });
  }
}
