import LocalStorage from "@/js/LocalStorage";

window.onerror = async (message, source, lineno, colno, error) => {
  alert(
    `\n出错啦 >_<\n\n` +
      `请联系开发者（邮箱：telephone.tan@qq.com）并提供以下错误信息的截图（官方客服联系方式位于底部）：\n\n` +
      `----------\n\n` +
      `Message: ${message}\n\n` +
      `At: ${source},${lineno},${colno}\n\n` +
      `Error: ${error}\n\n` +
      `Trace: ${error.stack}\n\n` +
      `----------\n\n` +
      `官方客服 QQ：\n` +
      `3450035134（QID：GUETKCHB）\n\n` +
      `官方客服邮箱：\n` +
      `guet.course.table@qq.com\n`
  );
  localStorage.clear();
  await LocalStorage.clear();
  location.reload();
};

import { createApp } from "vue";
import { createRouter, createWebHashHistory } from "vue-router";
import App from "./App.vue";

import { Picker, Radio, RadioGroup, Tabbar, TabbarItem } from "vant";
import "@vant/touch-emulator";
import "vant/es/picker/style/index";
import "vant/es/tabbar/style/index";
import "vant/es/tabbar-item/style/index";
import "vant/es/radio-group/style/index";
import "vant/es/radio/style/index";

import { ElProgress } from "element-plus";
import "element-plus/es/components/progress/style/css";

import "@/css/global.css";
import "@/css/tool_box/x.css";
import "@/css/page_transition/x.css";
import "@/css/week.css";
import "@/css/config/color/light.css";
import "@/css/config/color/dark.css";
import "@/css/tool_box/colorful_node/x.css";
import "@/css/config/light.css";
import "@/css/config/dark.css";
import "@/css/config/generic.css";
import "@/css/dialog.css";
import "@/css/progress_bar.css";
import "@/css/message.css";
import "@/css/snackbar.css";
import "@/css/popup.css";
import "@/css/input.css";
import "@/css/function_menu.css";
import "@/css/logon.css";
import "@/css/more.css";
import "@/css/day.css";
import "@/css/term_week_picker.css";
import "@/css/app.css";
import "@/css/sign-in.css";
import "@/css/radio.css";
import "@/css/images/all.css";
import FunctionMenu from "@/component/function_menu/FunctionMenu";
import Week from "@/component/week/Week";
import Page from "@/js/page";
import Logon from "@/component/logon/Logon";
import Store from "@/js/store";
import Day from "@/component/day/Day";
import More from "@/component/more/More";
import SignIn from "@/component/sign-in/SignIn";

const routes = [
  {
    name: Page.Week.name,
    path: Page.Week.path,
    component: Week,
  },
  {
    name: Page.FunctionMenu.name,
    path: Page.FunctionMenu.path,
    component: FunctionMenu,
  },
  {
    name: Page.Logon.name,
    path: Page.Logon.path,
    component: Logon,
  },
  {
    name: Page.Day.name,
    path: Page.Day.path,
    component: Day,
  },
  {
    name: Page.More.name,
    path: Page.More.path,
    component: More,
  },
  {
    name: Page.SignIn.name,
    path: Page.SignIn.path,
    component: SignIn,
  },
];

const defaultStartPageName = Page.Week.name;

async function calStartPageName() {
  let startPageName = await Store.getCurrentUserFavoriteStartPageName();
  if (startPageName == null) {
    startPageName = defaultStartPageName;
  } else {
    let isValidPageName = false;
    for (const r of routes) {
      if (r.name === startPageName) {
        isValidPageName = true;
        break;
      }
    }
    if (!isValidPageName) {
      startPageName = defaultStartPageName;
    }
  }
  return startPageName;
}

async function generateRouteList() {
  const res = [];
  let startPageName = await calStartPageName();
  for (const r of routes) {
    res.push({
      name: r.name,
      path: r.path,
      component: r.component,
      alias: r.name === startPageName ? Page.StartPage.path : [],
    });
  }
  return res;
}

const router = await (async function () {
  const res = createRouter({
    history: createWebHashHistory(),
    routes: await generateRouteList(),
  });
  let isFirstRoute = true;
  res.beforeEach(async (to) => {
    if (isFirstRoute) {
      isFirstRoute = false;
      const lastLeaveTimestampMS = Store.lastLeaveTimestampMS();
      const nowMS = Date.now();
      if (
        !(
          lastLeaveTimestampMS != null &&
          lastLeaveTimestampMS <= nowMS &&
          nowMS - lastLeaveTimestampMS <= 10000
        )
      ) {
        if (to.name !== (await calStartPageName())) {
          return Page.StartPage.path;
        }
      }
    }
    if (
      Page.mustLogin(to.path) &&
      (await Store.getActiveUserAndHisData()) == null
    ) {
      return Page.loginPage().path;
    }
  });
  return res;
})();

if ("serviceWorker" in navigator) {
  navigator.serviceWorker.register(`/welcome.js`);
}

const data = await Store.parseData();

export default {
  getPageNameList,
  refreshRouter,
  data,
};

const app = createApp(App);

app.config.unwrapInjectedRef = true;

app.use(router);
app.use(ElProgress);
app.use(Tabbar);
app.use(TabbarItem);
app.use(Picker);
app.use(RadioGroup);
app.use(Radio);

app.mount("#app");

function getPageNameList() {
  const res = [];
  for (const r of routes) {
    res.push(r.name);
  }
  return res;
}

async function refreshRouter() {
  const newRouteList = await generateRouteList();
  for (const r of newRouteList) {
    router.addRoute(r);
  }
}
