<template>
  <transition
      name="snackbar"
  >
    <div
        class="snackbar position-fixed"
        style="
          width: calc(100 * var(--vw));
          --padding-horizontal: 1.5em;
          --padding-vertical: 1em;
          padding: var(--padding-vertical) var(--padding-horizontal) var(--padding-vertical) var(--padding-horizontal);
          bottom: 0;
        "
    >
      {{ msg }}
    </div>
  </transition>
</template>

<script>
export default {
  name: "SnackBar",
  props: {
    msg: String,
  },
}
</script>

<style scoped>

</style>