export default {
    StartPage: {
        path: "/",
        name: "启动页",
    },
    Week: {
        path: "/week",
        name: "周课表",
    },
    FunctionMenu: {
        path: "/function-menu",
        name: "教务功能",
    },
    Logon: {
        path: "/logon",
        name: "登录",
    },
    SignIn: {
        path: "/sign-in",
        name: "登录2.0",
    },
    Day: {
        path: "/day",
        name: "日程",
    },
    More: {
        path: "/more",
        name: "更多",
    },
    publicPageList() {
        return [
            this.Logon,
            this.SignIn,
        ]
    },
    loginPage() {
        return this.SignIn
    },
    mustLogin(path) {
        const ppl = this.publicPageList()
        for (const pp of ppl) {
            if (pp.path === path) {
                return false
            }
        }
        return true
    },
}