export default {
  String(o) {
    return typeof o === "string" || o instanceof String;
  },
  Object(o) {
    return typeof o === "object";
  },
  Array(o) {
    return Array.isArray(o);
  },
  Map(o) {
    return this.Object(o) && o instanceof Map;
  },
  Symbol(o) {
    return typeof o === "symbol";
  },
};
