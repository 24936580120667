import HelperError from "./helperError.js";

export default class extends HelperError {
  /**
   * CAS: 账号信息待完善，请完善账号信息~
   */
  constructor() {
    super({
      code: "cas_account_needs_improvement",
      tip: "账号信息待完善，请完善账号信息~",
      httpCode: 403,
    });
  }
}
