export default {
    ID: {
        Prefix: {
            Event: "event" + "|",
            Course: "course" + "|",
            Experiment: "experiment" + "|",
            Exam: "exam" + "|",
            MakeUpExam: "make-up-exam" + "|",
        }
    },
    TypeDescription: {
        Event: "自定义事件",
        Course: "系统课程",
        Experiment: "系统实验安排",
        Exam: "系统考试安排",
        MakeUpExam: "系统补考/缓考安排",
        Unknown: "其他",
    },
    SingleCharMark: {
        Exam: '考'
    }
}