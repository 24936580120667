import { PromiseSemaphore } from "js-promise";
export default function JSLock(locked) {
  this.semaphore = new PromiseSemaphore(locked ? 0 : 1);
}
JSLock.prototype = {
  async Lock() {
    return this.semaphore.Acquire();
  },
  Unlock() {
    this.semaphore.Release();
  },
};
