<template>
  <div>
    <div class="function-menu-child-gap"/>
  </div>
</template>

<script>
export default {
  name: "ChildGap"
}
</script>

<style scoped>

</style>