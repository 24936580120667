import URLs from "js/guetcob-helper/constants/urls";

export default {
  Server: URLs.Server,
  GUETCourseTableHelperUserScript:
    "https://GUET课程表.com/浏览器用户脚本/GUET课程表小助手.user.js",
  GUETCourseTableGroup: "https://GUET课程表.com/QQ",
  GUETCourseTableDownload: "https://GUET课程表.com/安卓",
  GUETCourseTableQQGroupInfo: `${URLs.Server}/qq/group/guet-course-table`,
};
