import GuetAAS from "./guetAAS.js";
import URLs from "../../constants/urls.js";
import { HTTPHeader, HTTPMethod } from "js-http-request";
import ErrorUnknown from "../../types/error/ErrorUnknown.js";
import PeriodList from "../../constants/periodList.js";
import Require from "js-type-require";

export default class extends GuetAAS {
  async fetch(method, url, trigger, { form, headers } = {}) {
    return trigger(
      this.BaseRequest.Clone()
        .SetMethod(method)
        .SetURL(url)
        .SetCustomizedHeaderList(
          Require.Array(headers).concat([[HTTPHeader.Referer, url]]),
        )
        .SetRequestForm(form),
    );
  }
  async json(
    method,
    url,
    // eslint-disable-next-line no-unused-vars
    { transform = (json, str) => json, pack } = {},
  ) {
    return this.fetch(
      method,
      url,
      (req) =>
        req.JSON().then(async (jsonRes) => {
          const str = (await jsonRes.Request.String()).Result;
          if (jsonRes.Request.StatusCode !== 200) {
            throw new ErrorUnknown(str);
          }
          return transform(jsonRes.Result, str);
        }),
      pack,
    );
  }
  async jsonSuccess(method, url, { transform = (json) => json, pack } = {}) {
    return this.json(method, url, {
      transform: (json, str) => {
        if (json.success !== true) {
          throw new ErrorUnknown(str);
        }
        return transform(json);
      },
      pack: pack,
    });
  }
  async jsonData(method, url, { transform = (data) => data, pack } = {}) {
    return this.jsonSuccess(method, url, {
      transform: (json) => {
        return transform(json.data);
      },
      pack: pack,
    });
  }
  async jsonMSG(method, url, { transform = (msg) => msg, pack } = {}) {
    return this.jsonSuccess(method, url, {
      transform: (json) => {
        return transform(json.msg);
      },
      pack: pack,
    });
  }

  async fetchStudentInfo() {
    return this.json(
      HTTPMethod.POST,
      `${URLs.System.Get(this.IsLAN)}/student/StuInfo`,
    );
  }

  async fetchPersonalInfo() {
    return this.jsonData(
      HTTPMethod.POST,
      `${URLs.System.Get(this.IsLAN)}/Student/GetPerson`,
    );
  }

  async fetchCourseList() {
    return this.jsonData(
      HTTPMethod.GET,
      `${URLs.System.Get(this.IsLAN)}/student/getstutable`,
    );
  }

  async fetchGradeList() {
    return this.jsonData(
      HTTPMethod.GET,
      `${URLs.System.Get(this.IsLAN)}/student/GetStuScore`,
    );
  }

  async fetchSelectedCourseList(channel) {
    const getListOfTerm = async (term) => {
      return this.jsonData(
        HTTPMethod.GET,
        `${URLs.System.Get(
          this.IsLAN,
        )}/student/GetSctCourse?term=${term}&comm=1@1`,
      );
    };

    let selectedCourseList = [];
    let todoList = (await this.TermList(channel)).map((term) =>
      getListOfTerm(term.term),
    );
    (await Promise.all(todoList)).map(
      (listOfTerm) =>
        (selectedCourseList = selectedCourseList.concat(listOfTerm)),
    );
    return selectedCourseList;
  }

  async fetchExpList(channel) {
    const getListOfTerm = async (term) => {
      return this.jsonData(
        HTTPMethod.GET,
        `${URLs.System.Get(this.IsLAN)}/student/getlabtable?term=${term}`,
      );
    };

    // 裁剪学期列表，只保留与今年有关的学期
    let trimedTermList = (await this.TermList(channel)).filter((term) =>
      term.term.includes(new Date().getFullYear().toString()),
    );
    let expList = [];
    let todoList = trimedTermList.map((term) => getListOfTerm(term.term));
    (await Promise.all(todoList)).map(
      (listOfTerm) => (expList = expList.concat(listOfTerm)),
    );
    return expList;
  }

  async fetchExpGradeList() {
    return this.jsonData(
      HTTPMethod.GET,
      `${URLs.System.Get(this.IsLAN)}/student/getstulab`,
    );
  }

  async fetchExamList(channel) {
    const getListOfTerm = async (term) => {
      return this.jsonData(
        HTTPMethod.GET,
        `${URLs.System.Get(this.IsLAN)}/student/getexamap?term=${term}`,
      );
    };

    // 裁剪学期列表，只保留与今年有关的学期
    let trimedTermList = (await this.TermList(channel)).filter((term) =>
      term.term.includes(new Date().getFullYear().toString()),
    );
    let examList = [];
    let todoList = trimedTermList.map((term) => getListOfTerm(term.term));
    (await Promise.all(todoList)).map(
      (listOfTerm) => (examList = examList.concat(listOfTerm)),
    );
    return examList;
  }

  async fetchMakeUpExamList() {
    return this.jsonData(
      HTTPMethod.GET,
      `${URLs.System.Get(this.IsLAN)}/student/getbk`,
    );
  }

  async fetchCETList() {
    return this.jsonData(
      HTTPMethod.GET,
      `${URLs.System.Get(this.IsLAN)}/student/GetLvlScore`,
    );
  }

  async fetchValidCreditList() {
    return this.jsonData(
      HTTPMethod.GET,
      `${URLs.System.Get(this.IsLAN)}/student/Getyxxf`,
    );
  }

  async fetchPlanCourseGradeList() {
    return this.jsonData(
      HTTPMethod.GET,
      `${URLs.System.Get(this.IsLAN)}/student/getplancj`,
    );
  }

  async fetchFinancial(channel) {
    const stuInfo = await this.StudentInfo(channel);
    return this.jsonMSG(
      HTTPMethod.POST,
      `${URLs.System.Get(this.IsLAN)}/student/genstufee`,
      {
        pack: {
          form: [
            ["ctype", "byyqxf"],
            ["stid", stuInfo.stid],
            ["grade", stuInfo.grade],
            ["spno", stuInfo.spno],
          ],
        },
      },
    );
  }

  async updateGraduationInfo(channel) {
    const stuInfo = await this.StudentInfo(channel);
    const currentTerm = await this.CurrentTerm(channel);
    return this.jsonData(
      HTTPMethod.POST,
      `${URLs.System.Get(this.IsLAN)}/student/genstuby/${currentTerm}`,
      {
        pack: {
          form: [
            ["ctype", "byyqxf"],
            ["stid", stuInfo.stid],
            ["grade", stuInfo.grade],
            ["spno", stuInfo.spno],
          ],
        },
      },
    );
  }

  async fetchGraduationInfo() {
    return this.jsonData(
      HTTPMethod.GET,
      `${URLs.System.Get(this.IsLAN)}/student/getbyxw`,
      { transform: (data) => data[0] },
    );
  }

  async fetchGraduationRequirementList() {
    return this.jsonData(
      HTTPMethod.GET,
      `${URLs.System.Get(this.IsLAN)}/comm/getsctxw`,
    );
  }

  async updateInnovationInfo() {
    return this.jsonData(
      HTTPMethod.POST,
      `${URLs.System.Get(this.IsLAN)}/student/gencxcy`,
    );
  }

  async fetchInnovationInfo() {
    return this.jsonData(
      HTTPMethod.GET,
      `${URLs.System.Get(this.IsLAN)}/student/getcxcy`,
      { transform: (data) => data[0] },
    );
  }

  async fetchChangingMajorInfo() {
    return this.jsonData(
      HTTPMethod.GET,
      `${URLs.System.Get(this.IsLAN)}/student/GetSpnoStid`,
    );
  }

  async fetchHourList() {
    return this.jsonData(
      HTTPMethod.GET,
      `${URLs.System.Get(this.IsLAN)}/comm/gethours`,
    );
  }

  async fetchTermList(channel) {
    const sid = (await this.StudentInfo(channel)).stid;
    return this.jsonData(HTTPMethod.GET, `${URLs.Server}/terms`, {
      transform: (data) =>
        data
          .filter((term) => term.schoolyear >= "20" + sid.slice(0, 2))
          .sort((o1, o2) => o1.term.localeCompare(o2.term)),
    });
  }

  async fetchCurrentTerm() {
    return this.json(
      HTTPMethod.POST,
      `${URLs.System.Get(this.IsLAN)}/Comm/CurTerm`,
      { transform: (json) => json[0] },
    );
  }

  async fetchPeriodList() {
    return PeriodList;
  }
}
