import Blocker from "./blocker.js";
import Util from "js-util";

export default function BlockerChannel() {
  this.blockerList = [];
  this.waiterList = [];
}
BlockerChannel.prototype = {
  getBlocking() {
    for (const blocker of this.blockerList) {
      if (blocker.IsBlocking()) {
        return blocker;
      }
    }
  },
  async Next() {
    let blocking = this.getBlocking();
    if (blocking != null) {
      return blocking;
    }
    const waiter = await Util.NewPromiseAndResolve();
    blocking = this.getBlocking();
    if (blocking != null) {
      return blocking;
    }
    this.waiterList.push(waiter);
    return waiter.Promise;
  },
  async Bubble(blocker) {
    this.blockerList.push(blocker);
    for (const waiter of this.waiterList) {
      waiter.Resolve(blocker);
    }
    this.waiterList = [];
    return blocker.Block();
  },
  async Block(blockerType, challenge) {
    return this.Bubble(new Blocker(blockerType, challenge));
  },
};
