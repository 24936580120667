<template>
  <div>
    <div v-for="cet in cetList" :key="cet.cardNo"
         class="fc">
      <ChildGap/>
      <div class="fc">
        <div class="function-menu-cet">
          <div class="function-menu-cet-term">
            {{ cet.termCode }}
          </div>
        </div>
        <div class="function-menu-cet">
          <div class="function-menu-cet-name">
            {{ cet.name }}
          </div>
        </div>
        <div class="function-menu-cet">
          <div>
            {{ cet.grade }}
          </div>
        </div>
        <div class="function-menu-cet">
          <div>
            {{ cet.convertedGrade }}
          </div>
        </div>
        <div class="function-menu-cet">
          <div class="function-menu-cet-card-no">
            {{ cet.cardNo }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChildGap from "@/component/function_menu/element/ChildGap";

export default {
  name: "CET",
  components: {ChildGap},
  props: {
    cetList: {
      type: Array,
      default() {
        return []
      },
    },
  },
}
</script>

<style scoped>

</style>