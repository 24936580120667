<template>
  <div>
    <div v-for="gpc in gpcList" :key="gpc.courseCode + gpc.courseNo" class="fc">
      <ChildGap/>
      <div class="fr">
        <div class="flex-basis-0 fc function-menu-graduation-plan-course" style="flex: 5.5 5.5">
          <div class="margin-top-bottom-auto-in-fc">
            {{ gpc.name }}
          </div>
        </div>
        <div class="flex-basis-0 fc function-menu-graduation-plan-course" style="flex: 1.5 1.5">
          <div class="margin-top-bottom-auto-in-fc">
            {{ gpc.credit }}
          </div>
        </div>
        <div class="flex-basis-0 fc function-menu-graduation-plan-course" style="flex: 1.5 1.5">
          <div class="margin-top-bottom-auto-in-fc">
            {{ gpc.grade }}
          </div>
        </div>
        <div class="flex-basis-0 fc function-menu-graduation-plan-course" style="flex: 1.5 1.5">
          <div class="margin-top-bottom-auto-in-fc">
            {{ gpc.completed }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChildGap from "@/component/function_menu/element/ChildGap";

export default {
  name: "GraduationPlanCourse",
  components: {ChildGap},
  props: {
    gpcList: {
      type: Array,
      default() {
        return []
      },
    }
  },
}
</script>

<style scoped>

</style>