const localStorageDBName = "localStorageDBName";
const localStorageObjectStoreName = "localStorageObjectStoreName";
const localStorageCacheName = "localStorageCacheName";
const localStorageCache = caches.open(localStorageCacheName);
const localStorageCachePrefix = "/__localStorageCachePrefix__/";

const db = new Promise((resolve, reject) => {
  const req = window.indexedDB.open(localStorageDBName, 1);
  req.onupgradeneeded = (event) => {
    const db = event.target.result;
    let objectStore;
    switch (event.newVersion) {
      case 1:
        objectStore = db.createObjectStore(localStorageObjectStoreName);
        //
        objectStore.transaction.oncomplete = () => resolve(db);
        objectStore.transaction.onerror = (event) => reject(event);
        objectStore.transaction.onabort = (event) => reject(event);
        break;
    }
  };
  req.onsuccess = (event) => resolve(event.target.result);
  req.onerror = (event) => reject(event);
});

const useDB = true;

export default {
  async dbStore(mode, cb) {
    return cb(
      (await db)
        .transaction(localStorageObjectStoreName, mode)
        .objectStore(localStorageObjectStoreName)
    );
  },
  async dbReadOnlyStore(cb) {
    return this.dbStore("readonly", cb);
  },
  async dbReadWriteStore(cb) {
    return this.dbStore("readwrite", cb);
  },
  async getItemCache(key) {
    return (
      (
        await (
          await localStorageCache
        ).match(new Request(localStorageCachePrefix + key))
      )?.text() ?? null
    );
  },
  async getItemDB(key) {
    return this.dbReadOnlyStore(
      (store) =>
        new Promise((resolve, reject) => {
          const req = store.get(key);
          req.onsuccess = (event) => resolve(event.target.result);
          req.onerror = (event) => reject(event);
        })
    );
  },
  async getItem(key) {
    return useDB ? this.getItemDB(key) : this.getItemCache(key);
  },
  async setItemCache(key, value) {
    return (await localStorageCache).put(
      new Request(localStorageCachePrefix + key),
      new Response(value)
    );
  },
  async setItemDB(key, value) {
    return this.dbReadWriteStore(
      (store) =>
        new Promise((resolve, reject) => {
          store.put(value, key);
          store.transaction.oncomplete = () => resolve();
          store.transaction.onerror = (event) => reject(event);
          store.transaction.onabort = (event) => reject(event);
        })
    );
  },
  async setItem(key, value) {
    return useDB ? this.setItemDB(key, value) : this.setItemCache(key, value);
  },
  async removeItemCache(key) {
    return (await localStorageCache).delete(
      new Request(localStorageCachePrefix + key)
    );
  },
  async removeItemDB(key) {
    return this.dbReadWriteStore(
      (store) =>
        new Promise((resolve, reject) => {
          store.delete(key);
          store.transaction.oncomplete = () => resolve();
          store.transaction.onerror = (event) => reject(event);
          store.transaction.onabort = (event) => reject(event);
        })
    );
  },
  async removeItem(key) {
    return useDB ? this.removeItemDB(key) : this.removeItemCache(key);
  },
  async clearCache() {
    return caches.delete(localStorageCacheName);
  },
  async clearDB() {
    return this.dbReadWriteStore(
      (store) =>
        new Promise((resolve, reject) => {
          store.clear();
          store.transaction.oncomplete = () => resolve();
          store.transaction.onerror = (event) => reject(event);
          store.transaction.onabort = (event) => reject(event);
        })
    );
  },
  async clear() {
    return useDB ? this.clearDB() : this.clearCache();
  },
};
