<template>
  <!--  最外面这一层是留给外部添加边距的-->
  <div>
    <Input
      v-for="([k, v], index) in Array.from(activeInputMap)"
      :key="k"
      :ref="setInputRef"
      :label="k"
      :show-counter="v.showCounter"
      :submit-callback="
        index + 1 === activeInputMap.size
          ? submit
          : () => inputRefList[index + 1].focusInput()
      "
      :class="v.class"
      :type="v.type"
      :rows="v.rows"
      :rule="v.rule"
      :readonly="v.readonly"
      :hide-border="v.hideBorder"
      :could-be-empty="v.couldBeEmpty"
      :gap="gapBetweenInputBoxAndRuleText"
      :keep-rule-text="keepRuleText"
      :keep-counter-space="keepCounterSpace"
      :active-border-color="v.activeBorderColor"
      :inactive-border-color="v.inactiveBorderColor"
      :text-color="v.textColor"
      :tool-icon-alibaba="v.toolIconAlibaba"
      :style="{ marginTop: index === 0 ? 0 : getInputGapHeightString }"
      v-model="v.value"
      @update:model-value="setInputValue($event, k)"
    />
  </div>
</template>

<script>
import Input from "@/component/generic/Input";
import { inject } from "vue";
import Util from "@/js/util";

export default {
  name: "Form",
  components: { Input },
  setup() {
    return {
      gNumber: inject("gNumber"),
    };
  },
  data() {
    return {
      inputRefList: [],
    };
  },
  props: {
    modelValue: {
      example: new Map([
        [
          "firstInputName",
          {
            class: "",
            value: "",
            type: "",
            rows: 2,
            readonly: false,
            hideBorder: false,
            rule: {
              warning: "",
              contentValidator: () => true,
            },
            showCounter: false,
            couldBeEmpty: false,
            toolIconAlibaba: {
              id: "alibaba-icon-id",
              onClick: () => {},
            },
            activeBorderColor: "",
            inactiveBorderColor: "",
            textColor: "",
          },
        ],
      ]),
      type: Map,
      default() {
        return new Map();
      },
    },
    submitCallback: Function,
    gapBetweenInputBoxAndRuleText: String,
    gapBetweenInput: String,
  },
  emits: ["update:modelValue"],
  computed: {
    getInputGapHeightString() {
      return Util.empty(this.gapBetweenInput)
        ? this.gNumber.VH + "px"
        : this.gapBetweenInput;
    },
    keepRuleText() {
      let maxRule = "";
      for (const input of this.activeInputMap.values()) {
        if (
          input.rule &&
          input.rule.warning &&
          input.rule.warning.length > maxRule.length
        ) {
          maxRule = input.rule.warning;
        }
      }
      return maxRule;
    },
    keepCounterSpace() {
      for (const input of this.activeInputMap.values()) {
        if (input.showCounter) {
          return true;
        }
      }
      return false;
    },
    allInputMap() {
      return this.modelValue;
    },
    activeInputMap() {
      return new Map([...this.allInputMap].filter(([, v]) => v.active));
    },
  },
  beforeUpdate() {
    this.inputRefList = [];
  },
  methods: {
    setInputRef(input) {
      if (input) {
        this.inputRefList.push(input);
      }
    },
    getUserInput() {
      const userInput = { map: new Map(), valid: true };
      for (const input of this.inputRefList) {
        userInput.map.set(input.name(), {
          value: input.inputValue(),
          valid: input.isValid(),
        });
        if (!input.isValid()) {
          userInput.valid = false;
        }
      }
      return userInput;
    },
    submit() {
      if (this.submitCallback) {
        this.submitCallback(this.getUserInput());
      }
    },
    clear() {
      for (const [k] of this.allInputMap) {
        this.setInputValue("", k);
      }
    },
    isFocused() {
      for (const input of this.inputRefList) {
        if (input.isFocused()) {
          return true;
        }
      }
      return false;
    },
    hasInput() {
      for (const input of this.inputRefList) {
        if (input.hasInput()) {
          return true;
        }
      }
      return false;
    },
    setInputValue(value, key) {
      const oldMap = this.allInputMap;
      oldMap.get(key).value = value;
      this.$emit("update:modelValue", oldMap);
    },
  },
};
</script>

<style scoped></style>
