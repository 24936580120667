<template>
  <div>
    <div v-for="[k,v] in creditPointList" :key="k"
         class="fc">
      <ChildGap/>
      <div class="fr">
        <div class="flex-basis-0 align-self-center function-menu-credit-point text-end" style="flex: 1.25 1.25">
          {{ k }}
        </div>
        <div class="flex-shrink-0 align-self-center function-menu-credit-point">
          :
        </div>
        <div class="flex-basis-0 align-self-center function-menu-credit-point text-start" style="flex: 1 1">
          {{ v == null ? "暂无" : v }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChildGap from "@/component/function_menu/element/ChildGap";

export default {
  name: "CreditPoint",
  components: {ChildGap},
  props: {
    creditPointList: {
      type: Array,
      default() {
        return []
      },
    },
  },

}
</script>

<style scoped>

</style>