import HelperError from "./helperError.js";

export default class extends HelperError {
  /** 请求已过期 */
  constructor(details) {
    super({
      code: "gone",
      httpCode: 410,
      details: details,
    });
  }
}
