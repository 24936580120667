<template>
  <div>
    <div v-for="platform in quickLoginList" :key="platform.name"
         class="fc">
      <ChildGap/>
      <div class="fc unselectable-text" @click.prevent.stop="()=>quickLogin(platform)">
        <div class="function-menu-quick-login">
          <div>
            {{ platform.name }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChildGap from "@/component/function_menu/element/ChildGap";
import {inject} from "vue";
import Util from "@/js/util";

export default {
  name: "QuickLogin",
  components: {ChildGap},
  setup() {
    return {
      gParsedData: inject("gParsedData"),
      gDialog: inject("gDialog"),
      sQuickLoginFailReason: inject("sQuickLoginFailReason"),
      sQuickLoginFailURL: inject("sQuickLoginFailURL"),
      sLanURL: inject("sLanURL"),
      sWanURL: inject("sWanURL"),
    }
  },
  props: {
    quickLoginList: {
      type: Array,
      default() {
        return []
      },
    },
  },
  methods: {
    quickLogin(platform) {
      this.gDialog.quickLogin.show();
      (async () => {
        const helper = Util.Helper()
        if (helper == null) {
          this.sLanURL(platform.lanURL)
          this.sWanURL(platform.wanURL)
          this.gDialog.askForNetwork.show()
          throw null
        }
        const isLAN = await helper.isLAN()
        const url = isLAN ? platform.lanURL : platform.wanURL
        if (platform.run) {
          try {
            await platform.run(isLAN)
            this.gDialog.quickLoginSuccess.show()
            await new Promise(resolve => window.setTimeout(resolve, 500))
            this.gDialog.quickLoginSuccess.dismiss()
            return url
          } catch (reason) {
            this.sQuickLoginFailReason(reason)
            this.sQuickLoginFailURL(url)
            this.gDialog.quickLoginFail.show()
            throw null
          }
        } else {
          return url
        }
      })().then(url => {
        window.open(url)
      }).finally(() => {
        this.gDialog.quickLogin.dismiss()
      })
    },
  },
}
</script>

<style scoped>

</style>