<template>
  <div class="w100 h100 fc sign-in">
    <div class="flex-grow-1 fr justify-content-center">
      <div
        class="sign-in-card flex-shrink-0 flex-grow-1 fc"
        :style="{ maxWidth: groupMaxWidthString }"
      >
        <div class="flex-grow-2p5"></div>
        <img class="sign-in-logo align-self-center" />
        <div class="flex-grow-1"></div>
        <div class="sign-in-title align-self-center unselectable-text">
          登 录
        </div>
        <div class="flex-grow-2p5"></div>
        <Input
          ref="sid"
          class="sign-in-input"
          :label="Label.UserName"
          :type="Input.PredefinedType.Text"
          :text-color="'var(--sign-in-input-TextColor)'"
          :active-border-color="'var(--sign-in-input-TextColor)'"
          :inactive-border-color="'var(--sign-in-input-TextColor)'"
          :submit-callback="onSidEnter"
          v-model="sid"
          hide-border
        />
        <div class="flex-grow-1p8" v-show="hasAAWPassword"></div>
        <Input
          ref="aaw"
          class="sign-in-input"
          :label="Label.AAWPassword"
          :type="Input.PredefinedType.Password"
          :text-color="'var(--sign-in-input-TextColor)'"
          :active-border-color="'var(--sign-in-input-TextColor)'"
          :inactive-border-color="'var(--sign-in-input-TextColor)'"
          :submit-callback="onAAWEnter"
          v-model="aawPWD"
          hide-border
          v-show="hasAAWPassword"
        />
        <div class="flex-grow-1p8"></div>
        <Input
          ref="vpn"
          class="sign-in-input"
          :label="Label.VPNPassword"
          :type="Input.PredefinedType.Password"
          :text-color="'var(--sign-in-input-TextColor)'"
          :active-border-color="'var(--sign-in-input-TextColor)'"
          :inactive-border-color="'var(--sign-in-input-TextColor)'"
          :submit-callback="onVPNEnter"
          v-model="vpnPWD"
          hide-border
        />
        <div class="flex-grow-1p8"></div>
        <Input
          class="sign-in-input role unselectable-text"
          :label="roleText"
          :type="Input.PredefinedType.Text"
          :text-color="'var(--sign-in-role-TextColor)'"
          :active-border-color="'var(--sign-in-role-TextColor)'"
          :inactive-border-color="'var(--sign-in-role-TextColor)'"
          hide-border
          readonly
          @click.prevent.stop="chooseRole"
        />
        <div class="flex-grow-4" v-if="signing"></div>
        <div class="flex-grow-2p5" v-else></div>
        <div class="fc" v-if="signing">
          <ProgressBar
            class="sign-in-progress-bar"
            :height="'0.5em'"
            :foreground="'var(--sign-in-progress-bar-Color)'"
            :background="'var(--sign-in-progress-bar-BackgroundColor)'"
          />
          <Space
            class="sign-in-progress-bar-space-bottom"
            :height-string="'1.5em'"
          />
          <div
            class="align-self-center sign-in-progress-bar-tip unselectable-text"
            @click.prevent.stop="signIn"
          >
            登录中，约 {{ timeLeft }} 秒
          </div>
        </div>
        <div class="sign-in-button-panel fr" v-else>
          <div
            class="sign-in-sign-out-button align-self-center unselectable-text"
            @click.prevent.stop="callLogout"
          >
            登 出
          </div>
          <div class="flex-grow-1 flex-shrink-0 flex-basis-1em"></div>
          <div
            class="sign-in-sign-in-button align-self-center unselectable-text"
            @click.prevent.stop="signIn"
          >
            登 入
          </div>
        </div>
        <div class="flex-grow-4" v-if="signing"></div>
        <div class="flex-grow-5p5" v-else></div>
        <div
          class="sign-in-copyright align-self-center unselectable-text"
          @click.prevent.stop="icp"
        >
          {{ icpInfo }}
        </div>
        <div class="flex-grow-0p3"></div>
        <div
          class="sign-in-copyright align-self-center unselectable-text"
          @click.prevent.stop="gDialog.help.show()"
        >
          帮助 · 文档
        </div>
        <div class="flex-grow-2p5"></div>
      </div>
    </div>
    <svg
      class="alibaba-icon sign-in-back-icon position-fixed"
      aria-hidden="true"
      @click.prevent.stop="onBackPressed"
    >
      <use xlink:href="#alibaba-icon-back" />
    </svg>
  </div>
</template>

<script>
import Input from "@/component/generic/Input";
import { inject } from "vue";
import ProgressBar from "@/component/generic/ProgressBar";
import Space from "@/component/generic/Space";
import Predefined from "@/js/predefined";
import Login from "@/js/login";
import Store from "@/js/store";
import Page from "@/js/page";
import Util from "@/js/util";
import Tip from "@/js/tip";
import Counter from "@/js/type/Counter";
import Config from "@/js/config";
import GuetcobHelper from "@/js/js/guetcob-helper";

const Label = {
  UserName: "学号",
  AAWPassword: "教务系统密码",
  VPNPassword: "智慧校园密码",
};

export default {
  name: "SignIn",
  components: { Space, ProgressBar, Input },
  setup() {
    return {
      askForSMSCode: inject("askForSMSCode"),
      gDialog: inject("gDialog"),
      gParsedData: inject("gParsedData"),
      gNumber: inject("gNumber"),
      onBackPressed: inject("onBackPressed"),
      gRoleChosen: inject("gRoleChosen"),
      showSnackBar: inject("showSnackBar"),
      sPageProgressBarShown: inject("sPageProgressBarShown"),
      refreshData: inject("refreshData"),
      sThemeColorCSSName: inject("sThemeColorCSSName"),
      callLogout: inject("callLogout"),
      resetRole: inject("resetRole"),
    };
  },
  data() {
    //
    return {
      Label,
      Input,
      signing: false,
      sid: null,
      aawPWD: null,
      vpnPWD: null,
      timeLeft: null,
      counter: new Counter(
        Config.loginAndFetchDurationS,
        (value) => (this.timeLeft = value)
      ),
    };
  },
  computed: {
    groupMaxEM() {
      return 20;
    },
    groupMaxWidthString() {
      return this.groupMaxEM + "em";
    },
    roleDes() {
      return Predefined.RoleMap.get(this.gRoleChosen);
    },
    roleText() {
      return this.roleDes == null ? "选择身份" : this.roleDes;
    },
    hasAAWPassword() {
      return this.gRoleChosen === Predefined.Role.InternationalStudent;
    },
    allValid() {
      return (
        this.roleDes != null &&
        this.$refs.sid.isValid() &&
        (!this.hasAAWPassword || this.$refs.aaw.isValid()) &&
        this.$refs.vpn.isValid()
      );
    },
    icpInfo() {
      switch (window.location.hostname.toLowerCase()) {
        // GUET课程表.com
        case "xn--guet-e90kv52etxf.com".toLowerCase():
          return "桂ICP备2021005052号-2";
        // guetcob.com
        case "guetcob.com".toLowerCase():
          return "桂ICP备2021005052号-1";
        //yi-xi.tech
        case "yi-xi.tech".toLowerCase():
          return "沪ICP备2022032982号-1";
        //桂电课程表.cn
        case "xn--6xv310aewdi3rdhe.cn".toLowerCase():
          return "沪ICP备2022032982号-2";
        //桂电课程表.com
        case "xn--6xv310aewdi3rdhe.com".toLowerCase():
          return "沪ICP备2022032982号-3";
        //弋兮.com
        case "xn--75qs54a.com".toLowerCase():
          return "沪ICP备2022032982号-5";
        //GUET课程表.cn
        case "xn--guet-e90kv52etxf.cn".toLowerCase():
          return "沪ICP备2022032982号-6";

        default:
          return "GUET课程表";
      }
    },
  },
  methods: {
    icp() {
      window.open("https://beian.miit.gov.cn");
    },
    blurAll() {
      document.querySelectorAll(":focus").forEach((el) => el.blur());
    },
    signIn() {
      if (this.allValid) {
        this.blurAll();
        //
        this.sPageProgressBarShown(true);
        this.signing = true;
        this.counter.start();
        //
        const sid = this.sid;
        const vpnP = this.vpnPWD;
        const aawP = this.aawPWD;
        const role = this.gRoleChosen;
        new Promise((resolve, reject) => {
          let loginPromise;
          switch (role) {
            case Predefined.Role.Student:
              {
                loginPromise = Login.login(sid, vpnP, aawP, false);
              }
              break;
            default: {
              const e = new Error();
              e.tip = "开发者正在夜以继日地开发此功能，再等等吧~";
              e.justPrintIt = true;
              return reject(e);
            }
          }
          loginPromise
            .then(async (res) => {
              let deepTime = 0;
              while (res instanceof GuetcobHelper.SessionBlocker) {
                switch (res.Blocker.Type) {
                  case GuetcobHelper.BlockerTypes.SMSCode:
                    res = await res.Resolve(
                      await this.askForSMSCode(
                        sid,
                        res.Blocker.Challenge,
                        ++deepTime,
                        this.counter
                      )
                    );
                    break;
                  case GuetcobHelper.BlockerTypes.ConfirmToSendSMSCode:
                    res = await res.Resolve("1");
                    break;
                  default:
                    throw new GuetcobHelper.Errors.ErrorInternalServerError(
                      "未知的 Blocker Type"
                    );
                }
              }
              return res;
            })
            .then((res) => {
              resolve(res);
            })
            .catch((e) => {
              reject(e);
            });
        })
          .then(async (res) => {
            await Store.backup();
            await Store.deactivateAllUser();
            await Store.loginStore(sid, vpnP, aawP, true, res);
            return this.refreshData();
          })
          .catch(async (e) => {
            if (Util.empty(e.tip)) {
              await Store.restore();
              await this.refreshData();
              e.tip = Tip.ErrorParseData;
            }
            if (e.justPrintIt) {
              return e.tip;
            } else {
              return "登录失败！" + Util.getEmptyStringFromNull(e.tip);
            }
          })
          .then((reason) => {
            this.counter.stop();
            this.signing = false;
            this.sPageProgressBarShown(false);
            if (reason) {
              this.showSnackBar(reason);
              if (
                reason.includes(
                  new GuetcobHelper.Errors.ErrorCASUserPermissionsInsufficient()
                    .tip
                )
              ) {
                this.gDialog.insufficient.show();
              } else if (
                reason.includes(
                  new GuetcobHelper.Errors.ErrorCASAccountNeedsImprovement().tip
                )
              ) {
                this.gDialog.improve.show();
              }
            } else {
              this.showSnackBar("登录成功！");
              this.$router.replace(Page.StartPage.path);
            }
          });
      }
    },
    chooseRole() {
      // this.blurAll()
      // this.gDialog.roleSelector.show()
    },
    onSidEnter() {
      if (this.hasAAWPassword) {
        this.$refs.aaw.focusInput();
      } else {
        this.$refs.vpn.focusInput();
      }
    },
    onAAWEnter() {
      this.$refs.vpn.focusInput();
    },
    onVPNEnter() {
      if (this.roleDes == null) {
        this.chooseRole();
      } else {
        this.signIn();
      }
    },
  },
  mounted() {
    this.sThemeColorCSSName("--sign-in-BackgroundColor");
    if (this.gParsedData && this.gParsedData.username) {
      this.sid = this.gParsedData.username;
    }
  },
  unmounted() {
    this.resetRole();
  },
};
</script>

<style scoped></style>
