// ==UserScript==
// @name        GUET课程表小助手
// @description 此脚本用于实现 GUET课程表 的登录、同步完全本地化。使用此脚本能够直接与学校官方服务器通信，从而使得用户名密码、教务数据等敏感信息无需流经开发者服务器。
// @namespace   https://github.com/the-eric-kwok
// @icon        https://s2.loli.net/2022/03/18/OML4G3KnXwo1YQm.png
// @match       *://guetcob.com/*
// @match       *://guetcob.com:*/*
// @match       *://xn--guet-e90kv52etxf.com/*
// @match       *://xn--guet-e90kv52etxf.com:*/*
// @match       *://guetkcb.com/*
// @match       *://guetkcb.com:*/*
// @match       *://v.guet.edu.cn/*
// @match       *://cas.guet.edu.cn/*
// @connect     10.0.1.5
// @connect     v.guet.edu.cn
// @connect     cas.guet.edu.cn
// @connect     bkjw.guet.edu.cn
// @version     1.3.6
// @author      EricKwok
// @grant       GM_xmlhttpRequest
// @grant       GM_setValue
// @grant       GM_getValue
// @grant       GM_deleteValue
// @grant       unsafeWindow
// @run-at      document-start
// ==/UserScript==

"use strict";
var crypto;
// eslint-disable-next-line no-undef
if (typeof GM_info !== "undefined" && GM_info != null) {
  let script = document.createElement("script");
  script.src =
    "https://cdn.bootcdn.net/ajax/libs/crypto-js/4.1.1/crypto-js.min.js";
  script.onload = function () {
    console.log("crypto-js 已加载");
    // eslint-disable-next-line no-undef
    crypto = CryptoJS;
  };
  document.head.appendChild(script);
} else {
  crypto = require("crypto-js");
}

/**
 * 判断输入是否为 null / undefined 或空字符串
 * @param {Any} obj
 * @returns {Boolean}
 */
function isNullOrEmptyString(obj) {
  return obj == null || obj === "";
}

/**
 * 判断传入对象为一个 object，并且给定的键值包含在该对象内
 * @param {object} obj 对象
 * @param {string} key 键值
 * @returns {Boolean} 若对象是 object 且包含给定键值，则返回 true，否则返回 false
 */
function isInObject(obj, key) {
  return typeof obj === "object" && key in obj;
}

class HelperError extends Error {
  /**
   * 油猴脚本错误基类
   * @param {String} code 自定义错误码
   * @param {String} tip 展示给用户的错误信息
   * @param {String} details 错误细节，是开发者专用的，不适合展示给用户的内容
   * @param {Int} httpCode HTTP 状态码
   */
  constructor(
    code = "helper:unknown_error",
    tip = "出错啦，再试一次吧~ 若仍未解决，请联系开发者~",
    httpCode = 500,
    details
  ) {
    super(tip);
    this.code = code;
    this.tip = tip;
    this.httpCode = httpCode;
    this.details = details;
  }
}

class ErrorCannotGetSalt extends HelperError {
  /**
   * 无法获取盐值
   */
  constructor() {
    super("upstream:cannot_get_salt", "数据提取失败，请重试~", 401);
  }
}

class ErrorWrongCASPassword extends HelperError {
  /**
   * CAS: 学号或 CAS 密码错误
   */
  constructor(chanceRemaining) {
    super(
      "upstream:cas_password_error",
      `智慧校园用户名或密码错误${
        chanceRemaining == null
          ? ``
          : `，还剩 ${chanceRemaining} 次机会，机会耗尽账号将临时锁定`
      }，请仔细检查输入~`,
      401
    );
  }
}

class ErrorCASWrongCaptcha extends HelperError {
  /**
   * CAS: 验证码错误
   */
  constructor() {
    super("upstream:cas_wrong_captcha", "验证码识别错误，请稍后再试~", 401);
  }
}

class ErrorCASUserBlockedTemporary extends HelperError {
  /**
   * CAS: 用户账户被冻结
   */
  constructor() {
    super(
      "upstream:cas_user_blocked_temporary",
      "用户账户被冻结，请稍后再试~",
      401
    );
  }
}
class ErrorCASUserPermissionsInsufficient extends HelperError {
  /**
   * CAS: 账号权限不足，请重试~
   */
  constructor() {
    super(
      "upstream:cas_user_permissions_insufficient",
      "账号权限不足，请重试~",
      403
    );
  }
}

class ErrorCASAccountNeedsImprovement extends HelperError {
  /**
   * CAS: 账号信息待完善，请完善账号信息~
   */
  constructor() {
    super(
      "upstream:cas_account_needs_improvement",
      "账号信息待完善，请完善账号信息~",
      403
    );
  }
}

class ErrorCASTokenInvalid extends HelperError {
  /**
   * CAS: 非法的 token，重试即可~
   */
  constructor() {
    super("upstream:cas_token_invalid", "系统坏了，请重试~", 401);
  }
}

class ErrorLoginCASSomethingWrong extends HelperError {
  /**
   * 登录 CAS 时发生错误
   * @param {String} details 错误细节，是开发者专用的，不适合展示给用户的内容
   */
  constructor(details) {
    super("upstream:cas_something_wrong", undefined, undefined, details);
  }
}

class ErrorWrongAAWPassword extends HelperError {
  /**
   * 学号或教务系统密码错误
   */
  constructor() {
    super(
      "upstream:aaw_password_error",
      "教务系统用户名或密码错误，请仔细检查输入~",
      401
    );
  }
}

class ErrorWrongAAWCheckCode extends HelperError {
  /**
   * 教务系统验证码错误
   */
  constructor() {
    super(
      "upstream:aaw_check_code_error",
      "教务系统验证码错误，再试一次吧~",
      401
    );
  }
}

class ErrorLoginAAWSomethingWrong extends HelperError {
  /**
   * 登录教务系统时发生错误
   * @param {String} details 错误细节，是开发者专用的，不适合展示给用户的内容
   */
  constructor(details) {
    super("upstream:aaw_something_wrong", undefined, undefined, details);
  }
}

class ErrorFetchDataSomethingWrong extends HelperError {
  /**
   * 拉取信息时出错
   * @param {String} details 错误细节，是开发者专用的，不适合展示给用户的内容
   */
  constructor(details) {
    super(
      "upstream:fetch_data_error",
      "没拉到数据，再试一次吧~",
      undefined,
      details
    );
  }
}

class ErrorBusy extends HelperError {
  /**
   * 静态锁被占用时出错
   * @param {String} details 错误细节，是开发者专用的，不适合展示给用户的内容
   */
  constructor(details) {
    super(
      "helper:busy",
      "后台正在自动登录/同步中，稍后再试吧~",
      undefined,
      details
    );
  }
}

class ErrorNotImplemented extends HelperError {
  /** 功能未实现错误 */
  constructor() {
    super(
      "helper:not_implemented",
      "开发者正在夜以继日地开发此功能，再等等吧~",
      501
    );
  }
}

const GuetcobHelperErrors = {
  HelperError,
  ErrorWrongCASPassword,
  ErrorLoginCASSomethingWrong,
  ErrorCASWrongCaptcha,
  ErrorCASUserBlockedTemporary,
  ErrorCASUserPermissionsInsufficient,
  ErrorCASAccountNeedsImprovement,
  ErrorCASTokenInvalid,
  ErrorWrongAAWPassword,
  ErrorLoginAAWSomethingWrong,
  ErrorWrongAAWCheckCode,
  ErrorFetchDataSomethingWrong,
  ErrorNotImplemented,
  ErrorBusy,
};

class GuetcobEncrypter {
  static randStr(length) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  }
  static getAesString(data, key0, iv0) {
    key0 = key0.replace(/(^\s+)|(\s+$)/g, "");
    var key = crypto.enc.Utf8.parse(key0);
    var iv = crypto.enc.Utf8.parse(iv0);
    var encrypted = crypto.AES.encrypt(data, key, {
      iv: iv,
      mode: crypto.mode.CBC,
      padding: crypto.pad.Pkcs7,
    });
    return encrypted.toString();
  }
  static encryptPassword(data, aesKey) {
    if (!aesKey) {
      return data;
    }
    const a64 = "A".repeat(64);
    const a16 = "A".repeat(16);
    var encrypted = this.getAesString(a64 + data, aesKey, a16);
    return encrypted;
  }
}

class GuetcobHelper {
  urls = {
    CAS: {
      base_url: {
        vpn: "https://v.guet.edu.cn/https/77726476706e69737468656265737421f3f652d220256d44300d8db9d6562d",
        normal: "https://cas.guet.edu.cn",
      },
    },
    VPN: {
      base_url: {
        vpn: "https://v.guet.edu.cn/https/77726476706e69737468656265737421e6b94689222426557a1dc7af96",
        normal: "https://v.guet.edu.cn",
      },
    },
    System: {
      base_url: {
        vpn: "https://v.guet.edu.cn/https/77726476706e69737468656265737421f2fc4b8b69377d556a468ca88d1b203b",
        normal: "https://bkjw.guet.edu.cn",
      },
    },
    ICampus: {
      base_url: {
        vpn: "https://v.guet.edu.cn/http/77726476706e69737468656265737421f9f4409137257b1e791d8cb8d6502720b11d95",
        normal: "http://icampus.guet.edu.cn",
      },
    },
  };

  personalInfoStu;
  personalInfoPerson;
  currentTerm;
  termList;
  periodList = [
    {
      name: "1",
      description: "第一大节",
      start: {
        hour: 8,
        minute: 25,
      },
      end: {
        hour: 10,
        minute: 0,
      },
    },
    {
      name: "2",
      description: "第二大节",
      start: {
        hour: 10,
        minute: 25,
      },
      end: {
        hour: 12,
        minute: 0,
      },
    },
    {
      name: "3",
      description: "第三大节",
      start: {
        hour: 14,
        minute: 30,
      },
      end: {
        hour: 16,
        minute: 5,
      },
    },
    {
      name: "4",
      description: "第四大节",
      start: {
        hour: 16,
        minute: 30,
      },
      end: {
        hour: 18,
        minute: 5,
      },
    },
    {
      name: "晚",
      description: "第五大节/晚上第一大节",
      start: {
        hour: 19,
        minute: 30,
      },
      end: {
        hour: 20,
        minute: 15,
      },
    },
    {
      name: "6",
      description: "第六大节/晚上第二大节",
      start: {
        hour: 21,
        minute: 20,
      },
      end: {
        hour: 22,
        minute: 5,
      },
    },
    {
      name: "午",
      description: "中午",
      start: {
        hour: 12,
        minute: 0,
      },
      end: {
        hour: 14,
        minute: 30,
      },
    },
    {
      name: "56",
      description: "第五第六大节/晚上第一第二大节",
      start: {
        hour: 19,
        minute: 30,
      },
      end: {
        hour: 22,
        minute: 5,
      },
    },
  ];

  rootPromise = Promise.resolve();
  cookieJar;
  useVpn = true;
  static lock = null;

  /**
   * 实例化一个 GUET 课程表助手
   * @param {CookieJar} cookieJar 设置存取 Cookie 的容器 (浏览器不需要)
   */
  constructor(cookieJar = null) {
    this.cookieJar = cookieJar;
    this.isLAN().then((value) => (this.useVpn = !value));
  }

  async tryLock() {
    if (typeof window === "object" && window != null) {
      if (GuetcobHelper.lock == null) {
        GuetcobHelper.lock = this;
      }
      if (GuetcobHelper.lock !== this) {
        throw new ErrorBusy();
      }
    }
  }

  /** 将 helper 全局锁解锁 */
  unlock() {
    if (GuetcobHelper.lock === this) {
      GuetcobHelper.lock = null;
    }
  }

  /**
   * 判断是否处于校园网环境内
   * @returns {Promise<Boolean>} 如处于校园网内则返回 true，否则返回 false
   */
  isLAN() {
    return new Promise((resolve) => {
      // eslint-disable-next-line no-undef
      GM_xmlhttpRequest({
        url: "http://10.0.1.5",
        method: "GET",
        timeout: 500,
        onload: () => resolve(true),
        onerror: () => resolve(false),
        ontimeout: () => resolve(false),
      });
    });
  }

  /**
   * 获取盐值和 execution，注意这一步默认会清除 Cookie
   * @param {Object} option
   * @param {Boolean} [option.doNotClearCookie=false] 将此选项设置为 true 可以防止此步骤清除 Cookie
   */
  async getSalt({ doNotClearCookie = false } = {}) {
    let result = await this.registerService(
      this.useVpn
        ? `${this.urls.VPN.base_url.normal}/login?cas_login=true`
        : this.urls.System.base_url.normal,
      { shouldClearCookie: !doNotClearCookie }
    );
    if (
      result != null &&
      isInObject(result, "salt") &&
      !isNullOrEmptyString(result.salt)
    ) {
      return result;
    }
    throw new ErrorCannotGetSalt();
  }

  /**
   * 获取学生的完整信息
   * @param {string} studentId 学号
   * @param {string} vpnPassword 统一认证密码
   * @param {string} aawPassword 教务系统密码
   * @param {Object} option
   * @param {Boolean} [option.isInternational=false] 是否为国际学院
   * @param {Boolean} [option.isPlainPassword=false] 是否为明文密码
   * @param {Boolean} [option.shouldClearCookie=false] 是否需要清除 cookie
   * @returns 学生的完整信息
   */
  async fetch(
    studentId,
    vpnPassword,
    aawPassword,
    {
      isInternational = false,
      isPlainPassword = false,
      shouldClearCookie = false,
    } = {}
  ) {
    // 上锁，避免用户多次尝试拉取数据造成干扰
    await this.tryLock();
    try {
      // 登录教务系统
      await this.login(studentId, vpnPassword, aawPassword, {
        isInternational,
        isPlainPassword,
        shouldClearCookie,
      });

      // 提前拉取被后续操作依赖的信息，如个人信息等
      try {
        this.personalInfoStu = await this.getPersonalInfoStu();
        this.personalInfoPerson = await this.getPersonalInfoPerson();
        this.currentTerm = await this.getCurrentTerm();
        this.termList = await this.getTermList();
      } catch (err) {
        console.error(err + err.stack);
        throw new ErrorFetchDataSomethingWrong(
          "执行 prefetch 时出错，原因：" + err
        );
      }

      // 触发系统更新数据，此处 allSettled 用于忽略错误
      await Promise.allSettled([
        this.updateFinancial(),
        this.updateGraduateInfo(),
        this.personalInfoStu.grade <= 2018
          ? this.updateInnovationInformation()
          : null,
      ]);

      // 正式的拉取步骤，此处的 allSettled 是为了忽略错误，并且把拉取失败的字段置为 null，
      // 并非是为了并发，实际上 asyncRequest 方法已经把请求强制改成了串行，
      // 因为在教务系统上并发比串行更慢且更不可靠。
      let fetch = await Promise.allSettled([
        new Date().getTime() * 1000000,
        this.termList,
        this.periodList,
        this.getCourseList(),
        this.getExpList(),
        this.getExamList(),
        this.personalInfoPerson,
        this.personalInfoStu,
        this.getValidCreditList(),
        this.getPlanCourseGradeList(),
        this.getGradeList(),
        this.getCETList(),
        this.getSelectedCourseList(),
        this.getMakeUpExamList(),
        this.getExpGradeList(),
        this.getGraduationInformation(),
        this.getGraduationRequirementList(),
        this.personalInfoStu.grade <= 2018
          ? this.getInnovationPointInformation()
          : null,
        this.getChangingMajorInformation(),
        this.currentTerm,
        isInternational,
        this.getHourList(),
        isInternational ? "InternationalStudent" : "Student",
      ]);
      for (let i = 0; i < fetch.length; i++) {
        if (fetch[i].status === "rejected") {
          console.error(fetch[i].reason);
          fetch[i] = null;
        } else fetch[i] = fetch[i].value;
      }
      return {
        lastUpdateUnixNanoTimestamp: fetch[0], // number 运行时时间戳
        termList: fetch[1], // 学期列表
        periodList: fetch[2], // 预定义的时间段列表
        courseList: fetch[3], // 理论课表列表
        expList: fetch[4], // 实验课表列表
        examList: fetch[5], // 考试安排列表
        personalInfoPerson: fetch[6], // 个人信息
        personalInfoStu: fetch[7], // 学生信息
        validCreditList: fetch[8], // 有效学分列表
        planCourseGradeList: fetch[9], // 计划课程列表
        gradeList: fetch[10], // 理论课成绩列表
        cetList: fetch[11], // 四六级考试成绩列表
        selectedCourseList: fetch[12], // 已选课程列表
        makeUpExamList: fetch[13], // 补考缓考列表
        expGradeList: fetch[14], // 实验成绩列表
        graduationInformation: fetch[15], // 毕业学位信息
        graduationRequirementList: fetch[16], // 毕业条件
        innovationPointInformation: fetch[17], // 创新创业积分
        changingMajorInformation: fetch[18], // 转专业信息
        currentTerm: fetch[19], // string 当前学期
        isInternational: fetch[20], // bool 是否为国际学院
        hourList: fetch[21], // 教务系统返回的时间段列表
        role: fetch[22], // 身份
      };
    } finally {
      this.unlock();
    }
  }

  /**
   * 用户登录总入口
   * @param {String} studentId 学生学号
   * @param {String} vpnPassword 统一认证密码
   * @param {String} aawPassword 教务系统密码
   * @param {Object} option
   * @param {Boolean} [option.isInternational=false] 是否为国际学院
   * @param {Boolean} [option.isPlainPassword=false] 是否为明文密码
   * @param {Boolean} [option.shouldClearCookie=false] 是否需要清除 cookie
   * @param {Number} [retryLimit=5] 最大重试次数
   */
  async login(
    studentId,
    vpnPassword,
    aawPassword,
    {
      isInternational = false,
      isPlainPassword = false,
      shouldClearCookie = false,
    } = {},
    retryLimit = 5
  ) {
    try {
      if (shouldClearCookie) await this.getSalt().catch(() => {});
      if (this.useVpn) {
        await this.loginCAS(studentId, vpnPassword, {
          isPlainPassword,
        });
      }
      await this.loginEdu(studentId, vpnPassword, aawPassword, {
        isInternational,
        isPlainPassword,
      });
    } catch (error) {
      if (
        !(
          error instanceof ErrorCASWrongCaptcha ||
          (error.tip != null && error.tip.includes("登录凭证不可用"))
        )
      )
        throw error;
      if (retryLimit > 0) {
        await this.login(
          studentId,
          vpnPassword,
          aawPassword,
          {
            isInternational,
            isPlainPassword,
            shouldClearCookie,
          },
          --retryLimit
        );
        return;
      }
      throw error;
    }
  }

  /**
   * 处理验证码的流程
   * @returns {Promise<String>|Promise<void>} 验证码
   */
  async resolveCaptcha() {
    let xhr = await this.asyncRequest({
      url: `${
        this.useVpn ? this.urls.CAS.base_url.vpn : this.urls.CAS.base_url.normal
      }/authserver/getCaptcha.htl`,
      method: "GET",
      responseType: "blob",
    });
    if (xhr && xhr.response) {
      return this.parseCaptcha(xhr.response);
    }
  }

  /**
   * OCR 解析图片验证码
   * @param {Blob} data 验证码图片的二进制数据
   * @returns {Promise<String>|Promise<void>} 验证码
   */
  async parseCaptcha(data) {
    let xhr = await this.asyncRequest({
      url:
        // eslint-disable-next-line no-undef
        (typeof GM_info !== "undefined" && GM_info != null
          ? ""
          : "https://guetcob.com") + "/ocr/verification-code/guet/cas",
      method: "POST",
      data: data,
      isBlob: true,
    });
    if (xhr == null || isNullOrEmptyString(xhr.responseHeaders)) return null;
    let code = xhr.responseHeaders
      .split("\n")
      .map((item) => item.trim())
      .filter((e) => /code:/i.test(e));
    if (code.length > 0) {
      code = code[0];
      return code.replace(/Code:\s*/i, "");
    }
    return null;
  }

  /**
   * 检查是否需要验证码
   * @param {Number} studentId 学号
   * @returns {Promise<Boolean>} 是否需要验证码
   */
  async checkIsNeedCaptcha(studentId) {
    let request = await this.asyncRequest({
      url: `${
        this.useVpn ? this.urls.CAS.base_url.vpn : this.urls.CAS.base_url.normal
      }/authserver/checkNeedCaptcha.htl?${this.obj2QueryString({
        username: studentId,
      })}`,
      method: "GET",
    }).catch((err) => {
      throw new ErrorLoginCASSomethingWrong(`检查是否需要验证码时出错` + err);
    });
    if (request == null)
      throw new ErrorLoginCASSomethingWrong(
        "检查是否需要验证码时出错，request 不应为空"
      );
    if (request.status !== 200) {
      throw new ErrorLoginCASSomethingWrong(
        `检查是否需要验证码时出错，错误码：` +
          request.status +
          ", responseText: " +
          request.responseText
      );
    }
    if (
      request != null &&
      !isNullOrEmptyString(request.responseText) &&
      typeof request.responseText === "string"
    ) {
      try {
        let parsed = JSON.parse(request.responseText);
        if (
          parsed != null &&
          typeof parsed === "object" &&
          "isNeed" in parsed
        ) {
          return parsed.isNeed;
        }
      } catch (e) {
        throw new ErrorLoginCASSomethingWrong(
          "检查是否需要验证码时出错，处理 json 时出错，错误的 json 为：" +
            request.responseText
        );
      }
    }
    return false;
  }

  /**
   * 将要请求的域名注册到教务系统
   * @param {String} service 请求的目标服务地址
   * @param {Object} option
   * @param {Boolean} [option.shouldClearCookie=false] 是否应清除 Cookie
   * @returns 一个包含 salt 盐值和 execution 值的字典
   */
  async registerService(service, { shouldClearCookie = false } = {}) {
    let request = await this.asyncRequest({
      url: `${
        this.useVpn ? this.urls.CAS.base_url.vpn : this.urls.CAS.base_url.normal
      }/authserver/login?${this.obj2QueryString({
        service: service,
      })}`,
      method: "GET",
      headers: shouldClearCookie
        ? {
            cookie: "",
          }
        : {},
    }).catch((err) => {
      throw new ErrorLoginCASSomethingWrong(
        `将 ${service} 注册到教务系统时出错` + err
      );
    });
    if (request == null) {
      throw new ErrorLoginCASSomethingWrong(
        `将 ${service} 注册到教务系统时出错，请求不应为空`
      );
    }
    if (request.status !== 200) {
      throw new ErrorLoginCASSomethingWrong(
        `将 ${service} 注册到教务系统时出错，错误码：` +
          request.status +
          ", responseText: " +
          request.responseText
      );
    }

    let saltMatch = /"pwdEncryptSalt".*?value="(.*?)"/i.exec(
      request.responseText
    );
    let executionMatch = /"execution".*?value="(.*?)"/i.exec(
      request.responseText
    );

    if (saltMatch == null || executionMatch == null) {
      // 无匹配则已登录
      return null;
    }
    if (
      isNullOrEmptyString(saltMatch[1]) ||
      isNullOrEmptyString(executionMatch[1])
    ) {
      // 未知情况，如果出现进一步测试教务系统行为
      throw new ErrorLoginCASSomethingWrong(
        "盐值或execution不应为空，请检查教务系统是否出现更新"
      );
    }
    return {
      salt: saltMatch[1],
      execution: executionMatch[1],
    };
  }

  /**
   * 登录教务系统统一认证
   * @param {String} studentId 学生学号
   * @param {String} vpnPassword 统一认证密码
   * @param {Object} option
   * @param {Boolean} [option.isPlainPassword=false] 是否为明文密码
   */
  async loginCAS(studentId, vpnPassword, { isPlainPassword = false } = {}) {
    if (isNullOrEmptyString(studentId) || isNullOrEmptyString(vpnPassword)) {
      throw new ErrorLoginCASSomethingWrong(
        "loginCAS 方法的参数 studentId、vpnPassword 不应为空"
      );
    }
    // 尝试获取盐值（如果盐值为空则意味着已登录）
    let result = await this.registerService(
      `${this.urls.VPN.base_url.normal}/login?cas_login=true`
    );
    let isNeedCaptcha = await this.checkIsNeedCaptcha(studentId);
    let captcha = "";
    if (isNeedCaptcha) {
      captcha = await this.resolveCaptcha();
    }
    if (
      result != null &&
      isInObject(result, "execution") &&
      isInObject(result, "salt") &&
      result.execution != null &&
      result.salt != null
    ) {
      // 获取 VPN ST
      let request = await this.asyncRequest({
        url: `${this.urls.CAS.base_url.vpn}/authserver/login`,
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: this.obj2QueryString({
          username: `${studentId}`,
          password: `${
            isPlainPassword
              ? GuetcobEncrypter.encryptPassword(vpnPassword, result.salt)
              : vpnPassword
          }`,
          _eventId: "submit",
          cllt: "userNameLogin",
          dllt: "generalLogin",
          execution: `${result.execution}`,
          captcha: captcha,
        }),
      }).catch((err) => {
        this.loginErrorHandler(err);
        throw new ErrorLoginCASSomethingWrong("VPN 登录 CAS 时出错：" + err);
      });
      if (request == null) {
        throw new ErrorLoginCASSomethingWrong(
          "VPN 登录 CAS 时出错，request 不应为空"
        );
      }
      if (request.status !== 200) {
        throw new ErrorLoginCASSomethingWrong(
          "VPN 登录 CAS 时出错，错误码：" +
            request.status +
            ", responseText: " +
            request.responseText
        );
      }
      this.loginErrorHandler(request);
    }
  }

  /**
   * 登录教务系统
   * @param {String} studentId 学生学号
   * @param {String} vpnPassword 统一认证密码
   * @param {String} aawPassword 教务系统密码
   * @param {Object} option
   * @param {Boolean} [option.isInternational=false] 是否为国际学院
   * @param {Boolean} [option.isPlainPassword=false] 是否为明文密码
   */
  async loginEdu(
    studentId,
    vpnPassword,
    aawPassword,
    { isInternational = false, isPlainPassword = false } = {}
  ) {
    if (isInternational) {
      throw new ErrorNotImplemented();
    }
    let result = await this.registerService(
      `${this.urls.System.base_url.normal}`
    );
    let isNeedCaptcha = await this.checkIsNeedCaptcha(studentId);
    let captcha = "";
    if (isNeedCaptcha) {
      captcha = await this.resolveCaptcha();
    }
    if (
      result != null &&
      isInObject(result, "execution") &&
      isInObject(result, "salt") &&
      result.execution != null &&
      result.salt != null
    ) {
      // 获取 VPN ST
      let request = await this.asyncRequest({
        url: `${
          this.useVpn
            ? this.urls.CAS.base_url.vpn
            : this.urls.CAS.base_url.normal
        }/authserver/login`,
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: this.obj2QueryString({
          username: `${studentId}`,
          password: `${
            isPlainPassword
              ? GuetcobEncrypter.encryptPassword(vpnPassword, result.salt)
              : vpnPassword
          }`,
          _eventId: "submit",
          cllt: "userNameLogin",
          dllt: "generalLogin",
          execution: `${result.execution}`,
          captcha: captcha,
        }),
      }).catch((err) => {
        this.loginErrorHandler(err);
        throw new ErrorLoginCASSomethingWrong("校园网登录 CAS 时出错：" + err);
      });
      if (request == null) {
        throw new ErrorLoginCASSomethingWrong(
          "校园网登录 CAS 时出错，request 不应为空"
        );
      }
      if (request.status !== 200) {
        throw new ErrorLoginCASSomethingWrong(
          "校园网登录 CAS 时出错，错误码：" +
            request.status +
            ", responseText: " +
            request.responseText
        );
      }
      this.loginErrorHandler(request);
    }
  }

  loginErrorHandler(err) {
    if (err == null || typeof err.responseText !== "string") {
      throw new ErrorLoginCASSomethingWrong(
        "loginErrorHandler 时出错：err 为空或 err.responseText 非字符串"
      );
    }
    if (err.responseText.includes("用户名或者密码有误")) {
      throw new ErrorWrongCASPassword();
    }
    if (err.responseText.includes(">验证码错误")) {
      throw new ErrorCASWrongCaptcha();
    }
    if (err.responseText.includes("账号已经被冻结")) {
      throw new ErrorCASUserBlockedTemporary();
    }
    if (err.responseText.includes("应用没有权限")) {
      throw new ErrorCASUserPermissionsInsufficient();
    }
    if (err.responseText.includes("信息待完善")) {
      throw new ErrorCASAccountNeedsImprovement();
    }
    if (err.responseText.includes("非法的Token")) {
      throw new ErrorCASTokenInvalid();
    }
    let tryMatch = /id="showErrorTip".*?<span>(.*?)<\/span>/i.exec(
      err.responseText
    );
    if (tryMatch && tryMatch[1].length > 0) {
      throw new HelperError(undefined, tryMatch[1], 401, tryMatch[1]);
    }
  }

  /**
   * 见 [GM_xmlhttpRequest](https://wiki.greasespot.net/GM.xmlHttpRequest)
   * @param {Object} details
   * @returns {Promise<ResponseObject>}
   */
  async asyncRequest(details, maxRetries = 3) {
    if (maxRetries < 0) throw new Error("已达到最大重试次数");
    let self = this;
    // 利用链式调用的特性，把并发过来的请求串在前一个请求的后面，由此将并发的调用转换为串行请求
    this.rootPromise = this.rootPromise.then(
      () =>
        new Promise(function (resolve, reject) {
          let originalOnload = details.onload;
          let originalOnerror = details.onerror;
          let originalOntimeout = details.ontimeout;
          details.onload = function (responseObject) {
            if (responseObject.status.toString().startsWith("2")) {
              if (originalOnload) {
                originalOnload(responseObject);
              }
              resolve(responseObject);
            } else {
              if (originalOnerror) {
                originalOnerror(responseObject);
              }
              reject(responseObject);
            }
          };
          details.onerror = function (responseObject) {
            if (originalOnerror) {
              originalOnerror(responseObject);
            }
            reject(responseObject);
          };
          details.ontimeout = function (responseObject) {
            if (originalOntimeout) {
              originalOntimeout(responseObject);
            }
            reject(responseObject);
          };
          let originalOnabort = details.onabort;
          details.onabort = function (responseObject) {
            if (originalOnabort) {
              originalOnabort(responseObject);
            }
            reject(responseObject);
          };
          // eslint-disable-next-line no-undef
          GM_xmlhttpRequest(details, self.cookieJar);
        }),
      () => {
        if (typeof window === "object") {
          this.rootPromise = self.asyncRequest(details, --maxRetries);
        }
      }
    );
    return this.rootPromise;
  }

  obj2QueryString(obj) {
    let list = [];
    for (let key in obj) {
      list.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`);
    }
    return list.join("&");
  }

  /**
   * 获取当前学期
   * @returns {Promise<string>} 当前学期
   */
  async getCurrentTerm() {
    let xhr = await this.asyncRequest({
      url: `${
        this.useVpn
          ? this.urls.System.base_url.vpn
          : this.urls.System.base_url.normal
      }/Comm/CurTerm`,
      method: "POST",
      headers: {
        "Accept-Encoding": "gzip",
        Connection: "keep-alive",
        "x-requested-with": "XMLHttpRequest",
      },
    }).catch((err) => {
      throw new ErrorFetchDataSomethingWrong("获取当前学期出错" + err);
    });
    if (xhr == null) {
      throw new ErrorFetchDataSomethingWrong("获取当前学期出错，xhr 不应为空");
    }
    if (xhr.status !== 200) {
      throw new ErrorFetchDataSomethingWrong(
        "获取当前学期出错，状态码：" + xhr.status
      );
    }
    if (
      isNullOrEmptyString(xhr.responseText) ||
      typeof xhr.responseText !== "string" ||
      !(xhr.responseText.startsWith("{") || xhr.responseText.startsWith("["))
    )
      throw new ErrorFetchDataSomethingWrong(
        "获取当前学期时教务系统返回的内容为空或格式错误"
      );
    let response = JSON.parse(xhr.responseText);
    return response[0];
  }

  /**
   * 获取学期列表
   * @returns {Promise<Array>} 学期列表
   */
  async getTermList() {
    let xhr = await this.asyncRequest({
      url: `${
        this.useVpn
          ? this.urls.System.base_url.vpn
          : this.urls.System.base_url.normal
      }/Comm/GetTerm`,
      method: "GET",
      headers: {
        "Accept-Encoding": "gzip",
        Connection: "keep-alive",
        "x-requested-with": "XMLHttpRequest",
      },
    }).catch((err) => {
      throw new ErrorFetchDataSomethingWrong("获取学期列表出错" + err);
    });
    if (xhr == null) {
      throw new ErrorFetchDataSomethingWrong("获取学期列表出错，xhr 不应为空");
    }
    if (xhr.status !== 200) {
      throw new ErrorFetchDataSomethingWrong(
        "获取学期列表出错，状态码：" + xhr.status
      );
    }
    if (
      isNullOrEmptyString(xhr.responseText) ||
      typeof xhr.responseText !== "string" ||
      !(xhr.responseText.startsWith("{") || xhr.responseText.startsWith("["))
    )
      throw new ErrorFetchDataSomethingWrong(
        "获取学期列表时教务系统返回的内容为空或格式错误"
      );
    let response = JSON.parse(xhr.responseText);
    if (!response.success) {
      throw new ErrorFetchDataSomethingWrong(
        "教务系统获取学期列表失败，response 内容为: " + xhr.responseText
      );
    }
    // 裁剪学期列表，只保留该生入学后的学期（根据学号前2位加上20确定入学年份）
    let trimedTermList = response.data
      .filter(
        (term) =>
          term.schoolyear >= "20" + this.personalInfoStu.stid.slice(0, 2)
      )
      .sort((o1, o2) => o1.term.localeCompare(o2.term));
    return trimedTermList;
  }

  /**
   * 获取时间段列表
   * @returns {Promise<Array>} 时间段列表
   */
  async getHourList() {
    let xhr = await this.asyncRequest({
      url: `${
        this.useVpn
          ? this.urls.System.base_url.vpn
          : this.urls.System.base_url.normal
      }/comm/gethours`,
      method: "GET",
      headers: {
        "Accept-Encoding": "gzip",
        Connection: "keep-alive",
        "x-requested-with": "XMLHttpRequest",
      },
    }).catch((err) => {
      throw new ErrorFetchDataSomethingWrong("获取时间段列表出错" + err);
    });
    if (xhr == null) {
      throw new ErrorFetchDataSomethingWrong(
        "获取时间段列表出错，xhr 不应为空"
      );
    }
    if (xhr.status !== 200) {
      throw new ErrorFetchDataSomethingWrong(
        "获取时间段列表出错，状态码：" + xhr.status
      );
    }
    if (
      isNullOrEmptyString(xhr.responseText) ||
      typeof xhr.responseText !== "string" ||
      (!xhr.responseText.startsWith("{") && !xhr.responseText.startsWith("["))
    )
      throw new ErrorFetchDataSomethingWrong(
        "获取时间段列表时教务系统返回的内容为空或格式错误"
      );
    let response = JSON.parse(xhr.responseText);
    if (!response.success) {
      throw new ErrorFetchDataSomethingWrong(
        "教务系统获取时间段列表失败，response 内容为: " + xhr.responseText
      );
    }
    return response.data;
  }

  /**
   * 获取个人信息
   * @returns {Promise<object>} 个人信息
   */
  async getPersonalInfoPerson() {
    let xhr = await this.asyncRequest({
      url: `${
        this.useVpn
          ? this.urls.System.base_url.vpn
          : this.urls.System.base_url.normal
      }/Student/GetPerson`,
      method: "POST",
      headers: {
        "Accept-Encoding": "gzip",
        Connection: "keep-alive",
        "x-requested-with": "XMLHttpRequest",
        Referer: this.useVpn
          ? this.urls.System.base_url.vpn
          : this.urls.System.base_url.normal,
      },
    }).catch((err) => {
      throw new ErrorFetchDataSomethingWrong("获取个人信息出错" + err);
    });
    if (xhr == null) {
      throw new ErrorFetchDataSomethingWrong("获取个人信息出错，xhr 不应为空");
    }
    if (xhr.status !== 200) {
      throw new ErrorFetchDataSomethingWrong(
        "获取个人信息出错，状态码：" + xhr.status
      );
    }
    if (
      isNullOrEmptyString(xhr.responseText) ||
      typeof xhr.responseText !== "string" ||
      (!xhr.responseText.startsWith("{") && !xhr.responseText.startsWith("["))
    )
      throw new ErrorFetchDataSomethingWrong(
        "获取个人信息时教务系统返回的内容为空或格式错误"
      );
    let response = JSON.parse(xhr.responseText);
    if (!response.success) {
      throw new ErrorFetchDataSomethingWrong(
        "教务系统获取个人信息失败，response 内容为: " + xhr.responseText
      );
    }
    return response.data;
  }

  /**
   * 获取学生信息
   * @returns {Promise<object>} 学生信息
   */
  async getPersonalInfoStu() {
    let xhr = await this.asyncRequest({
      url: `${
        this.useVpn
          ? this.urls.System.base_url.vpn
          : this.urls.System.base_url.normal
      }/student/StuInfo`,
      method: "POST",
      headers: {
        "Accept-Encoding": "gzip",
        Connection: "keep-alive",
        "x-requested-with": "XMLHttpRequest",
        Referer: this.useVpn
          ? this.urls.System.base_url.vpn
          : this.urls.System.base_url.normal,
      },
    }).catch((err) => {
      throw new ErrorFetchDataSomethingWrong("获取学生信息出错" + err);
    });
    if (xhr == null) {
      throw new ErrorFetchDataSomethingWrong("获取学生信息出错，xhr 不应为空");
    }
    if (xhr.status !== 200) {
      throw new ErrorFetchDataSomethingWrong(
        "获取学生信息出错，状态码：" + xhr.status
      );
    }
    if (
      isNullOrEmptyString(xhr.responseText) ||
      typeof xhr.responseText !== "string" ||
      (!xhr.responseText.startsWith("{") && !xhr.responseText.startsWith("["))
    )
      throw new ErrorFetchDataSomethingWrong(
        "获取学生信息时教务系统返回的内容为空或格式错误"
      );
    let response = JSON.parse(xhr.responseText);
    return response;
  }

  /**
   * 获取理论课表
   * @returns {Promise<Array>} 理论课表
   */
  async getCourseList() {
    let xhr = await this.asyncRequest({
      url: `${
        this.useVpn
          ? this.urls.System.base_url.vpn
          : this.urls.System.base_url.normal
      }/student/getstutable`,
      method: "GET",
      headers: {
        "Accept-Encoding": "gzip",
        Connection: "keep-alive",
        "x-requested-with": "XMLHttpRequest",
        Referer: this.useVpn
          ? this.urls.System.base_url.vpn
          : this.urls.System.base_url.normal,
      },
    }).catch((err) => {
      throw new ErrorFetchDataSomethingWrong("获取理论课表出错" + err);
    });
    if (xhr == null) {
      throw new ErrorFetchDataSomethingWrong("获取理论课表出错，xhr 不应为空");
    }
    if (xhr.status !== 200) {
      throw new ErrorFetchDataSomethingWrong(
        "获取理论课表出错，状态码：" + xhr.status
      );
    }
    if (
      isNullOrEmptyString(xhr.responseText) ||
      typeof xhr.responseText !== "string" ||
      (!xhr.responseText.startsWith("{") && !xhr.responseText.startsWith("["))
    )
      throw new ErrorFetchDataSomethingWrong(
        "获取理论课表时教务系统返回的内容为空或格式错误"
      );
    let response = JSON.parse(xhr.responseText);
    if (!response.success) {
      throw new ErrorFetchDataSomethingWrong(
        "教务系统获取理论课表失败，response 内容为: " + xhr.responseText
      );
    }
    return response.data;
  }

  /**
   * 获取理论成绩
   * @returns {Promise<Array>} 理论成绩
   */
  async getGradeList() {
    let xhr = await this.asyncRequest({
      url: `${
        this.useVpn
          ? this.urls.System.base_url.vpn
          : this.urls.System.base_url.normal
      }/student/GetStuScore`,
      method: "GET",
      headers: {
        "Accept-Encoding": "gzip",
        Connection: "keep-alive",
        "x-requested-with": "XMLHttpRequest",
        Referer: this.useVpn
          ? this.urls.System.base_url.vpn
          : this.urls.System.base_url.normal,
      },
    }).catch((err) => {
      throw new ErrorFetchDataSomethingWrong("获取理论成绩出错" + err);
    });
    if (xhr == null) {
      throw new ErrorFetchDataSomethingWrong("获取理论成绩出错，xhr 不应为空");
    }
    if (xhr.status !== 200) {
      throw new ErrorFetchDataSomethingWrong(
        "获取理论成绩出错，状态码：" + xhr.status
      );
    }
    if (
      isNullOrEmptyString(xhr.responseText) ||
      typeof xhr.responseText !== "string" ||
      (!xhr.responseText.startsWith("{") && !xhr.responseText.startsWith("["))
    )
      throw new ErrorFetchDataSomethingWrong(
        "获取理论成绩时教务系统返回的内容为空或格式错误"
      );
    let response = JSON.parse(xhr.responseText);
    if (!response.success) {
      throw new ErrorFetchDataSomethingWrong(
        "教务系统获取理论成绩失败，response 内容为: " + xhr.responseText
      );
    }
    return response.data;
  }

  /**
   * 获取已选课程
   * @returns {Promise<Array>} 已选课程
   */
  async getSelectedCourseList() {
    let self = this;

    async function getListOfTerm(term) {
      let xhr = await self
        .asyncRequest({
          url: `${
            self.useVpn
              ? self.urls.System.base_url.vpn
              : self.urls.System.base_url.normal
          }/student/GetSctCourse?${self.obj2QueryString({
            term: term,
            comm: "1@1",
          })}`,
          method: "GET",
          headers: {
            "Accept-Encoding": "gzip",
            Connection: "keep-alive",
            "x-requested-with": "XMLHttpRequest",
            Referer: self.useVpn
              ? self.urls.System.base_url.vpn
              : self.urls.System.base_url.normal,
          },
        })
        .catch((err) => {
          throw new ErrorFetchDataSomethingWrong("获取已选课程出错" + err);
        });
      if (xhr == null) {
        throw new ErrorFetchDataSomethingWrong(
          "获取已选课程出错，xhr 不应为空"
        );
      }
      if (xhr.status !== 200) {
        throw new ErrorFetchDataSomethingWrong(
          "获取已选课程出错，状态码：" + xhr.status
        );
      }
      if (
        isNullOrEmptyString(xhr.responseText) ||
        typeof xhr.responseText !== "string" ||
        (!xhr.responseText.startsWith("{") && !xhr.responseText.startsWith("["))
      )
        throw new ErrorFetchDataSomethingWrong(
          "获取已选课程时教务系统返回的内容为空或格式错误"
        );
      let response = JSON.parse(xhr.responseText);
      if (!response.success) {
        throw new ErrorFetchDataSomethingWrong(
          "教务系统获取已选课程失败，response 内容为: " + xhr.responseText
        );
      }
      return response.data;
    }

    let selectedCourseList = [];
    let todoList = this.termList.map((term) => getListOfTerm(term.term));
    (await Promise.all(todoList)).map(
      (listOfTerm) =>
        (selectedCourseList = selectedCourseList.concat(listOfTerm))
    );
    return selectedCourseList;
  }

  /**
   * 获取实验课表
   * @returns {Promise<Array>} 实验课表
   */
  async getExpList() {
    let self = this;

    async function getListOfTerm(term) {
      let xhr = await self
        .asyncRequest({
          url: `${
            self.useVpn
              ? self.urls.System.base_url.vpn
              : self.urls.System.base_url.normal
          }/student/getlabtable?${self.obj2QueryString({
            term: term,
          })}`,
          method: "GET",
          headers: {
            "Accept-Encoding": "gzip",
            Connection: "keep-alive",
            "x-requested-with": "XMLHttpRequest",
            Referer: self.useVpn
              ? self.urls.System.base_url.vpn
              : self.urls.System.base_url.normal,
          },
        })
        .catch((err) => {
          throw new ErrorFetchDataSomethingWrong("获取实验课表出错" + err);
        });
      if (xhr == null) {
        throw new ErrorFetchDataSomethingWrong(
          "获取实验课表出错，xhr 不应为空"
        );
      }
      if (xhr.status !== 200) {
        throw new ErrorFetchDataSomethingWrong(
          "获取实验课表出错，状态码：" + xhr.status
        );
      }
      if (
        isNullOrEmptyString(xhr.responseText) ||
        typeof xhr.responseText !== "string" ||
        (!xhr.responseText.startsWith("{") && !xhr.responseText.startsWith("["))
      )
        throw new ErrorFetchDataSomethingWrong(
          "获取实验课表时教务系统返回的内容为空或格式错误"
        );
      let response = JSON.parse(xhr.responseText);
      if (!response.success) {
        throw new ErrorFetchDataSomethingWrong(
          "教务系统获取实验课表失败，response 内容为: " + xhr.responseText
        );
      }
      return response.data;
    }

    // 裁剪学期列表，只保留与今年有关的学期
    let trimedTermList = this.termList.filter((term) =>
      term.term.includes(new Date().getFullYear().toString())
    );
    let expList = [];
    let todoList = trimedTermList.map((term) => getListOfTerm(term.term));
    (await Promise.all(todoList)).map(
      (listOfTerm) => (expList = expList.concat(listOfTerm))
    );
    return expList;
  }

  /**
   * 获取实验成绩
   * @returns {Promise<Array>} 实验成绩
   */
  async getExpGradeList() {
    let xhr = await this.asyncRequest({
      url: `${
        this.useVpn
          ? this.urls.System.base_url.vpn
          : this.urls.System.base_url.normal
      }/student/getstulab`,
      method: "GET",
      headers: {
        "Accept-Encoding": "gzip",
        Connection: "keep-alive",
        "x-requested-with": "XMLHttpRequest",
        Referer: this.useVpn
          ? this.urls.System.base_url.vpn
          : this.urls.System.base_url.normal,
      },
    }).catch((err) => {
      throw new ErrorFetchDataSomethingWrong("获取实验成绩出错" + err);
    });
    if (xhr == null) {
      throw new ErrorFetchDataSomethingWrong("获取实验成绩出错，xhr 不应为空");
    }
    if (xhr.status !== 200) {
      throw new ErrorFetchDataSomethingWrong(
        "获取实验成绩出错，状态码：" + xhr.status
      );
    }
    if (
      isNullOrEmptyString(xhr.responseText) ||
      typeof xhr.responseText !== "string" ||
      (!xhr.responseText.startsWith("{") && !xhr.responseText.startsWith("["))
    )
      throw new ErrorFetchDataSomethingWrong(
        "获取实验成绩时教务系统返回的内容为空或格式错误"
      );
    let response = JSON.parse(xhr.responseText);
    if (!response.success) {
      throw new ErrorFetchDataSomethingWrong(
        "教务系统获取实验成绩失败，response 内容为: " + xhr.responseText
      );
    }
    return response.data;
  }

  /**
   * 获取考试安排
   * @returns {Promise<Array>} 考试安排
   */
  async getExamList() {
    let self = this;
    async function getListOfTerm(term) {
      let xhr = await self
        .asyncRequest({
          url: `${
            self.useVpn
              ? self.urls.System.base_url.vpn
              : self.urls.System.base_url.normal
          }/student/getexamap?${self.obj2QueryString({
            term: term,
          })}`,
          method: "GET",
          headers: {
            "Accept-Encoding": "gzip",
            Connection: "keep-alive",
            "x-requested-with": "XMLHttpRequest",
            Referer: self.useVpn
              ? self.urls.System.base_url.vpn
              : self.urls.System.base_url.normal,
          },
        })
        .catch((err) => {
          throw new ErrorFetchDataSomethingWrong("获取考试安排出错" + err);
        });
      if (xhr == null) {
        throw new ErrorFetchDataSomethingWrong(
          "获取考试安排出错，xhr 不应为空"
        );
      }
      if (xhr.status !== 200) {
        throw new ErrorFetchDataSomethingWrong(
          "获取考试安排出错，状态码：" + xhr.status
        );
      }
      if (
        isNullOrEmptyString(xhr.responseText) ||
        typeof xhr.responseText !== "string" ||
        (!xhr.responseText.startsWith("{") && !xhr.responseText.startsWith("["))
      )
        throw new ErrorFetchDataSomethingWrong(
          "获取考试安排时教务系统返回的内容为空或格式错误"
        );
      let response = JSON.parse(xhr.responseText);
      if (!response.success) {
        throw new ErrorFetchDataSomethingWrong(
          "教务系统获取考试安排失败，response 内容为: " + xhr.responseText
        );
      }
      return response.data;
    }

    // 裁剪学期列表，只保留与今年有关的学期
    let trimedTermList = this.termList.filter((term) =>
      term.term.includes(new Date().getFullYear().toString())
    );
    let examList = [];
    let todoList = trimedTermList.map((term) => getListOfTerm(term.term));
    (await Promise.all(todoList)).map(
      (listOfTerm) => (examList = examList.concat(listOfTerm))
    );
    return examList;
  }

  /**
   * 获取补考缓考安排
   * @returns {Promise<Array>} 补考缓考安排
   */
  async getMakeUpExamList() {
    let xhr = await this.asyncRequest({
      url: `${
        this.useVpn
          ? this.urls.System.base_url.vpn
          : this.urls.System.base_url.normal
      }/student/getbk`,
      method: "GET",
      headers: {
        "Accept-Encoding": "gzip",
        Connection: "keep-alive",
        "x-requested-with": "XMLHttpRequest",
        Referer: this.useVpn
          ? this.urls.System.base_url.vpn
          : this.urls.System.base_url.normal,
      },
    }).catch((err) => {
      throw new ErrorFetchDataSomethingWrong("获取补考缓考安排出错" + err);
    });
    if (xhr == null) {
      throw new ErrorFetchDataSomethingWrong("获取补考缓考出错，xhr 不应为空");
    }
    if (xhr.status !== 200) {
      throw new ErrorFetchDataSomethingWrong(
        "获取补考缓考出错，状态码：" + xhr.status
      );
    }
    if (
      isNullOrEmptyString(xhr.responseText) ||
      typeof xhr.responseText !== "string" ||
      (!xhr.responseText.startsWith("{") && !xhr.responseText.startsWith("["))
    )
      throw new ErrorFetchDataSomethingWrong(
        "获取补考缓考安排时教务系统返回的内容为空或格式错误"
      );
    let response = JSON.parse(xhr.responseText);
    if (!response.success) {
      throw new ErrorFetchDataSomethingWrong(
        "教务系统获取补考缓考安排失败，response 内容为: " + xhr.responseText
      );
    }
    return response.data;
  }

  /**
   * 获取等级考试成绩
   * @returns {Promise<Array>} 等级考试成绩
   */
  async getCETList() {
    let xhr = await this.asyncRequest({
      url: `${
        this.useVpn
          ? this.urls.System.base_url.vpn
          : this.urls.System.base_url.normal
      }/student/GetLvlScore`,
      method: "GET",
      headers: {
        "Accept-Encoding": "gzip",
        Connection: "keep-alive",
        "x-requested-with": "XMLHttpRequest",
        Referer: this.useVpn
          ? this.urls.System.base_url.vpn
          : this.urls.System.base_url.normal,
      },
    }).catch((err) => {
      throw new ErrorFetchDataSomethingWrong("获取等级考试成绩出错" + err);
    });
    if (xhr == null) {
      throw new ErrorFetchDataSomethingWrong("获取等级考试出错，xhr 不应为空");
    }
    if (xhr.status !== 200) {
      throw new ErrorFetchDataSomethingWrong(
        "获取等级考试出错，状态码：" + xhr.status
      );
    }
    if (
      isNullOrEmptyString(xhr.responseText) ||
      typeof xhr.responseText !== "string" ||
      (!xhr.responseText.startsWith("{") && !xhr.responseText.startsWith("["))
    )
      throw new ErrorFetchDataSomethingWrong(
        "获取等级考试成绩时教务系统返回的内容为空或格式错误"
      );
    let response = JSON.parse(xhr.responseText);
    if (!response.success) {
      throw new ErrorFetchDataSomethingWrong(
        "教务系统获取等级考试成绩失败，response 内容为: " + xhr.responseText
      );
    }
    return response.data;
  }

  /**
   * 获取有效学分（毕业计划课程一）
   * @returns {Promise<Array>} 有效学分
   */
  async getValidCreditList() {
    let xhr = await this.asyncRequest({
      url: `${
        this.useVpn
          ? this.urls.System.base_url.vpn
          : this.urls.System.base_url.normal
      }/student/Getyxxf`,
      method: "GET",
      headers: {
        "Accept-Encoding": "gzip",
        Connection: "keep-alive",
        "x-requested-with": "XMLHttpRequest",
        Referer: this.useVpn
          ? this.urls.System.base_url.vpn
          : this.urls.System.base_url.normal,
      },
    }).catch((err) => {
      throw new ErrorFetchDataSomethingWrong("获取有效学分出错" + err);
    });
    if (xhr == null) {
      throw new ErrorFetchDataSomethingWrong("获取有效学分出错，xhr 不应为空");
    }
    if (xhr.status !== 200) {
      throw new ErrorFetchDataSomethingWrong(
        "获取有效学分出错，状态码：" + xhr.status
      );
    }
    if (
      !xhr.responseText ||
      typeof xhr.responseText !== "string" ||
      (!xhr.responseText.startsWith("{") && !xhr.responseText.startsWith("["))
    )
      throw new ErrorFetchDataSomethingWrong(
        "获取有效学分时教务系统返回的内容为空或格式错误"
      );
    let response = JSON.parse(xhr.responseText);
    if (!response.success) {
      throw new ErrorFetchDataSomethingWrong(
        "教务系统获取有效学分失败，response 内容为: " + xhr.responseText
      );
    }
    return response.data;
  }

  /**
   * 获取计划成绩（毕业计划课程二）
   * @returns {Promise<Array>} 计划成绩
   */
  async getPlanCourseGradeList() {
    let xhr = await this.asyncRequest({
      url: `${
        this.useVpn
          ? this.urls.System.base_url.vpn
          : this.urls.System.base_url.normal
      }/student/getplancj`,
      method: "GET",
      headers: {
        "Accept-Encoding": "gzip",
        Connection: "keep-alive",
        "x-requested-with": "XMLHttpRequest",
        Referer: this.useVpn
          ? this.urls.System.base_url.vpn
          : this.urls.System.base_url.normal,
      },
    }).catch((err) => {
      throw new ErrorFetchDataSomethingWrong("获取计划成绩出错" + err);
    });
    if (xhr == null) {
      throw new ErrorFetchDataSomethingWrong("获取计划成绩出错，xhr 不应为空");
    }
    if (xhr.status !== 200) {
      throw new ErrorFetchDataSomethingWrong(
        "获取计划成绩出错，状态码：" + xhr.status
      );
    }
    if (
      isNullOrEmptyString(xhr.responseText) ||
      typeof xhr.responseText !== "string" ||
      (!xhr.responseText.startsWith("{") && !xhr.responseText.startsWith("["))
    )
      throw new ErrorFetchDataSomethingWrong(
        "获取计划成绩时教务系统返回的内容为空或格式错误"
      );
    let response = JSON.parse(xhr.responseText);
    if (!response.success) {
      throw new ErrorFetchDataSomethingWrong(
        "教务系统获取计划成绩失败，response 内容为: " + xhr.responseText
      );
    }
    return response.data;
  }

  /** 更新财务费用 */
  async updateFinancial() {
    let stuInfo = this.personalInfoStu;
    let xhr = await this.asyncRequest({
      url: `${
        this.useVpn
          ? this.urls.System.base_url.vpn
          : this.urls.System.base_url.normal
      }/student/genstufee`,
      method: "POST",
      headers: {
        "Accept-Encoding": "gzip",
        Connection: "keep-alive",
        "x-requested-with": "XMLHttpRequest",
        Referer: this.useVpn
          ? this.urls.System.base_url.vpn
          : this.urls.System.base_url.normal,
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: this.obj2QueryString({
        ctype: "byyqxf",
        stid: stuInfo.stid,
        grade: stuInfo.grade,
        spno: stuInfo.spno,
      }),
    }).catch((err) => {
      throw new ErrorFetchDataSomethingWrong("更新财务费用出错" + err);
    });
    if (xhr == null) {
      throw new ErrorFetchDataSomethingWrong("获取财务费用出错，xhr 不应为空");
    }
    if (xhr.status !== 200) {
      throw new ErrorFetchDataSomethingWrong(
        "获取财务费用出错，状态码：" + xhr.status
      );
    }
    if (
      isNullOrEmptyString(xhr.responseText) ||
      typeof xhr.responseText !== "string" ||
      (!xhr.responseText.startsWith("{") && !xhr.responseText.startsWith("["))
    )
      throw new ErrorFetchDataSomethingWrong(
        "更新财务费用时教务系统返回的内容为空或格式错误"
      );
    let response = JSON.parse(xhr.responseText);
    if (!response.success) {
      throw new ErrorFetchDataSomethingWrong(
        "教务系统更新财务费用失败，response 内容为: " + xhr.responseText
      );
    }
  }

  /** 更新毕业信息 */
  async updateGraduateInfo() {
    let stuInfo = this.personalInfoStu;
    let currentTerm = this.currentTerm;
    let xhr = await this.asyncRequest({
      url: `${
        this.useVpn
          ? this.urls.System.base_url.vpn
          : this.urls.System.base_url.normal
      }/student/genstuby/${currentTerm}`,
      method: "POST",
      headers: {
        "Accept-Encoding": "gzip",
        Connection: "keep-alive",
        "x-requested-with": "XMLHttpRequest",
        Referer: this.useVpn
          ? this.urls.System.base_url.vpn
          : this.urls.System.base_url.normal,
        "Content-Type": "application/x-www-form-urlencoded",
      },
      data: this.obj2QueryString({
        ctype: "byyqxf",
        stid: stuInfo.stid,
        grade: stuInfo.grade,
        spno: stuInfo.spno,
      }),
    }).catch((err) => {
      throw new ErrorFetchDataSomethingWrong("更新毕业信息出错" + err);
    });
    if (xhr == null) {
      throw new ErrorFetchDataSomethingWrong("获取毕业信息出错，xhr 不应为空");
    }
    if (xhr.status !== 200) {
      throw new ErrorFetchDataSomethingWrong(
        "获取毕业信息出错，状态码：" + xhr.status
      );
    }
    if (
      isNullOrEmptyString(xhr.responseText) ||
      typeof xhr.responseText !== "string" ||
      (!xhr.responseText.startsWith("{") && !xhr.responseText.startsWith("["))
    )
      throw new ErrorFetchDataSomethingWrong(
        "更新毕业信息时教务系统返回的内容为空或格式错误"
      );
    let response = JSON.parse(xhr.responseText);
    if (!response.success) {
      throw new ErrorFetchDataSomethingWrong(
        "教务系统更新毕业信息失败，response 内容为: " + xhr.responseText
      );
    }
  }

  /**
   * 毕业学位查询
   * @returns {Promise<object>} 毕业学位
   */
  async getGraduationInformation() {
    let xhr = await this.asyncRequest({
      url: `${
        this.useVpn
          ? this.urls.System.base_url.vpn
          : this.urls.System.base_url.normal
      }/student/getbyxw`,
      method: "GET",
      headers: {
        "Accept-Encoding": "gzip",
        Connection: "keep-alive",
        "x-requested-with": "XMLHttpRequest",
        Referer: this.useVpn
          ? this.urls.System.base_url.vpn
          : this.urls.System.base_url.normal,
      },
    }).catch((err) => {
      throw new ErrorFetchDataSomethingWrong("获取毕业学位出错" + err);
    });
    if (xhr == null) {
      throw new ErrorFetchDataSomethingWrong("获取毕业学位出错，xhr 不应为空");
    }
    if (xhr.status !== 200) {
      throw new ErrorFetchDataSomethingWrong(
        "获取毕业学位出错，状态码：" + xhr.status
      );
    }
    if (
      isNullOrEmptyString(xhr.responseText) ||
      typeof xhr.responseText !== "string" ||
      (!xhr.responseText.startsWith("{") && !xhr.responseText.startsWith("["))
    )
      throw new ErrorFetchDataSomethingWrong(
        "获取毕业学位时教务系统返回的内容为空或格式错误"
      );
    let response = JSON.parse(xhr.responseText);
    if (!response.success) {
      throw new ErrorFetchDataSomethingWrong(
        "教务系统获取毕业学位失败，response 内容为: " + xhr.responseText
      );
    }
    return response.data[0];
  }

  /**
   * 毕业条件查询
   * @returns {Promise<Array>} 毕业条件
   */
  async getGraduationRequirementList() {
    let xhr = await this.asyncRequest({
      url: `${
        this.useVpn
          ? this.urls.System.base_url.vpn
          : this.urls.System.base_url.normal
      }/comm/getsctxw`,
      method: "GET",
      headers: {
        "Accept-Encoding": "gzip",
        Connection: "keep-alive",
        "x-requested-with": "XMLHttpRequest",
      },
    }).catch((err) => {
      throw new ErrorFetchDataSomethingWrong("获取毕业条件出错" + err);
    });
    if (xhr == null) {
      throw new ErrorFetchDataSomethingWrong("获取毕业条件出错，xhr 不应为空");
    }
    if (xhr.status !== 200) {
      throw new ErrorFetchDataSomethingWrong(
        "获取毕业条件出错，状态码：" + xhr.status
      );
    }
    if (
      isNullOrEmptyString(xhr.responseText) ||
      typeof xhr.responseText !== "string" ||
      (!xhr.responseText.startsWith("{") && !xhr.responseText.startsWith("["))
    )
      throw new ErrorFetchDataSomethingWrong(
        "获取毕业条件时教务系统返回的内容为空或格式错误"
      );
    let response = JSON.parse(xhr.responseText);
    if (!response.success) {
      throw new ErrorFetchDataSomethingWrong(
        "教务系统获取毕业条件失败，response 内容为: " + xhr.responseText
      );
    }
    return response.data;
  }

  /** 创新结果更新 */
  async updateInnovationInformation() {
    let xhr = await this.asyncRequest({
      url: `${
        this.useVpn
          ? this.urls.System.base_url.vpn
          : this.urls.System.base_url.normal
      }/student/gencxcy`,
      method: "POST",
      headers: {
        "Accept-Encoding": "gzip",
        Connection: "keep-alive",
        "x-requested-with": "XMLHttpRequest",
        Referer: this.useVpn
          ? this.urls.System.base_url.vpn
          : this.urls.System.base_url.normal,
      },
    }).catch((err) => {
      throw new ErrorFetchDataSomethingWrong("更新创新结果出错" + err);
    });
    if (xhr == null) {
      throw new ErrorFetchDataSomethingWrong("获取创新结果出错，xhr 不应为空");
    }
    if (xhr.status !== 200) {
      throw new ErrorFetchDataSomethingWrong(
        "获取创新结果出错，状态码：" + xhr.status
      );
    }
    if (
      isNullOrEmptyString(xhr.responseText) ||
      typeof xhr.responseText !== "string" ||
      (!xhr.responseText.startsWith("{") && !xhr.responseText.startsWith("["))
    )
      throw new ErrorFetchDataSomethingWrong(
        "更新创新结果时教务系统返回的内容为空或格式错误"
      );
    let response = JSON.parse(xhr.responseText);
    if (!response.success) {
      throw new ErrorFetchDataSomethingWrong(
        "教务系统更新创新结果失败，response 内容为: " + xhr.responseText
      );
    }
  }

  /**
   *  创新积分查询
   * @returns {Promise<object>} 创新积分
   */
  async getInnovationPointInformation() {
    let xhr = await this.asyncRequest({
      url: `${
        this.useVpn
          ? this.urls.System.base_url.vpn
          : this.urls.System.base_url.normal
      }/student/getcxcy`,
      method: "GET",
      headers: {
        "Accept-Encoding": "gzip",
        Connection: "keep-alive",
        "x-requested-with": "XMLHttpRequest",
        Referer: this.useVpn
          ? this.urls.System.base_url.vpn
          : this.urls.System.base_url.normal,
      },
    }).catch((err) => {
      throw new ErrorFetchDataSomethingWrong("查询创新积分出错" + err);
    });
    if (xhr == null) {
      throw new ErrorFetchDataSomethingWrong("获取创新积分出错，xhr 不应为空");
    }
    if (xhr.status !== 200) {
      throw new ErrorFetchDataSomethingWrong(
        "获取创新积分出错，状态码：" + xhr.status
      );
    }
    if (
      isNullOrEmptyString(xhr.responseText) ||
      typeof xhr.responseText !== "string" ||
      (!xhr.responseText.startsWith("{") && !xhr.responseText.startsWith("["))
    )
      throw new ErrorFetchDataSomethingWrong(
        "查询创新积分时教务系统返回的内容为空或格式错误"
      );
    let response = JSON.parse(xhr.responseText);
    if (!response.success) {
      throw new ErrorFetchDataSomethingWrong(
        "教务系统查询创新积分失败，response 内容为: " + xhr.responseText
      );
    }
    return response.data[0];
  }

  /**
   *  转专业查询
   * @returns {Promise<object>} 转专业信息
   */
  async getChangingMajorInformation() {
    let xhr = await this.asyncRequest({
      url: `${
        this.useVpn
          ? this.urls.System.base_url.vpn
          : this.urls.System.base_url.normal
      }/student/GetSpnoStid`,
      method: "GET",
      headers: {
        "Accept-Encoding": "gzip",
        Connection: "keep-alive",
        "x-requested-with": "XMLHttpRequest",
        Referer: this.useVpn
          ? this.urls.System.base_url.vpn
          : this.urls.System.base_url.normal,
      },
    }).catch((err) => {
      throw new ErrorFetchDataSomethingWrong("获取转专业信息出错" + err);
    });
    if (xhr == null) {
      throw new ErrorFetchDataSomethingWrong(
        "获取转专业信息出错，xhr 不应为空"
      );
    }
    if (xhr.status !== 200) {
      throw new ErrorFetchDataSomethingWrong(
        "获取转专业信息出错，状态码：" + xhr.status
      );
    }
    if (
      isNullOrEmptyString(xhr.responseText) ||
      typeof xhr.responseText !== "string" ||
      (!xhr.responseText.startsWith("{") && !xhr.responseText.startsWith("["))
    )
      throw new ErrorFetchDataSomethingWrong(
        "获取转专业信息时教务系统返回的内容为空或格式错误"
      );
    let response = JSON.parse(xhr.responseText);
    if (!response.success) {
      throw new ErrorFetchDataSomethingWrong(
        "教务系统获取转专业信息失败，response 内容为: " + xhr.responseText
      );
    }
    return response.data;
  }
}

if (module && module.exports) {
  module.exports = {
    GuetcobHelperErrors,
  };
}

let forU;
// eslint-disable-next-line no-undef
if (typeof GM_info !== "undefined" && GM_info != null) {
  // eslint-disable-next-line no-undef
  forU =
    // eslint-disable-next-line no-undef
    typeof unsafeWindow !== "undefined" && unsafeWindow != null
      ? // eslint-disable-next-line no-undef
        unsafeWindow
      : window;
} else {
  forU = module.exports;
}
forU.GuetcobHelper = GuetcobHelper;
