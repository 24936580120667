<template>
  <div
      v-if="isPanelVisible"
      class="position-fixed"
      style="width: calc(100 * var(--vw)); height: calc(100 * var(--vh))"
      :style="{'--popup-duration': durationString}"
      @click.prevent.stop="hide()"
  >
    <transition name="popup">
      <div
          v-if="isBoxVisible"
          class="fc position-fixed bottom0"
          style="
          width: calc(100 * var(--vw));
          --br: var(--popup-BorderRadius);
          border-radius: var(--br) var(--br) 0 0;
          background: var(--popup-BackgroundColor);
          box-shadow: 0 0 20vmax var(--popup-BackgroundShadowColor);
        "
          @click.prevent.stop
      >
        <slot/>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "Popup",
  data() {
    return {
      durationMS: 100,
      isPanelVisible: false,
      isBoxVisible: false,
      lockHide: false,
    }
  },
  computed: {
    durationString() {
      return this.durationMS + "ms"
    },
  },
  methods: {
    hide() {
      if (!this.lockHide) {
        this.lockHide = true
        this.isBoxVisible = false
        window.setTimeout(() => {
          this.isPanelVisible = false
          this.lockHide = false
        }, this.durationMS * 1.1)
      }
    },
    show() {
      this.isPanelVisible = true
      this.isBoxVisible = true
    },
    isVisible() {
      return this.isPanelVisible || this.isBoxVisible
    },
  },
}
</script>

<style scoped>

</style>