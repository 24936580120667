<template>
  <div
    class="
      head-box
      bar-back-color-white
      head-bar-height
      bar-weight
      head-bar-display
    "
  >
    <!-- 装内容的盒子，方便添加padding -->
    <div class="more-head-bar head-bar-inner-box-display bar-padding-l-r">
      <div class="head-bar-right-display" @click.prevent.stop="gotoLogin">
        <img
            class="head-img head-img-margin-right"
            alt=""
        />
        <div class="head-bar-right-text-display head-bar-text-height">
          <div class="head-stu-info-text unselectable-text">{{ username }}</div>
          <div class="head-sync-time unselectable-text">
            {{ gLastSyncText + (this.gIsCurrentUserSyncing ? `，同步中... 约 ${gSyncCountdownText} 秒` : "") }}
          </div>
        </div>
      </div>
      <svg class="icon bar-font-size more-right-arrow" @click.prevent.stop="gotoLogin" aria-hidden="true">
        <use xlink:href="#alibaba-icon-xiangyou1"></use>
      </svg>
    </div>
  </div>
</template>

<script>
import {inject} from 'vue'
import Page from "@/js/page";

export default {
  name: "HeadBar",
  setup() {
    return {
      gParsedData: inject('gParsedData'),
      gLastSyncText: inject('gLastSyncText'),
      gIsCurrentUserSyncing: inject('gIsCurrentUserSyncing'),
      gSyncCountdownText: inject('gSyncCountdownText'),
    }
  },
  methods: {
    gotoLogin() {
      this.$router.push(Page.loginPage().path)
    },
  },
  computed: {
    username() {
      return this.gParsedData.username + this.gParsedData.personalInfo.name;
    },
  }
};
</script>

<style>
</style>