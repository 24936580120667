import GuetAASCredit from "./guetAASCredit.js";
import { HTTPMethod } from "js-http-request";
import URLs from "../../constants/urls.js";
import Util from "js-util";
import Require from "js-type-require";

export default class extends GuetAASCredit {
  async jsonResult(method, url, { transform = (result) => result, pack } = {}) {
    return this.jsonSuccess(method, url, {
      transform: (json) => {
        return transform(json.result);
      },
      pack: pack,
    });
  }

  async getCourseTableTermNameToIndexMap() {
    if (this.courseTableTermNameToIndexMap != null) {
      return this.courseTableTermNameToIndexMap;
    }
    return (this.courseTableTermNameToIndexMap = await this.fetch(
      HTTPMethod.GET,
      `${URLs.SystemSupWisdom.Get(this.IsLAN)}/student/for-std/course-table`,
      (req) =>
        req.HTMLDocument().then((docRes) => {
          const m = new Map();
          const allSemesters =
            docRes.Result.getElementById("allSemesters").children;
          for (const semester of allSemesters) {
            m.set(semester.textContent, semester.value);
          }
          return m;
        }),
    ));
  }

  parseCourseWeeks(course) {
    const ranges = [];
    if (course.weekIndexes == null) {
      return ranges;
    }
    const weeks = [...course.weekIndexes];
    weeks.sort((a, b) => a - b);
    let r = null;
    for (const w of weeks) {
      if (r == null || w - r[1] !== 1) {
        ranges.push((r = [w, w]));
      } else {
        r[1] = w;
      }
    }
    return ranges;
  }

  parseCourseSessions(course) {
    const res = new Set();
    const begin = course.startUnit;
    const end = course.endUnit;
    if (begin == null || end == null) {
      return res;
    }
    for (let i = begin; i <= end; i++) {
      switch (i) {
        case 1:
        case 2:
          res.add(1);
          break;
        case 3:
        case 4:
          res.add(2);
          break;
        case 6:
        case 7:
          res.add(3);
          break;
        case 8:
        case 9:
          res.add(4);
          break;
        case 10:
        case 11:
          res.add(5);
          break;
        case 12:
          res.add(6);
          break;
        case 5:
          res.add(7);
          break;
      }
    }
    return res;
  }

  parseCourseTimeInfo(course) {
    return {
      weekRanges: this.parseCourseWeeks(course),
      weekday: course.weekday,
      sessions: this.parseCourseSessions(course),
    };
  }

  async fetchCourseList(channel) {
    await this.getCourseTableTermNameToIndexMap();
    const termList = Util.ParseTermList(await this.TermList(channel));
    const res = [];
    for (const t of termList) {
      const index = this.courseTableTermNameToIndexMap.get(t.termName);
      if (index == null) {
        continue;
      }
      res.push(
        ...(await this.json(
          HTTPMethod.GET,
          `${URLs.SystemSupWisdom.Get(
            this.IsLAN,
          )}/student/for-std/course-table/semester/${index}/print-data?hasExperiment=true&semesterId=${index}`,
          {
            transform: (json) => {
              const res = [];
              const spaces = json.studentTableVms;
              if (spaces == null) {
                return res;
              }
              for (const space of spaces) {
                const schedules = space.activities;
                if (schedules == null) {
                  continue;
                }
                for (const c of schedules) {
                  let courseType = null;
                  let courseTypeCode = null;
                  if (c.courseType != null) {
                    courseType = c.courseType.name;
                    courseTypeCode = c.courseType.code;
                  }
                  let teacher = null;
                  if (c.teachers != null) {
                    teacher = c.teachers.join(",");
                  }
                  const { weekRanges, weekday, sessions } =
                    this.parseCourseTimeInfo(c);
                  for (const wr of weekRanges) {
                    for (const s of sessions) {
                      res.push({
                        term: t.termCode,
                        week: weekday,
                        seq: s,
                        cname: c.courseName,
                        courseno: c.lessonCode,
                        startweek: wr[0],
                        endweek: wr[1],
                        name: teacher,
                        croomno: c.room,
                        comm: c.lessonRemark,
                        xf: c.credits,
                        tname: courseType,
                        ctype: courseTypeCode,
                        courseid: c.courseCode,
                      });
                    }
                  }
                }
              }
              return res;
            },
          },
        )),
      );
    }
    return res;
  }

  async getLabToken() {
    if (this.labToken != null) {
      return this.labToken;
    }
    return (this.labToken = await this.fetch(
      HTTPMethod.GET,
      `${URLs.SystemSupWisdom.Get(
        this.IsLAN,
      )}/student/for-std/extra-system/newcapec-experiment/course/stu`,
      (req) =>
        req
          .SetFollowRedirect(false)
          .Send()
          .then(
            (reqRes) =>
              reqRes.Request.GetHeader("Location")[0].match(/token=([^&]*)/)[1],
          ),
    ));
  }

  async getLabAccessToken() {
    if (this.labAccessToken != null) {
      return this.labAccessToken;
    }
    return (this.labAccessToken = await this.jsonResult(
      HTTPMethod.GET,
      `${URLs.SystemSupWisdom.Get(
        this.IsLAN,
      )}/guet-lab-system/api/authentication/getAccessTokenByEduToken?token=${await this.getLabToken()}`,
      { transform: (res) => res.token },
    ));
  }

  async fetchExpList(channel) {
    const res = [];
    const nts = Date.now();
    const offset = 8 * 31 * 24 * 60 * 60 * 1000;
    const result = await this.jsonResult(
      HTTPMethod.GET,
      `${URLs.SystemSupWisdom.Get(
        this.IsLAN,
      )}/guet-lab-system/schedule/mesCourseScheduleItem/queryScheduleInfo?type=6&startDate=${Util.GMT8YYYY_MM_DD(
        nts - offset,
      )}&endDate=${Util.GMT8YYYY_MM_DD(nts + offset)}`,
      {
        pack: { headers: [["X-Access-Token", await this.getLabAccessToken()]] },
      },
    );
    if (result == null) {
      return res;
    }
    const termList = Util.ParseTermList(await this.TermList(channel));
    const selectTermByTimeStampMS = (ts) =>
      termList.find(
        (t) => Util.NToM(t.startNano) <= ts && Util.NToM(t.endNano) >= ts,
      );
    const sessionRangeToSessions = (begin, end) => {
      const res = new Set();
      for (let i = begin; i <= end; i++) {
        switch (i) {
          case 1:
          case 2:
            res.add(1);
            break;
          case 3:
          case 4:
            res.add(2);
            break;
          case 5:
          case 6:
            res.add(3);
            break;
          case 7:
          case 8:
            res.add(4);
            break;
          case 9:
          case 10:
            res.add(5);
            break;
          case 11:
          case 12:
            res.add(6);
            break;
          case 13:
          case 14:
            res.add(7);
            break;
        }
      }
      return res;
    };
    for (const lesson of result) {
      const { dayVos } = lesson;
      if (dayVos == null) {
        continue;
      }
      for (const day of dayVos) {
        const { scheduleItemList } = day;
        if (scheduleItemList == null) {
          continue;
        }
        for (const item of scheduleItemList) {
          const term = Require.NonNull(
            selectTermByTimeStampMS(
              Util.GMT8DateStringToTimestampMS(item.schoolTime),
            ),
          ).termCode;
          const sessions = sessionRangeToSessions(
            item.lessonBegin,
            item.lessonEnd,
          );
          if (sessions == null) {
            continue;
          }
          for (const s of sessions) {
            res.push({
              xh: item.subjectId,
              cname: item.taskName,
              itemname: item.subjectName,
              srdd: item.labNames,
              name: item.teacherName,
              term: term,
              jc: s,
              zc: item.weekNum,
              xq: item.weekDay,
              comm: item.description,
            });
          }
        }
      }
    }
    return res;
  }
}
