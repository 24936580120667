<template>
  <div>
    <div v-for="exam in makeUpExamList" :key="exam.termCode + exam.courseNo + exam.type + exam.time + exam.location"
         class="fc">
      <ChildGap/>
      <div class="fr">
        <div class="flex-basis-0 fc function-menu-make-up-exam" style="flex: 1.5 1.5">
          <div class="margin-top-bottom-auto-in-fc">
            {{ exam.courseNo }}
          </div>
        </div>
        <div class="flex-basis-0 fc function-menu-make-up-exam" style="flex: 1.5 1.5">
          <div class="margin-top-bottom-auto-in-fc">
            {{ exam.type }}
          </div>
        </div>
        <div class="flex-basis-0 fc function-menu-make-up-exam" style="flex: 1.5 1.5">
          <div class="margin-top-bottom-auto-in-fc margin-left-right-auto-in-fc function-menu-make-up-exam-name"
               :class="{important: exam.important}"
          >
            {{ exam.name }}
          </div>
        </div>
        <div class="flex-basis-0 fc function-menu-make-up-exam" style="flex: 3.142857 3.142857">
          <div class="margin-top-bottom-auto-in-fc function-menu-make-up-exam-time">
            {{ exam.time }}
          </div>
        </div>
        <div class="flex-basis-0 fc function-menu-make-up-exam" style="flex: 2.357143 2.357143">
          <div class="margin-top-bottom-auto-in-fc function-menu-make-up-exam-location">
            {{ exam.location }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChildGap from "@/component/function_menu/element/ChildGap";

export default {
  name: "MakeUpExam",
  components: {ChildGap},
  props: {
    makeUpExamList: {
      type: Array,
      default() {
        return []
      },
    },
  },
}
</script>

<style scoped>

</style>