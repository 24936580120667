import HelperError from "./helperError.js";

export default class extends HelperError {
  /** 试图以不同的响应来解决同一个阻塞器 */
  constructor() {
    super({
      code: "multiple_blocker_response",
      httpCode: 501,
      mayCache: false,
    });
  }
}
