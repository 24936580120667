import HelperError from "./helperError.js";

export default class extends HelperError {
  /** 功能未实现错误 */
  constructor(details) {
    super({
      code: "not_implemented",
      tip: "开发者正在夜以继日地开发此功能，再等等吧~",
      httpCode: 501,
      details: details,
      mayCache: false,
    });
  }
}
