export const Role = {
  Undergraduate: "Student",
  UndergraduateI18n: "InternationalStudent",
};
export default class {
  constructor(studentID, vpnPassword, aasPassword, isInternational) {
    this.StudentID = studentID;
    this.VPNPassword = vpnPassword;
    this.AASPassword = aasPassword;
    this.IsInternational = isInternational;
  }
  get Role() {
    return this.IsInternational ? Role.UndergraduateI18n : Role.Undergraduate;
  }
  get IsUndergraduate() {
    return this.Role === Role.Undergraduate;
  }
  get IsUndergraduateI18n() {
    return this.Role === Role.UndergraduateI18n;
  }
}
