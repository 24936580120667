export default {
    string(o) {
        return typeof (o) === "string"
    },
    object(o) {
        return typeof (o) === "object"
    },
    array(o) {
        return Array.isArray(o)
    },
}