import Typeof from "js-typeof";
import RFC2047 from "rfc2047";
import CryptoNode from "./CryptoNode.js";
import { NetworkProxy, NetworkProxyType } from "js-http-request";
import Require from "js-type-require";

export default {
  Empty(o, noSemantic) {
    return Require.Util.Empty(o, noSemantic);
  },
  NotEmpty(o, noSemantic) {
    return Require.Util.NotEmpty(o, noSemantic);
  },
  GetEmptyStringFromNull(s, noSemantic) {
    return Require.Util.GetEmptyStringFromNull(s, noSemantic);
  },
  GetEmptyArrayFromNull(a) {
    return a == null ? [] : a;
  },
  IsNodeJS() {
    return typeof window === "undefined" || window == null;
  },
  BToA(s) {
    return this.IsNodeJS()
      ? Buffer.from(s, "binary").toString("base64")
      : window.btoa(s);
  },
  AToB(s) {
    return this.IsNodeJS()
      ? Buffer.from(s, "base64").toString("binary")
      : window.atob(s);
  },
  ArrayBufferToBase64(ab) {
    if (ab == null) {
      return null;
    }
    let bString = "";
    new Uint8Array(ab).forEach((byte) => {
      bString += String.fromCharCode(byte);
    });
    return this.BToA(bString);
  },
  Base64ToArrayBuffer(s) {
    if (s == null) {
      return null;
    }
    let bString = this.AToB(s);
    return this.TypedArrayToArrayBuffer(
      Uint8Array.from(bString, (element, index) => {
        return bString.charCodeAt(index);
      }),
    );
  },
  EqualsIgnoreCase(s1, s2) {
    return (
      (s1 == null && s2 == null) ||
      (s1 != null && s2 != null && s1.toUpperCase() === s2.toUpperCase())
    );
  },
  MapSet(map, key, value) {
    if (!(map instanceof Map)) {
      return;
    }
    for (const [k] of map) {
      if (this.EqualsIgnoreCase(k, key)) {
        key = k;
        break;
      }
    }
    map.set(key, value);
  },
  MapRemove(map, key) {
    if (!(map instanceof Map)) {
      return;
    }
    for (const [k] of map) {
      if (this.EqualsIgnoreCase(k, key)) {
        key = k;
        break;
      }
    }
    map.delete(key);
  },
  MapGet(map, key) {
    if (!(map instanceof Map)) {
      return null;
    }
    for (const [k] of map) {
      if (this.EqualsIgnoreCase(k, key)) {
        key = k;
        break;
      }
    }
    return map.get(key);
  },
  TypedArrayToArrayBuffer(ary) {
    return ary.buffer.slice(ary.byteOffset, ary.byteOffset + ary.byteLength);
  },
  IsInObject(obj, key) {
    return typeof obj === "object" && key in obj;
  },
  RandomUIntN(max) {
    return Math.floor(Math.random() * max);
  },
  RandomUInt() {
    return this.RandomUIntN(Number.MAX_SAFE_INTEGER);
  },
  Android() {
    // eslint-disable-next-line no-undef
    return typeof Android !== "undefined" && Android != null ? Android : null;
  },
  IsSingleCookieJar() {
    return this.Android() != null;
  },
  HTTPRequestGateway() {
    if (this.IsNodeJS()) {
      return "http://localhost:10086/gateway";
    }
    const Android = this.Android();
    if (Android != null && typeof Android.HTTPRequestGateway === "function") {
      return Android.HTTPRequestGateway();
    }
    return null;
  },
  HTTPRequestProxy() {
    if (this.HTTPRequestGateway() == null) {
      return null;
    }
    return this.IsNodeJS()
      ? new NetworkProxy(NetworkProxyType.SOCKS, "127.0.0.1", 43123)
      : null;
  },
  async NewPromiseAndResolve() {
    let p;
    const r = await new Promise((rs) => (p = new Promise((rss) => rs(rss))));
    return {
      Promise: p,
      Resolve: r,
    };
  },
  ForKeyOrSymbol(keyOrSymbol) {
    if (Typeof.Symbol(keyOrSymbol)) {
      return keyOrSymbol;
    } else if (Typeof.String(keyOrSymbol)) {
      return Symbol.for(keyOrSymbol);
    }
    return null;
  },
  ArrayBufferToHexString(buffer) {
    return [...new Uint8Array(buffer)]
      .map((x) => x.toString(16).padStart(2, "0"))
      .join("");
  },
  async CryptoSubtle() {
    return this.IsNodeJS() ? CryptoNode.webcrypto.subtle : window.crypto.subtle;
  },
  async Digest(algorithm, data) {
    if (Typeof.String(data)) {
      data = new TextEncoder().encode(data);
    }
    return this.ArrayBufferToHexString(
      await (await this.CryptoSubtle()).digest(algorithm, data),
    );
  },
  async SHA256(data) {
    return this.Digest("SHA-256", data);
  },
  async SHA512(data) {
    return this.Digest("SHA-512", data);
  },
  EncodeRFC2047(s) {
    return RFC2047.encode(s);
  },
  DecodeRFC2047(s) {
    return RFC2047.decode(s);
  },
  GetHeader(headers, name) {
    if (headers == null) {
      return null;
    }
    for (const n in headers) {
      if (this.EqualsIgnoreCase(n, name)) {
        name = n;
        break;
      }
    }
    return headers[name];
  },
  GMT8YYYY_MM_DD(tsMS = Date.now()) {
    return new Date(tsMS + 8 * 60 * 60 * 1000).toISOString().split("T")[0];
  },
  MToN(m) {
    return m * 1000 * 1000;
  },
  NToM(n) {
    return Math.floor(n / (1000 * 1000));
  },
  FirstUnixNanoTimestampOfMondayOfFirstWeekOfTerm(termStartUnixNano) {
    let m = this.NToM(termStartUnixNano);
    while (new Date(m).getDay() !== 1) {
      m += 86400000;
    }
    const d = new Date(m);
    return this.MToN(
      new Date(
        d.getFullYear(),
        d.getMonth(),
        d.getDate(),
        0,
        0,
        0,
        0,
      ).getTime(),
    );
  },
  LastUnixNanoTimestampOfSundayOfLastWeekOfTerm(termEndUnixNano) {
    let m = this.NToM(termEndUnixNano);
    while (new Date(m).getDay() !== 0) {
      m += 86400000;
    }
    const d = new Date(m);
    return (
      this.MToN(
        new Date(
          d.getFullYear(),
          d.getMonth(),
          d.getDate(),
          23,
          59,
          59,
          999,
        ).getTime(),
      ) +
      1000 * 1000 -
      1
    );
  },
  ParseTermList(termList) {
    let res = [];
    this.GetEmptyArrayFromNull(termList).forEach((t) => {
      const termParsed = t.term.split(/[-_]/);
      const startDateParsed = t.startdate.split(/[/ :]/);
      const startD = new Date(
        startDateParsed[0].toString().padStart(4, "0") +
          "-" +
          startDateParsed[1].toString().padStart(2, "0") +
          "-" +
          startDateParsed[2].toString().padStart(2, "0") +
          "T" +
          startDateParsed[3].toString().padStart(2, "0") +
          ":" +
          startDateParsed[4].toString().padStart(2, "0") +
          ":" +
          startDateParsed[5].toString().padStart(2, "0") +
          "+0800",
      );
      const endDateParsed = t.enddate.split(/[/ :]/);
      const endD = new Date(
        endDateParsed[0].toString().padStart(4, "0") +
          "-" +
          endDateParsed[1].toString().padStart(2, "0") +
          "-" +
          endDateParsed[2].toString().padStart(2, "0") +
          "T" +
          endDateParsed[3].toString().padStart(2, "0") +
          ":" +
          endDateParsed[4].toString().padStart(2, "0") +
          ":" +
          endDateParsed[5].toString().padStart(2, "0") +
          "+0800",
      );
      const fMNano = Require.Number(
        this.FirstUnixNanoTimestampOfMondayOfFirstWeekOfTerm(
          this.MToN(startD.getTime()),
        ),
      );
      const lSNano = Require.Number(
        this.LastUnixNanoTimestampOfSundayOfLastWeekOfTerm(
          this.MToN(endD.getTime()),
        ),
      );
      const wNum = Require.Number(
        Math.ceil((lSNano + 1 - fMNano) / (604800000 * 1000 * 1000)),
      );
      res.push({
        firstYear: Require.Number(termParsed[0]),
        secondYear: Require.Number(termParsed[1]),
        serialNumber: Require.Number(termParsed[2]),
        weekNum: wNum,
        startNano: Require.Number(this.MToN(startD.getTime())),
        endNano: Require.Number(this.MToN(endD.getTime())),
        firstMondayNano: fMNano,
        lastSundayNano: lSNano,
        termCode: Require.String(t.term),
        termName: Require.String(t.termname),
        schoolYear: Require.Number(t.schoolyear),
        comment: Require.String(t.comm),
      });
    });
    // 时间定位
    const yearNow = new Date().getFullYear();
    // 筛选学期列表
    res = res.filter((term) => {
      return term.firstYear >= yearNow - 10;
    });
    // 排列学期列表
    // 按学期代码升序排列
    res.sort((a, b) => a.termCode.localeCompare(b.termCode));
    //
    return res;
  },
  GMT8DateStringToTimestampMS(str) {
    return new Date(str).getTime() - 8 * 60 * 60 * 1000;
  },
};
