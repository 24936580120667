import crypto from "crypto-js";

export default {
  RandStr(length) {
    let result = "";
    const characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
      counter += 1;
    }
    return result;
  },
  GetAESString(data, key0, iv0) {
    key0 = key0.replace(/(^\s+)|(\s+$)/g, "");
    const key = crypto.enc.Utf8.parse(key0);
    const iv = crypto.enc.Utf8.parse(iv0);
    const encrypted = crypto.AES.encrypt(data, key, {
      iv: iv,
      mode: crypto.mode.CBC,
      padding: crypto.pad.Pkcs7
    });
    return encrypted.toString();
  },
  EncryptPassword(pwd, salt) {
    if (!salt) {
      return pwd;
    }
    const a64 = this.RandStr(64);
    const a16 = this.RandStr(16);
    return this.GetAESString(a64 + pwd, salt, a16);
  }
};
