import Typeof from "@/js/typeof";
import Util from "@/js/util";

export default {
    string(o) {
        return Util.getEmptyStringFromNull(String(o))
    },
    stringAllowEmpty(o) {
        return String(o)
    },
    number(o, d = 0) {
        if (Typeof.string(o)) {
            o = o.trim()
        }
        if (o == null) {
            return d
        } else {
            const num = Number(o)
            return Number.isNaN(num) ? d : num
        }
    },
    boolean(o) {
        if (Typeof.string(o)) {
            o = o.trim()
            if (o.toLowerCase() === "true") {
                return true
            }
            if (o.toLowerCase() === "false") {
                return false
            }
        }
        return Boolean(o)
    },
    object(o) {
        return Typeof.object(o) ? o : {}
    },
    array(o) {
        return Typeof.array(o) ? o : []
    },
    anything(o){
        return o
    },
}