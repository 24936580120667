<template>
  <div class="function-menu fc w100 h100">
    <div ref="head" class="flex-shrink-0">
      <div class="action-bar">
        <svg class="alibaba-icon back-icon" aria-hidden="true" @click.prevent.stop="onBackPressed">
          <use xlink:href="#alibaba-icon-back"/>
        </svg>
        <div class="title">
          教务功能
        </div>
        <div class="menu" @click.prevent.stop="sync">
          <div class="item">
            {{ this.gIsCurrentUserSyncing ? '同步中' : '立即同步' }}
          </div>
        </div>
      </div>
    </div>
    <div class="flex-grow-1 position-relative fc">
      <div class="function-menu-update-text unselectable-text flex-shrink-0" @click.prevent.stop="sync">{{
          updateText
        }}
      </div>
      <!--    这里之所以要用相对定位，是因为要计算子节点的 offsetTop-->
      <div ref="dataList" class="flex-grow-1 position-relative fc" @scroll="updateGroupScrollVisibility">
        <div v-for="(group, index) in groupList" :key="group.text" :ref="setGroupAndChildRefList"
             class="function-menu-data flex-shrink-0 fc"
        >
          <div class="fc margin-left-right-auto-in-fc"
               :style="{width: groupWidthString, minWidth: minGroupWidthString}"
          >
            <FunctionMenuGroup
                :icon-i-d="group.iconID"
                :text="group.text"
                :ref="setGroupRefList"
                @click.prevent.stop="()=>toggleChildVisibility(index)"
            />
            <div
                class="function-menu-child-list"
                v-show="group.visible"
            >
              <div
                  class="function-menu-no-content"
                  v-if="
                    (index === 0 && Util.isEmptyArray(personalInformationList))
                    || (index === 1 && Util.isEmptyArray(graduationPlanCourseList, 2))
                    || (index === 2 && Util.isEmptyArray(gradeList, 2))
                    || (index === 3 && Util.isEmptyArray(expGradeList, 2))
                    || (index === 4 && Util.isEmptyArray(selectedCourseList, 2))
                    || (index === 5 && Util.isEmptyArray(examList, 2))
                    || (index === 6 && Util.isEmptyArray(makeUpExamList, 2))
                    || (index === 7 && Util.isEmptyArray(cetList))
                    || (index === 8 && Util.isEmptyArray(graduationDegreeInfoList))
                    || (index === 9 && Util.isEmptyArray(creditPointList))
                    || (index === 10 && Util.isEmptyArray(quickLoginList))
                  "
              >
                {{ "暂无" + group.text }}
              </div>
              <div v-else>
                <PersonalInfo
                    v-if="index === 0"
                    :info-list="personalInformationList"
                />
                <GraduationPlanCourse
                    v-else-if="index === 1"
                    :gpc-list="graduationPlanCourseList"
                />
                <Grade
                    v-else-if="index === 2"
                    :grade-list="gradeList"
                />
                <ExpGrade
                    v-else-if="index === 3"
                    :exp-grade-list="expGradeList"
                />
                <SelectedCourse
                    v-else-if="index === 4"
                    :selected-course-list="selectedCourseList"
                />
                <Exam
                    v-else-if="index === 5"
                    :exam-list="examList"
                />
                <MakeUpExam
                    v-else-if="index === 6"
                    :make-up-exam-list="makeUpExamList"
                />
                <CET
                    v-else-if="index === 7"
                    :cet-list="cetList"
                />
                <CreditPoint
                    v-else-if="index === 9"
                    :credit-point-list="creditPointList"
                />
                <QuickLogin
                    v-else-if="index === 10"
                    :quick-login-list="quickLoginList"
                />
              </div>
              <div class="position-fixed function-menu-collapse-button-wrapper fr z-index1">
                <div
                    class="function-menu-collapse-button fc"
                    :style="{width: fabSizeString, height: fabSizeString}"
                    @click.prevent.stop="()=>toggleChildVisibility(index)"
                >
                  <svg class="svg fill-current-color margin-top-bottom-auto-in-fc align-self-center"
                       viewBox="0 0 1024 1024" xmlns="http://www.w3.org/2000/svg"
                       width="200" height="200">
                    <path
                        d="M947.4 864C893.2 697.7 736.2 578.9 551 575.5c-23.1-0.4-44.9 0.1-65.6 1.5v164.3c0.1 0.5 0.2 1 0.2 1.5 0 4-3.3 7.3-7.3 7.3-2.7 0-5-1.4-6.2-3.5v0.7L68.8 465.4h2.1c-4 0-7.3-3.3-7.3-7.3 0-2.9 1.7-5.4 4.1-6.6L472 169v0.7c1.3-2.1 3.6-3.5 6.2-3.5 4 0 7.3 3.3 7.3 7.3 0 0.5-0.1 1-0.2 1.5v159.4c18.5-0.9 37.9-1.2 58.3-0.8 230.1 3.9 416.7 196.9 416.7 427.1 0.1 35.5-4.5 70.2-12.9 103.3z m-462-704.4v0.2h-0.4l0.4-0.2z m0 596.9l-0.3-0.2h0.3v0.2z"></path>
                  </svg>
                </div>
                <div class="function-menu-collapse-button-right-filler"
                     :style="{'--estimated-width': fabMarginRightString}"></div>
              </div>
              <div
                  class="position-fixed function-menu-collapse-item fc"
                  @click.prevent.stop="()=>toggleChildVisibility(index)"
                  v-if="false"
              >
                <FunctionMenuGroup
                    :icon-i-d="collapseIconID"
                    :text="collapseTip"
                    :has-top-margin="false"
                    class="visibility-hidden"
                    :style="{width: groupWidthString, minWidth: minGroupWidthString}"
                />
                <div class="background position-absolute top0 bottom0 left0 right0"></div>
                <FunctionMenuGroup
                    :icon-i-d="collapseIconID"
                    :text="collapseTip"
                    :has-top-margin="false"
                    class="position-absolute top0 w100"
                />
              </div>
            </div>
          </div>
        </div>
        <FunctionMenuGroup
            :icon-i-d="collapseIconID"
            :text="collapseTip"
            class="flex-shrink-0 visibility-hidden"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {inject, nextTick} from "vue";
import Util from "@/js/util";
import FunctionMenuGroup from "@/component/function_menu/element/FunctionMenuGroup";
import PersonalInfo from "@/component/function_menu/element/PersonalInfo";
import Require from "@/js/require";
import GraduationPlanCourse from "@/component/function_menu/element/GraduationPlanCourse";
import Grade from "@/component/function_menu/element/Grade";
import Typeof from "@/js/typeof";
import ExpGrade from "@/component/function_menu/element/ExpGrade";
import SelectedCourse from "@/component/function_menu/element/SelectedCourse";
import Schedule from "@/js/schedule";
import Exam from "@/component/function_menu/element/Exam";
import MakeUpExam from "@/component/function_menu/element/MakeUpExam";
import CET from "@/component/function_menu/element/CET";
import QuickLogin from "@/component/function_menu/element/QuickLogin";
import CreditPoint from "@/component/function_menu/element/CreditPoint";

export default {
  name: "FunctionMenu",
  components: {
    CreditPoint,
    QuickLogin,
    CET,
    MakeUpExam,
    Exam,
    SelectedCourse,
    ExpGrade,
    Grade,
    GraduationPlanCourse,
    PersonalInfo,
    FunctionMenuGroup
  },
  setup() {
    return {
      gParsedData: inject('gParsedData'),
      gNumber: inject("gNumber"),
      gNowTime: inject("gNowTime"),
      gHideEndedExamsInFunctionMenu: inject("gHideEndedExamsInFunctionMenu"),
      gDialog: inject("gDialog"),
      gLastSyncText: inject("gLastSyncText"),
      refreshData: inject("refreshData"),
      sThemeColorCSSName: inject("sThemeColorCSSName"),
      sync: inject("sync"),
      gIsCurrentUserSyncing: inject("gIsCurrentUserSyncing"),
      gSyncCountdownText: inject("gSyncCountdownText"),
      onBackPressed: inject("onBackPressed"),
      sOnHostBackPressed: inject("sOnHostBackPressed"),
    }
  },
  props: {},
  computed: {
    minGroupWidthEM() {
      let maxCharNum = 0
      for (const group of this.groupList) {
        const len = Util.getEmptyStringFromNull(group.text).length
        if (len > maxCharNum) {
          maxCharNum = len
        }
      }
      return maxCharNum + 2
    },
    minGroupWidthString() {
      return this.minGroupWidthEM + "em"
    },
    isPortrait() {
      return this.gNumber.VW <= this.gNumber.VH
    },
    portraitGroupRatio() {
      return 90
    },
    landscapeGroupRatio() {
      return 61.8
    },
    groupWidthString() {
      const percent = this.isPortrait ? this.portraitGroupRatio : this.landscapeGroupRatio
      return percent + "%"
    },
    portraitFABMarginRightRatio() {
      return (100 - this.portraitGroupRatio) / 4
    },
    landscapeFABMarginRightRatio() {
      return (100 - this.landscapeGroupRatio) / 4
    },
    fabSizePX() {
      return this.gNumber.VMAX * 2 + 28
    },
    fabSizeString() {
      return this.fabSizePX + "px"
    },
    fabMarginRightString() {
      const percent = this.isPortrait ? this.portraitFABMarginRightRatio : this.landscapeFABMarginRightRatio
      return (percent * this.gNumber.VW - (this.fabSizePX / 2)) + "px"
    },
    updateText() {
      return this.gLastSyncText + (this.gIsCurrentUserSyncing ? `，同步中... 约 ${this.gSyncCountdownText} 秒` : "，点击此处立即同步")
    },
    personalInformationList() {
      const p = this.gParsedData.personalInfo
      return [
        {
          name: "学号",
          value: p.sid,
        },
        {
          name: "姓名",
          value: p.name,
        },
        {
          name: "曾用名",
          value: p.formerName,
        },
        {
          name: "英文名",
          value: p.englishName,
        },
        {
          name: "性别",
          value: p.sex,
        },
        {
          name: "年级",
          value: p.grade,
        },
        {
          name: "学院号",
          value: p.schoolNo,
        },
        {
          name: "学院名称",
          value: p.schoolName,
        },
        {
          name: "班级",
          value: p.classNo,
        },
        {
          name: "专业号",
          value: p.majorNo,
        },
        {
          name: "专业名称",
          value: p.majorName,
        },
        {
          name: "第二专业号",
          value: p.secondMajorNo,
        },
        {
          name: "状态",
          value: p.status,
        },
        {
          name: "身份证号",
          value: p.idNumber,
        },
        {
          name: "学生类型",
          value: p.studentType,
        },
        {
          name: "民族",
          value: p.nationality,
        },
        {
          name: "政治面貌",
          value: p.politicalProfile,
        },
        {
          name: "籍贯",
          value: p.hometown,
        },
        {
          name: "高考考生号",
          value: p.collegeEntranceExaminationCandidateNumber,
        },
        {
          name: "入学日期",
          value: p.enrollmentDate,
        },
        {
          name: "离校日期",
          value: p.leavingSchoolDate,
        },
        {
          name: "宿舍",
          value: p.dormitory,
        },
        {
          name: "联系电话",
          value: p.telephoneNumber,
        },
        {
          name: "家庭邮编",
          value: p.familyPostCode,
        },
        {
          name: "家庭住址",
          value: p.familyAddress,
        },
        {
          name: "家庭联系电话",
          value: p.familyPhoneNumber,
        },
        {
          name: "家庭户主",
          value: p.familyHead,
        },
        {
          name: "高考总分",
          value: p.ceeTotal,
        },
        {
          name: "高考语文(或英语)",
          value: p.ceeChinese,
        },
        {
          name: "高考数学",
          value: p.ceeMath,
        },
        {
          name: "高考英语(或语文)",
          value: p.ceeEnglish,
        },
        {
          name: "高考综合",
          value: p.ceeComprehensiveTest,
        },
        {
          name: "高考其他",
          value: p.ceeOther,
        },
        {
          name: "备注",
          value: p.notes,
        },
      ]
    },
    graduationPlanCourseList() {
      const gl = this.gParsedData.graduationPlanCourseList
      const r = this.gParsedData.graduationPlanRequiredCredit
      const rx = this.gParsedData.graduationPlanXZCredit == null ? "待定" : this.gParsedData.graduationPlanXZCredit
      const rr = this.gParsedData.graduationPlanRZCredit == null ? "待定" : this.gParsedData.graduationPlanRZCredit
      const t = this.gParsedData.graduationPlanRequiredCreditTaken
      const tx = this.gParsedData.graduationPlanXZCreditTaken
      const tr = this.gParsedData.graduationPlanRZCreditTaken
      const res = [{
        courseCode: "MyUniqueCourseCodeHead",
        courseNo: "MyUniqueCourseNoHead",
        name: "课程",
        credit: "学分",
        grade: "成绩",
        completed: "☑",
      }]
      gl.forEach(g => {
        res.push({
          courseCode: Require.string(g.courseCode),
          courseNo: Require.string(g.courseNo),
          name:
              Require.string(g.name)
              + (Util.empty(Require.string(g.type)) ? "" : `\n${Require.string(g.type)}`)
              + `\n${Require.string(g.courseCode)}`
          ,
          credit: Require.string(g.credit),
          grade: Require.string(g.grade),
          completed: Require.string(g.completed ? '✓' : ''),
        })
      })
      res.push({
        courseCode: Require.string("MyUniqueCourseCodeTailTotalHead"),
        courseNo: Require.string("MyUniqueCourseNoTailTotalHead"),
        name: Require.string(),
        credit: Require.string("计划"),
        grade: Require.string(),
        completed: Require.string("已获"),
      })
      res.push({
        courseCode: Require.string("MyUniqueCourseCodeTail"),
        courseNo: Require.string("MyUniqueCourseNoTail"),
        name: Require.string("毕业计划必修学分"),
        credit: Require.string(r),
        grade: Require.string(),
        completed: Require.string(t),
      })
      res.push({
        courseCode: Require.string("MyUniqueCourseCodeTailXZ"),
        courseNo: Require.string("MyUniqueCourseNoTailXZ"),
        name: Require.string("毕业计划限选学分"),
        credit: Require.string(rx),
        grade: Require.string(),
        completed: Require.string(tx),
      })
      res.push({
        courseCode: Require.string("MyUniqueCourseCodeTailRZ"),
        courseNo: Require.string("MyUniqueCourseNoTailRZ"),
        name: Require.string("毕业计划任选学分"),
        credit: Require.string(rr),
        grade: Require.string(),
        completed: Require.string(tr),
      })
      return res
    },
    gradeList() {
      const res = []
      const gl = this.gParsedData.gradeList
      gl.forEach(g => {
        let isGradeSuccessful = null
        if (!Number.isNaN(Number(g.finalGrade))) {
          const score = Number(g.finalGrade)
          isGradeSuccessful = score >= 60
        } else if (Typeof.string(g.finalGrade)) {
          const des = g.finalGrade
          isGradeSuccessful = !(des.includes("旷") || des.includes("缺") || des.includes("取消") || des.includes("不"))
        }
        res.push({
          termCode: Require.string(g.termCode),
          name: Require.string(g.name),
          finalGrade: Require.string(g.finalGrade),
          dailyPerformance: Require.string(g.dailyPerformance),
          experimentPerformance: Require.string(g.experimentPerformance),
          examPerformance: Require.string(g.examPerformance),
          gradeType: Require.string(g.gradeType),
          examType: Require.string(g.examType),
          isGradeSuccessful: Require.anything(isGradeSuccessful),
        })
      })
      res.sort((a, b) => {
        return b.termCode.localeCompare(a.termCode)
      })
      res.unshift({
        termCode: Require.string("MyUniqueTermCodeHead"),
        name: Require.string("课程"),
        finalGrade: Require.string("成绩"),
        dailyPerformance: Require.string("平时"),
        experimentPerformance: Require.string("实验"),
        examPerformance: Require.string("考核"),
        gradeType: Require.string("MyUniqueGradeTypeHead"),
        examType: Require.string("MyUniqueExamTypeHead"),
        isGradeSuccessful: Require.anything(null),
      })
      let important = true
      let lastTermCode = null
      res.forEach(g => {
        if (g.termCode !== lastTermCode) {
          important = !important
          lastTermCode = g.termCode
        }
        g.important = Require.boolean(important)
      })
      return res
    },
    expGradeList() {
      const res = []
      const egl = this.gParsedData.expGradeList
      egl.forEach(eg => {
        let isGradeSuccessful = null
        if (!Number.isNaN(Number(eg.finalGrade))) {
          const score = Number(eg.finalGrade)
          isGradeSuccessful = score >= 60
        } else if (Typeof.string(eg.finalGrade)) {
          const des = eg.finalGrade
          isGradeSuccessful = !(des.includes("旷") || des.includes("缺") || des.includes("取消") || des.includes("不"))
        }
        res.push({
          termCode: Require.string(eg.termCode),
          name: Require.string(eg.name),
          teacherName: Require.string(eg.teacherName),
          finalGrade: Require.string(eg.finalGrade),
          dailyPerformance: Require.string(eg.dailyPerformance),
          examPerformance: Require.string(eg.examPerformance),
          gradeType: Require.string(eg.gradeType),
          examType: Require.string(eg.examType),
          isGradeSuccessful: Require.anything(isGradeSuccessful),
        })
      })
      res.sort((a, b) => {
        return b.termCode.localeCompare(a.termCode)
      })
      res.unshift({
        termCode: Require.string("MyUniqueTermCodeHead"),
        name: Require.string("实验"),
        teacherName: Require.string("教师"),
        finalGrade: Require.string("成绩"),
        dailyPerformance: Require.string("平时"),
        examPerformance: Require.string("考核"),
        gradeType: Require.string("MyUniqueGradeTypeHead"),
        examType: Require.string("MyUniqueExamTypeHead"),
        isGradeSuccessful: Require.anything(null),
      })
      let important = true
      let lastTermCode = null
      res.forEach(eg => {
        if (eg.termCode !== lastTermCode) {
          important = !important
          lastTermCode = eg.termCode
        }
        eg.important = Require.boolean(important)
      })
      return res
    },
    selectedCourseList() {
      const res = []
      const scl = this.gParsedData.selectedCourseList
      scl.forEach(sc => {
        res.push({
          termCode: Require.string(sc.termCode),
          name: Require.string(sc.name),
          credit: Require.string(sc.credit),
          notes: Require.string(sc.notes),
          courseCode: Require.string(sc.courseCode),
          courseNo: Require.string(sc.courseNo),
        })
      })
      res.sort((a, b) => {
        return b.termCode.localeCompare(a.termCode)
      })
      res.unshift({
        termCode: Require.string("MyUniqueTermCodeHead"),
        name: Require.string("课程"),
        credit: Require.string("学分"),
        notes: Require.string("备注"),
        courseCode: Require.string("MyUniqueCourseCodeHead"),
        courseNo: Require.string("MyUniqueCourseNoHead"),
      })
      let important = true
      let lastTermCode = null
      res.forEach(sc => {
        if (sc.termCode !== lastTermCode) {
          important = !important
          lastTermCode = sc.termCode
        }
        sc.important = Require.boolean(important)
      })
      return res
    },
    examList() {
      const res = []
      const sel = this.gParsedData.singleEventList
      const nanoNow = this.gNowTime.nanoNow
      const hidePast = this.gHideEndedExamsInFunctionMenu
      for (const se of sel) {
        if (se.id.startsWith(Schedule.ID.Prefix.Exam) && (!hidePast || se.nanoTime.end > nanoNow)) {
          res.push({
            termCode: Require.string(se.data.termCode),
            courseNo: Require.string(se.data.courseNo),
            type: Require.number(se.data.type),
            name: Require.string(se.data.name),
            time: Require.string(`第${Require.number(se.data.week, 0)}周\n周${["零", "一", "二", "三", "四", "五", "六", "七"][Require.number(se.data.weekday, 0)]}\n第${Require.number(se.data.period, 0)}大节\n${se.data.date}\n${se.data.time}`),
            location: Require.string(Require.string(se.data.location).replaceAll("，", "\n")),
            nanoStart: Require.number(se.nanoTime.start),
            nanoEnd: Require.number(se.nanoTime.end),
          })
        }
      }
      res.sort((a, b) => {
        let res = b.termCode.localeCompare(a.termCode)
        if (res === 0) {
          res = a.nanoStart - b.nanoStart
        }
        if (res === 0) {
          res = a.nanoEnd - b.nanoEnd
        }
        return res
      })
      res.unshift({
        termCode: Require.string("MyUniqueTermCodeHead"),
        courseNo: Require.string("课号"),
        type: Require.number(0),
        name: Require.string("考试"),
        time: Require.string("时间"),
        location: Require.string("地点"),
        nanoStart: Require.number(0),
        nanoEnd: Require.number(0),
      })
      let important = true
      let lastTermCode = null
      res.forEach(exam => {
        if (exam.termCode !== lastTermCode) {
          important = !important
          lastTermCode = exam.termCode
        }
        exam.important = Require.boolean(important)
      })
      return res
    },
    makeUpExamList() {
      const res = []
      const ml = this.gParsedData.makeUpExamList
      for (const m of ml) {
        res.push({
          termCode: Require.string(m.termCode),
          courseNo: Require.string(m.courseNo),
          type: Require.string(m.type),
          name: Require.string(m.name),
          time: Require.string(`${Require.string(m.termCode)}学期\n${Require.string(m.date)}\n${Require.string(m.time)}`),
          location: Require.string(m.location),
        })
      }
      res.sort((a, b) => {
        return b.termCode.localeCompare(a.termCode)
      })
      res.unshift({
        termCode: Require.string("MyUniqueTermCodeHead"),
        courseNo: Require.string("课号"),
        type: Require.string("类型"),
        name: Require.string("考试"),
        time: Require.string("时间"),
        location: Require.string("地点"),
      })
      let important = true
      let lastTermCode = null
      res.forEach(exam => {
        if (exam.termCode !== lastTermCode) {
          important = !important
          lastTermCode = exam.termCode
        }
        exam.important = Require.boolean(important)
      })
      return res
    },
    cetList() {
      const res = []
      const cl = this.gParsedData.cetList
      cl.forEach(cet => {
        res.push({
          termCode: Require.string("学期：" + cet.termCode),
          name: Require.string(cet.name),
          grade: Require.string("考试成绩：" + cet.grade),
          convertedGrade: Require.string("折算成绩：" + cet.convertedGrade),
          cardNo: Require.string("证书编号：" + cet.cardNo),
        })
      })
      return res
    },
    graduationDegreeInfoList() {
      return []
    },
    creditPointList() {
      return this.gParsedData.creditPointList
    },
    quickLoginList() {
      const cas = async () => {
        const helper = Util.Helper()
        if (helper != null) {
          const {username, vpnPassword} = this.gParsedData
          try {
            await helper.login(username, vpnPassword)
          } catch (e) {
            if (Util.empty(e.tip)) {
              e.tip = "发生了错误 :("
            }
            throw e.tip
          } finally {
            helper.unlock()
          }
        } else {
          throw "无法为您自动登录 :("
        }
      }
      return Util.linkDisabled() ? [] : [
        {
          name: "学分制教务系统",
          lanURL: "https://cas.guet.edu.cn/cas/login?service=https://bkjw.guet.edu.cn",
          wanURL: "https://v.guet.edu.cn/https/77726476706e69737468656265737421f3f652d220256d44300d8db9d6562d/cas/login?service=https://bkjw.guet.edu.cn",
          run: cas,
        },
        {
          name: "创新创业系统",
          lanURL: "https://cas.guet.edu.cn/cas/login?service=https://bkjw.guet.edu.cn",
          wanURL: "https://v.guet.edu.cn/https/77726476706e69737468656265737421f3f652d220256d44300d8db9d6562d/cas/login?service=https://bkjw.guet.edu.cn",
          run: cas,
        },
        {
          name: "智慧校园",
          lanURL: "https://cas.guet.edu.cn/cas/login?service=http://icampus.guet.edu.cn/GuetAccount/CasLogin",
          wanURL: "https://v.guet.edu.cn/https/77726476706e69737468656265737421f3f652d220256d44300d8db9d6562d/cas/login?service=http://icampus.guet.edu.cn/GuetAccount/CasLogin",
          run: cas,
        },
        {
          name: "漓江学堂",
          lanURL: "http://zxjx.gxljxt.cn",
          wanURL: "http://zxjx.gxljxt.cn",
        },
        {
          name: "WebVPN",
          lanURL: "https://cas.guet.edu.cn/cas/login?service=https://v.guet.edu.cn/login?cas_login=true",
          wanURL: "https://v.guet.edu.cn/https/77726476706e69737468656265737421f3f652d220256d44300d8db9d6562d/cas/login?service=https://v.guet.edu.cn/login?cas_login=true",
          run: cas,
        },
      ]
    },
    isAnyGroupVisible() {
      for (const gsv of this.groupScrollVisibilityList) {
        if (gsv) {
          return true
        }
      }
      return false
    },
  },
  data() {
    const groupList = [
      {text: '个人信息', iconID: 'alibaba-icon-personal-info'},
      {text: '毕业计划课程', iconID: 'alibaba-icon-graduation-score'},
      {text: '成绩单', iconID: 'alibaba-icon-grade'},
      {text: '实验成绩', iconID: 'alibaba-icon-lab-score'},
      {text: '已选课程', iconID: 'alibaba-icon-selected-course'},
      {text: '考试安排', iconID: 'alibaba-icon-exam'},
      {text: '补考/缓考安排', iconID: 'alibaba-icon-make-up-exam'},
      {text: '等级考试成绩', iconID: 'alibaba-icon-cet'},
      {text: '毕业学位', iconID: 'alibaba-icon-graduation'},
      {text: '学分绩', iconID: 'alibaba-icon-credit-score'},
      {text: '快速登入', iconID: 'alibaba-icon-quick-login'},
    ]
    const groupScrollVisibilityList = []
    for (let i = 0; i < groupList.length; i++) {
      groupScrollVisibilityList.push(true)
    }
    return {
      Util,
      groupList,
      groupAndChildRefList: [],
      groupRefList: [],
      groupScrollVisibilityList,
      collapseTip: "点这里收起",
      collapseIconID: "alibaba-icon-xiangyou1",
    }
  },
  beforeUpdate() {
    this.groupAndChildRefList = []
    this.groupRefList = []
  },
  methods: {
    setGroupAndChildRefList(ref) {
      if (ref) {
        this.groupAndChildRefList.push(ref)
      }
    },
    setGroupRefList(ref) {
      if (ref) {
        this.groupRefList.push(ref)
      }
    },
    toggleChildVisibility(index) {
      for (let i = 0; i < this.groupList.length; i++) {
        const g = this.groupList[i]
        if (index === i) {
          g.visible = !g.visible
          nextTick().then(() => {
            this.$refs.dataList.scrollTop = this.groupAndChildRefList[index].offsetTop
          })
        } else {
          g.visible = false
        }
      }
    },
    updateGroupScrollVisibility() {
      const scrollTop = this.$refs.dataList.scrollTop
      const dataListHeight = this.$refs.dataList.offsetHeight
      for (let i = 0; i < this.groupRefList.length; i++) {
        const group = this.groupRefList[i].getGroupView()
        const maxScrollTop = group.offsetTop + group.offsetHeight
        const minScrollTop = group.offsetTop - dataListHeight
        this.groupScrollVisibilityList[i] = scrollTop < maxScrollTop && scrollTop > minScrollTop
      }
    },
    onHostBackPressed() {
      let handled = false
      let groupCollapsed = false
      let index = null
      for (let i = 0; i < this.groupList.length; i++) {
        const group = this.groupList[i]
        if (group.visible) {
          group.visible = false
          //
          handled = true
          groupCollapsed = true
          index = i
        }
      }
      if (groupCollapsed) {
        nextTick().then(() => {
          this.$refs.dataList.scrollTop = index == null ? 0 : this.groupAndChildRefList[index].offsetTop
        })
      }
      return handled
    },
  },
  mounted() {
    this.sThemeColorCSSName("--function-menu-BackgroundColor")
    this.updateGroupScrollVisibility()
    this.sOnHostBackPressed(this.onHostBackPressed)
  },
}
</script>

<style scoped>

</style>