import HelperError from "./helperError.js";

export default class extends HelperError {
  /**
   * CAS: 验证码错误
   */
  constructor() {
    super({
      code: "cas_wrong_captcha",
      tip: "验证码识别错误，请重试~",
      httpCode: 503,
    });
  }
}
