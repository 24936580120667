<template>
  <div
    class="w100 h100 position-absolute overflow-hidden-important"
    @[keydown].capture.stop.prevent
    @[keyup].capture.stop.prevent
    @[mousedown].capture.stop.prevent
    @[mouseup].capture.stop.prevent
    @[click].capture.stop.prevent
    @[input].capture.stop.prevent
    @[textInput].capture.stop.prevent
    @[change].capture.stop.prevent
  >
    <div
      ref="main"
      class="w100 h100 position-absolute overflow-hidden-important config color z-index0"
      :class="{ dark: darkMode, light: !darkMode }"
    >
      <div
        id="main"
        class="w100 h100 position-absolute overflow-hidden-important z-index1"
      >
        <router-view v-slot="{ Component }">
          <component v-if="Util.iSafari()" :is="Component" ref="currentPage" />
          <transition v-else name="fade" mode="out-in">
            <component :is="Component" ref="currentPage" />
          </transition>
        </router-view>
      </div>
      <Popup ref="tw" class="z-index2">
        <div class="flex-shrink-0 fr">
          <van-picker
            class="flex-basis-0 flex-grow-2 term_week_picker"
            :show-toolbar="false"
            :columns="termColumn"
            :default-index="calTermIndex"
            @change="onTermChange"
          >
            <template v-slot:columns-top>
              <div style="padding: 1em">学期</div>
            </template>
          </van-picker>
          <van-picker
            class="flex-basis-0 flex-grow-1 term_week_picker"
            :show-toolbar="false"
            :columns="weekColumn"
            :default-index="calWeekIndex"
            @change="onWeekChange"
          >
            <template v-slot:columns-top>
              <div style="padding: 1em">周次</div>
            </template>
          </van-picker>
        </div>
      </Popup>
      <Popup ref="wcd" class="z-index3">
        <div
          class="fc day-config"
          style="
            max-height: calc(70 * var(--vh));
            margin: calc(1.5 * var(--popup-BorderRadius)) 0;
          "
        >
          <Card
            v-for="se in Util.getEmptyArrayFromNull(singleEventListToShow)"
            :key="se.id"
            :single-event="se"
            class="flex-shrink-0"
          />
        </div>
      </Popup>
      <Dialog
        ref="si"
        class="z-index4"
        :title="'同步数据'"
        :msg="`数据同步中... 约 ${syncCountDownText} 秒`"
        :cancelable="false"
      >
        <template v-slot:view>
          <ProgressBar style="margin-top: var(--dialog-Gap)" />
        </template>
      </Dialog>
      <Dialog
        ref="ss"
        class="z-index5"
        :title="'同步数据'"
        :msg="'数据同步成功！'"
      >
        <template v-slot:view>
          <ProgressBar
            style="margin-top: var(--dialog-Gap)"
            :type="ProgressBar.PredefinedType.Success"
          />
        </template>
      </Dialog>
      <Dialog
        ref="sf"
        class="z-index6"
        :title="'同步数据'"
        :msg="
          '数据同步失败！' + Util.getEmptyStringFromNull(syncErrorMSGSuffix)
        "
      >
        <template v-slot:view>
          <ProgressBar
            style="margin-top: var(--dialog-Gap)"
            :type="ProgressBar.PredefinedType.Fail"
          />
        </template>
      </Dialog>
      <Dialog
        ref="quickLogin"
        class="z-index7"
        :title="'快速登入'"
        :msg="`自动登录中... 马上就好~`"
        :cancelable="false"
      >
        <template v-slot:view>
          <ProgressBar style="margin-top: var(--dialog-Gap)" />
        </template>
      </Dialog>
      <Dialog
        ref="quickLoginSuccess"
        class="z-index8"
        :title="'快速登入'"
        :msg="'自动登录成功！'"
        :cancelable="false"
      >
        <template v-slot:view>
          <ProgressBar
            style="margin-top: var(--dialog-Gap)"
            :type="ProgressBar.PredefinedType.Success"
          />
        </template>
      </Dialog>
      <Dialog
        ref="quickLoginFail"
        class="z-index9"
        :title="'快速登入'"
        :msg="`自动登录失败！\n\n${quickLoginFailReason}\n\n您可以选择打开未登录的页面，然后手动登录。`"
        :show-yes="true"
        :show-no="true"
        :yes-text="'打开未登录页面'"
        :no-text="'不用了'"
        :yes-callback="
          () => {
            window.open(quickLoginFailURL);
          }
        "
      >
        <template v-slot:view>
          <ProgressBar
            style="margin-top: var(--dialog-Gap)"
            :type="ProgressBar.PredefinedType.Fail"
          />
        </template>
      </Dialog>
      <Dialog
        ref="askForNetwork"
        class="z-index10"
        :title="'快速登入'"
        :msg="'请选择您当前的网络状态'"
        :cancelable="true"
        :show-yes="true"
        :show-no="true"
        :yes-text="'校园网'"
        :no-text="'非校园网'"
        :yes-callback="
          () => {
            window.open(lanURL);
          }
        "
        :no-callback="
          () => {
            window.open(wanURL);
          }
        "
      />
      <Dialog
        ref="logout"
        class="z-index11"
        :title="'登出'"
        :msg="'您确定要退出登录吗？'"
        :show-yes="true"
        :yes-text="'确定'"
        :show-no="true"
        :no-text="'我再想想'"
        :yes-callback="logout"
      />
      <Dialog
        ref="help"
        class="z-index12"
        :title="'帮助文档'"
        show-yes
        :yes-text="'好的'"
      >
        <template v-slot:view>
          <div class="app-dialog-logon-help-help-text">
            <div>
              {{ "1. GUET课程表小助手 油猴脚本（直接点击即可安装）：" }}
            </div>
            <div
              style="margin-left: 1.1em"
              @click.prevent.stop="
                () => {
                  if (!Util.linkDisabled()) {
                    window.open(URLCenter.GUETCourseTableHelperUserScript);
                  }
                }
              "
            >
              {{ URLCenter.GUETCourseTableHelperUserScript }}
            </div>
          </div>
        </template>
      </Dialog>
      <Dialog
        ref="contact"
        class="z-index13"
        :title="'联系我们'"
        show-yes
        :yes-text="'好的'"
      >
        <template v-slot:view>
          <div class="app-dialog-contact-text">
            <div>
              {{
                `${NameCenter.GUETCourseTableGroup}：\n（点击群号可一键加群）`
              }}
            </div>
            <div
              style="margin-left: 1.1em"
              @click.prevent.stop="
                () => {
                  if (!Util.linkDisabled()) {
                    window.open(URLCenter.GUETCourseTableGroup);
                  }
                }
              "
            >
              {{ guetCourseTableQQGroup.Number }}
            </div>
          </div>
        </template>
      </Dialog>
      <Dialog
        ref="sponsor"
        class="z-index14"
        :title="'谢谢你 :D'"
        show-yes
        :yes-text="'不客气'"
        :msg="'在手机上点击支付宝二维码可以直接跳转到支付宝噢~ 微信的话直接扫一扫或者截图之后去微信扫一扫'"
      >
        <template v-slot:view>
          <div
            class="w100 position-relative"
            style="padding-top: var(--dialog-Gap)"
          >
            <div
              class="position-absolute h100 mh100 w50"
              @click.prevent.stop="
                () => {
                  if (!Util.linkDisabled()) {
                    window.open(receiptQRCode.AliPayURL);
                  }
                }
              "
            />
            <svg
              v-show="
                receiptQRCode.Name === ReceiptQRCode.Developer.Telephone.Name
              "
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              viewBox="0 0 8192 4096"
            >
              <g id="图层_1" data-name="图层 1">
                <rect width="4096" height="4096" style="fill: #1890ff" />
                <rect
                  x="4096"
                  width="4096"
                  height="4096"
                  style="fill: #1aad19"
                />
              </g>
              <g id="图层_3" data-name="图层 3">
                <rect
                  id="支付宝"
                  x="548"
                  y="657.6"
                  width="3000"
                  height="3000"
                  style="fill: #fff"
                />
                <rect
                  id="微信"
                  x="4644"
                  y="657.6"
                  width="3000"
                  height="3000"
                  style="fill: #fff"
                />
                <g id="Telephone微信">
                  <rect
                    x="5144"
                    y="1157.6"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="5144"
                    y="1339.44"
                    width="60.6"
                    height="242.41"
                    style="fill: #231815"
                  />
                  <rect
                    x="5144"
                    y="1642.45"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5144"
                    y="1824.29"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5144"
                    y="2369.74"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5144"
                    y="2490.94"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5144"
                    y="2612.15"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5144"
                    y="2733.38"
                    width="60.6"
                    height="303.04"
                    style="fill: #231815"
                  />
                  <rect
                    x="5144"
                    y="3036.39"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5204.63"
                    y="1157.6"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5204.63"
                    y="1521.24"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5204.63"
                    y="1824.29"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5204.63"
                    y="1945.49"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5204.63"
                    y="2066.7"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5204.63"
                    y="2369.74"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5204.63"
                    y="2490.94"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5204.63"
                    y="2612.15"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5204.63"
                    y="2733.38"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5204.63"
                    y="3097"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5265.23"
                    y="1157.6"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5265.23"
                    y="1278.83"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5265.23"
                    y="1339.44"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5265.23"
                    y="1521.24"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5265.23"
                    y="1642.45"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5265.23"
                    y="1824.29"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5265.23"
                    y="2127.3"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5265.23"
                    y="2248.53"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5265.23"
                    y="2430.34"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5265.23"
                    y="2490.94"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5265.23"
                    y="2612.15"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5265.23"
                    y="2733.38"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5265.23"
                    y="2854.59"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="5265.23"
                    y="3097"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5325.84"
                    y="1157.6"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5325.84"
                    y="1278.83"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5325.84"
                    y="1339.44"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5325.84"
                    y="1521.24"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5325.84"
                    y="1945.49"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="5325.84"
                    y="2187.93"
                    width="60.6"
                    height="303.04"
                    style="fill: #231815"
                  />
                  <rect
                    x="5325.84"
                    y="2490.94"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5325.84"
                    y="2733.38"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5325.84"
                    y="2854.59"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="5325.84"
                    y="3097"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5386.44"
                    y="1157.6"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5386.44"
                    y="1278.83"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5386.44"
                    y="1339.44"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5386.44"
                    y="1521.24"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5386.44"
                    y="1703.08"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5386.44"
                    y="1824.29"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5386.44"
                    y="2066.7"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5386.44"
                    y="2187.93"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5386.44"
                    y="2490.94"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5386.44"
                    y="2733.38"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5386.44"
                    y="2854.59"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="5386.44"
                    y="3097"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5447.04"
                    y="1157.6"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5447.04"
                    y="1521.24"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5447.04"
                    y="1703.08"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5447.04"
                    y="2006.09"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5447.04"
                    y="2309.14"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5447.04"
                    y="2430.34"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5447.04"
                    y="2490.94"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5447.04"
                    y="2612.15"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5447.04"
                    y="2733.38"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5447.04"
                    y="3097"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5507.64"
                    y="1157.6"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="5507.64"
                    y="1339.44"
                    width="60.6"
                    height="242.41"
                    style="fill: #231815"
                  />
                  <rect
                    x="5507.64"
                    y="1642.45"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5507.64"
                    y="1763.68"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5507.64"
                    y="1884.89"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5507.64"
                    y="2006.09"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5507.64"
                    y="2127.3"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5507.64"
                    y="2248.53"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5507.64"
                    y="2369.74"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5507.64"
                    y="2490.94"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5507.64"
                    y="2612.15"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5507.64"
                    y="2733.38"
                    width="60.6"
                    height="303.04"
                    style="fill: #231815"
                  />
                  <rect
                    x="5507.64"
                    y="3036.39"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5568.25"
                    y="1642.45"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5568.25"
                    y="1763.68"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5568.25"
                    y="2127.3"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5568.25"
                    y="2309.14"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="5628.85"
                    y="1157.6"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5628.85"
                    y="1278.83"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5628.85"
                    y="1460.64"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5628.85"
                    y="1824.29"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5628.85"
                    y="2006.09"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5628.85"
                    y="2187.93"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5628.85"
                    y="2369.74"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5628.85"
                    y="2490.94"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5628.85"
                    y="2672.78"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5628.85"
                    y="2733.38"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5628.85"
                    y="2975.79"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5628.85"
                    y="3097"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5689.48"
                    y="1157.6"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="5689.48"
                    y="1339.44"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5689.48"
                    y="1642.45"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5689.48"
                    y="1824.29"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5689.48"
                    y="2006.09"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5689.48"
                    y="2187.93"
                    width="60.6"
                    height="303.04"
                    style="fill: #231815"
                  />
                  <rect
                    x="5689.48"
                    y="2490.94"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5689.48"
                    y="2612.15"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5689.48"
                    y="2793.98"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5689.48"
                    y="3036.39"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5750.08"
                    y="1278.83"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5750.08"
                    y="1339.44"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5750.08"
                    y="1460.64"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5750.08"
                    y="1824.29"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5750.08"
                    y="2006.09"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5750.08"
                    y="2490.94"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5750.08"
                    y="2672.78"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5750.08"
                    y="2733.38"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5750.08"
                    y="2915.19"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5750.08"
                    y="3036.39"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5810.69"
                    y="1157.6"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5810.69"
                    y="1339.44"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5810.69"
                    y="1581.85"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5810.69"
                    y="1703.08"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5810.69"
                    y="1884.89"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5810.69"
                    y="2066.7"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5810.69"
                    y="2248.53"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5810.69"
                    y="2369.74"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5810.69"
                    y="2551.55"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5810.69"
                    y="2793.98"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5810.69"
                    y="2915.19"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5871.29"
                    y="1339.44"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5871.29"
                    y="1521.24"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="5871.29"
                    y="1824.29"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5871.29"
                    y="2066.7"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5871.29"
                    y="2309.14"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5871.29"
                    y="2490.94"
                    width="60.6"
                    height="242.41"
                    style="fill: #231815"
                  />
                  <rect
                    x="5871.29"
                    y="2733.38"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5871.29"
                    y="2915.19"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5871.29"
                    y="3036.39"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5931.89"
                    y="1157.6"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5931.89"
                    y="1278.83"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5931.89"
                    y="1400.04"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5931.89"
                    y="1642.45"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5931.89"
                    y="1824.29"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5931.89"
                    y="2066.7"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5931.89"
                    y="2248.53"
                    width="60.6"
                    height="242.41"
                    style="fill: #231815"
                  />
                  <rect
                    x="5931.89"
                    y="2490.94"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5931.89"
                    y="2612.15"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5931.89"
                    y="2975.79"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5992.49"
                    y="1521.24"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5992.49"
                    y="2127.3"
                    width="60.6"
                    height="303.04"
                    style="fill: #231815"
                  />
                  <rect
                    x="5992.49"
                    y="2490.94"
                    width="60.6"
                    height="242.41"
                    style="fill: #231815"
                  />
                  <rect
                    x="5992.49"
                    y="2793.98"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5992.49"
                    y="2915.19"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5992.49"
                    y="3097"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6053.1"
                    y="1339.44"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="6053.1"
                    y="1581.85"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6053.1"
                    y="1703.08"
                    width="60.6"
                    height="242.41"
                    style="fill: #231815"
                  />
                  <rect
                    x="6053.1"
                    y="2006.09"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6053.1"
                    y="2127.3"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6053.1"
                    y="2248.53"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="6053.1"
                    y="2551.55"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6053.1"
                    y="2672.78"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6053.1"
                    y="2733.38"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6053.1"
                    y="2975.79"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6053.1"
                    y="3097"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6113.7"
                    y="1521.24"
                    width="60.6"
                    height="242.44"
                    style="fill: #231815"
                  />
                  <rect
                    x="6113.7"
                    y="1824.29"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6113.7"
                    y="2187.93"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6113.7"
                    y="2369.74"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6113.7"
                    y="2490.94"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6113.7"
                    y="2733.38"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6113.7"
                    y="3097"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6174.33"
                    y="1157.6"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6174.33"
                    y="1278.83"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6174.33"
                    y="1400.04"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6174.33"
                    y="1703.08"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6174.33"
                    y="1824.29"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6174.33"
                    y="2006.09"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6174.33"
                    y="2248.53"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6174.33"
                    y="2369.74"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6174.33"
                    y="2490.94"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6174.33"
                    y="2672.78"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6174.33"
                    y="2793.98"
                    width="60.6"
                    height="242.44"
                    style="fill: #231815"
                  />
                  <rect
                    x="6174.33"
                    y="3097"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6234.93"
                    y="1400.04"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="6234.93"
                    y="2066.7"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6234.93"
                    y="2248.53"
                    width="60.6"
                    height="242.41"
                    style="fill: #231815"
                  />
                  <rect
                    x="6234.93"
                    y="2490.94"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6234.93"
                    y="2672.78"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6234.93"
                    y="2975.79"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6234.93"
                    y="3036.39"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6295.54"
                    y="1278.83"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6295.54"
                    y="1400.04"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6295.54"
                    y="1581.85"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6295.54"
                    y="1763.68"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6295.54"
                    y="2006.09"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6295.54"
                    y="2248.53"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="6295.54"
                    y="2490.94"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6295.54"
                    y="2733.38"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6295.54"
                    y="2854.59"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="6295.54"
                    y="3036.39"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6356.14"
                    y="1218.23"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6356.14"
                    y="1460.64"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6356.14"
                    y="1703.08"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6356.14"
                    y="1945.49"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6356.14"
                    y="2187.93"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6356.14"
                    y="2430.34"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6356.14"
                    y="2551.55"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6356.14"
                    y="2672.78"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6356.14"
                    y="2733.38"
                    width="60.6"
                    height="303.04"
                    style="fill: #231815"
                  />
                  <rect
                    x="6416.74"
                    y="1157.6"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6416.74"
                    y="1278.83"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6416.74"
                    y="1339.44"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6416.74"
                    y="1884.89"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="6416.74"
                    y="2127.3"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6416.74"
                    y="2369.74"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6416.74"
                    y="2612.15"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6416.74"
                    y="2733.38"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6416.74"
                    y="2854.59"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6416.74"
                    y="3097"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6477.34"
                    y="1278.83"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6477.34"
                    y="1521.24"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6477.34"
                    y="1824.29"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6477.34"
                    y="2006.09"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6477.34"
                    y="2248.53"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6477.34"
                    y="2430.34"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6477.34"
                    y="2490.94"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6477.34"
                    y="2672.78"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6477.34"
                    y="2733.38"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6477.34"
                    y="2915.19"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6537.95"
                    y="1278.83"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6537.95"
                    y="1339.44"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6537.95"
                    y="1763.68"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6537.95"
                    y="2006.09"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6537.95"
                    y="2127.3"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6537.95"
                    y="2248.53"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6537.95"
                    y="2369.74"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6537.95"
                    y="2490.94"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6537.95"
                    y="2612.15"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6537.95"
                    y="2733.38"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="6537.95"
                    y="2975.79"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6537.95"
                    y="3036.39"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6598.55"
                    y="1218.23"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6598.55"
                    y="1339.44"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6598.55"
                    y="1460.64"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="6598.55"
                    y="1703.08"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6598.55"
                    y="1884.89"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6598.55"
                    y="2187.93"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6598.55"
                    y="2430.34"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6598.55"
                    y="2612.15"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6598.55"
                    y="2733.38"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="6598.55"
                    y="2975.79"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6598.55"
                    y="3036.39"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6659.18"
                    y="1703.08"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6659.18"
                    y="1824.29"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6659.18"
                    y="1945.49"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6659.18"
                    y="2248.53"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6659.18"
                    y="2369.74"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6659.18"
                    y="2612.15"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6659.18"
                    y="2854.59"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6659.18"
                    y="3097"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6719.78"
                    y="1157.6"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="6719.78"
                    y="1339.44"
                    width="60.6"
                    height="242.41"
                    style="fill: #231815"
                  />
                  <rect
                    x="6719.78"
                    y="1703.08"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6719.78"
                    y="1884.89"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6719.78"
                    y="2006.09"
                    width="60.6"
                    height="303.04"
                    style="fill: #231815"
                  />
                  <rect
                    x="6719.78"
                    y="2369.74"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6719.78"
                    y="2490.94"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="6719.78"
                    y="2733.38"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6719.78"
                    y="2854.59"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6719.78"
                    y="3097"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6780.38"
                    y="1157.6"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6780.38"
                    y="1521.24"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6780.38"
                    y="1642.45"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6780.38"
                    y="1763.68"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="6780.38"
                    y="2006.09"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6780.38"
                    y="2127.3"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6780.38"
                    y="2309.14"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6780.38"
                    y="2551.55"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6780.38"
                    y="2854.59"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6780.38"
                    y="2975.79"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6780.38"
                    y="3036.39"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6840.99"
                    y="1157.6"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6840.99"
                    y="1278.83"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6840.99"
                    y="1339.44"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6840.99"
                    y="1521.24"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6840.99"
                    y="2006.09"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6840.99"
                    y="2309.14"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6840.99"
                    y="2490.94"
                    width="60.6"
                    height="242.41"
                    style="fill: #231815"
                  />
                  <rect
                    x="6840.99"
                    y="2733.38"
                    width="60.6"
                    height="303.04"
                    style="fill: #231815"
                  />
                  <rect
                    x="6840.99"
                    y="3036.39"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6901.59"
                    y="1157.6"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6901.59"
                    y="1278.83"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6901.59"
                    y="1339.44"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6901.59"
                    y="1521.24"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6901.59"
                    y="1763.68"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6901.59"
                    y="2066.7"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6901.59"
                    y="2248.53"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6901.59"
                    y="2490.94"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6901.59"
                    y="2733.38"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6901.59"
                    y="2854.59"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6901.59"
                    y="3036.39"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6962.19"
                    y="1157.6"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6962.19"
                    y="1278.83"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6962.19"
                    y="1339.44"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6962.19"
                    y="1521.24"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6962.19"
                    y="1642.45"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="6962.19"
                    y="1945.49"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6962.19"
                    y="2127.3"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6962.19"
                    y="2248.53"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6962.19"
                    y="2672.78"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6962.19"
                    y="2975.79"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="7022.8"
                    y="1157.6"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="7022.8"
                    y="1521.24"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="7022.8"
                    y="1824.29"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="7022.8"
                    y="2066.7"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="7022.8"
                    y="2309.14"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="7022.8"
                    y="2551.55"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="7022.8"
                    y="2672.78"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="7022.8"
                    y="2915.19"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="7083.4"
                    y="1157.6"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="7083.4"
                    y="1339.44"
                    width="60.6"
                    height="242.41"
                    style="fill: #231815"
                  />
                  <rect
                    x="7083.4"
                    y="1642.45"
                    width="60.6"
                    height="666.66"
                    style="fill: #231815"
                  />
                  <rect
                    x="7083.4"
                    y="2369.74"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="7083.4"
                    y="2490.94"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="7083.4"
                    y="2612.15"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="7083.4"
                    y="2733.38"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="7083.4"
                    y="2915.19"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="7083.4"
                    y="3097"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                </g>
                <g id="Telephone支付宝">
                  <rect
                    x="1048"
                    y="1157.6"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="1048"
                    y="1339.44"
                    width="60.6"
                    height="242.41"
                    style="fill: #231815"
                  />
                  <rect
                    x="1048"
                    y="1642.45"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1048"
                    y="1763.68"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1048"
                    y="1945.49"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="1048"
                    y="2187.93"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1048"
                    y="2369.74"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="1048"
                    y="2490.94"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="1048"
                    y="2733.38"
                    width="60.6"
                    height="303.04"
                    style="fill: #231815"
                  />
                  <rect
                    x="1048"
                    y="3036.39"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="1108.63"
                    y="1157.6"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1108.63"
                    y="1521.24"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1108.63"
                    y="1763.68"
                    width="60.6"
                    height="242.41"
                    style="fill: #231815"
                  />
                  <rect
                    x="1108.63"
                    y="2733.38"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1108.63"
                    y="3097"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1169.23"
                    y="1157.6"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1169.23"
                    y="1278.83"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1169.23"
                    y="1339.44"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="1169.23"
                    y="1521.24"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1169.23"
                    y="1642.45"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1169.23"
                    y="1763.68"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1169.23"
                    y="2006.09"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1169.23"
                    y="2187.93"
                    width="60.6"
                    height="242.41"
                    style="fill: #231815"
                  />
                  <rect
                    x="1169.23"
                    y="2490.94"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="1169.23"
                    y="2733.38"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1169.23"
                    y="2854.59"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="1169.23"
                    y="3097"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1229.84"
                    y="1157.6"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1229.84"
                    y="1278.83"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1229.84"
                    y="1339.44"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="1229.84"
                    y="1521.24"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1229.84"
                    y="1642.45"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1229.84"
                    y="1945.49"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="1229.84"
                    y="2309.14"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1229.84"
                    y="2490.94"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="1229.84"
                    y="2733.38"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1229.84"
                    y="2854.59"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="1229.84"
                    y="3097"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1290.44"
                    y="1157.6"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1290.44"
                    y="1278.83"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1290.44"
                    y="1339.44"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="1290.44"
                    y="1521.24"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1290.44"
                    y="1642.45"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1290.44"
                    y="1763.68"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="1290.44"
                    y="2187.93"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1290.44"
                    y="2369.74"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1290.44"
                    y="2490.94"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="1290.44"
                    y="2733.38"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1290.44"
                    y="2854.59"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="1290.44"
                    y="3097"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1351.04"
                    y="1157.6"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1351.04"
                    y="1521.24"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1351.04"
                    y="1642.45"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1351.04"
                    y="2066.7"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1351.04"
                    y="2187.93"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="1351.04"
                    y="2430.34"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1351.04"
                    y="2490.94"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1351.04"
                    y="2612.15"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1351.04"
                    y="2733.38"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1351.04"
                    y="3097"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1411.64"
                    y="1157.6"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="1411.64"
                    y="1339.44"
                    width="60.6"
                    height="242.41"
                    style="fill: #231815"
                  />
                  <rect
                    x="1411.64"
                    y="1642.45"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1411.64"
                    y="1763.68"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1411.64"
                    y="1884.89"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1411.64"
                    y="2006.09"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1411.64"
                    y="2127.3"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1411.64"
                    y="2248.53"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1411.64"
                    y="2369.74"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1411.64"
                    y="2490.94"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1411.64"
                    y="2612.15"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1411.64"
                    y="2733.38"
                    width="60.6"
                    height="303.04"
                    style="fill: #231815"
                  />
                  <rect
                    x="1411.64"
                    y="3036.39"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="1472.25"
                    y="1703.08"
                    width="60.6"
                    height="303.04"
                    style="fill: #231815"
                  />
                  <rect
                    x="1472.25"
                    y="2127.3"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1472.25"
                    y="2309.14"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1472.25"
                    y="2430.34"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1472.25"
                    y="2490.94"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1532.85"
                    y="1278.83"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1532.85"
                    y="1339.44"
                    width="60.6"
                    height="303.04"
                    style="fill: #231815"
                  />
                  <rect
                    x="1532.85"
                    y="1763.68"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1532.85"
                    y="1884.89"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1532.85"
                    y="2006.09"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="1532.85"
                    y="2187.93"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1532.85"
                    y="2309.14"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="1532.85"
                    y="2490.94"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1532.85"
                    y="2612.15"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="1532.85"
                    y="2793.98"
                    width="60.6"
                    height="242.44"
                    style="fill: #231815"
                  />
                  <rect
                    x="1532.85"
                    y="3097"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1593.48"
                    y="1157.6"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1593.48"
                    y="1642.45"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="1593.48"
                    y="1824.29"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="1593.48"
                    y="2248.53"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1593.48"
                    y="2369.74"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1593.48"
                    y="2490.94"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="1593.48"
                    y="2854.59"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1593.48"
                    y="2975.79"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1593.48"
                    y="3036.39"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1654.08"
                    y="1157.6"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1654.08"
                    y="1400.04"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="1654.08"
                    y="1642.45"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1654.08"
                    y="1824.29"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1654.08"
                    y="2006.09"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1654.08"
                    y="2127.3"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1654.08"
                    y="2309.14"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1654.08"
                    y="2430.34"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1654.08"
                    y="2551.55"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="1654.08"
                    y="2733.38"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1654.08"
                    y="2975.79"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1654.08"
                    y="3097"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1714.69"
                    y="1218.23"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="1714.69"
                    y="1460.64"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1714.69"
                    y="1642.45"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="1714.69"
                    y="1824.29"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1714.69"
                    y="2187.93"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1714.69"
                    y="2369.74"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1714.69"
                    y="2490.94"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1714.69"
                    y="2612.15"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1714.69"
                    y="2733.38"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1714.69"
                    y="2854.59"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1714.69"
                    y="3036.39"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1775.29"
                    y="1218.23"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1775.29"
                    y="1339.44"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="1775.29"
                    y="1521.24"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1775.29"
                    y="1763.68"
                    width="60.6"
                    height="363.64"
                    style="fill: #231815"
                  />
                  <rect
                    x="1775.29"
                    y="2309.14"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1775.29"
                    y="2430.34"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1775.29"
                    y="2490.94"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="1775.29"
                    y="2915.19"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="1775.29"
                    y="3036.39"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="1835.89"
                    y="1703.08"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="1835.89"
                    y="2006.09"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="1835.89"
                    y="2430.34"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1835.89"
                    y="2490.94"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1835.89"
                    y="2612.15"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1835.89"
                    y="2793.98"
                    width="60.6"
                    height="242.44"
                    style="fill: #231815"
                  />
                  <rect
                    x="1835.89"
                    y="3097"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1896.49"
                    y="1278.83"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1896.49"
                    y="1400.04"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1896.49"
                    y="1521.24"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="1896.49"
                    y="1763.68"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1896.49"
                    y="1884.89"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1896.49"
                    y="2127.3"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1896.49"
                    y="2430.34"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1896.49"
                    y="2490.94"
                    width="60.6"
                    height="242.41"
                    style="fill: #231815"
                  />
                  <rect
                    x="1896.49"
                    y="2793.98"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1896.49"
                    y="2975.79"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1957.1"
                    y="1157.6"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1957.1"
                    y="1339.44"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="1957.1"
                    y="1703.08"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1957.1"
                    y="1824.29"
                    width="60.6"
                    height="424.25"
                    style="fill: #231815"
                  />
                  <rect
                    x="1957.1"
                    y="2309.14"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1957.1"
                    y="2430.34"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1957.1"
                    y="2672.78"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1957.1"
                    y="2733.38"
                    width="60.6"
                    height="303.04"
                    style="fill: #231815"
                  />
                  <rect
                    x="1957.1"
                    y="3036.39"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2017.7"
                    y="1218.23"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2017.7"
                    y="1400.04"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2017.7"
                    y="1521.24"
                    width="60.6"
                    height="242.44"
                    style="fill: #231815"
                  />
                  <rect
                    x="2017.7"
                    y="1884.89"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2017.7"
                    y="2006.09"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2017.7"
                    y="2127.3"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2017.7"
                    y="2309.14"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2017.7"
                    y="2430.34"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2017.7"
                    y="2551.55"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2017.7"
                    y="2854.59"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2017.7"
                    y="2975.79"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2017.7"
                    y="3097"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2078.33"
                    y="1400.04"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2078.33"
                    y="1642.45"
                    width="60.6"
                    height="242.41"
                    style="fill: #231815"
                  />
                  <rect
                    x="2078.33"
                    y="2006.09"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2078.33"
                    y="2127.3"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2078.33"
                    y="2430.34"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2078.33"
                    y="2490.94"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2078.33"
                    y="2612.15"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2078.33"
                    y="2733.38"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2078.33"
                    y="2915.19"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2138.93"
                    y="1157.6"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2138.93"
                    y="1339.44"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2138.93"
                    y="1521.24"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2138.93"
                    y="1703.08"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2138.93"
                    y="1824.29"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2138.93"
                    y="1945.49"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2138.93"
                    y="2066.7"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2138.93"
                    y="2187.93"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2138.93"
                    y="2369.74"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2138.93"
                    y="2490.94"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2138.93"
                    y="2793.98"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2138.93"
                    y="2915.19"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2138.93"
                    y="3036.39"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2199.54"
                    y="1157.6"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2199.54"
                    y="1339.44"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2199.54"
                    y="1460.64"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2199.54"
                    y="1581.85"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="2199.54"
                    y="1824.29"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2199.54"
                    y="2187.93"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2199.54"
                    y="2309.14"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2199.54"
                    y="2490.94"
                    width="60.6"
                    height="242.41"
                    style="fill: #231815"
                  />
                  <rect
                    x="2199.54"
                    y="2793.98"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2260.14"
                    y="1218.23"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2260.14"
                    y="1400.04"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2260.14"
                    y="1521.24"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2260.14"
                    y="1703.08"
                    width="60.6"
                    height="242.41"
                    style="fill: #231815"
                  />
                  <rect
                    x="2260.14"
                    y="2006.09"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2260.14"
                    y="2187.93"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2260.14"
                    y="2430.34"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2260.14"
                    y="2551.55"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2260.14"
                    y="2672.78"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2260.14"
                    y="2793.98"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2260.14"
                    y="2975.79"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2320.74"
                    y="1157.6"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="2320.74"
                    y="1339.44"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2320.74"
                    y="1460.64"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2320.74"
                    y="1581.85"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2320.74"
                    y="1884.89"
                    width="60.6"
                    height="242.44"
                    style="fill: #231815"
                  />
                  <rect
                    x="2320.74"
                    y="2187.93"
                    width="60.6"
                    height="303.04"
                    style="fill: #231815"
                  />
                  <rect
                    x="2320.74"
                    y="2490.94"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2320.74"
                    y="2854.59"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2320.74"
                    y="2975.79"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2320.74"
                    y="3036.39"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2381.34"
                    y="1521.24"
                    width="60.6"
                    height="303.04"
                    style="fill: #231815"
                  />
                  <rect
                    x="2381.34"
                    y="1884.89"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2381.34"
                    y="2127.3"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2381.34"
                    y="2309.14"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2381.34"
                    y="2430.34"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2381.34"
                    y="2551.55"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="2381.34"
                    y="2733.38"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2381.34"
                    y="2854.59"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2381.34"
                    y="3036.39"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2441.95"
                    y="1157.6"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2441.95"
                    y="1339.44"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2441.95"
                    y="1642.45"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2441.95"
                    y="2006.09"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2441.95"
                    y="2127.3"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2441.95"
                    y="2309.14"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2441.95"
                    y="2490.94"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2441.95"
                    y="2612.15"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2441.95"
                    y="2733.38"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2441.95"
                    y="3036.39"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2502.55"
                    y="1218.23"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2502.55"
                    y="1339.44"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2502.55"
                    y="1460.64"
                    width="60.6"
                    height="242.44"
                    style="fill: #231815"
                  />
                  <rect
                    x="2502.55"
                    y="1824.29"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2502.55"
                    y="2127.3"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2502.55"
                    y="2248.53"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2502.55"
                    y="2369.74"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2502.55"
                    y="2551.55"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="2502.55"
                    y="2733.38"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="2502.55"
                    y="2975.79"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2502.55"
                    y="3036.39"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2563.18"
                    y="1763.68"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2563.18"
                    y="1945.49"
                    width="60.6"
                    height="303.04"
                    style="fill: #231815"
                  />
                  <rect
                    x="2563.18"
                    y="2309.14"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2563.18"
                    y="2430.34"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2563.18"
                    y="2551.55"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2563.18"
                    y="2854.59"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="2563.18"
                    y="3097"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2623.78"
                    y="1157.6"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="2623.78"
                    y="1339.44"
                    width="60.6"
                    height="242.41"
                    style="fill: #231815"
                  />
                  <rect
                    x="2623.78"
                    y="1642.45"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="2623.78"
                    y="1884.89"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2623.78"
                    y="2006.09"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2623.78"
                    y="2187.93"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2623.78"
                    y="2369.74"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2623.78"
                    y="2612.15"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2623.78"
                    y="2733.38"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2623.78"
                    y="2854.59"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2623.78"
                    y="2975.79"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2623.78"
                    y="3036.39"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2684.38"
                    y="1157.6"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2684.38"
                    y="1521.24"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2684.38"
                    y="1642.45"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2684.38"
                    y="1884.89"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2684.38"
                    y="2006.09"
                    width="60.6"
                    height="363.64"
                    style="fill: #231815"
                  />
                  <rect
                    x="2684.38"
                    y="2551.55"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2684.38"
                    y="2854.59"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2684.38"
                    y="2975.79"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2684.38"
                    y="3097"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2744.99"
                    y="1157.6"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2744.99"
                    y="1278.83"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2744.99"
                    y="1339.44"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2744.99"
                    y="1521.24"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2744.99"
                    y="1642.45"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2744.99"
                    y="1763.68"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2744.99"
                    y="2006.09"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="2744.99"
                    y="2248.53"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2744.99"
                    y="2490.94"
                    width="60.6"
                    height="242.41"
                    style="fill: #231815"
                  />
                  <rect
                    x="2744.99"
                    y="2733.38"
                    width="60.6"
                    height="242.44"
                    style="fill: #231815"
                  />
                  <rect
                    x="2744.99"
                    y="3036.39"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2805.59"
                    y="1157.6"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2805.59"
                    y="1278.83"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2805.59"
                    y="1339.44"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2805.59"
                    y="1521.24"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2805.59"
                    y="1642.45"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2805.59"
                    y="1824.29"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="2805.59"
                    y="2127.3"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2805.59"
                    y="2309.14"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2805.59"
                    y="2551.55"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2805.59"
                    y="2793.98"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2805.59"
                    y="2975.79"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2805.59"
                    y="3036.39"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2866.19"
                    y="1157.6"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2866.19"
                    y="1278.83"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2866.19"
                    y="1339.44"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2866.19"
                    y="1521.24"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2866.19"
                    y="1642.45"
                    width="60.6"
                    height="242.41"
                    style="fill: #231815"
                  />
                  <rect
                    x="2866.19"
                    y="2006.09"
                    width="60.6"
                    height="363.64"
                    style="fill: #231815"
                  />
                  <rect
                    x="2866.19"
                    y="2490.94"
                    width="60.6"
                    height="242.41"
                    style="fill: #231815"
                  />
                  <rect
                    x="2866.19"
                    y="2733.38"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2866.19"
                    y="3097"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2926.8"
                    y="1157.6"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2926.8"
                    y="1521.24"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2926.8"
                    y="1824.29"
                    width="60.6"
                    height="242.44"
                    style="fill: #231815"
                  />
                  <rect
                    x="2926.8"
                    y="2309.14"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2926.8"
                    y="2490.94"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2926.8"
                    y="2672.78"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2926.8"
                    y="2733.38"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2926.8"
                    y="2915.19"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2926.8"
                    y="3097"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2987.4"
                    y="1157.6"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="2987.4"
                    y="1339.44"
                    width="60.6"
                    height="242.41"
                    style="fill: #231815"
                  />
                  <rect
                    x="2987.4"
                    y="1884.89"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2987.4"
                    y="2127.3"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2987.4"
                    y="2248.53"
                    width="60.6"
                    height="242.41"
                    style="fill: #231815"
                  />
                  <rect
                    x="2987.4"
                    y="2612.15"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2987.4"
                    y="2793.98"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                </g>
                <g id="支付宝-2" data-name="支付宝">
                  <rect
                    x="1823"
                    y="1932.6"
                    width="450"
                    height="450"
                    rx="54.87"
                    style="fill: #58fff6"
                  />
                  <path
                    d="M1940.64,2244.08c-4.66-34.76,15.73-83.23,32.52-103.22-1-12.13-2.59-43.29,6.77-48.65,7.73-4.44,26.76,6.42,38.85,14.37l120,.83c12-7.95,32.09-19.73,40.08-15.16,10.42,6,7.25,43.9,6.46,52,16.09,19.34,26.63,77.83,26.63,103.4"
                    style="fill: #00ece0"
                  />
                  <path
                    d="M2179.84,2234.86c.57,12.7-11.78,17.49-28.13,17.49s-31.82-3.43-31-17.49c.74-13.54,16-24.53,29.57-24.53C2165.47,2210.33,2179.26,2221.32,2179.84,2234.86Zm-216.48,0c.57,12.7-11.78,17.49-28.12,17.49s-31.82-3.43-31-17.49c.75-13.54,16-24.53,29.58-24.53C1949,2210.33,1962.75,2221.32,1963.36,2234.86Z"
                    style="fill: #00ece0"
                  />
                  <path
                    d="M1908,2224.13c-4.66-34.76,15.73-83.23,32.52-103.22-1-12.13-2.6-43.29,6.76-48.65,7.74-4.44,26.77,6.41,38.85,14.37l120.06.83c12-7.95,32.08-19.73,40.08-15.16,10.41,6,7.25,43.9,6.46,52,16.08,19.34,26.63,77.83,26.63,103.4"
                    style="fill: #fff"
                  />
                  <path
                    d="M2182.08,2227.65h-5.54c0-13.36-2.81-34.32-7.21-53.39-5.23-22.86-11.91-40-18.76-48.21a2.72,2.72,0,0,1-.62-2.07c2.11-21.14,1.41-45.57-5.09-49.3-1.85-1.06-9.89-3-37.18,15.07a2.75,2.75,0,0,1-1.54.48l-120.06-.83a3,3,0,0,1-1.49-.44c-26.33-17.27-34.19-15.3-35.95-14.28-5.32,3-7.43,21.09-5.36,46a2.85,2.85,0,0,1-.66,2c-16.39,19.47-36.43,67.32-31.91,101.07l-5.49.75c-2.15-16.09.79-36.83,8.35-58.45,6.33-18.15,15.29-35.24,24.08-46a196.12,196.12,0,0,1-.44-26.15c.88-13.32,3.7-21.18,8.66-24,6.9-4,20.74.75,41.09,14l118.39.79c21-13.85,35.24-18.86,42.32-14.81,5.36,3.07,8.26,12.08,8.83,27.51a205.76,205.76,0,0,1-.88,26c16.44,21.23,26.46,79.19,26.46,104.33Z"
                    style="fill: #7a3134"
                  />
                  <path
                    d="M2207.08,2230.51H1870a3.26,3.26,0,0,1,0-6.51h337.06a3.26,3.26,0,0,1,0,6.51Z"
                    style="fill: #7a3134"
                  />
                  <path
                    d="M1968.33,2159c0,5.29,2.92,9.59,6.54,9.59s6.55-4.28,6.55-9.57v0c0-5.29-2.92-9.58-6.54-9.59s-6.55,4.28-6.55,9.57Z"
                  />
                  <path
                    d="M2045.36,2091.46h61.13s29.22-18.54,37.49-15.77,11.73,12.56,7.73,51c0,0,13.62,25.36,13.49,32,0,0-38.76,56.17-106.78,14.81C2023.57,2149,2030.25,2106.27,2045.36,2091.46Z"
                    style="fill: #ffaf00"
                  />
                  <path
                    d="M2089.13,2159c0,5.29,2.93,9.58,6.55,9.58s6.55-4.29,6.55-9.58-2.93-9.58-6.55-9.58S2089.13,2153.67,2089.13,2159Z"
                  />
                  <path
                    d="M2028.58,2187a8.69,8.69,0,0,1-6.86-3.29,1.81,1.81,0,1,1,2.81-2.29,5.22,5.22,0,0,0,9.28-3.29,1.8,1.8,0,1,1,3.6,0,8.84,8.84,0,0,1-8.83,8.87Z"
                  />
                  <path
                    d="M2042.73,2187a8.78,8.78,0,0,1-8.79-8.79,1.8,1.8,0,1,1,3.6,0,5.24,5.24,0,0,0,5.23,5.23,5.11,5.11,0,0,0,4-1.93,1.82,1.82,0,0,1,2.82,2.29A9.21,9.21,0,0,1,2042.73,2187Z"
                  />
                  <path
                    d="M2041.23,2103.06c-1.89,5.19-3.78,7.61-8.26,7.61s-6.46-3.52-8.13-8.18a8.2,8.2,0,0,1,8.09-11.2C2040.27,2091.51,2043.34,2097.26,2041.23,2103.06Zm21.8-3.12c-1.36,2.64-2.73,3.83-6,3.83s-4.66-1.8-5.84-4.13c-1.54-2.95,1.49-5.76,5.84-5.67C2062.37,2094.1,2064.57,2097,2063,2099.94Zm-45.53,0c-1.36,2.64-2.72,3.83-6,3.83s-4.66-1.8-5.85-4.13c-1.54-2.95,1.49-5.76,5.85-5.67C2016.84,2094.1,2019,2097,2017.5,2099.94Z"
                    style="fill: #ff7800"
                  />
                  <path
                    d="M2028.71,2166.74h14.72a2.77,2.77,0,0,1,2.11,4.57l-7.56,7.25a2.8,2.8,0,0,1-4.22,0l-7.16-7.25A2.77,2.77,0,0,1,2028.71,2166.74Z"
                  />
                  <path
                    d="M2117,2091.68c8-5.27,20.48-12.35,29.67-15.12a.81.81,0,0,1-.27-.17c-7.95-4.53-27.9,7.29-39.94,15.25H2117Z"
                    style="fill: #ffe6a2"
                  />
                  <path
                    d="M1953.08,2123.85c-1-12-8-42.54,4.22-48.16-2.77-.4-5.58-.49-7.39.52-9.36,5.36-7.38,35.55-6.37,47.68-16.83,20-37.22,68.43-32.52,103.23l10.86.13C1917.53,2192.49,1936.33,2143.67,1953.08,2123.85Z"
                    style="fill: #f4e8e3"
                  />
                  <path
                    d="M1956.15,2227.65c.58,12.7-11.77,17.49-28.12,17.49s-31.82-3.43-31-17.49c.75-13.54,16-24.52,29.58-24.52C1941.74,2203.13,1955.54,2214.11,1956.15,2227.65Z"
                    style="fill: #fff"
                  />
                  <path
                    d="M1928,2248c-14.72,0-24.43-2.69-29.66-8.22a15.9,15.9,0,0,1-4.22-12.31c.39-7.2,4.35-14.06,11.07-19.42a35.2,35.2,0,0,1,21.36-7.82c16.92,0,31.77,12.48,32.43,27.24a16.36,16.36,0,0,1-4.48,12.57c-5,5.32-13.93,8-26.5,8Zm-1.45-42c-14.33,0-26.1,11.73-26.68,21.79a10.54,10.54,0,0,0,2.64,8c4,4.26,12.61,6.41,25.49,6.41,10.77,0,18.5-2.11,22.37-6.15a10.61,10.61,0,0,0,2.85-8.31C1952.73,2216,1940.51,2206,1926.58,2206Z"
                    style="fill: #7a3134"
                  />
                  <path
                    d="M1952.9,2227.25a18.91,18.91,0,0,0-3.6-9.49,26.54,26.54,0,0,1,.44,3.17c.48,11.29-7.34,14.76-21.89,14.76s-25.22-2.9-24.52-15.42c.05-.88.57-1.45.75-2.29-1.67,2.46-3.21,5-3.6,8.35-1.63,13.36,12.3,16.52,27.42,16.52C1940.51,2242.9,1953.87,2238.81,1952.9,2227.25Z"
                    style="fill: #f4e8e3"
                  />
                  <path
                    d="M2151.58,2125.3s10.5,15.51,14.15,31.42a22.34,22.34,0,0,1-4.7,5.93s-.66-16.39-9.45-37.35Z"
                    style="fill: #f69300"
                  />
                  <path
                    d="M2165.73,2156.72a49.79,49.79,0,0,1-4.7,5.93h0s6.76,36.21,5.53,62l10.68,1.14s-2.46-47.15-11.51-69.08"
                    style="fill: #f4e8e3"
                  />
                  <path
                    d="M2172.67,2227.65c.57,12.7-11.77,17.49-28.12,17.49s-31.82-3.43-31-17.49c.75-13.54,16-24.52,29.58-24.52C2158.26,2203.13,2172.06,2214.11,2172.67,2227.65Z"
                    style="fill: #fff"
                  />
                  <path
                    d="M2144.55,2248c-14.72,0-24.44-2.69-29.67-8.22a15.93,15.93,0,0,1-4.21-12.31c.39-7.2,4.35-14.06,11.07-19.42a35.2,35.2,0,0,1,21.36-7.82c16.92,0,31.77,12.48,32.43,27.24a16.4,16.4,0,0,1-4.48,12.57c-5,5.32-13.93,8-26.5,8Zm-1.45-42c-14.33,0-26.11,11.73-26.68,21.79a10.54,10.54,0,0,0,2.64,8c4,4.26,12.61,6.41,25.49,6.41,10.76,0,18.5-2.11,22.37-6.15a10.61,10.61,0,0,0,2.85-8.31C2169.25,2216,2157,2206,2143.1,2206Z"
                    style="fill: #7a3134"
                  />
                  <path
                    d="M2169.51,2227.17a19.61,19.61,0,0,0-3.74-9.58,26.1,26.1,0,0,1,.44,3.16c.49,11.29-7.25,14.9-21.79,14.9s-25.27-2.9-24.57-15.43c0-.88.48-1.32.66-2.15-1.67,2.46-3.12,4.48-3.52,7.82-1.62,13.4,11.65,16.88,27.47,16.92C2159.75,2242.94,2170.43,2238.72,2169.51,2227.17Z"
                    style="fill: #f4e8e3"
                  />
                  <path
                    d="M1933,2180.63c-1.36,1.32-2.72,1.93-6,1.93s-4.65-.88-5.84-2.06c-1.54-1.45,1.49-2.9,5.84-2.82,5.28.05,7.52,1.5,6,3Zm-1.36,13c-1.36,2-2.73,2.94-6,2.94a6.15,6.15,0,0,1-5.84-3.16c-1.54-2.29,1.49-4.44,5.84-4.35C1930.89,2189.15,1933.13,2191.39,1931.59,2193.68Zm240-3.12c-1.36,2-2.72,2.94-6,2.94a6.17,6.17,0,0,1-5.85-3.16c-1.54-2.29,1.5-4.44,5.85-4.35C2170.92,2186,2173.11,2188.27,2171.57,2190.56Z"
                    style="fill: #ff7800"
                  />
                </g>
                <g id="微信-2" data-name="微信">
                  <rect
                    x="5919"
                    y="1932.6"
                    width="450"
                    height="450"
                    rx="54.87"
                    style="fill: #58fff6"
                  />
                  <path
                    d="M6036.64,2244.08c-4.66-34.76,15.73-83.23,32.52-103.22-1-12.13-2.59-43.29,6.77-48.65,7.73-4.44,26.76,6.42,38.85,14.37l120.05.83c12-7.95,32.09-19.73,40.08-15.16,10.42,6,7.25,43.9,6.46,52,16.09,19.34,26.63,77.83,26.63,103.4"
                    style="fill: #00ece0"
                  />
                  <path
                    d="M6275.84,2234.86c.57,12.7-11.78,17.49-28.13,17.49s-31.82-3.43-31-17.49c.74-13.54,16-24.53,29.57-24.53C6261.47,2210.33,6275.26,2221.32,6275.84,2234.86Zm-216.48,0c.57,12.7-11.78,17.49-28.12,17.49s-31.82-3.43-31-17.49c.75-13.54,16-24.53,29.58-24.53C6045,2210.33,6058.75,2221.32,6059.36,2234.86Z"
                    style="fill: #00ece0"
                  />
                  <path
                    d="M6004,2224.13c-4.66-34.76,15.73-83.23,32.52-103.22-1-12.13-2.6-43.29,6.76-48.65,7.74-4.44,26.77,6.41,38.85,14.37l120.06.83c12-7.95,32.08-19.73,40.08-15.16,10.41,6,7.25,43.9,6.46,52,16.08,19.34,26.63,77.83,26.63,103.4"
                    style="fill: #fff"
                  />
                  <path
                    d="M6278.08,2227.65h-5.54c0-13.36-2.81-34.32-7.21-53.39-5.23-22.86-11.91-40-18.76-48.21a2.72,2.72,0,0,1-.62-2.07c2.11-21.14,1.41-45.57-5.09-49.3-1.85-1.06-9.89-3-37.18,15.07a2.75,2.75,0,0,1-1.54.48l-120.06-.83a3,3,0,0,1-1.49-.44c-26.33-17.27-34.19-15.3-35.95-14.28-5.32,3-7.43,21.09-5.36,46a2.85,2.85,0,0,1-.66,2c-16.39,19.47-36.43,67.32-31.91,101.07l-5.49.75c-2.15-16.09.79-36.83,8.35-58.45,6.33-18.15,15.29-35.24,24.08-46a196.12,196.12,0,0,1-.44-26.15c.88-13.32,3.7-21.18,8.66-24,6.9-4,20.74.75,41.09,14l118.39.79c21-13.85,35.24-18.86,42.32-14.81,5.36,3.07,8.26,12.08,8.83,27.51a205.76,205.76,0,0,1-.88,26c16.44,21.23,26.46,79.19,26.46,104.33Z"
                    style="fill: #7a3134"
                  />
                  <path
                    d="M6303.08,2230.51H5966a3.26,3.26,0,0,1,0-6.51h337.06a3.26,3.26,0,0,1,0,6.51Z"
                    style="fill: #7a3134"
                  />
                  <path
                    d="M6064.33,2159c0,5.29,2.92,9.59,6.54,9.59s6.55-4.28,6.55-9.57v0c0-5.29-2.92-9.58-6.54-9.59s-6.55,4.28-6.55,9.57Z"
                  />
                  <path
                    d="M6141.36,2091.46h61.13s29.22-18.54,37.49-15.77,11.73,12.56,7.73,51c0,0,13.62,25.36,13.49,32,0,0-38.76,56.17-106.78,14.81C6119.57,2149,6126.25,2106.27,6141.36,2091.46Z"
                    style="fill: #ffaf00"
                  />
                  <path
                    d="M6185.13,2159c0,5.29,2.93,9.58,6.55,9.58s6.55-4.29,6.55-9.58-2.93-9.58-6.55-9.58S6185.13,2153.67,6185.13,2159Z"
                  />
                  <path
                    d="M6124.58,2187a8.69,8.69,0,0,1-6.86-3.29,1.81,1.81,0,1,1,2.81-2.29,5.22,5.22,0,0,0,9.28-3.29,1.8,1.8,0,1,1,3.6,0,8.84,8.84,0,0,1-8.83,8.87Z"
                  />
                  <path
                    d="M6138.73,2187a8.78,8.78,0,0,1-8.79-8.79,1.8,1.8,0,0,1,3.6,0,5.24,5.24,0,0,0,5.23,5.23,5.11,5.11,0,0,0,4-1.93,1.82,1.82,0,0,1,2.82,2.29A9.21,9.21,0,0,1,6138.73,2187Z"
                  />
                  <path
                    d="M6137.23,2103.06c-1.89,5.19-3.78,7.61-8.26,7.61s-6.46-3.52-8.13-8.18a8.2,8.2,0,0,1,8.09-11.2C6136.27,2091.51,6139.34,2097.26,6137.23,2103.06Zm21.8-3.12c-1.36,2.64-2.73,3.83-6,3.83s-4.66-1.8-5.84-4.13c-1.54-2.95,1.49-5.76,5.84-5.67C6158.37,2094.1,6160.57,2097,6159,2099.94Zm-45.53,0c-1.36,2.64-2.72,3.83-6,3.83s-4.66-1.8-5.85-4.13c-1.54-2.95,1.49-5.76,5.85-5.67C6112.84,2094.1,6115,2097,6113.5,2099.94Z"
                    style="fill: #ff7800"
                  />
                  <path
                    d="M6124.71,2166.74h14.72a2.77,2.77,0,0,1,2.11,4.57l-7.56,7.25a2.8,2.8,0,0,1-4.22,0l-7.16-7.25A2.77,2.77,0,0,1,6124.71,2166.74Z"
                  />
                  <path
                    d="M6213,2091.68c8-5.27,20.48-12.35,29.67-15.12a.81.81,0,0,1-.27-.17c-8-4.53-27.9,7.29-39.94,15.25H6213Z"
                    style="fill: #ffe6a2"
                  />
                  <path
                    d="M6049.08,2123.85c-1-12-8-42.54,4.22-48.16-2.77-.4-5.58-.49-7.39.52-9.36,5.36-7.38,35.55-6.37,47.68-16.83,20-37.22,68.43-32.52,103.23l10.86.13C6013.53,2192.49,6032.33,2143.67,6049.08,2123.85Z"
                    style="fill: #f4e8e3"
                  />
                  <path
                    d="M6052.15,2227.65c.58,12.7-11.77,17.49-28.12,17.49s-31.82-3.43-31-17.49c.75-13.54,16-24.52,29.58-24.52C6037.74,2203.13,6051.54,2214.11,6052.15,2227.65Z"
                    style="fill: #fff"
                  />
                  <path
                    d="M6024,2248c-14.72,0-24.43-2.69-29.66-8.22a15.9,15.9,0,0,1-4.22-12.31c.39-7.2,4.35-14.06,11.07-19.42a35.2,35.2,0,0,1,21.36-7.82c16.92,0,31.77,12.48,32.43,27.24a16.36,16.36,0,0,1-4.48,12.57c-5,5.32-13.93,8-26.5,8Zm-1.45-42c-14.33,0-26.1,11.73-26.68,21.79a10.54,10.54,0,0,0,2.64,8c4,4.26,12.61,6.41,25.49,6.41,10.77,0,18.5-2.11,22.37-6.15a10.61,10.61,0,0,0,2.85-8.31C6048.73,2216,6036.51,2206,6022.58,2206Z"
                    style="fill: #7a3134"
                  />
                  <path
                    d="M6048.9,2227.25a18.91,18.91,0,0,0-3.6-9.49,26.54,26.54,0,0,1,.44,3.17c.48,11.29-7.34,14.76-21.89,14.76s-25.22-2.9-24.52-15.42c.05-.88.57-1.45.75-2.29-1.67,2.46-3.21,5-3.6,8.35-1.63,13.36,12.3,16.52,27.42,16.52C6036.51,2242.9,6049.87,2238.81,6048.9,2227.25Z"
                    style="fill: #f4e8e3"
                  />
                  <path
                    d="M6247.58,2125.3s10.5,15.51,14.15,31.42a22.34,22.34,0,0,1-4.7,5.93s-.66-16.39-9.45-37.35Z"
                    style="fill: #f69300"
                  />
                  <path
                    d="M6261.73,2156.72a49.79,49.79,0,0,1-4.7,5.93h0s6.76,36.21,5.53,62l10.68,1.14s-2.46-47.15-11.51-69.08"
                    style="fill: #f4e8e3"
                  />
                  <path
                    d="M6268.67,2227.65c.57,12.7-11.77,17.49-28.12,17.49s-31.82-3.43-31-17.49c.75-13.54,16-24.52,29.58-24.52C6254.26,2203.13,6268.06,2214.11,6268.67,2227.65Z"
                    style="fill: #fff"
                  />
                  <path
                    d="M6240.55,2248c-14.72,0-24.44-2.69-29.67-8.22a15.93,15.93,0,0,1-4.21-12.31c.39-7.2,4.35-14.06,11.07-19.42a35.2,35.2,0,0,1,21.36-7.82c16.92,0,31.77,12.48,32.43,27.24a16.4,16.4,0,0,1-4.48,12.57c-5,5.32-13.93,8-26.5,8Zm-1.45-42c-14.33,0-26.11,11.73-26.68,21.79a10.54,10.54,0,0,0,2.64,8c4,4.26,12.61,6.41,25.49,6.41,10.76,0,18.5-2.11,22.37-6.15a10.61,10.61,0,0,0,2.85-8.31C6265.25,2216,6253,2206,6239.1,2206Z"
                    style="fill: #7a3134"
                  />
                  <path
                    d="M6265.51,2227.17a19.61,19.61,0,0,0-3.74-9.58,26.1,26.1,0,0,1,.44,3.16c.49,11.29-7.25,14.9-21.79,14.9s-25.27-2.9-24.57-15.43c0-.88.48-1.32.66-2.15-1.67,2.46-3.12,4.48-3.52,7.82-1.62,13.4,11.65,16.88,27.47,16.92C6255.75,2242.94,6266.43,2238.72,6265.51,2227.17Z"
                    style="fill: #f4e8e3"
                  />
                  <path
                    d="M6029,2180.63c-1.36,1.32-2.72,1.93-6,1.93s-4.65-.88-5.84-2.06c-1.54-1.45,1.49-2.9,5.84-2.82,5.28.05,7.52,1.5,6,3Zm-1.36,13c-1.36,2-2.73,2.94-6,2.94a6.15,6.15,0,0,1-5.84-3.16c-1.54-2.29,1.49-4.44,5.84-4.35C6026.89,2189.15,6029.13,2191.39,6027.59,2193.68Zm240-3.12c-1.36,2-2.72,2.94-6,2.94a6.17,6.17,0,0,1-5.85-3.16c-1.54-2.29,1.5-4.44,5.85-4.35C6266.92,2186,6269.11,2188.27,6267.57,2190.56Z"
                    style="fill: #ff7800"
                  />
                </g>
                <path
                  d="M5347.64,3386.41V3328h-40.82v-10.55h161.33V3328h-38.28v58.4h46.67V3397h-46.67v80.86h-10.94V3397h-60.74a158.93,158.93,0,0,1-3.52,27.15,84.57,84.57,0,0,1-5.66,16.41,88.55,88.55,0,0,1-13.48,19.53,216.12,216.12,0,0,1-21.68,20.7l-8.59-8.2a259.18,259.18,0,0,0,20.9-18.75,100.37,100.37,0,0,0,10.15-13.28,75.49,75.49,0,0,0,7.82-19.34,150.4,150.4,0,0,0,3.12-24.22h-48.43v-10.54Zm10.94,0h60.35V3328h-60.35v58.4Z"
                />
                <path
                  d="M5559.75,3453.59a207,207,0,0,0,27.34-13.86,121.63,121.63,0,0,1-33.59-44.14,222.92,222.92,0,0,1-20.32,37.1,278.25,278.25,0,0,1-34.18,40l-8-8.79a305,305,0,0,0,24-26.17,252.85,252.85,0,0,0,18.55-27.93,220.48,220.48,0,0,0,21.29-56.25h-46.68v-9.37q5.28-18,9.57-36.52l10.55,2.34q-3.9,17-8.4,33.4H5557q3.9-19.53,6.06-39.45l11.13.78q-2.34,19.53-5.67,38.67h99.22v10.15H5566.39q-3.14,13.88-7.43,27h85v10.15a169.57,169.57,0,0,1-18,29.1,130.8,130.8,0,0,1-19.92,19.93A177,177,0,0,0,5632.4,3454q18.94,7.82,38.87,13.87l-4.5,11.13a366.81,366.81,0,0,1-42.18-16.21,172.82,172.82,0,0,1-28.32-16,189.23,189.23,0,0,1-29.1,15.43,444.08,444.08,0,0,1-43.75,16.4l-4.69-10.55Q5539.43,3461.61,5559.75,3453.59Zm36.91-20.51a108,108,0,0,0,34.57-42.18h-68.16A112.81,112.81,0,0,0,5596.66,3433.08ZM5608.77,3304l35.15,19.92-5.47,9.37q-17.56-10.35-35.35-20.31Z"
                />
                <path
                  d="M5762.28,3363.56V3336h-49.61v-9.38h49.61v-22.85h10.93v22.85h47.66V3336h-47.66v27.54h18.95a229.44,229.44,0,0,0,51.37-50.2l8.78,5.86a225.5,225.5,0,0,1-42.57,44.34h56.64v9.57h-69.73a285.68,285.68,0,0,1-40.82,23.82h81.25v80.86h-10.94v-10.55h-87.49v10.55h-10.94V3410q-18,7.62-36.13,14.45l-3.71-10.16q26.94-9.55,52.92-21.48a325.33,325.33,0,0,0,36.92-19.72h-84.38v-9.57Zm-23.63,63.86h87.49v-21.09h-87.49Zm0,30.66h87.49V3436.6h-87.49Z"
                />
                <path
                  d="M5938.64,3390.7h-16.21v-26h16.21Zm0,74.81h-16.21v-26.18h16.21Z"
                />
                <path
                  d="M6071.45,3324.69h111.91V3336H6134v129.49h-13.28V3336h-49.22Z"
                />
                <path
                  d="M6232.19,3362.19q21.87,0,33.59,13.57t11.72,35.45v6.84h-79.1q0,18.55,9.28,29t25.68,10.45q23.24,0,31.44-18.17h12.31a36.41,36.41,0,0,1-15.43,21.2q-11.52,7.51-28.32,7.51-23.25,0-35.25-14.45t-12-39.06q0-15.42,5.56-27.24a43,43,0,0,1,16-18.46Q6218.12,3362.19,6232.19,3362.19Zm32.81,45.9q0-15.43-8.59-25.39t-24.22-10q-14.46,0-23.83,9.76t-10,25.59Z"
                />
                <path d="M6313,3465.51h-12.5V3320.2H6313Z" />
                <path
                  d="M6382.38,3362.19q21.87,0,33.59,13.57t11.72,35.45v6.84h-79.1q0,18.55,9.27,29t25.69,10.45q23.23,0,31.44-18.17h12.3a36.35,36.35,0,0,1-15.42,21.2q-11.53,7.51-28.32,7.51-23.25,0-35.26-14.45t-12-39.06q0-15.42,5.57-27.24a43.08,43.08,0,0,1,16-18.46Q6368.32,3362.19,6382.38,3362.19Zm32.81,45.9q0-15.43-8.6-25.39t-24.21-10q-14.46,0-23.83,9.76t-10,25.59Z"
                />
                <path
                  d="M6463.23,3452.62V3504h-12.5V3364.73h11.72l.78,12.89h.39a46.18,46.18,0,0,1,34.77-15.43q19.72,0,31.15,14.35t11.42,38.58q0,23.63-11,38.28T6497.8,3468a44.72,44.72,0,0,1-19.53-4.1,43.15,43.15,0,0,1-14.65-11.32Zm0-12.11q13.28,17,32.42,17,16,0,24.51-11.72t8.5-30.66q0-18.95-8.69-30.67T6496,3372.74a38.76,38.76,0,0,0-18.16,4.59,42.87,42.87,0,0,0-14.65,12.4Z"
                />
                <path
                  d="M6576.7,3465.51h-12.5V3320.2h12.5v58.59h.39a55,55,0,0,1,17-12.3,46.74,46.74,0,0,1,19.73-4.3q17,0,26.07,9.86t9.09,28.22v65.24h-12.5V3403.4q0-30.66-26.76-30.66a36.36,36.36,0,0,0-17.58,4.68,54.78,54.78,0,0,0-15.43,12.7Z"
                />
                <path
                  d="M6671.62,3415.12a65.11,65.11,0,0,1,5.37-26.86,43.42,43.42,0,0,1,16.11-19q10.74-7,26.37-7,23.23,0,35.45,14.74t12.2,38.19q0,23.63-12.1,38.28T6719.47,3468q-15.44,0-26.27-6.93a42.67,42.67,0,0,1-16.21-18.94A65.61,65.61,0,0,1,6671.62,3415.12Zm83.2,0q0-19.34-9.28-30.86t-26.07-11.52q-16.61,0-26,11.62t-9.37,30.76q0,18.75,9.28,30.56t26.07,11.82q17.19,0,26.27-11.72T6754.82,3415.12Z"
                />
                <path
                  d="M6789.78,3465.51V3364.73h12.5v14.06h.39a50.22,50.22,0,0,1,16.7-12.3,47.82,47.82,0,0,1,20-4.3q17,0,26.07,9.86t9.08,28.22v65.24H6862V3403.4q0-30.66-26.75-30.66a36.33,36.33,0,0,0-17.58,4.68,54.64,54.64,0,0,0-15.43,12.7v75.39Z"
                />
                <path
                  d="M6943.29,3362.19q21.87,0,33.59,13.57t11.72,35.45v6.84h-79.1q0,18.55,9.28,29t25.68,10.45q23.23,0,31.44-18.17h12.31a36.41,36.41,0,0,1-15.43,21.2q-11.52,7.51-28.32,7.51-23.25,0-35.25-14.45t-12-39.06q0-15.42,5.56-27.24a43,43,0,0,1,16-18.46Q6929.22,3362.19,6943.29,3362.19Zm32.81,45.9q0-15.43-8.59-25.39t-24.22-10q-14.46,0-23.83,9.76t-10,25.59Z"
                />
                <path
                  d="M1251.64,3386.41V3328h-40.82v-10.55h161.33V3328h-38.28v58.4h46.67V3397h-46.67v80.86h-10.94V3397h-60.74a158.93,158.93,0,0,1-3.52,27.15,84.57,84.57,0,0,1-5.66,16.41,88.55,88.55,0,0,1-13.48,19.53,216.12,216.12,0,0,1-21.68,20.7l-8.59-8.2a259.18,259.18,0,0,0,20.9-18.75,100.37,100.37,0,0,0,10.15-13.28,75.49,75.49,0,0,0,7.82-19.34,150.4,150.4,0,0,0,3.12-24.22h-48.43v-10.54Zm10.94,0h60.35V3328h-60.35v58.4Z"
                />
                <path
                  d="M1463.75,3453.59a207,207,0,0,0,27.34-13.86,121.63,121.63,0,0,1-33.59-44.14,222.92,222.92,0,0,1-20.32,37.1,278.25,278.25,0,0,1-34.18,40l-8-8.79a305,305,0,0,0,24-26.17,252.85,252.85,0,0,0,18.55-27.93,220.48,220.48,0,0,0,21.29-56.25h-46.68v-9.37q5.28-18,9.57-36.52l10.55,2.34q-3.91,17-8.4,33.4H1461q3.9-19.53,6.06-39.45l11.13.78q-2.34,19.53-5.67,38.67h99.22v10.15H1470.39q-3.14,13.88-7.43,27h85v10.15a169.57,169.57,0,0,1-18,29.1,130.8,130.8,0,0,1-19.92,19.93A177,177,0,0,0,1536.4,3454q18.94,7.82,38.87,13.87l-4.5,11.13a366.81,366.81,0,0,1-42.18-16.21,172.82,172.82,0,0,1-28.32-16,189.23,189.23,0,0,1-29.1,15.43,444.08,444.08,0,0,1-43.75,16.4l-4.69-10.55Q1443.43,3461.61,1463.75,3453.59Zm36.91-20.51a108,108,0,0,0,34.57-42.18h-68.16A112.81,112.81,0,0,0,1500.66,3433.08ZM1512.77,3304l35.15,19.92-5.47,9.37q-17.57-10.35-35.35-20.31Z"
                />
                <path
                  d="M1666.28,3363.56V3336h-49.61v-9.38h49.61v-22.85h10.94v22.85h47.65V3336h-47.65v27.54h18.94a229.44,229.44,0,0,0,51.37-50.2l8.78,5.86a225.5,225.5,0,0,1-42.57,44.34h56.64v9.57h-69.73a285.68,285.68,0,0,1-40.82,23.82h81.25v80.86h-10.94v-10.55h-87.49v10.55h-10.94V3410q-18,7.62-36.13,14.45l-3.71-10.16q26.94-9.55,52.92-21.48a325.33,325.33,0,0,0,36.92-19.72h-84.38v-9.57Zm-23.63,63.86h87.49v-21.09h-87.49Zm0,30.66h87.49V3436.6h-87.49Z"
                />
                <path
                  d="M1842.64,3390.7h-16.21v-26h16.21Zm0,74.81h-16.21v-26.18h16.21Z"
                />
                <path
                  d="M1975.45,3324.69h111.91V3336H2038v129.49h-13.28V3336h-49.22Z"
                />
                <path
                  d="M2136.19,3362.19q21.87,0,33.59,13.57t11.72,35.45v6.84h-79.1q0,18.55,9.28,29t25.68,10.45q23.23,0,31.44-18.17h12.31a36.41,36.41,0,0,1-15.43,21.2q-11.52,7.51-28.32,7.51-23.25,0-35.25-14.45t-12-39.06q0-15.42,5.56-27.24a43,43,0,0,1,16-18.46Q2122.12,3362.19,2136.19,3362.19Zm32.81,45.9q0-15.43-8.59-25.39t-24.22-10q-14.46,0-23.83,9.76t-10,25.59Z"
                />
                <path d="M2217,3465.51h-12.5V3320.2H2217Z" />
                <path
                  d="M2286.38,3362.19q21.87,0,33.59,13.57t11.72,35.45v6.84h-79.1q0,18.55,9.27,29t25.69,10.45q23.23,0,31.44-18.17h12.31a36.41,36.41,0,0,1-15.43,21.2q-11.53,7.51-28.32,7.51-23.25,0-35.26-14.45t-12-39.06q0-15.42,5.57-27.24a43.08,43.08,0,0,1,16-18.46Q2272.31,3362.19,2286.38,3362.19Zm32.81,45.9q0-15.43-8.6-25.39t-24.21-10q-14.46,0-23.83,9.76t-10,25.59Z"
                />
                <path
                  d="M2367.23,3452.62V3504h-12.5V3364.73h11.72l.78,12.89h.39a46.18,46.18,0,0,1,34.77-15.43q19.72,0,31.15,14.35t11.42,38.58q0,23.63-11,38.28T2401.8,3468a44.72,44.72,0,0,1-19.53-4.1,43.15,43.15,0,0,1-14.65-11.32Zm0-12.11q13.28,17,32.42,17,16,0,24.51-11.72t8.5-30.66q0-18.95-8.69-30.67T2400,3372.74a38.76,38.76,0,0,0-18.16,4.59,42.87,42.87,0,0,0-14.65,12.4Z"
                />
                <path
                  d="M2480.7,3465.51h-12.5V3320.2h12.5v58.59h.39a55,55,0,0,1,17-12.3,46.74,46.74,0,0,1,19.73-4.3q17,0,26.07,9.86t9.09,28.22v65.24h-12.5V3403.4q0-30.66-26.76-30.66a36.36,36.36,0,0,0-17.58,4.68,54.78,54.78,0,0,0-15.43,12.7Z"
                />
                <path
                  d="M2575.62,3415.12a65.11,65.11,0,0,1,5.37-26.86,43.42,43.42,0,0,1,16.11-19q10.74-7,26.37-7,23.24,0,35.45,14.74t12.21,38.19q0,23.63-12.11,38.28T2623.47,3468q-15.43,0-26.27-6.93a42.67,42.67,0,0,1-16.21-18.94A65.61,65.61,0,0,1,2575.62,3415.12Zm83.2,0q0-19.34-9.28-30.86t-26.07-11.52q-16.6,0-26,11.62t-9.37,30.76q0,18.75,9.28,30.56t26.07,11.82q17.19,0,26.27-11.72T2658.82,3415.12Z"
                />
                <path
                  d="M2693.78,3465.51V3364.73h12.5v14.06h.39a50.22,50.22,0,0,1,16.7-12.3,47.82,47.82,0,0,1,20-4.3q17,0,26.07,9.86t9.08,28.22v65.24H2766V3403.4q0-30.66-26.75-30.66a36.36,36.36,0,0,0-17.58,4.68,54.64,54.64,0,0,0-15.43,12.7v75.39Z"
                />
                <path
                  d="M2847.29,3362.19q21.87,0,33.59,13.57t11.72,35.45v6.84h-79.1q0,18.55,9.28,29t25.68,10.45q23.23,0,31.44-18.17h12.31a36.41,36.41,0,0,1-15.43,21.2q-11.52,7.51-28.32,7.51-23.25,0-35.25-14.45t-12-39.06q0-15.42,5.56-27.24a43,43,0,0,1,16-18.46Q2833.22,3362.19,2847.29,3362.19Zm32.81,45.9q0-15.43-8.59-25.39t-24.22-10q-14.46,0-23.83,9.76t-10,25.59Z"
                />
                <path
                  d="M795.15,312.37H760.59V287.54h78.08V259.63H740.36V234.55h98.31V204.34h27.64v30.21h98.05v25.08H866.31v27.91h65.28v24.83a276.79,276.79,0,0,1-26.88,39.94A178.48,178.48,0,0,1,875,380.47a259.11,259.11,0,0,0,43.26,17.66,460.17,460.17,0,0,0,48.64,12.29l-7.68,27.65a518.76,518.76,0,0,1-59.39-17.92,276.85,276.85,0,0,1-49.66-23.81,447,447,0,0,1-48.64,21.76q-27.91,10.48-56.32,19.2l-9.48-27.65q24.06-6.39,47.88-14.34a349.05,349.05,0,0,0,42.49-17.15,190.28,190.28,0,0,1-23.29-23.81,282.76,282.76,0,0,1-22.28-32.76Zm55,52.48a147.87,147.87,0,0,0,49.66-52.48H805.64A162.76,162.76,0,0,0,850.19,364.85Z"
                  style="fill: #fff"
                />
                <path
                  d="M1020.43,263.47a417.69,417.69,0,0,0,24.32-58.36l26.62,6.14q-9.48,26.12-20.22,51.2V431.67h-26.37V311.6a323.67,323.67,0,0,1-21,28.93L986.89,317A303.08,303.08,0,0,0,1020.43,263.47Zm120.06,140.8a41.17,41.17,0,0,0,10.24-.76,16.93,16.93,0,0,0,4.61-1.8,8.51,8.51,0,0,0,3.07-4.6,39.23,39.23,0,0,0,.77-7.68V279.35H1064V253.49h95.23v-47.1h26.88v47.1h37.37v25.86h-37.37v118a58.42,58.42,0,0,1-2.31,16.39,25.43,25.43,0,0,1-5.12,8.19,28.36,28.36,0,0,1-12,6.4,89.72,89.72,0,0,1-18.94,2.05h-33l-5.37-26.12Zm-44.8-111.87q20.73,29.44,41.22,59.39l-22,14.85q-20-30.19-40.7-59.9Z"
                  style="fill: #fff"
                />
                <path
                  d="M1350.15,333.87V305.2h-83.71V280.37h196.87V305.2h-86.79v28.67h78.59v24.84H1422.6q11.78,15.36,23.55,31l-12.54,9.22H1473v24.57H1256V398.9h94.2V358.71h-76.28V333.87Zm4.1-105q-5.64-11.25-10.5-22.78l26.88-6.14q6.66,14.58,13.57,28.92h86.53v46.85h-27.39v-23H1285.13v23H1259V228.91Zm59.65,129.8h-37.38V398.9h44.8q-11-14.59-21.76-29.44Z"
                  style="fill: #fff"
                />
                <path
                  d="M1621.25,225.84H1683v24.58h-32.77V446.26H1683v24.57h-61.7Z"
                  style="fill: #fff"
                />
                <path
                  d="M1827.33,406.32a25.54,25.54,0,0,0,7.17-.51,11.55,11.55,0,0,0,3.32-1.54,7.56,7.56,0,0,0,2-3.84,39,39,0,0,0,.51-6.4V341l-33,11.78-5.12-25.09q18.94-5.62,38.14-12.29V272.18h-33V247.09h33v-42h25.09v42h30v25.09h-30v34.3q13.83-4.61,27.39-10.24l1.8,13.06,1.79,12.29-31,10.75v70.14a60.15,60.15,0,0,1-1.79,14.59,21,21,0,0,1-3.84,7.17,22.31,22.31,0,0,1-9.73,5.12,82.85,82.85,0,0,1-15.87,1.54H1812l-5.12-24.58ZM2023.42,222V429.11h-26.11V417.59h-108.8V392h108.8V330h-93.44V304.18h93.44V248.11h-98.56V222Z"
                  style="fill: #fff"
                />
                <path
                  d="M2291.71,296.24v28.67H2059.78V296.24Z"
                  style="fill: #fff"
                />
                <path
                  d="M2339.33,406.32a25.54,25.54,0,0,0,7.17-.51,11.55,11.55,0,0,0,3.32-1.54,7.56,7.56,0,0,0,2-3.84,39,39,0,0,0,.51-6.4V341l-33,11.78-5.12-25.09q18.95-5.62,38.14-12.29V272.18h-33V247.09h33v-42h25.09v42h30v25.09h-30v34.3q13.83-4.61,27.39-10.24l1.8,13.06,1.79,12.29-31,10.75v70.14a60.15,60.15,0,0,1-1.79,14.59,21,21,0,0,1-3.84,7.17,22.31,22.31,0,0,1-9.73,5.12,82.85,82.85,0,0,1-15.87,1.54H2324l-5.12-24.58ZM2535.42,222V429.11h-26.11V417.59h-108.8V392h108.8V330h-93.44V304.18h93.44V248.11h-98.56V222Z"
                  style="fill: #fff"
                />
                <path
                  d="M2669.05,250.42V225.84h61.7v245h-61.7V446.26h32.77V250.42Z"
                  style="fill: #fff"
                />
                <path
                  d="M2889.21,406.58a26.78,26.78,0,0,0,6.4-.26,7.53,7.53,0,0,0,3.58-2.05,6.5,6.5,0,0,0,1.54-2.56,26.33,26.33,0,0,0,.76-6.4V343.09l-28.16,10-4.86-24.32q16.38-4.61,33-10.24V271.15H2874.1v-23.8h27.39V204.08h23.81v43.27h27.39v23.8H2925.3v39.43q11.26-3.58,22.53-8.19l1.28,12.54,1.28,10.75q-12.54,4.88-25.09,9V403a55.15,55.15,0,0,1-1.79,13.83,24.49,24.49,0,0,1-3.58,6.91,20,20,0,0,1-9.22,4.86,67.89,67.89,0,0,1-14.34,1.54h-20.48l-5.12-23.55Zm202-114.95V401.2a60.15,60.15,0,0,1-1.79,14.59,21,21,0,0,1-6.15,9.22,26.63,26.63,0,0,1-7.93,3.84,78,78,0,0,1-16.64,1.79h-29.19l-4.35-21.25h27.14a35.44,35.44,0,0,0,7.42-.76,18.28,18.28,0,0,0,3.59-1.28,7.79,7.79,0,0,0,2.81-4.1,30.75,30.75,0,0,0,.77-6.91V388.4h-85.76v43h-24.83V291.63Zm-6.91-78.33V277H2963.7V213.3ZM2981.11,330.55h85.76V312.88h-85.76Zm0,37.37h85.76V350.77h-85.76Zm7.17-112.13h70.91V234.55h-70.91Z"
                  style="fill: #fff"
                />
                <path
                  d="M3145.72,394.29a100.14,100.14,0,0,0,10.75-15.87,79.88,79.88,0,0,0,7.68-23.3,219.67,219.67,0,0,0,2-29.95v-73h18.43V330.8a301.75,301.75,0,0,1-1.54,30.72l.26-.25q21.51,24.06,42.75,48.64l-15.87,16.89q-16.65-20.48-33.54-40.7a89.66,89.66,0,0,1-12.54,21.5,243.22,243.22,0,0,1-24.58,26.88l-16.89-17.41A224.12,224.12,0,0,0,3145.72,394.29Zm69.37-176.9v148h-18.68V241.2h-42.24V366.13h-19.46V217.39Zm46.85-16.12q9.46,13,17.67,26.88l-7.94,4.86h22.27q10.24-14.85,19.2-31l19.2,10.24L3319.54,233h32.51v85.25h-126.2V233h30.72l-13.32-21Zm79.36,130.56V432.69h-21V423h-64.77v9.73h-21.25V331.83Zm-96.51-33h34.05V252.72h-34.05Zm15.87-42.24q9,14.85,17.67,29.44l-15.62,9.22q-8.45-14.85-17.15-30Zm-5.12,110.59h64.77v-15.1h-64.77Zm0,35.33h64.77V387.63h-64.77Zm42.75-149.76v32.51q9-13.82,16.9-28.67l15.87,8.71q-8.7,15.36-17.41,30l-15.36-9V298.8h33.54V252.72Z"
                  style="fill: #fff"
                />
                <path
                  d="M4979.47,306.74a321.78,321.78,0,0,0,22.78-40.19l19.46,10.75Q5014,293.17,5005.32,308V418.87a162.89,162.89,0,0,0,11.52-12.8,62.2,62.2,0,0,0,5.38-9.22,59.63,59.63,0,0,0,4.09-13.57,151.58,151.58,0,0,0,1-17.66V338h67.07v40.45q7.68-5.9,15.36-12.29l5.89,19.2q-12.81,9.74-24.58,20a58.22,58.22,0,0,0-9,8.19l-14.33-14.85a15,15,0,0,0,5.12-11v-27.9h-23.56v10.5a122.13,122.13,0,0,1-1.28,17.92,68.34,68.34,0,0,1-4.35,15.36,110.26,110.26,0,0,1-18.43,27.9l-20-11.78V430.9h-22.53V341.3q-7.94,10.5-16.38,19.71L4956.17,337A238,238,0,0,0,4979.47,306.74Zm24.32-100.1,20.48,12.8a413,413,0,0,1-47.88,65.79l-17.66-16.38A330.63,330.63,0,0,0,5003.79,206.64Zm130.3,159.23A227.31,227.31,0,0,1,5118.22,308q-2.56,4.86-5.12,9.21l-9.73-15.36v20h-86.53V300.08h85.51l-2.82-4.61q3.07-5.62,6.14-11.77h-84V224.31h20v38.4h12.8v-57.6h19.46v57.6h12.8v-38.4h19.71v57.85a295.29,295.29,0,0,0,23.55-76l22,2.56a350.55,350.55,0,0,1-8.7,37.38h49.4v23h-10.24a377.28,377.28,0,0,1-9,57.08A210.81,210.81,0,0,1,5160,365.62a202.63,202.63,0,0,0,38.91,48.89l-17.67,19.72a284.54,284.54,0,0,1-34.81-44.8,221.36,221.36,0,0,1-15.87,21.24q-11.28,12.81-23,25.09l-17.67-16.89A213.59,213.59,0,0,0,5134.09,365.87Zm0-92.41a220.89,220.89,0,0,0,13.06,64,297.24,297.24,0,0,0,14.08-68.35h-25.6Z"
                  style="fill: #fff"
                />
                <path
                  d="M5242.63,258.87a369,369,0,0,0,20.23-54.79L5288,210A486.6,486.6,0,0,1,5269,264.24V432.18h-24.83V311.09q-8.44,12.81-17.66,24.58l-14.85-23A312.17,312.17,0,0,0,5242.63,258.87Zm127-56.58q7.17,14.6,13.82,29.18h64.77V255H5283.59V231.47h71.68q-5.37-11.76-11.26-23.29Zm61.44,143.36v86h-25.6V419.89h-85v11.78h-25.6v-86Zm-3.07-74.5V292.4H5297.67V271.15Zm0,37.12V330H5297.67V308.27Zm-107.52,89.09h85V368.18h-85Z"
                  style="fill: #fff"
                />
                <path
                  d="M5589.25,225.84H5651v24.58h-32.77V446.26H5651v24.57h-61.7Z"
                  style="fill: #fff"
                />
                <path
                  d="M5795.33,406.32a25.54,25.54,0,0,0,7.17-.51,11.55,11.55,0,0,0,3.32-1.54,7.56,7.56,0,0,0,2.05-3.84,39,39,0,0,0,.51-6.4V341l-33,11.78-5.12-25.09q18.94-5.62,38.14-12.29V272.18h-33V247.09h33v-42h25.09v42h29.95v25.09h-29.95v34.3q13.83-4.61,27.39-10.24l1.8,13.06,1.79,12.29-31,10.75v70.14a60.15,60.15,0,0,1-1.79,14.59,21,21,0,0,1-3.84,7.17,22.31,22.31,0,0,1-9.73,5.12,82.85,82.85,0,0,1-15.87,1.54H5780l-5.12-24.58ZM5991.42,222V429.11h-26.11V417.59h-108.8V392h108.8V330h-93.44V304.18h93.44V248.11h-98.56V222Z"
                  style="fill: #fff"
                />
                <path
                  d="M6259.71,296.24v28.67H6027.78V296.24Z"
                  style="fill: #fff"
                />
                <path
                  d="M6307.33,406.32a25.54,25.54,0,0,0,7.17-.51,11.55,11.55,0,0,0,3.32-1.54,7.56,7.56,0,0,0,2.05-3.84,39,39,0,0,0,.51-6.4V341l-33,11.78-5.12-25.09q18.94-5.62,38.14-12.29V272.18h-33V247.09h33v-42h25.09v42h29.95v25.09h-29.95v34.3q13.83-4.61,27.39-10.24l1.8,13.06,1.79,12.29-31,10.75v70.14a60.15,60.15,0,0,1-1.79,14.59,21,21,0,0,1-3.84,7.17,22.31,22.31,0,0,1-9.73,5.12,82.85,82.85,0,0,1-15.87,1.54H6292l-5.12-24.58ZM6503.42,222V429.11h-26.11V417.59h-108.8V392h108.8V330h-93.44V304.18h93.44V248.11h-98.56V222Z"
                  style="fill: #fff"
                />
                <path
                  d="M6637.05,250.42V225.84h61.7v245h-61.7V446.26h32.77V250.42Z"
                  style="fill: #fff"
                />
                <path
                  d="M6857.21,406.58a26.78,26.78,0,0,0,6.4-.26,7.53,7.53,0,0,0,3.58-2.05,6.5,6.5,0,0,0,1.54-2.56,26.33,26.33,0,0,0,.76-6.4V343.09l-28.16,10-4.86-24.32q16.38-4.61,33-10.24V271.15H6842.1v-23.8h27.39V204.08h23.81v43.27h27.39v23.8H6893.3v39.43q11.27-3.58,22.53-8.19l1.28,12.54,1.28,10.75q-12.54,4.88-25.09,9V403a55.15,55.15,0,0,1-1.79,13.83,24.49,24.49,0,0,1-3.58,6.91,20,20,0,0,1-9.22,4.86,67.89,67.89,0,0,1-14.34,1.54h-20.48l-5.12-23.55Zm202-114.95V401.2a60.15,60.15,0,0,1-1.79,14.59,21,21,0,0,1-6.15,9.22,26.63,26.63,0,0,1-7.93,3.84,78,78,0,0,1-16.64,1.79h-29.19l-4.35-21.25h27.14a35.44,35.44,0,0,0,7.42-.76,18.28,18.28,0,0,0,3.59-1.28,7.79,7.79,0,0,0,2.81-4.1,30.75,30.75,0,0,0,.77-6.91V388.4h-85.76v43h-24.83V291.63Zm-6.91-78.33V277H6931.7V213.3ZM6949.11,330.55h85.76V312.88h-85.76Zm0,37.37h85.76V350.77h-85.76Zm7.17-112.13h70.91V234.55h-70.91Z"
                  style="fill: #fff"
                />
                <path
                  d="M7113.72,394.29a100.14,100.14,0,0,0,10.75-15.87,79.88,79.88,0,0,0,7.68-23.3,219.67,219.67,0,0,0,2.05-29.95v-73h18.43V330.8a301.75,301.75,0,0,1-1.54,30.72l.26-.25q21.51,24.06,42.75,48.64l-15.87,16.89q-16.65-20.48-33.54-40.7a89.66,89.66,0,0,1-12.54,21.5,243.22,243.22,0,0,1-24.58,26.88l-16.89-17.41A224.12,224.12,0,0,0,7113.72,394.29Zm69.37-176.9v148h-18.68V241.2h-42.24V366.13h-19.46V217.39Zm46.85-16.12q9.47,13,17.67,26.88l-7.94,4.86h22.27q10.25-14.85,19.2-31l19.2,10.24L7287.54,233h32.51v85.25h-126.2V233h30.72l-13.32-21Zm79.36,130.56V432.69h-21V423h-64.77v9.73h-21.25V331.83Zm-96.51-33h34.05V252.72h-34.05Zm15.87-42.24q9,14.85,17.67,29.44l-15.62,9.22q-8.45-14.85-17.15-30Zm-5.12,110.59h64.77v-15.1h-64.77Zm0,35.33h64.77V387.63h-64.77Zm42.75-149.76v32.51q9-13.82,16.9-28.67l15.87,8.71q-8.7,15.36-17.41,30l-15.36-9V298.8h33.54V252.72Z"
                  style="fill: #fff"
                />
              </g>
            </svg>
            <svg
              v-show="receiptQRCode.Name === ReceiptQRCode.Developer.Eric.Name"
              xmlns="http://www.w3.org/2000/svg"
              width="100%"
              viewBox="0 0 8192 4096"
            >
              <g id="图层_1" data-name="图层 1">
                <rect width="4096" height="4096" style="fill: #1890ff" />
                <rect
                  x="4096"
                  width="4096"
                  height="4096"
                  style="fill: #1aad19"
                />
              </g>
              <g id="图层_3" data-name="图层 3">
                <rect
                  id="支付宝"
                  x="548"
                  y="657.6"
                  width="3000"
                  height="3000"
                  style="fill: #fff"
                />
                <rect
                  id="微信"
                  x="4644"
                  y="657.6"
                  width="3000"
                  height="3000"
                  style="fill: #fff"
                />
                <g id="小明微信">
                  <rect
                    x="5144"
                    y="1157.6"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="5144"
                    y="1339.44"
                    width="60.6"
                    height="242.41"
                    style="fill: #231815"
                  />
                  <rect
                    x="5144"
                    y="1642.45"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5144"
                    y="2066.7"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5144"
                    y="2309.14"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="5144"
                    y="2612.15"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5144"
                    y="2733.38"
                    width="60.6"
                    height="303.04"
                    style="fill: #231815"
                  />
                  <rect
                    x="5144"
                    y="3036.39"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5204.63"
                    y="1157.6"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5204.63"
                    y="1521.24"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5204.63"
                    y="1763.68"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5204.63"
                    y="1884.89"
                    width="60.6"
                    height="242.44"
                    style="fill: #231815"
                  />
                  <rect
                    x="5204.63"
                    y="2187.93"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="5204.63"
                    y="2430.34"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5204.63"
                    y="2612.15"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5204.63"
                    y="2733.38"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5204.63"
                    y="3097"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5265.23"
                    y="1157.6"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5265.23"
                    y="1278.83"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5265.23"
                    y="1339.44"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5265.23"
                    y="1521.24"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5265.23"
                    y="1703.08"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5265.23"
                    y="1945.49"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5265.23"
                    y="2066.7"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5265.23"
                    y="2490.94"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="5265.23"
                    y="2733.38"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5265.23"
                    y="2854.59"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="5265.23"
                    y="3097"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5325.84"
                    y="1157.6"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5325.84"
                    y="1278.83"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5325.84"
                    y="1339.44"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5325.84"
                    y="1521.24"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5325.84"
                    y="1824.29"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="5325.84"
                    y="2127.3"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5325.84"
                    y="2248.53"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5325.84"
                    y="2369.74"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5325.84"
                    y="2490.94"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5325.84"
                    y="2612.15"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5325.84"
                    y="2733.38"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5325.84"
                    y="2854.59"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="5325.84"
                    y="3097"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5386.44"
                    y="1157.6"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5386.44"
                    y="1278.83"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5386.44"
                    y="1339.44"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5386.44"
                    y="1521.24"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5386.44"
                    y="1642.45"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5386.44"
                    y="1763.68"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="5386.44"
                    y="2006.09"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5386.44"
                    y="2187.93"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5386.44"
                    y="2369.74"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5386.44"
                    y="2490.94"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="5386.44"
                    y="2733.38"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5386.44"
                    y="2854.59"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="5386.44"
                    y="3097"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5447.04"
                    y="1157.6"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5447.04"
                    y="1521.24"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5447.04"
                    y="1763.68"
                    width="60.6"
                    height="242.41"
                    style="fill: #231815"
                  />
                  <rect
                    x="5447.04"
                    y="2127.3"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5447.04"
                    y="2369.74"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5447.04"
                    y="2490.94"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5447.04"
                    y="2733.38"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5447.04"
                    y="3097"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5507.64"
                    y="1157.6"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="5507.64"
                    y="1339.44"
                    width="60.6"
                    height="242.41"
                    style="fill: #231815"
                  />
                  <rect
                    x="5507.64"
                    y="1642.45"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5507.64"
                    y="1763.68"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5507.64"
                    y="1884.89"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5507.64"
                    y="2006.09"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5507.64"
                    y="2127.3"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5507.64"
                    y="2248.53"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5507.64"
                    y="2369.74"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5507.64"
                    y="2490.94"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5507.64"
                    y="2612.15"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5507.64"
                    y="2733.38"
                    width="60.6"
                    height="303.04"
                    style="fill: #231815"
                  />
                  <rect
                    x="5507.64"
                    y="3036.39"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5568.25"
                    y="1642.45"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="5568.25"
                    y="1945.49"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5568.25"
                    y="2066.7"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5568.25"
                    y="2248.53"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5568.25"
                    y="2430.34"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5568.25"
                    y="2490.94"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="5628.85"
                    y="1157.6"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5628.85"
                    y="1339.44"
                    width="60.6"
                    height="363.64"
                    style="fill: #231815"
                  />
                  <rect
                    x="5628.85"
                    y="1824.29"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5628.85"
                    y="2187.93"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5628.85"
                    y="2369.74"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5628.85"
                    y="2672.78"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5628.85"
                    y="2733.38"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5628.85"
                    y="2854.59"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5628.85"
                    y="3097"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5689.48"
                    y="1218.23"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5689.48"
                    y="1400.04"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5689.48"
                    y="1642.45"
                    width="60.6"
                    height="242.41"
                    style="fill: #231815"
                  />
                  <rect
                    x="5689.48"
                    y="1945.49"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="5689.48"
                    y="2187.93"
                    width="60.6"
                    height="303.04"
                    style="fill: #231815"
                  />
                  <rect
                    x="5689.48"
                    y="2612.15"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5689.48"
                    y="2793.98"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5689.48"
                    y="3036.39"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5750.08"
                    y="1278.83"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5750.08"
                    y="1521.24"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5750.08"
                    y="1884.89"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5750.08"
                    y="2248.53"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5750.08"
                    y="2490.94"
                    width="60.6"
                    height="242.41"
                    style="fill: #231815"
                  />
                  <rect
                    x="5750.08"
                    y="2733.38"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5750.08"
                    y="2854.59"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5750.08"
                    y="2975.79"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5750.08"
                    y="3036.39"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5810.69"
                    y="1157.6"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="5810.69"
                    y="1339.44"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5810.69"
                    y="1642.45"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5810.69"
                    y="1824.29"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5810.69"
                    y="1945.49"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5810.69"
                    y="2066.7"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5810.69"
                    y="2430.34"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5810.69"
                    y="2490.94"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5810.69"
                    y="2612.15"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5810.69"
                    y="2733.38"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="5810.69"
                    y="2975.79"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5810.69"
                    y="3036.39"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5871.29"
                    y="1157.6"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5871.29"
                    y="1339.44"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5871.29"
                    y="1521.24"
                    width="60.6"
                    height="303.04"
                    style="fill: #231815"
                  />
                  <rect
                    x="5871.29"
                    y="1884.89"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5871.29"
                    y="2006.09"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5871.29"
                    y="2309.14"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5871.29"
                    y="2551.55"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5871.29"
                    y="2793.98"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5871.29"
                    y="2915.19"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5871.29"
                    y="3036.39"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5931.89"
                    y="1157.6"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5931.89"
                    y="1400.04"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5931.89"
                    y="1763.68"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="5931.89"
                    y="2006.09"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5931.89"
                    y="2127.3"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5931.89"
                    y="2309.14"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="5931.89"
                    y="2490.94"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5931.89"
                    y="2612.15"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5931.89"
                    y="2793.98"
                    width="60.6"
                    height="242.44"
                    style="fill: #231815"
                  />
                  <rect
                    x="5992.49"
                    y="1157.6"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="5992.49"
                    y="1521.24"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5992.49"
                    y="1703.08"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5992.49"
                    y="1945.49"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5992.49"
                    y="2369.74"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5992.49"
                    y="2490.94"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="5992.49"
                    y="2854.59"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="5992.49"
                    y="3036.39"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6053.1"
                    y="1157.6"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6053.1"
                    y="1339.44"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6053.1"
                    y="1460.64"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6053.1"
                    y="1642.45"
                    width="60.6"
                    height="242.41"
                    style="fill: #231815"
                  />
                  <rect
                    x="6053.1"
                    y="2006.09"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6053.1"
                    y="2248.53"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6053.1"
                    y="2612.15"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6053.1"
                    y="2733.38"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6053.1"
                    y="2975.79"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6053.1"
                    y="3036.39"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6113.7"
                    y="1157.6"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6113.7"
                    y="1339.44"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6113.7"
                    y="1521.24"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6113.7"
                    y="1703.08"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6113.7"
                    y="1884.89"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6113.7"
                    y="2066.7"
                    width="60.6"
                    height="303.04"
                    style="fill: #231815"
                  />
                  <rect
                    x="6113.7"
                    y="2430.34"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6113.7"
                    y="2490.94"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6113.7"
                    y="2612.15"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6113.7"
                    y="2733.38"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6113.7"
                    y="2854.59"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6113.7"
                    y="3036.39"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6174.33"
                    y="1157.6"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="6174.33"
                    y="1339.44"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6174.33"
                    y="1581.85"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="6174.33"
                    y="2066.7"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6174.33"
                    y="2248.53"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6174.33"
                    y="2430.34"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6174.33"
                    y="2490.94"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6174.33"
                    y="2672.78"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6174.33"
                    y="3036.39"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6234.93"
                    y="1157.6"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="6234.93"
                    y="1400.04"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6234.93"
                    y="1521.24"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="6234.93"
                    y="1763.68"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6234.93"
                    y="1945.49"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6234.93"
                    y="2187.93"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6234.93"
                    y="2309.14"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6234.93"
                    y="2490.94"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6234.93"
                    y="2733.38"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6234.93"
                    y="2915.19"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6234.93"
                    y="3036.39"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6295.54"
                    y="1218.23"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6295.54"
                    y="1460.64"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6295.54"
                    y="1581.85"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6295.54"
                    y="1703.08"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6295.54"
                    y="1884.89"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6295.54"
                    y="2066.7"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="6295.54"
                    y="2369.74"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6295.54"
                    y="2672.78"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6295.54"
                    y="3097"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6356.14"
                    y="1157.6"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6356.14"
                    y="1278.83"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6356.14"
                    y="1460.64"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6356.14"
                    y="1763.68"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6356.14"
                    y="2066.7"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6356.14"
                    y="2248.53"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6356.14"
                    y="2369.74"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6356.14"
                    y="2490.94"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6356.14"
                    y="2733.38"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6356.14"
                    y="2854.59"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6356.14"
                    y="3036.39"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6416.74"
                    y="1218.23"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6416.74"
                    y="1400.04"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6416.74"
                    y="1581.85"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6416.74"
                    y="1824.29"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6416.74"
                    y="1945.49"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6416.74"
                    y="2066.7"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6416.74"
                    y="2309.14"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6416.74"
                    y="2551.55"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="6416.74"
                    y="2733.38"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="6416.74"
                    y="2975.79"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6416.74"
                    y="3036.39"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6477.34"
                    y="1278.83"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6477.34"
                    y="1400.04"
                    width="60.6"
                    height="303.04"
                    style="fill: #231815"
                  />
                  <rect
                    x="6477.34"
                    y="1763.68"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="6477.34"
                    y="2066.7"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6477.34"
                    y="2248.53"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="6477.34"
                    y="2490.94"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6477.34"
                    y="2672.78"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6477.34"
                    y="2733.38"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6477.34"
                    y="2915.19"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6477.34"
                    y="3036.39"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6537.95"
                    y="1400.04"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6537.95"
                    y="1581.85"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6537.95"
                    y="1763.68"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6537.95"
                    y="1945.49"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="6537.95"
                    y="2187.93"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6537.95"
                    y="2369.74"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6537.95"
                    y="2490.94"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6537.95"
                    y="2612.15"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6537.95"
                    y="2975.79"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6537.95"
                    y="3097"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6598.55"
                    y="1278.83"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6598.55"
                    y="1521.24"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="6598.55"
                    y="2006.09"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6598.55"
                    y="2187.93"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="6598.55"
                    y="2490.94"
                    width="60.6"
                    height="242.41"
                    style="fill: #231815"
                  />
                  <rect
                    x="6598.55"
                    y="2733.38"
                    width="60.6"
                    height="242.44"
                    style="fill: #231815"
                  />
                  <rect
                    x="6598.55"
                    y="3097"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6659.18"
                    y="1642.45"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6659.18"
                    y="1945.49"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6659.18"
                    y="2187.93"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6659.18"
                    y="2309.14"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6659.18"
                    y="2490.94"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6659.18"
                    y="2612.15"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6659.18"
                    y="2854.59"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="6659.18"
                    y="3036.39"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6719.78"
                    y="1157.6"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="6719.78"
                    y="1339.44"
                    width="60.6"
                    height="242.41"
                    style="fill: #231815"
                  />
                  <rect
                    x="6719.78"
                    y="1642.45"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6719.78"
                    y="1824.29"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="6719.78"
                    y="2066.7"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6719.78"
                    y="2248.53"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6719.78"
                    y="2369.74"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6719.78"
                    y="2551.55"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6719.78"
                    y="2733.38"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6719.78"
                    y="2854.59"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="6780.38"
                    y="1157.6"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6780.38"
                    y="1521.24"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6780.38"
                    y="1642.45"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6780.38"
                    y="1763.68"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6780.38"
                    y="1945.49"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6780.38"
                    y="2066.7"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6780.38"
                    y="2187.93"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6780.38"
                    y="2369.74"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6780.38"
                    y="2612.15"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6780.38"
                    y="2854.59"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6780.38"
                    y="2975.79"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6780.38"
                    y="3097"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6840.99"
                    y="1157.6"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6840.99"
                    y="1278.83"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6840.99"
                    y="1339.44"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6840.99"
                    y="1521.24"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6840.99"
                    y="1642.45"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6840.99"
                    y="1824.29"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6840.99"
                    y="2006.09"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="6840.99"
                    y="2369.74"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6840.99"
                    y="2490.94"
                    width="60.6"
                    height="242.41"
                    style="fill: #231815"
                  />
                  <rect
                    x="6840.99"
                    y="2733.38"
                    width="60.6"
                    height="303.04"
                    style="fill: #231815"
                  />
                  <rect
                    x="6840.99"
                    y="3097"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6901.59"
                    y="1157.6"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6901.59"
                    y="1278.83"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6901.59"
                    y="1339.44"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6901.59"
                    y="1521.24"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6901.59"
                    y="1642.45"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6901.59"
                    y="1763.68"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6901.59"
                    y="1884.89"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6901.59"
                    y="2127.3"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6901.59"
                    y="2248.53"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="6901.59"
                    y="2490.94"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6901.59"
                    y="2733.38"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6901.59"
                    y="2915.19"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6962.19"
                    y="1157.6"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6962.19"
                    y="1278.83"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6962.19"
                    y="1339.44"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="6962.19"
                    y="1521.24"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6962.19"
                    y="1945.49"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6962.19"
                    y="2187.93"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6962.19"
                    y="2490.94"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6962.19"
                    y="2612.15"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6962.19"
                    y="2733.38"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6962.19"
                    y="2915.19"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="6962.19"
                    y="3097"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="7022.8"
                    y="1157.6"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="7022.8"
                    y="1521.24"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="7022.8"
                    y="2006.09"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="7022.8"
                    y="2248.53"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="7022.8"
                    y="2369.74"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="7022.8"
                    y="2490.94"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="7022.8"
                    y="2733.38"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="7083.4"
                    y="1157.6"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="7083.4"
                    y="1339.44"
                    width="60.6"
                    height="242.41"
                    style="fill: #231815"
                  />
                  <rect
                    x="7083.4"
                    y="1642.45"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="7083.4"
                    y="1824.29"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="7083.4"
                    y="2066.7"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="7083.4"
                    y="2369.74"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="7083.4"
                    y="2612.15"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="7083.4"
                    y="2793.98"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="7083.4"
                    y="3097"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                </g>
                <g id="小明支付宝">
                  <rect
                    x="1048"
                    y="1157.6"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="1048"
                    y="1339.44"
                    width="60.6"
                    height="242.41"
                    style="fill: #231815"
                  />
                  <rect
                    x="1048"
                    y="1642.45"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1048"
                    y="1945.49"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1048"
                    y="2127.3"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1048"
                    y="2369.74"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="1048"
                    y="2490.94"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="1048"
                    y="2733.38"
                    width="60.6"
                    height="303.04"
                    style="fill: #231815"
                  />
                  <rect
                    x="1048"
                    y="3036.39"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="1108.63"
                    y="1157.6"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1108.63"
                    y="1521.24"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1108.63"
                    y="1824.29"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1108.63"
                    y="2006.09"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1108.63"
                    y="2187.93"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="1108.63"
                    y="2369.74"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="1108.63"
                    y="2733.38"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1108.63"
                    y="3097"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1169.23"
                    y="1157.6"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1169.23"
                    y="1278.83"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1169.23"
                    y="1339.44"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="1169.23"
                    y="1521.24"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1169.23"
                    y="1642.45"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1169.23"
                    y="1763.68"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="1169.23"
                    y="1945.49"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1169.23"
                    y="2066.7"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1169.23"
                    y="2187.93"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="1169.23"
                    y="2430.34"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1169.23"
                    y="2551.55"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="1169.23"
                    y="2733.38"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1169.23"
                    y="2854.59"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="1169.23"
                    y="3097"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1229.84"
                    y="1157.6"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1229.84"
                    y="1278.83"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1229.84"
                    y="1339.44"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="1229.84"
                    y="1521.24"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1229.84"
                    y="1642.45"
                    width="60.6"
                    height="303.04"
                    style="fill: #231815"
                  />
                  <rect
                    x="1229.84"
                    y="2187.93"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="1229.84"
                    y="2612.15"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1229.84"
                    y="2733.38"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1229.84"
                    y="2854.59"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="1229.84"
                    y="3097"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1290.44"
                    y="1157.6"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1290.44"
                    y="1278.83"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1290.44"
                    y="1339.44"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="1290.44"
                    y="1521.24"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1290.44"
                    y="1642.45"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="1290.44"
                    y="1945.49"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="1290.44"
                    y="2127.3"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1290.44"
                    y="2309.14"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="1290.44"
                    y="2490.94"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="1290.44"
                    y="2733.38"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1290.44"
                    y="2854.59"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="1290.44"
                    y="3097"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1351.04"
                    y="1157.6"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1351.04"
                    y="1521.24"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1351.04"
                    y="1642.45"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="1351.04"
                    y="1824.29"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1351.04"
                    y="1945.49"
                    width="60.6"
                    height="242.44"
                    style="fill: #231815"
                  />
                  <rect
                    x="1351.04"
                    y="2248.53"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="1351.04"
                    y="2490.94"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="1351.04"
                    y="2733.38"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1351.04"
                    y="3097"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1411.64"
                    y="1157.6"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="1411.64"
                    y="1339.44"
                    width="60.6"
                    height="242.41"
                    style="fill: #231815"
                  />
                  <rect
                    x="1411.64"
                    y="1642.45"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1411.64"
                    y="1763.68"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1411.64"
                    y="1884.89"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1411.64"
                    y="2006.09"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1411.64"
                    y="2127.3"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1411.64"
                    y="2248.53"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1411.64"
                    y="2369.74"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1411.64"
                    y="2490.94"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1411.64"
                    y="2612.15"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1411.64"
                    y="2733.38"
                    width="60.6"
                    height="303.04"
                    style="fill: #231815"
                  />
                  <rect
                    x="1411.64"
                    y="3036.39"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="1472.25"
                    y="1703.08"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="1472.25"
                    y="1884.89"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1472.25"
                    y="2006.09"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="1472.25"
                    y="2248.53"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="1472.25"
                    y="2490.94"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1472.25"
                    y="2612.15"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1532.85"
                    y="1278.83"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1532.85"
                    y="1339.44"
                    width="60.6"
                    height="303.04"
                    style="fill: #231815"
                  />
                  <rect
                    x="1532.85"
                    y="1763.68"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="1532.85"
                    y="2127.3"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="1532.85"
                    y="2369.74"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="1532.85"
                    y="2490.94"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1532.85"
                    y="2612.15"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="1532.85"
                    y="2793.98"
                    width="60.6"
                    height="242.44"
                    style="fill: #231815"
                  />
                  <rect
                    x="1532.85"
                    y="3097"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1593.48"
                    y="1218.23"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1593.48"
                    y="1339.44"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="1593.48"
                    y="1642.45"
                    width="60.6"
                    height="242.41"
                    style="fill: #231815"
                  />
                  <rect
                    x="1593.48"
                    y="2187.93"
                    width="60.6"
                    height="303.04"
                    style="fill: #231815"
                  />
                  <rect
                    x="1593.48"
                    y="2551.55"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1593.48"
                    y="2672.78"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1593.48"
                    y="2854.59"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1593.48"
                    y="2975.79"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1593.48"
                    y="3036.39"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="1654.08"
                    y="1157.6"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="1654.08"
                    y="1521.24"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="1654.08"
                    y="1763.68"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1654.08"
                    y="1945.49"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1654.08"
                    y="2127.3"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1654.08"
                    y="2248.53"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1654.08"
                    y="2430.34"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1654.08"
                    y="2793.98"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="1654.08"
                    y="2975.79"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1654.08"
                    y="3036.39"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="1714.69"
                    y="1157.6"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1714.69"
                    y="1339.44"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1714.69"
                    y="1642.45"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="1714.69"
                    y="1884.89"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1714.69"
                    y="2066.7"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1714.69"
                    y="2369.74"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="1714.69"
                    y="2490.94"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1714.69"
                    y="2672.78"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1714.69"
                    y="2854.59"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1714.69"
                    y="3036.39"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1775.29"
                    y="1218.23"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1775.29"
                    y="1339.44"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1775.29"
                    y="1460.64"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="1775.29"
                    y="1703.08"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1775.29"
                    y="1884.89"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="1775.29"
                    y="2187.93"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1775.29"
                    y="2309.14"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="1775.29"
                    y="2490.94"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="1775.29"
                    y="2672.78"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1775.29"
                    y="2733.38"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1775.29"
                    y="2854.59"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="1775.29"
                    y="3036.39"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="1835.89"
                    y="1460.64"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1835.89"
                    y="1642.45"
                    width="60.6"
                    height="242.41"
                    style="fill: #231815"
                  />
                  <rect
                    x="1835.89"
                    y="2006.09"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="1835.89"
                    y="2248.53"
                    width="60.6"
                    height="242.41"
                    style="fill: #231815"
                  />
                  <rect
                    x="1835.89"
                    y="2490.94"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="1835.89"
                    y="2733.38"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1835.89"
                    y="2854.59"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1896.49"
                    y="1157.6"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1896.49"
                    y="1278.83"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1896.49"
                    y="1400.04"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="1896.49"
                    y="1642.45"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1896.49"
                    y="1763.68"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1896.49"
                    y="1884.89"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1896.49"
                    y="2066.7"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="1896.49"
                    y="2369.74"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="1896.49"
                    y="2733.38"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="1957.1"
                    y="1218.23"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="1957.1"
                    y="1339.44"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="1957.1"
                    y="1763.68"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="1957.1"
                    y="1884.89"
                    width="60.6"
                    height="303.04"
                    style="fill: #231815"
                  />
                  <rect
                    x="1957.1"
                    y="2248.53"
                    width="60.6"
                    height="242.41"
                    style="fill: #231815"
                  />
                  <rect
                    x="1957.1"
                    y="2733.38"
                    width="60.6"
                    height="303.04"
                    style="fill: #231815"
                  />
                  <rect
                    x="1957.1"
                    y="3036.39"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2017.7"
                    y="1218.23"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2017.7"
                    y="1400.04"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2017.7"
                    y="1521.24"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="2017.7"
                    y="1763.68"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="2017.7"
                    y="2006.09"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2017.7"
                    y="2127.3"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2017.7"
                    y="2430.34"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2017.7"
                    y="2551.55"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2017.7"
                    y="2733.38"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2017.7"
                    y="2854.59"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2017.7"
                    y="2975.79"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2017.7"
                    y="3097"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2078.33"
                    y="1400.04"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2078.33"
                    y="1642.45"
                    width="60.6"
                    height="242.41"
                    style="fill: #231815"
                  />
                  <rect
                    x="2078.33"
                    y="2006.09"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2078.33"
                    y="2127.3"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2078.33"
                    y="2430.34"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2078.33"
                    y="2490.94"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2078.33"
                    y="2612.15"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2078.33"
                    y="2733.38"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2078.33"
                    y="2915.19"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2078.33"
                    y="3097"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2138.93"
                    y="1157.6"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2138.93"
                    y="1339.44"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2138.93"
                    y="1521.24"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2138.93"
                    y="1703.08"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2138.93"
                    y="1824.29"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2138.93"
                    y="1945.49"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2138.93"
                    y="2066.7"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2138.93"
                    y="2187.93"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2138.93"
                    y="2369.74"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2138.93"
                    y="2490.94"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2138.93"
                    y="2793.98"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2138.93"
                    y="2915.19"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2138.93"
                    y="3036.39"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2199.54"
                    y="1157.6"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2199.54"
                    y="1339.44"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2199.54"
                    y="1460.64"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2199.54"
                    y="1581.85"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="2199.54"
                    y="1824.29"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2199.54"
                    y="2187.93"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2199.54"
                    y="2309.14"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2199.54"
                    y="2490.94"
                    width="60.6"
                    height="242.41"
                    style="fill: #231815"
                  />
                  <rect
                    x="2199.54"
                    y="2793.98"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2260.14"
                    y="1218.23"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2260.14"
                    y="1400.04"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2260.14"
                    y="1521.24"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2260.14"
                    y="1703.08"
                    width="60.6"
                    height="242.41"
                    style="fill: #231815"
                  />
                  <rect
                    x="2260.14"
                    y="2006.09"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2260.14"
                    y="2187.93"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2260.14"
                    y="2430.34"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2260.14"
                    y="2551.55"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2260.14"
                    y="2672.78"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2260.14"
                    y="2793.98"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2260.14"
                    y="2975.79"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2320.74"
                    y="1157.6"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2320.74"
                    y="1278.83"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2320.74"
                    y="1339.44"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2320.74"
                    y="1460.64"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2320.74"
                    y="1581.85"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2320.74"
                    y="1884.89"
                    width="60.6"
                    height="242.44"
                    style="fill: #231815"
                  />
                  <rect
                    x="2320.74"
                    y="2187.93"
                    width="60.6"
                    height="303.04"
                    style="fill: #231815"
                  />
                  <rect
                    x="2320.74"
                    y="2490.94"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2320.74"
                    y="2854.59"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2320.74"
                    y="2975.79"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2320.74"
                    y="3036.39"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2381.34"
                    y="1218.23"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2381.34"
                    y="1521.24"
                    width="60.6"
                    height="303.04"
                    style="fill: #231815"
                  />
                  <rect
                    x="2381.34"
                    y="1884.89"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2381.34"
                    y="2127.3"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2381.34"
                    y="2309.14"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2381.34"
                    y="2430.34"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2381.34"
                    y="2551.55"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="2381.34"
                    y="2733.38"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2381.34"
                    y="2854.59"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2381.34"
                    y="3036.39"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2441.95"
                    y="1157.6"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2441.95"
                    y="1339.44"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2441.95"
                    y="1703.08"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2441.95"
                    y="1945.49"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2441.95"
                    y="2187.93"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2441.95"
                    y="2309.14"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="2441.95"
                    y="2551.55"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2441.95"
                    y="2733.38"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2441.95"
                    y="3036.39"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2502.55"
                    y="1218.23"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2502.55"
                    y="1400.04"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2502.55"
                    y="1521.24"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2502.55"
                    y="1642.45"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2502.55"
                    y="1824.29"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2502.55"
                    y="2127.3"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2502.55"
                    y="2248.53"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2502.55"
                    y="2490.94"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2502.55"
                    y="2612.15"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2502.55"
                    y="2733.38"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="2502.55"
                    y="2975.79"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2502.55"
                    y="3097"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2563.18"
                    y="1824.29"
                    width="60.6"
                    height="484.85"
                    style="fill: #231815"
                  />
                  <rect
                    x="2563.18"
                    y="2369.74"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2563.18"
                    y="2551.55"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2563.18"
                    y="2854.59"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="2563.18"
                    y="3036.39"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2623.78"
                    y="1157.6"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="2623.78"
                    y="1339.44"
                    width="60.6"
                    height="242.41"
                    style="fill: #231815"
                  />
                  <rect
                    x="2623.78"
                    y="1642.45"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="2623.78"
                    y="1945.49"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="2623.78"
                    y="2187.93"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2623.78"
                    y="2430.34"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2623.78"
                    y="2612.15"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2623.78"
                    y="2733.38"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2623.78"
                    y="2854.59"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2623.78"
                    y="2975.79"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2684.38"
                    y="1157.6"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2684.38"
                    y="1521.24"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2684.38"
                    y="1642.45"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2684.38"
                    y="1945.49"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2684.38"
                    y="2127.3"
                    width="60.6"
                    height="242.41"
                    style="fill: #231815"
                  />
                  <rect
                    x="2684.38"
                    y="2551.55"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2684.38"
                    y="2854.59"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2684.38"
                    y="2975.79"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2684.38"
                    y="3097"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2744.99"
                    y="1157.6"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2744.99"
                    y="1278.83"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2744.99"
                    y="1339.44"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2744.99"
                    y="1521.24"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2744.99"
                    y="1642.45"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2744.99"
                    y="1763.68"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="2744.99"
                    y="2127.3"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2744.99"
                    y="2248.53"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="2744.99"
                    y="2612.15"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2744.99"
                    y="2733.38"
                    width="60.6"
                    height="242.44"
                    style="fill: #231815"
                  />
                  <rect
                    x="2744.99"
                    y="3036.39"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2805.59"
                    y="1157.6"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2805.59"
                    y="1278.83"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2805.59"
                    y="1339.44"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2805.59"
                    y="1521.24"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2805.59"
                    y="1642.45"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2805.59"
                    y="1824.29"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="2805.59"
                    y="2066.7"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="2805.59"
                    y="2309.14"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2805.59"
                    y="2490.94"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2805.59"
                    y="2612.15"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2805.59"
                    y="2793.98"
                    width="60.6"
                    height="242.44"
                    style="fill: #231815"
                  />
                  <rect
                    x="2805.59"
                    y="3097"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2866.19"
                    y="1157.6"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2866.19"
                    y="1278.83"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2866.19"
                    y="1339.44"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2866.19"
                    y="1521.24"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2866.19"
                    y="1642.45"
                    width="60.6"
                    height="242.41"
                    style="fill: #231815"
                  />
                  <rect
                    x="2866.19"
                    y="1945.49"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2866.19"
                    y="2066.7"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2866.19"
                    y="2187.93"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="2866.19"
                    y="2490.94"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2866.19"
                    y="2672.78"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2866.19"
                    y="2733.38"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2866.19"
                    y="2915.19"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2866.19"
                    y="3036.39"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2926.8"
                    y="1157.6"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2926.8"
                    y="1521.24"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2926.8"
                    y="1763.68"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2926.8"
                    y="1884.89"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="2926.8"
                    y="2248.53"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2926.8"
                    y="2369.74"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2926.8"
                    y="2490.94"
                    width="60.6"
                    height="242.41"
                    style="fill: #231815"
                  />
                  <rect
                    x="2926.8"
                    y="2733.38"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2926.8"
                    y="3097"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2987.4"
                    y="1157.6"
                    width="60.6"
                    height="181.81"
                    style="fill: #231815"
                  />
                  <rect
                    x="2987.4"
                    y="1339.44"
                    width="60.6"
                    height="242.41"
                    style="fill: #231815"
                  />
                  <rect
                    x="2987.4"
                    y="1703.08"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2987.4"
                    y="1945.49"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2987.4"
                    y="2127.3"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2987.4"
                    y="2430.34"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2987.4"
                    y="2612.15"
                    width="60.6"
                    height="121.21"
                    style="fill: #231815"
                  />
                  <rect
                    x="2987.4"
                    y="2793.98"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                  <rect
                    x="2987.4"
                    y="2915.19"
                    width="60.6"
                    height="60.6"
                    style="fill: #231815"
                  />
                </g>
                <g id="支付宝-2" data-name="支付宝">
                  <rect
                    x="1823"
                    y="1932.6"
                    width="450"
                    height="450"
                    rx="54.87"
                    style="fill: #58fff6"
                  />
                  <path
                    d="M1940.64,2244.08c-4.66-34.76,15.73-83.23,32.52-103.22-1-12.13-2.59-43.29,6.77-48.65,7.73-4.44,26.76,6.42,38.85,14.37l120,.83c12-7.95,32.09-19.73,40.08-15.16,10.42,6,7.25,43.9,6.46,52,16.09,19.34,26.63,77.83,26.63,103.4"
                    style="fill: #00ece0"
                  />
                  <path
                    d="M2179.84,2234.86c.57,12.7-11.78,17.49-28.13,17.49s-31.82-3.43-31-17.49c.74-13.54,16-24.53,29.57-24.53C2165.47,2210.33,2179.26,2221.32,2179.84,2234.86Zm-216.48,0c.57,12.7-11.78,17.49-28.12,17.49s-31.82-3.43-31-17.49c.75-13.54,16-24.53,29.58-24.53C1949,2210.33,1962.75,2221.32,1963.36,2234.86Z"
                    style="fill: #00ece0"
                  />
                  <path
                    d="M1908,2224.13c-4.66-34.76,15.73-83.23,32.52-103.22-1-12.13-2.6-43.29,6.76-48.65,7.74-4.44,26.77,6.41,38.85,14.37l120.06.83c12-7.95,32.08-19.73,40.08-15.16,10.41,6,7.25,43.9,6.46,52,16.08,19.34,26.63,77.83,26.63,103.4"
                    style="fill: #fff"
                  />
                  <path
                    d="M2182.08,2227.65h-5.54c0-13.36-2.81-34.32-7.21-53.39-5.23-22.86-11.91-40-18.76-48.21a2.72,2.72,0,0,1-.62-2.07c2.11-21.14,1.41-45.57-5.09-49.3-1.85-1.06-9.89-3-37.18,15.07a2.75,2.75,0,0,1-1.54.48l-120.06-.83a3,3,0,0,1-1.49-.44c-26.33-17.27-34.19-15.3-35.95-14.28-5.32,3-7.43,21.09-5.36,46a2.85,2.85,0,0,1-.66,2c-16.39,19.47-36.43,67.32-31.91,101.07l-5.49.75c-2.15-16.09.79-36.83,8.35-58.45,6.33-18.15,15.29-35.24,24.08-46a196.12,196.12,0,0,1-.44-26.15c.88-13.32,3.7-21.18,8.66-24,6.9-4,20.74.75,41.09,14l118.39.79c21-13.85,35.24-18.86,42.32-14.81,5.36,3.07,8.26,12.08,8.83,27.51a205.76,205.76,0,0,1-.88,26c16.44,21.23,26.46,79.19,26.46,104.33Z"
                    style="fill: #7a3134"
                  />
                  <path
                    d="M2207.08,2230.51H1870a3.26,3.26,0,0,1,0-6.51h337.06a3.26,3.26,0,0,1,0,6.51Z"
                    style="fill: #7a3134"
                  />
                  <path
                    d="M1968.33,2159c0,5.29,2.92,9.59,6.54,9.59s6.55-4.28,6.55-9.57v0c0-5.29-2.92-9.58-6.54-9.59s-6.55,4.28-6.55,9.57Z"
                  />
                  <path
                    d="M2045.36,2091.46h61.13s29.22-18.54,37.49-15.77,11.73,12.56,7.73,51c0,0,13.62,25.36,13.49,32,0,0-38.76,56.17-106.78,14.81C2023.57,2149,2030.25,2106.27,2045.36,2091.46Z"
                    style="fill: #ffaf00"
                  />
                  <path
                    d="M2089.13,2159c0,5.29,2.93,9.58,6.55,9.58s6.55-4.29,6.55-9.58-2.93-9.58-6.55-9.58S2089.13,2153.67,2089.13,2159Z"
                  />
                  <path
                    d="M2028.58,2187a8.69,8.69,0,0,1-6.86-3.29,1.81,1.81,0,1,1,2.81-2.29,5.22,5.22,0,0,0,9.28-3.29,1.8,1.8,0,1,1,3.6,0,8.84,8.84,0,0,1-8.83,8.87Z"
                  />
                  <path
                    d="M2042.73,2187a8.78,8.78,0,0,1-8.79-8.79,1.8,1.8,0,1,1,3.6,0,5.24,5.24,0,0,0,5.23,5.23,5.11,5.11,0,0,0,4-1.93,1.82,1.82,0,0,1,2.82,2.29A9.21,9.21,0,0,1,2042.73,2187Z"
                  />
                  <path
                    d="M2041.23,2103.06c-1.89,5.19-3.78,7.61-8.26,7.61s-6.46-3.52-8.13-8.18a8.2,8.2,0,0,1,8.09-11.2C2040.27,2091.51,2043.34,2097.26,2041.23,2103.06Zm21.8-3.12c-1.36,2.64-2.73,3.83-6,3.83s-4.66-1.8-5.84-4.13c-1.54-2.95,1.49-5.76,5.84-5.67C2062.37,2094.1,2064.57,2097,2063,2099.94Zm-45.53,0c-1.36,2.64-2.72,3.83-6,3.83s-4.66-1.8-5.85-4.13c-1.54-2.95,1.49-5.76,5.85-5.67C2016.84,2094.1,2019,2097,2017.5,2099.94Z"
                    style="fill: #ff7800"
                  />
                  <path
                    d="M2028.71,2166.74h14.72a2.77,2.77,0,0,1,2.11,4.57l-7.56,7.25a2.8,2.8,0,0,1-4.22,0l-7.16-7.25A2.77,2.77,0,0,1,2028.71,2166.74Z"
                  />
                  <path
                    d="M2117,2091.68c8-5.27,20.48-12.35,29.67-15.12a.81.81,0,0,1-.27-.17c-7.95-4.53-27.9,7.29-39.94,15.25H2117Z"
                    style="fill: #ffe6a2"
                  />
                  <path
                    d="M1953.08,2123.85c-1-12-8-42.54,4.22-48.16-2.77-.4-5.58-.49-7.39.52-9.36,5.36-7.38,35.55-6.37,47.68-16.83,20-37.22,68.43-32.52,103.23l10.86.13C1917.53,2192.49,1936.33,2143.67,1953.08,2123.85Z"
                    style="fill: #f4e8e3"
                  />
                  <path
                    d="M1956.15,2227.65c.58,12.7-11.77,17.49-28.12,17.49s-31.82-3.43-31-17.49c.75-13.54,16-24.52,29.58-24.52C1941.74,2203.13,1955.54,2214.11,1956.15,2227.65Z"
                    style="fill: #fff"
                  />
                  <path
                    d="M1928,2248c-14.72,0-24.43-2.69-29.66-8.22a15.9,15.9,0,0,1-4.22-12.31c.39-7.2,4.35-14.06,11.07-19.42a35.2,35.2,0,0,1,21.36-7.82c16.92,0,31.77,12.48,32.43,27.24a16.36,16.36,0,0,1-4.48,12.57c-5,5.32-13.93,8-26.5,8Zm-1.45-42c-14.33,0-26.1,11.73-26.68,21.79a10.54,10.54,0,0,0,2.64,8c4,4.26,12.61,6.41,25.49,6.41,10.77,0,18.5-2.11,22.37-6.15a10.61,10.61,0,0,0,2.85-8.31C1952.73,2216,1940.51,2206,1926.58,2206Z"
                    style="fill: #7a3134"
                  />
                  <path
                    d="M1952.9,2227.25a18.91,18.91,0,0,0-3.6-9.49,26.54,26.54,0,0,1,.44,3.17c.48,11.29-7.34,14.76-21.89,14.76s-25.22-2.9-24.52-15.42c.05-.88.57-1.45.75-2.29-1.67,2.46-3.21,5-3.6,8.35-1.63,13.36,12.3,16.52,27.42,16.52C1940.51,2242.9,1953.87,2238.81,1952.9,2227.25Z"
                    style="fill: #f4e8e3"
                  />
                  <path
                    d="M2151.58,2125.3s10.5,15.51,14.15,31.42a22.34,22.34,0,0,1-4.7,5.93s-.66-16.39-9.45-37.35Z"
                    style="fill: #f69300"
                  />
                  <path
                    d="M2165.73,2156.72a49.79,49.79,0,0,1-4.7,5.93h0s6.76,36.21,5.53,62l10.68,1.14s-2.46-47.15-11.51-69.08"
                    style="fill: #f4e8e3"
                  />
                  <path
                    d="M2172.67,2227.65c.57,12.7-11.77,17.49-28.12,17.49s-31.82-3.43-31-17.49c.75-13.54,16-24.52,29.58-24.52C2158.26,2203.13,2172.06,2214.11,2172.67,2227.65Z"
                    style="fill: #fff"
                  />
                  <path
                    d="M2144.55,2248c-14.72,0-24.44-2.69-29.67-8.22a15.93,15.93,0,0,1-4.21-12.31c.39-7.2,4.35-14.06,11.07-19.42a35.2,35.2,0,0,1,21.36-7.82c16.92,0,31.77,12.48,32.43,27.24a16.4,16.4,0,0,1-4.48,12.57c-5,5.32-13.93,8-26.5,8Zm-1.45-42c-14.33,0-26.11,11.73-26.68,21.79a10.54,10.54,0,0,0,2.64,8c4,4.26,12.61,6.41,25.49,6.41,10.76,0,18.5-2.11,22.37-6.15a10.61,10.61,0,0,0,2.85-8.31C2169.25,2216,2157,2206,2143.1,2206Z"
                    style="fill: #7a3134"
                  />
                  <path
                    d="M2169.51,2227.17a19.61,19.61,0,0,0-3.74-9.58,26.1,26.1,0,0,1,.44,3.16c.49,11.29-7.25,14.9-21.79,14.9s-25.27-2.9-24.57-15.43c0-.88.48-1.32.66-2.15-1.67,2.46-3.12,4.48-3.52,7.82-1.62,13.4,11.65,16.88,27.47,16.92C2159.75,2242.94,2170.43,2238.72,2169.51,2227.17Z"
                    style="fill: #f4e8e3"
                  />
                  <path
                    d="M1933,2180.63c-1.36,1.32-2.72,1.93-6,1.93s-4.65-.88-5.84-2.06c-1.54-1.45,1.49-2.9,5.84-2.82,5.28.05,7.52,1.5,6,3Zm-1.36,13c-1.36,2-2.73,2.94-6,2.94a6.15,6.15,0,0,1-5.84-3.16c-1.54-2.29,1.49-4.44,5.84-4.35C1930.89,2189.15,1933.13,2191.39,1931.59,2193.68Zm240-3.12c-1.36,2-2.72,2.94-6,2.94a6.17,6.17,0,0,1-5.85-3.16c-1.54-2.29,1.5-4.44,5.85-4.35C2170.92,2186,2173.11,2188.27,2171.57,2190.56Z"
                    style="fill: #ff7800"
                  />
                </g>
                <g id="微信-2" data-name="微信">
                  <rect
                    x="5919"
                    y="1932.6"
                    width="450"
                    height="450"
                    rx="54.87"
                    style="fill: #58fff6"
                  />
                  <path
                    d="M6036.64,2244.08c-4.66-34.76,15.73-83.23,32.52-103.22-1-12.13-2.59-43.29,6.77-48.65,7.73-4.44,26.76,6.42,38.85,14.37l120.05.83c12-7.95,32.09-19.73,40.08-15.16,10.42,6,7.25,43.9,6.46,52,16.09,19.34,26.63,77.83,26.63,103.4"
                    style="fill: #00ece0"
                  />
                  <path
                    d="M6275.84,2234.86c.57,12.7-11.78,17.49-28.13,17.49s-31.82-3.43-31-17.49c.74-13.54,16-24.53,29.57-24.53C6261.47,2210.33,6275.26,2221.32,6275.84,2234.86Zm-216.48,0c.57,12.7-11.78,17.49-28.12,17.49s-31.82-3.43-31-17.49c.75-13.54,16-24.53,29.58-24.53C6045,2210.33,6058.75,2221.32,6059.36,2234.86Z"
                    style="fill: #00ece0"
                  />
                  <path
                    d="M6004,2224.13c-4.66-34.76,15.73-83.23,32.52-103.22-1-12.13-2.6-43.29,6.76-48.65,7.74-4.44,26.77,6.41,38.85,14.37l120.06.83c12-7.95,32.08-19.73,40.08-15.16,10.41,6,7.25,43.9,6.46,52,16.08,19.34,26.63,77.83,26.63,103.4"
                    style="fill: #fff"
                  />
                  <path
                    d="M6278.08,2227.65h-5.54c0-13.36-2.81-34.32-7.21-53.39-5.23-22.86-11.91-40-18.76-48.21a2.72,2.72,0,0,1-.62-2.07c2.11-21.14,1.41-45.57-5.09-49.3-1.85-1.06-9.89-3-37.18,15.07a2.75,2.75,0,0,1-1.54.48l-120.06-.83a3,3,0,0,1-1.49-.44c-26.33-17.27-34.19-15.3-35.95-14.28-5.32,3-7.43,21.09-5.36,46a2.85,2.85,0,0,1-.66,2c-16.39,19.47-36.43,67.32-31.91,101.07l-5.49.75c-2.15-16.09.79-36.83,8.35-58.45,6.33-18.15,15.29-35.24,24.08-46a196.12,196.12,0,0,1-.44-26.15c.88-13.32,3.7-21.18,8.66-24,6.9-4,20.74.75,41.09,14l118.39.79c21-13.85,35.24-18.86,42.32-14.81,5.36,3.07,8.26,12.08,8.83,27.51a205.76,205.76,0,0,1-.88,26c16.44,21.23,26.46,79.19,26.46,104.33Z"
                    style="fill: #7a3134"
                  />
                  <path
                    d="M6303.08,2230.51H5966a3.26,3.26,0,0,1,0-6.51h337.06a3.26,3.26,0,0,1,0,6.51Z"
                    style="fill: #7a3134"
                  />
                  <path
                    d="M6064.33,2159c0,5.29,2.92,9.59,6.54,9.59s6.55-4.28,6.55-9.57v0c0-5.29-2.92-9.58-6.54-9.59s-6.55,4.28-6.55,9.57Z"
                  />
                  <path
                    d="M6141.36,2091.46h61.13s29.22-18.54,37.49-15.77,11.73,12.56,7.73,51c0,0,13.62,25.36,13.49,32,0,0-38.76,56.17-106.78,14.81C6119.57,2149,6126.25,2106.27,6141.36,2091.46Z"
                    style="fill: #ffaf00"
                  />
                  <path
                    d="M6185.13,2159c0,5.29,2.93,9.58,6.55,9.58s6.55-4.29,6.55-9.58-2.93-9.58-6.55-9.58S6185.13,2153.67,6185.13,2159Z"
                  />
                  <path
                    d="M6124.58,2187a8.69,8.69,0,0,1-6.86-3.29,1.81,1.81,0,1,1,2.81-2.29,5.22,5.22,0,0,0,9.28-3.29,1.8,1.8,0,1,1,3.6,0,8.84,8.84,0,0,1-8.83,8.87Z"
                  />
                  <path
                    d="M6138.73,2187a8.78,8.78,0,0,1-8.79-8.79,1.8,1.8,0,0,1,3.6,0,5.24,5.24,0,0,0,5.23,5.23,5.11,5.11,0,0,0,4-1.93,1.82,1.82,0,0,1,2.82,2.29A9.21,9.21,0,0,1,6138.73,2187Z"
                  />
                  <path
                    d="M6137.23,2103.06c-1.89,5.19-3.78,7.61-8.26,7.61s-6.46-3.52-8.13-8.18a8.2,8.2,0,0,1,8.09-11.2C6136.27,2091.51,6139.34,2097.26,6137.23,2103.06Zm21.8-3.12c-1.36,2.64-2.73,3.83-6,3.83s-4.66-1.8-5.84-4.13c-1.54-2.95,1.49-5.76,5.84-5.67C6158.37,2094.1,6160.57,2097,6159,2099.94Zm-45.53,0c-1.36,2.64-2.72,3.83-6,3.83s-4.66-1.8-5.85-4.13c-1.54-2.95,1.49-5.76,5.85-5.67C6112.84,2094.1,6115,2097,6113.5,2099.94Z"
                    style="fill: #ff7800"
                  />
                  <path
                    d="M6124.71,2166.74h14.72a2.77,2.77,0,0,1,2.11,4.57l-7.56,7.25a2.8,2.8,0,0,1-4.22,0l-7.16-7.25A2.77,2.77,0,0,1,6124.71,2166.74Z"
                  />
                  <path
                    d="M6213,2091.68c8-5.27,20.48-12.35,29.67-15.12a.81.81,0,0,1-.27-.17c-8-4.53-27.9,7.29-39.94,15.25H6213Z"
                    style="fill: #ffe6a2"
                  />
                  <path
                    d="M6049.08,2123.85c-1-12-8-42.54,4.22-48.16-2.77-.4-5.58-.49-7.39.52-9.36,5.36-7.38,35.55-6.37,47.68-16.83,20-37.22,68.43-32.52,103.23l10.86.13C6013.53,2192.49,6032.33,2143.67,6049.08,2123.85Z"
                    style="fill: #f4e8e3"
                  />
                  <path
                    d="M6052.15,2227.65c.58,12.7-11.77,17.49-28.12,17.49s-31.82-3.43-31-17.49c.75-13.54,16-24.52,29.58-24.52C6037.74,2203.13,6051.54,2214.11,6052.15,2227.65Z"
                    style="fill: #fff"
                  />
                  <path
                    d="M6024,2248c-14.72,0-24.43-2.69-29.66-8.22a15.9,15.9,0,0,1-4.22-12.31c.39-7.2,4.35-14.06,11.07-19.42a35.2,35.2,0,0,1,21.36-7.82c16.92,0,31.77,12.48,32.43,27.24a16.36,16.36,0,0,1-4.48,12.57c-5,5.32-13.93,8-26.5,8Zm-1.45-42c-14.33,0-26.1,11.73-26.68,21.79a10.54,10.54,0,0,0,2.64,8c4,4.26,12.61,6.41,25.49,6.41,10.77,0,18.5-2.11,22.37-6.15a10.61,10.61,0,0,0,2.85-8.31C6048.73,2216,6036.51,2206,6022.58,2206Z"
                    style="fill: #7a3134"
                  />
                  <path
                    d="M6048.9,2227.25a18.91,18.91,0,0,0-3.6-9.49,26.54,26.54,0,0,1,.44,3.17c.48,11.29-7.34,14.76-21.89,14.76s-25.22-2.9-24.52-15.42c.05-.88.57-1.45.75-2.29-1.67,2.46-3.21,5-3.6,8.35-1.63,13.36,12.3,16.52,27.42,16.52C6036.51,2242.9,6049.87,2238.81,6048.9,2227.25Z"
                    style="fill: #f4e8e3"
                  />
                  <path
                    d="M6247.58,2125.3s10.5,15.51,14.15,31.42a22.34,22.34,0,0,1-4.7,5.93s-.66-16.39-9.45-37.35Z"
                    style="fill: #f69300"
                  />
                  <path
                    d="M6261.73,2156.72a49.79,49.79,0,0,1-4.7,5.93h0s6.76,36.21,5.53,62l10.68,1.14s-2.46-47.15-11.51-69.08"
                    style="fill: #f4e8e3"
                  />
                  <path
                    d="M6268.67,2227.65c.57,12.7-11.77,17.49-28.12,17.49s-31.82-3.43-31-17.49c.75-13.54,16-24.52,29.58-24.52C6254.26,2203.13,6268.06,2214.11,6268.67,2227.65Z"
                    style="fill: #fff"
                  />
                  <path
                    d="M6240.55,2248c-14.72,0-24.44-2.69-29.67-8.22a15.93,15.93,0,0,1-4.21-12.31c.39-7.2,4.35-14.06,11.07-19.42a35.2,35.2,0,0,1,21.36-7.82c16.92,0,31.77,12.48,32.43,27.24a16.4,16.4,0,0,1-4.48,12.57c-5,5.32-13.93,8-26.5,8Zm-1.45-42c-14.33,0-26.11,11.73-26.68,21.79a10.54,10.54,0,0,0,2.64,8c4,4.26,12.61,6.41,25.49,6.41,10.76,0,18.5-2.11,22.37-6.15a10.61,10.61,0,0,0,2.85-8.31C6265.25,2216,6253,2206,6239.1,2206Z"
                    style="fill: #7a3134"
                  />
                  <path
                    d="M6265.51,2227.17a19.61,19.61,0,0,0-3.74-9.58,26.1,26.1,0,0,1,.44,3.16c.49,11.29-7.25,14.9-21.79,14.9s-25.27-2.9-24.57-15.43c0-.88.48-1.32.66-2.15-1.67,2.46-3.12,4.48-3.52,7.82-1.62,13.4,11.65,16.88,27.47,16.92C6255.75,2242.94,6266.43,2238.72,6265.51,2227.17Z"
                    style="fill: #f4e8e3"
                  />
                  <path
                    d="M6029,2180.63c-1.36,1.32-2.72,1.93-6,1.93s-4.65-.88-5.84-2.06c-1.54-1.45,1.49-2.9,5.84-2.82,5.28.05,7.52,1.5,6,3Zm-1.36,13c-1.36,2-2.73,2.94-6,2.94a6.15,6.15,0,0,1-5.84-3.16c-1.54-2.29,1.49-4.44,5.84-4.35C6026.89,2189.15,6029.13,2191.39,6027.59,2193.68Zm240-3.12c-1.36,2-2.72,2.94-6,2.94a6.17,6.17,0,0,1-5.85-3.16c-1.54-2.29,1.5-4.44,5.85-4.35C6266.92,2186,6269.11,2188.27,6267.57,2190.56Z"
                    style="fill: #ff7800"
                  />
                </g>
                <path
                  d="M5615.5,3386.41V3328h-40.82v-10.55H5736V3328h-38.28v58.4h46.68V3397h-46.68v80.86h-10.93V3397h-60.74a159.93,159.93,0,0,1-3.52,27.15,84.57,84.57,0,0,1-5.66,16.41,88.55,88.55,0,0,1-13.48,19.53,217.19,217.19,0,0,1-21.68,20.7l-8.59-8.2a259.18,259.18,0,0,0,20.9-18.75,100.37,100.37,0,0,0,10.15-13.28,75.1,75.1,0,0,0,7.81-19.34,150.41,150.41,0,0,0,3.13-24.22h-48.44v-10.54Zm10.94,0h60.35V3328h-60.35v58.4Z"
                />
                <path
                  d="M5827.6,3453.59a206.51,206.51,0,0,0,27.35-13.86,121.76,121.76,0,0,1-33.6-44.14,222.87,222.87,0,0,1-20.31,37.1,278.89,278.89,0,0,1-34.18,40l-8-8.79a306.58,306.58,0,0,0,24-26.17,252.85,252.85,0,0,0,18.55-27.93,220.48,220.48,0,0,0,21.29-56.25H5776v-9.37q5.28-18,9.57-36.52l10.55,2.34q-3.91,17-8.4,33.4h37.11q3.9-19.53,6.05-39.45l11.14.78q-2.36,19.53-5.67,38.67h99.22v10.15H5834.24q-3.12,13.88-7.42,27h85v10.15a169.57,169.57,0,0,1-18,29.1,130.8,130.8,0,0,1-19.92,19.93,177,177,0,0,0,26.37,14.25q18.93,7.82,38.86,13.87l-4.49,11.13a367.67,367.67,0,0,1-42.19-16.21,173.65,173.65,0,0,1-28.32-16,188.31,188.31,0,0,1-29.1,15.43,443,443,0,0,1-43.74,16.4l-4.69-10.55Q5807.29,3461.61,5827.6,3453.59Zm36.92-20.51a107.86,107.86,0,0,0,34.56-42.18h-68.16A112.93,112.93,0,0,0,5864.52,3433.08Zm12.1-129.09,35.16,19.92-5.47,9.37q-17.58-10.35-35.35-20.31Z"
                />
                <path
                  d="M6030.14,3363.56V3336h-49.61v-9.38h49.61v-22.85h10.93v22.85h47.66V3336h-47.66v27.54h19a229.18,229.18,0,0,0,51.36-50.2l8.79,5.86a225.5,225.5,0,0,1-42.57,44.34h56.63v9.57h-69.72a285.68,285.68,0,0,1-40.82,23.82h81.25v80.86H6094v-10.55h-87.5v10.55h-10.93V3410q-18,7.62-36.14,14.45l-3.71-10.16q27-9.55,52.93-21.48a325.19,325.19,0,0,0,36.91-19.72h-84.37v-9.57Zm-23.64,63.86H6094v-21.09h-87.5Zm0,30.66H6094V3436.6h-87.5Z"
                />
                <path
                  d="M6206.5,3390.7h-16.21v-26h16.21Zm0,74.81h-16.21v-26.18h16.21Z"
                />
                <path
                  d="M6416.65,3319.61v121.68h-46.49v13.28h-10v-135Zm-46.49,55.27h36.72v-44.72h-36.72Zm36.72,56.06v-45.51h-36.72v45.51Zm15.63,22.46a82.43,82.43,0,0,0,9.37-14.65,92,92,0,0,0,6.06-24.22q1.56-17.58,1.56-35.35v-64.06h72.07V3453a40.41,40.41,0,0,1-1.37,10.54,19.3,19.3,0,0,1-3.13,5.28,19,19,0,0,1-7.42,4.1,63.62,63.62,0,0,1-12.11,1.17H6467l-2-10.35h20.51a43.71,43.71,0,0,0,8.2-.78,12.34,12.34,0,0,0,3.91-1.57,8.24,8.24,0,0,0,2.73-3.9,24.12,24.12,0,0,0,1-6.84v-31.83H6447.9a82.5,82.5,0,0,1-10.55,31.25,89.63,89.63,0,0,1-11.33,14.84q-7.62,8-16,15.23l-8.4-6.83A202.53,202.53,0,0,0,6422.51,3453.4Zm26.56-44.73h52.34v-35.74H6450v11.13Q6450,3396.57,6449.07,3408.67Zm1-45.7h51.37v-37.3H6450Z"
                />
                <path
                  d="M6612,3319.61v121.68h-46.48v13.28h-10v-135Zm-46.48,55.27h36.72v-44.72h-36.72Zm36.72,56.06v-45.51h-36.72v45.51Zm15.62,22.46a82.48,82.48,0,0,0,9.38-14.65,91.93,91.93,0,0,0,6.05-24.22q1.56-17.58,1.56-35.35v-64.06h72.07V3453a40.4,40.4,0,0,1-1.36,10.54,19.81,19.81,0,0,1-3.13,5.28,19,19,0,0,1-7.42,4.1,63.77,63.77,0,0,1-12.11,1.17h-20.51l-1.95-10.35h20.51a43.79,43.79,0,0,0,8.2-.78,12.43,12.43,0,0,0,3.91-1.57,8.24,8.24,0,0,0,2.73-3.9,24.12,24.12,0,0,0,1-6.84v-31.83H6643.2a82.48,82.48,0,0,1-10.54,31.25,90.18,90.18,0,0,1-11.33,14.84q-7.62,8-16,15.23l-8.39-6.83A201.42,201.42,0,0,0,6617.81,3453.4Zm26.57-44.73h52.34v-35.74h-51.37v11.13Q6645.35,3396.57,6644.38,3408.67Zm1-45.7h51.37v-37.3h-51.37Z"
                />
                <path
                  d="M1519.5,3386.41V3328h-40.82v-10.55H1640V3328h-38.28v58.4h46.68V3397h-46.68v80.86h-10.93V3397h-60.74a159.93,159.93,0,0,1-3.52,27.15,84.57,84.57,0,0,1-5.66,16.41,88.55,88.55,0,0,1-13.48,19.53,217.19,217.19,0,0,1-21.68,20.7l-8.59-8.2a259.18,259.18,0,0,0,20.9-18.75,100.37,100.37,0,0,0,10.15-13.28,75.1,75.1,0,0,0,7.81-19.34,149.3,149.3,0,0,0,3.13-24.22h-48.44v-10.54Zm10.94,0h60.35V3328h-60.35v58.4Z"
                />
                <path
                  d="M1731.6,3453.59a206.51,206.51,0,0,0,27.35-13.86,121.76,121.76,0,0,1-33.6-44.14,222.87,222.87,0,0,1-20.31,37.1,278.25,278.25,0,0,1-34.18,40l-8-8.79a305,305,0,0,0,24-26.17,252.85,252.85,0,0,0,18.55-27.93,220.48,220.48,0,0,0,21.29-56.25H1680v-9.37q5.28-18,9.57-36.52l10.55,2.34q-3.92,17-8.4,33.4h37.11q3.9-19.53,6.05-39.45l11.14.78q-2.36,19.53-5.67,38.67h99.22v10.15H1738.24q-3.12,13.88-7.42,27h85v10.15a169.57,169.57,0,0,1-18,29.1,130.8,130.8,0,0,1-19.92,19.93,177,177,0,0,0,26.37,14.25q18.95,7.82,38.86,13.87l-4.49,11.13a367.67,367.67,0,0,1-42.19-16.21,173.15,173.15,0,0,1-28.31-16,188.87,188.87,0,0,1-29.11,15.43,443,443,0,0,1-43.74,16.4l-4.69-10.55Q1711.29,3461.61,1731.6,3453.59Zm36.92-20.51a108,108,0,0,0,34.57-42.18h-68.17A112.93,112.93,0,0,0,1768.52,3433.08Zm12.1-129.09,35.16,19.92-5.47,9.37q-17.58-10.35-35.35-20.31Z"
                />
                <path
                  d="M1934.14,3363.56V3336h-49.61v-9.38h49.61v-22.85h10.93v22.85h47.66V3336h-47.66v27.54h19a229.18,229.18,0,0,0,51.36-50.2l8.79,5.86a225.5,225.5,0,0,1-42.57,44.34h56.63v9.57h-69.72a285.68,285.68,0,0,1-40.82,23.82h81.25v80.86H1998v-10.55h-87.5v10.55h-10.93V3410q-18,7.62-36.13,14.45l-3.72-10.16q27-9.55,52.93-21.48a326.18,326.18,0,0,0,36.92-19.72h-84.38v-9.57Zm-23.64,63.86H1998v-21.09h-87.5Zm0,30.66H1998V3436.6h-87.5Z"
                />
                <path
                  d="M2110.5,3390.7h-16.21v-26h16.21Zm0,74.81h-16.21v-26.18h16.21Z"
                />
                <path
                  d="M2320.65,3319.61v121.68h-46.48v13.28h-10v-135Zm-46.48,55.27h36.71v-44.72h-36.71Zm36.71,56.06v-45.51h-36.71v45.51Zm15.63,22.46a82.43,82.43,0,0,0,9.37-14.65,92,92,0,0,0,6.06-24.22q1.56-17.58,1.56-35.35v-64.06h72.07V3453a40.41,40.41,0,0,1-1.37,10.54,19.53,19.53,0,0,1-3.12,5.28,19.06,19.06,0,0,1-7.43,4.1,63.62,63.62,0,0,1-12.11,1.17H2371l-2-10.35h20.51a43.71,43.71,0,0,0,8.2-.78,12.34,12.34,0,0,0,3.91-1.57,8.24,8.24,0,0,0,2.73-3.9,24.12,24.12,0,0,0,1-6.84v-31.83H2351.9a82.5,82.5,0,0,1-10.55,31.25,89.63,89.63,0,0,1-11.33,14.84q-7.62,8-16,15.23l-8.4-6.83A201.52,201.52,0,0,0,2326.51,3453.4Zm26.56-44.73h52.34v-35.74h-51.36v11.13Q2354.05,3396.57,2353.07,3408.67Zm1-45.7h51.36v-37.3h-51.36Z"
                />
                <path
                  d="M2516,3319.61v121.68h-46.48v13.28h-10v-135Zm-46.48,55.27h36.72v-44.72h-36.72Zm36.72,56.06v-45.51h-36.72v45.51Zm15.62,22.46a82.48,82.48,0,0,0,9.38-14.65,91.93,91.93,0,0,0,6-24.22q1.56-17.58,1.57-35.35v-64.06h72.06V3453a40.4,40.4,0,0,1-1.36,10.54,19.55,19.55,0,0,1-3.13,5.28,19,19,0,0,1-7.42,4.1,63.7,63.7,0,0,1-12.11,1.17h-20.51l-2-10.35h20.51a43.79,43.79,0,0,0,8.2-.78,12.43,12.43,0,0,0,3.91-1.57,8.24,8.24,0,0,0,2.73-3.9,24.12,24.12,0,0,0,1-6.84v-31.83H2547.2a82.48,82.48,0,0,1-10.54,31.25,89.63,89.63,0,0,1-11.33,14.84q-7.62,8-16,15.23l-8.39-6.83A201.42,201.42,0,0,0,2521.81,3453.4Zm26.57-44.73h52.34v-35.74h-51.37v11.13Q2549.35,3396.57,2548.38,3408.67Zm1-45.7h51.37v-37.3h-51.37Z"
                />
                <path
                  d="M795.15,312.37H760.59V287.54h78.08V259.63H740.36V234.55h98.31V204.34h27.64v30.21h98.05v25.08H866.31v27.91h65.28v24.83a276.79,276.79,0,0,1-26.88,39.94A178.48,178.48,0,0,1,875,380.47a259.11,259.11,0,0,0,43.26,17.66,460.17,460.17,0,0,0,48.64,12.29l-7.68,27.65a518.76,518.76,0,0,1-59.39-17.92,276.85,276.85,0,0,1-49.66-23.81,447,447,0,0,1-48.64,21.76q-27.91,10.48-56.32,19.2l-9.48-27.65q24.06-6.39,47.88-14.34a349.05,349.05,0,0,0,42.49-17.15,190.28,190.28,0,0,1-23.29-23.81,282.76,282.76,0,0,1-22.28-32.76Zm55,52.48a147.87,147.87,0,0,0,49.66-52.48H805.64A162.76,162.76,0,0,0,850.19,364.85Z"
                  style="fill: #fff"
                />
                <path
                  d="M1020.43,263.47a417.69,417.69,0,0,0,24.32-58.36l26.62,6.14q-9.48,26.12-20.22,51.2V431.67h-26.37V311.6a323.67,323.67,0,0,1-21,28.93L986.89,317A303.08,303.08,0,0,0,1020.43,263.47Zm120.06,140.8a41.17,41.17,0,0,0,10.24-.76,16.93,16.93,0,0,0,4.61-1.8,8.51,8.51,0,0,0,3.07-4.6,39.23,39.23,0,0,0,.77-7.68V279.35H1064V253.49h95.23v-47.1h26.88v47.1h37.37v25.86h-37.37v118a58.42,58.42,0,0,1-2.31,16.39,25.43,25.43,0,0,1-5.12,8.19,28.36,28.36,0,0,1-12,6.4,89.72,89.72,0,0,1-18.94,2.05h-33l-5.37-26.12Zm-44.8-111.87q20.73,29.44,41.22,59.39l-22,14.85q-20-30.19-40.7-59.9Z"
                  style="fill: #fff"
                />
                <path
                  d="M1350.15,333.87V305.2h-83.71V280.37h196.87V305.2h-86.79v28.67h78.59v24.84H1422.6q11.78,15.36,23.55,31l-12.54,9.22H1473v24.57H1256V398.9h94.2V358.71h-76.28V333.87Zm4.1-105q-5.64-11.25-10.5-22.78l26.88-6.14q6.66,14.58,13.57,28.92h86.53v46.85h-27.39v-23H1285.13v23H1259V228.91Zm59.65,129.8h-37.38V398.9h44.8q-11-14.59-21.76-29.44Z"
                  style="fill: #fff"
                />
                <path
                  d="M1621.25,225.84H1683v24.58h-32.77V446.26H1683v24.57h-61.7Z"
                  style="fill: #fff"
                />
                <path
                  d="M1827.33,406.32a25.54,25.54,0,0,0,7.17-.51,11.55,11.55,0,0,0,3.32-1.54,7.56,7.56,0,0,0,2-3.84,39,39,0,0,0,.51-6.4V341l-33,11.78-5.12-25.09q18.94-5.62,38.14-12.29V272.18h-33V247.09h33v-42h25.09v42h30v25.09h-30v34.3q13.83-4.61,27.39-10.24l1.8,13.06,1.79,12.29-31,10.75v70.14a60.15,60.15,0,0,1-1.79,14.59,21,21,0,0,1-3.84,7.17,22.31,22.31,0,0,1-9.73,5.12,82.85,82.85,0,0,1-15.87,1.54H1812l-5.12-24.58ZM2023.42,222V429.11h-26.11V417.59h-108.8V392h108.8V330h-93.44V304.18h93.44V248.11h-98.56V222Z"
                  style="fill: #fff"
                />
                <path
                  d="M2291.71,296.24v28.67H2059.78V296.24Z"
                  style="fill: #fff"
                />
                <path
                  d="M2339.33,406.32a25.54,25.54,0,0,0,7.17-.51,11.55,11.55,0,0,0,3.32-1.54,7.56,7.56,0,0,0,2-3.84,39,39,0,0,0,.51-6.4V341l-33,11.78-5.12-25.09q18.95-5.62,38.14-12.29V272.18h-33V247.09h33v-42h25.09v42h30v25.09h-30v34.3q13.83-4.61,27.39-10.24l1.8,13.06,1.79,12.29-31,10.75v70.14a60.15,60.15,0,0,1-1.79,14.59,21,21,0,0,1-3.84,7.17,22.31,22.31,0,0,1-9.73,5.12,82.85,82.85,0,0,1-15.87,1.54H2324l-5.12-24.58ZM2535.42,222V429.11h-26.11V417.59h-108.8V392h108.8V330h-93.44V304.18h93.44V248.11h-98.56V222Z"
                  style="fill: #fff"
                />
                <path
                  d="M2669.05,250.42V225.84h61.7v245h-61.7V446.26h32.77V250.42Z"
                  style="fill: #fff"
                />
                <path
                  d="M2889.21,406.58a26.78,26.78,0,0,0,6.4-.26,7.53,7.53,0,0,0,3.58-2.05,6.5,6.5,0,0,0,1.54-2.56,26.33,26.33,0,0,0,.76-6.4V343.09l-28.16,10-4.86-24.32q16.38-4.61,33-10.24V271.15H2874.1v-23.8h27.39V204.08h23.81v43.27h27.39v23.8H2925.3v39.43q11.26-3.58,22.53-8.19l1.28,12.54,1.28,10.75q-12.54,4.88-25.09,9V403a55.15,55.15,0,0,1-1.79,13.83,24.49,24.49,0,0,1-3.58,6.91,20,20,0,0,1-9.22,4.86,67.89,67.89,0,0,1-14.34,1.54h-20.48l-5.12-23.55Zm202-114.95V401.2a60.15,60.15,0,0,1-1.79,14.59,21,21,0,0,1-6.15,9.22,26.63,26.63,0,0,1-7.93,3.84,78,78,0,0,1-16.64,1.79h-29.19l-4.35-21.25h27.14a35.44,35.44,0,0,0,7.42-.76,18.28,18.28,0,0,0,3.59-1.28,7.79,7.79,0,0,0,2.81-4.1,30.75,30.75,0,0,0,.77-6.91V388.4h-85.76v43h-24.83V291.63Zm-6.91-78.33V277H2963.7V213.3ZM2981.11,330.55h85.76V312.88h-85.76Zm0,37.37h85.76V350.77h-85.76Zm7.17-112.13h70.91V234.55h-70.91Z"
                  style="fill: #fff"
                />
                <path
                  d="M3145.72,394.29a100.14,100.14,0,0,0,10.75-15.87,79.88,79.88,0,0,0,7.68-23.3,219.67,219.67,0,0,0,2-29.95v-73h18.43V330.8a301.75,301.75,0,0,1-1.54,30.72l.26-.25q21.51,24.06,42.75,48.64l-15.87,16.89q-16.65-20.48-33.54-40.7a89.66,89.66,0,0,1-12.54,21.5,243.22,243.22,0,0,1-24.58,26.88l-16.89-17.41A224.12,224.12,0,0,0,3145.72,394.29Zm69.37-176.9v148h-18.68V241.2h-42.24V366.13h-19.46V217.39Zm46.85-16.12q9.46,13,17.67,26.88l-7.94,4.86h22.27q10.24-14.85,19.2-31l19.2,10.24L3319.54,233h32.51v85.25h-126.2V233h30.72l-13.32-21Zm79.36,130.56V432.69h-21V423h-64.77v9.73h-21.25V331.83Zm-96.51-33h34.05V252.72h-34.05Zm15.87-42.24q9,14.85,17.67,29.44l-15.62,9.22q-8.45-14.85-17.15-30Zm-5.12,110.59h64.77v-15.1h-64.77Zm0,35.33h64.77V387.63h-64.77Zm42.75-149.76v32.51q9-13.82,16.9-28.67l15.87,8.71q-8.7,15.36-17.41,30l-15.36-9V298.8h33.54V252.72Z"
                  style="fill: #fff"
                />
                <path
                  d="M4979.47,306.74a321.78,321.78,0,0,0,22.78-40.19l19.46,10.75Q5014,293.17,5005.32,308V418.87a162.89,162.89,0,0,0,11.52-12.8,62.2,62.2,0,0,0,5.38-9.22,59.63,59.63,0,0,0,4.09-13.57,151.58,151.58,0,0,0,1-17.66V338h67.07v40.45q7.68-5.9,15.36-12.29l5.89,19.2q-12.81,9.74-24.58,20a58.22,58.22,0,0,0-9,8.19l-14.33-14.85a15,15,0,0,0,5.12-11v-27.9h-23.56v10.5a122.13,122.13,0,0,1-1.28,17.92,68.34,68.34,0,0,1-4.35,15.36,110.26,110.26,0,0,1-18.43,27.9l-20-11.78V430.9h-22.53V341.3q-7.94,10.5-16.38,19.71L4956.17,337A238,238,0,0,0,4979.47,306.74Zm24.32-100.1,20.48,12.8a413,413,0,0,1-47.88,65.79l-17.66-16.38A330.63,330.63,0,0,0,5003.79,206.64Zm130.3,159.23A227.31,227.31,0,0,1,5118.22,308q-2.56,4.86-5.12,9.21l-9.73-15.36v20h-86.53V300.08h85.51l-2.82-4.61q3.07-5.62,6.14-11.77h-84V224.31h20v38.4h12.8v-57.6h19.46v57.6h12.8v-38.4h19.71v57.85a295.29,295.29,0,0,0,23.55-76l22,2.56a350.55,350.55,0,0,1-8.7,37.38h49.4v23h-10.24a377.28,377.28,0,0,1-9,57.08A210.81,210.81,0,0,1,5160,365.62a202.63,202.63,0,0,0,38.91,48.89l-17.67,19.72a284.54,284.54,0,0,1-34.81-44.8,221.36,221.36,0,0,1-15.87,21.24q-11.28,12.81-23,25.09l-17.67-16.89A213.59,213.59,0,0,0,5134.09,365.87Zm0-92.41a220.89,220.89,0,0,0,13.06,64,297.24,297.24,0,0,0,14.08-68.35h-25.6Z"
                  style="fill: #fff"
                />
                <path
                  d="M5242.63,258.87a369,369,0,0,0,20.23-54.79L5288,210A486.6,486.6,0,0,1,5269,264.24V432.18h-24.83V311.09q-8.44,12.81-17.66,24.58l-14.85-23A312.17,312.17,0,0,0,5242.63,258.87Zm127-56.58q7.17,14.6,13.82,29.18h64.77V255H5283.59V231.47h71.68q-5.37-11.76-11.26-23.29Zm61.44,143.36v86h-25.6V419.89h-85v11.78h-25.6v-86Zm-3.07-74.5V292.4H5297.67V271.15Zm0,37.12V330H5297.67V308.27Zm-107.52,89.09h85V368.18h-85Z"
                  style="fill: #fff"
                />
                <path
                  d="M5589.25,225.84H5651v24.58h-32.77V446.26H5651v24.57h-61.7Z"
                  style="fill: #fff"
                />
                <path
                  d="M5795.33,406.32a25.54,25.54,0,0,0,7.17-.51,11.55,11.55,0,0,0,3.32-1.54,7.56,7.56,0,0,0,2.05-3.84,39,39,0,0,0,.51-6.4V341l-33,11.78-5.12-25.09q18.94-5.62,38.14-12.29V272.18h-33V247.09h33v-42h25.09v42h29.95v25.09h-29.95v34.3q13.83-4.61,27.39-10.24l1.8,13.06,1.79,12.29-31,10.75v70.14a60.15,60.15,0,0,1-1.79,14.59,21,21,0,0,1-3.84,7.17,22.31,22.31,0,0,1-9.73,5.12,82.85,82.85,0,0,1-15.87,1.54H5780l-5.12-24.58ZM5991.42,222V429.11h-26.11V417.59h-108.8V392h108.8V330h-93.44V304.18h93.44V248.11h-98.56V222Z"
                  style="fill: #fff"
                />
                <path
                  d="M6259.71,296.24v28.67H6027.78V296.24Z"
                  style="fill: #fff"
                />
                <path
                  d="M6307.33,406.32a25.54,25.54,0,0,0,7.17-.51,11.55,11.55,0,0,0,3.32-1.54,7.56,7.56,0,0,0,2.05-3.84,39,39,0,0,0,.51-6.4V341l-33,11.78-5.12-25.09q18.94-5.62,38.14-12.29V272.18h-33V247.09h33v-42h25.09v42h29.95v25.09h-29.95v34.3q13.83-4.61,27.39-10.24l1.8,13.06,1.79,12.29-31,10.75v70.14a60.15,60.15,0,0,1-1.79,14.59,21,21,0,0,1-3.84,7.17,22.31,22.31,0,0,1-9.73,5.12,82.85,82.85,0,0,1-15.87,1.54H6292l-5.12-24.58ZM6503.42,222V429.11h-26.11V417.59h-108.8V392h108.8V330h-93.44V304.18h93.44V248.11h-98.56V222Z"
                  style="fill: #fff"
                />
                <path
                  d="M6637.05,250.42V225.84h61.7v245h-61.7V446.26h32.77V250.42Z"
                  style="fill: #fff"
                />
                <path
                  d="M6857.21,406.58a26.78,26.78,0,0,0,6.4-.26,7.53,7.53,0,0,0,3.58-2.05,6.5,6.5,0,0,0,1.54-2.56,26.33,26.33,0,0,0,.76-6.4V343.09l-28.16,10-4.86-24.32q16.38-4.61,33-10.24V271.15H6842.1v-23.8h27.39V204.08h23.81v43.27h27.39v23.8H6893.3v39.43q11.27-3.58,22.53-8.19l1.28,12.54,1.28,10.75q-12.54,4.88-25.09,9V403a55.15,55.15,0,0,1-1.79,13.83,24.49,24.49,0,0,1-3.58,6.91,20,20,0,0,1-9.22,4.86,67.89,67.89,0,0,1-14.34,1.54h-20.48l-5.12-23.55Zm202-114.95V401.2a60.15,60.15,0,0,1-1.79,14.59,21,21,0,0,1-6.15,9.22,26.63,26.63,0,0,1-7.93,3.84,78,78,0,0,1-16.64,1.79h-29.19l-4.35-21.25h27.14a35.44,35.44,0,0,0,7.42-.76,18.28,18.28,0,0,0,3.59-1.28,7.79,7.79,0,0,0,2.81-4.1,30.75,30.75,0,0,0,.77-6.91V388.4h-85.76v43h-24.83V291.63Zm-6.91-78.33V277H6931.7V213.3ZM6949.11,330.55h85.76V312.88h-85.76Zm0,37.37h85.76V350.77h-85.76Zm7.17-112.13h70.91V234.55h-70.91Z"
                  style="fill: #fff"
                />
                <path
                  d="M7113.72,394.29a100.14,100.14,0,0,0,10.75-15.87,79.88,79.88,0,0,0,7.68-23.3,219.67,219.67,0,0,0,2.05-29.95v-73h18.43V330.8a301.75,301.75,0,0,1-1.54,30.72l.26-.25q21.51,24.06,42.75,48.64l-15.87,16.89q-16.65-20.48-33.54-40.7a89.66,89.66,0,0,1-12.54,21.5,243.22,243.22,0,0,1-24.58,26.88l-16.89-17.41A224.12,224.12,0,0,0,7113.72,394.29Zm69.37-176.9v148h-18.68V241.2h-42.24V366.13h-19.46V217.39Zm46.85-16.12q9.47,13,17.67,26.88l-7.94,4.86h22.27q10.25-14.85,19.2-31l19.2,10.24L7287.54,233h32.51v85.25h-126.2V233h30.72l-13.32-21Zm79.36,130.56V432.69h-21V423h-64.77v9.73h-21.25V331.83Zm-96.51-33h34.05V252.72h-34.05Zm15.87-42.24q9,14.85,17.67,29.44l-15.62,9.22q-8.45-14.85-17.15-30Zm-5.12,110.59h64.77v-15.1h-64.77Zm0,35.33h64.77V387.63h-64.77Zm42.75-149.76v32.51q9-13.82,16.9-28.67l15.87,8.71q-8.7,15.36-17.41,30l-15.36-9V298.8h33.54V252.72Z"
                  style="fill: #fff"
                />
              </g>
            </svg>
          </div>
        </template>
      </Dialog>
      <Dialog ref="roleSelector" class="z-index15">
        <template v-slot:view>
          <div class="fc">
            <Radio
              :radio-map="Predefined.RoleMap"
              :radio-border-color="'var(--role-selector-radio-border-Color)'"
              :radio-color="'var(--role-selector-radio-checked-Color)'"
              :radio-text-color="'var(--role-selector-text-Color)'"
              :radio-icon-color="'var(--role-selector-radio-checked-icon-Color)'"
              v-model="roleChosen"
              :onChange="onRoleChange"
              :onClick="onRoleClick"
            />
          </div>
        </template>
      </Dialog>
      <Dialog
        ref="improve"
        class="z-index16"
        :title="'账号信息待完善'"
        :msg="`请完善账号信息~`"
        :show-yes="true"
        :yes-callback="
          () => {
            window.open(
              'https://cas.guet.edu.cn/authserver/improveInfo/improveUserInfo.do'
            );
            this.dialog.improve.dismiss();
          }
        "
        :yes-text="'去完善信息'"
        :show-no="true"
      />
      <Dialog
        ref="insufficient"
        class="z-index17"
        :title="'账号权限不足'"
        :msg="`试试重置密码~`"
        :show-yes="true"
        :yes-callback="
          () => {
            window.open(
              'https://cas.guet.edu.cn/retrieve-password/retrievePassword/index.html'
            );
            this.dialog.insufficient.dismiss();
          }
        "
        :yes-text="'去重置密码'"
        :show-no="true"
      />
      <Dialog
        ref="smsBlock"
        class="z-index18"
        :title="'短信验证'"
        show-yes
        show-no
        :no-text="'取消'"
        :yes-text="'确定'"
        :yes-callback="smsCodeYesCB"
        :no-callback="smsCodeNoCB"
        :cancelable="false"
        :before-dismiss-callback="smsDismissCB"
      >
        <template v-slot:view>
          <div class="w100 h100 fc sign-in">
            <div class="flex-grow-1 fr justify-content-center">
              <div class="sign-in-card flex-shrink-0 flex-grow-1 fc">
                <Form
                  :model-value="smsCodeValue"
                  :submit-callback="smsCodeSubmitCB"
                />
              </div>
            </div>
          </div>
        </template>
      </Dialog>
      <SnackBar v-show="snackBarShown" class="z-index19" :msg="snackBarMsg" />
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import Util from "@/js/util";
import ColorSelector from "@/js/type/ColorSelector";
import Store from "@/js/store";
import Dialog from "@/component/generic/Dialog";
import ProgressBar from "@/component/generic/ProgressBar";
import SnackBar from "@/component/generic/SnackBar";
import Require from "@/js/require";
import Page from "@/js/page";
import Popup from "@/component/generic/Popup";
import Login from "@/js/login";
import Main from "@/main";
import Config from "@/js/config";
import URLCenter from "@/js/url_center";
import NameCenter from "@/js/name_center";
import Tip from "@/js/tip";
import Card from "@/component/day/card/Card";
import ReceiptQRCode from "@/js/receipt_QR_code";
import Radio from "@/component/generic/Radio";
import Predefined from "@/js/predefined";
import Form from "@/component/generic/Form.vue";
import GuetcobHelper from "guetcob-helper";
import Counter from "./js/type/Counter";
import { HTTPMethod, HTTPRequest } from "js/js-http-request";

export default {
  name: "App",
  components: { Form, Radio, Card, Popup, SnackBar, ProgressBar, Dialog },
  data() {
    return {
      window,
      URLCenter,
      NameCenter,
      ReceiptQRCode,
      Util,
      ProgressBar,
      Predefined,
      // 是否已挂载
      isMounted: false,
      // Viewport 宽度
      viewportWidth: 0,
      // Viewport 高度
      viewportHeight: 0,
      // 解析后的数据
      parsedData: null,
      // 自动定位的时间信息，会自动刷新
      nowTime: null,
      // 用户选择的深浅色模式（true：深色 false：浅色 null：跟随系统）
      userSelectedDarkMode: null,
      // 系统的深浅色模式（true：深色 false：浅色 null：不知道）
      systemDarkMode: null,
      // 默认的深浅色模式：浅色
      defaultDarkMode: false,
      // 系统深色监听器
      systemDarkMediaQueryList: null,
      // 系统浅色监听器
      systemLightMediaQueryList: null,
      // 系统无色监听器
      systemNoColorMediaQueryList: null,
      // 时间线的高度
      timelineHeightVH: 0.25,
      // 自动刷新时间的定时器
      timer: null,
      // 自动同步的定时器
      syncTimer: null,
      // 全局的颜色选择器
      colorSelector: new ColorSelector(),
      // 自动刷新时间的间隔，默认为 1 秒，不建议更改
      timeRefreshIntervalMS: 1000,
      // 自动同步的间隔，默认为 15分钟 * 60秒/分钟 = 900秒，不建议更改
      autoSyncIntervalMS: 900000,
      // 页头、页脚的 CSS 变量名称
      themeColorCSSName: "",
      // 是否在教务功能页面中隐藏已经结束的考试安排
      hideEndedExamsInFunctionMenu: true,
      // SnackBar 是否存在
      snackBarShown: false,
      // SnackBar 内容
      snackBarMsg: "",
      // SnackBar 守护者
      snackBarPromise: new Promise((resolve) => resolve()),
      // SnackBar 序列号
      snackBarSeqNum: 0,
      // SnackBar 动画时间（注意：不是存在时间）
      snackBarTransitionDurationMS: 200,
      // SnackBar 两次之间的最短间隔时间
      snackBarMinIntervalMS: 200,
      // 页面进度条是否存在
      pageProgressBarShown: false,
      // 当前选择的学期名称
      selectedTermName: null,
      // 当前选择的周次号
      selectedWeekNo: null,
      // 寒假暑假的学期名称
      vacationTermName: "寒假/暑假",
      // 寒假暑假的周次
      vacationWeekNo: "无",
      // 数据同步失败对话框中的提示消息
      syncErrorMSGSuffix: null,
      // 正在同步中的用户名
      syncingUsername: null,
      // 同步剩余时间
      syncTimeLeft: null,
      // 同步倒计时器
      syncCounter: new Counter(Config.loginAndFetchDurationS, (value) => {
        this.syncTimeLeft = value;
      }),
      // 需要展示的日程列表
      singleEventListToShow: null,
      // 需要展示的收款码
      receiptQRCode: null,
      // 快速登入失败的原因
      quickLoginFailReason: null,
      // 快速登入失败后可以打开的未登录页面
      quickLoginFailURL: null,
      // 校园网状态下打开的页面
      lanURL: null,
      // 非校园网状态下打开的页面
      wanURL: null,
      // 用户选中的身份（不一定是当前用户的真实身份）
      roleChosen: Predefined.Role.Student,
      // 页面指定的的物理机返回按钮回调
      onHostBackPressed: null,
      // 短信验证码输入完成回调
      smsCodeYesCB: null,
      // 短信验证码输入回车回调
      smsCodeSubmitCB: null,
      // 短信验证码输入取消回调
      smsCodeNoCB: null,
      // 短信验证码弹框消失回调
      smsDismissCB: null,
      // 短信验证码输入表单
      smsCodeValue: null,
      // 课表交流群
      guetCourseTableQQGroup: {
        Number: "398278088",
      },
    };
  },
  watch: {
    parsedData() {
      if (this.parsedData) {
        // 深色模式
        switch (this.parsedData.userPreferredColorMode) {
          default:
          case undefined:
          case null:
          case Predefined.UserPreferredColorMode.System:
            {
              this.userSelectedDarkMode = null;
            }
            break;
          case Predefined.UserPreferredColorMode.Dark:
            {
              this.userSelectedDarkMode = true;
            }
            break;
          case Predefined.UserPreferredColorMode.Light:
            {
              this.userSelectedDarkMode = false;
            }
            break;
        }
        // 用户身份
        this.roleChosen = this.parsedData.role;
      } else {
        // 深色模式
        this.userSelectedDarkMode = null;
        // 用户身份
        this.roleChosen = null;
      }
    },
    selectedWeekNo(newWeekNo) {
      if (this.parsedData && this.nowTime) {
        if (newWeekNo != null) {
          if (newWeekNo === this.vacationWeekNo) {
            this.selectedTermName = this.vacationTermName;
          } else {
            if (this.selectedTermName == null) {
              if (this.nowTerm) {
                this.selectedTermName = this.nowTerm.termName;
              } else {
                this.selectedTermName = this.vacationTermName;
              }
            }
          }
        }
      }
    },
    selectedTermName(newTermName) {
      if (this.parsedData && this.nowTime) {
        if (newTermName != null) {
          if (newTermName === this.vacationTermName) {
            this.selectedWeekNo = this.vacationWeekNo;
          } else {
            if (this.selectedWeekNo == null) {
              if (this.nowWeekNo == null) {
                this.selectedWeekNo = this.vacationWeekNo;
                return;
              } else {
                this.selectedWeekNo = this.nowWeekNo;
              }
            }
            const st = Util.findTermWithTermName(
              this.parsedData.termList,
              newTermName
            );
            // [1, 总周数]
            this.selectedWeekNo = Math.min(
              st.weekNum,
              Math.max(1, Require.number(this.selectedWeekNo))
            );
          }
        }
      }
    },
  },
  computed: {
    isCurrentWeek() {
      const st = this.finallyUsedTime;
      const nt = this.nowTime;
      if (st.currentTerm !== nt.currentTerm) {
        return false;
      } else if (st.currentTerm && nt.currentTerm && st.weekNo !== nt.weekNo) {
        return false;
      }
      return true;
    },
    nowTerm() {
      if (this.nowTime) {
        return this.nowTime.currentTerm;
      } else {
        return null;
      }
    },
    nowWeekNo() {
      if (this.nowTime && this.nowTime.currentTerm) {
        return this.nowTime.weekNo;
      } else {
        return null;
      }
    },
    selectedNano() {
      if (this.parsedData && this.nowTime) {
        if (
          this.selectedWeekNo != null &&
          this.selectedWeekNo !== this.vacationWeekNo &&
          this.selectedTermName != null &&
          this.selectedTermName !== this.vacationTermName
        ) {
          return (
            Util.calDayNanoOfWeekNoAndWeekday(
              Util.findTermWithTermName(
                this.parsedData.termList,
                this.selectedTermName
              ),
              Require.number(this.selectedWeekNo),
              Util.chineseWeekday(this.nowTime.now.getDay())
            ) + Util.calNanoOffsetWithinDay(this.nowTime.nanoNow)
          );
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    finallyUsedTime() {
      if (this.parsedData && this.nowTime) {
        if (this.selectedNano == null) {
          return this.nowTime;
        } else {
          return Util.nowTimeInfo(
            this.parsedData.termList,
            new Date(Util.ntom(this.selectedNano))
          );
        }
      } else {
        return null;
      }
    },
    termColumn() {
      const res = [];
      if (this.parsedData && this.nowTime) {
        for (const term of this.parsedData.termList) {
          res.push(Require.string(term.termName));
        }
        res.push(Require.string(this.vacationTermName));
      }
      return res;
    },
    calTermIndex() {
      if (this.selectedTermName) {
        return this.termColumn.findIndex((tn) => tn === this.selectedTermName);
      } else {
        const currentTermName = this.nowTerm
          ? this.nowTerm.termName
          : this.vacationTermName;
        return this.termColumn.findIndex((tn) => tn === currentTermName);
      }
    },
    weekColumn() {
      const res = [];
      if (this.parsedData && this.nowTime) {
        const currentTermName = this.termColumn[this.calTermIndex];
        const currentTerm = Util.findTermWithTermName(
          this.parsedData.termList,
          currentTermName
        );
        if (currentTerm) {
          // [1, 总周数]
          for (let i = 1; i <= currentTerm.weekNum; i++) {
            res.push(Require.string(i));
          }
        } else {
          res.push(Require.string(this.vacationWeekNo));
        }
      }
      return res;
    },
    calWeekIndex() {
      if (this.selectedWeekNo != null) {
        return this.weekColumn.findIndex(
          (wnoStr) => wnoStr === Require.string(this.selectedWeekNo)
        );
      } else {
        let currentWeekNo;
        if (this.nowWeekNo != null) {
          // [1, 总周数]
          currentWeekNo = Math.min(
            this.nowTerm.weekNum,
            Math.max(1, this.nowWeekNo)
          );
        } else {
          currentWeekNo = this.vacationWeekNo;
        }
        return this.weekColumn.findIndex(
          (wnoStr) => wnoStr === Require.string(currentWeekNo)
        );
      }
    },
    shouldWearMask() {
      return (
        ((this.$refs.sf && this.$refs.sf.isVisible) ||
          (this.$refs.ss && this.$refs.ss.isVisible) ||
          (this.$refs.si && this.$refs.si.isVisible) ||
          this.pageProgressBarShown) &&
        (!this.$refs.smsBlock || !this.$refs.smsBlock.isVisible)
      );
    },
    keydown() {
      return this.shouldWearMask ? "keydown" : null;
    },
    keyup() {
      return this.shouldWearMask ? "keyup" : null;
    },
    mousedown() {
      return this.shouldWearMask ? "mousedown" : null;
    },
    mouseup() {
      return this.shouldWearMask ? "mouseup" : null;
    },
    click() {
      return this.shouldWearMask ? "click" : null;
    },
    input() {
      return this.shouldWearMask ? "input" : null;
    },
    textInput() {
      return this.shouldWearMask ? "textInput" : null;
    },
    change() {
      return this.shouldWearMask ? "change" : null;
    },
    color() {
      const colorSet = this.isMounted
        ? {
            ProgressBar: {
              Loading: window
                .getComputedStyle(this.$refs.main)
                .getPropertyValue("--progress-bar-LoadingColor"),
              Success: window
                .getComputedStyle(this.$refs.main)
                .getPropertyValue("--progress-bar-SuccessColor"),
              Fail: window
                .getComputedStyle(this.$refs.main)
                .getPropertyValue("--progress-bar-FailColor"),
              Warning: window
                .getComputedStyle(this.$refs.main)
                .getPropertyValue("--progress-bar-WarningColor"),
              Background: window
                .getComputedStyle(this.$refs.main)
                .getPropertyValue("--progress-bar-BackgroundColor"),
            },
          }
        : {
            ProgressBar: {
              Loading: "#00000000",
              Success: "#00000000",
              Fail: "#00000000",
              Warning: "#00000000",
              Background: "#00000000",
            },
          };
      // 【重要】这个看似无用的语句建立了 darkMode 和颜色值之间的关联性，这样在切换深浅色模式的时候会重新计算颜色值
      return this.darkMode ? colorSet : colorSet;
    },
    number() {
      return {
        // 动态计算的 vh 值（单位是 px）
        VH: this.viewportHeight / 100,
        // 动态计算的 vw 值（单位是 px）
        VW: this.viewportWidth / 100,
        VMIN: Math.min(this.viewportHeight, this.viewportWidth) / 100,
        VMAX: Math.max(this.viewportHeight, this.viewportWidth) / 100,
      };
    },
    darkMode() {
      return this.userSelectedDarkMode == null
        ? this.systemDarkMode == null
          ? this.defaultDarkMode
          : this.systemDarkMode
        : this.userSelectedDarkMode;
    },
    logoutWho() {
      if (this.parsedData) {
        return this.parsedData.username;
      } else {
        return null;
      }
    },
    // 是否是国际学院
    isInternational() {
      return this.parsedData == null ? false : this.parsedData.isInternational;
    },
    isCurrentUserSyncing() {
      return (
        this.parsedData && this.parsedData.username === this.syncingUsername
      );
    },
    lastSyncText() {
      let des = "过去";
      if (this.parsedData && this.nowTime) {
        des = Util.pastTimeDescription(
          this.nowTime.nanoNow - this.parsedData.lastUpdateUnixNanoTimestamp
        );
      }
      return des + "同步";
    },
    syncCountDownText() {
      return this.syncTimeLeft == null
        ? ""
        : this.syncTimeLeft.toLocaleString("fullwide", {
            maximumFractionDigits: 20,
          });
    },
    dialog() {
      return {
        smsBlock: this.$refs.smsBlock,
        insufficient: this.$refs.insufficient,
        improve: this.$refs.improve,
        roleSelector: this.$refs.roleSelector,
        sponsor: this.$refs.sponsor,
        contact: this.$refs.contact,
        help: this.$refs.help,
        logout: this.$refs.logout,
        askForNetwork: this.$refs.askForNetwork,
        quickLoginFail: this.$refs.quickLoginFail,
        quickLoginSuccess: this.$refs.quickLoginSuccess,
        quickLogin: this.$refs.quickLogin,
        sf: this.$refs.sf,
        ss: this.$refs.ss,
        si: this.$refs.s,
      };
    },
    popup() {
      return {
        wcd: this.$refs.wcd,
        tw: this.$refs.tw,
      };
    },
  },
  provide() {
    return {
      gGUETCourseTableQQGroup: computed(() => this.guetCourseTableQQGroup),
      gParsedData: computed(() => this.parsedData),
      gSelectedNano: computed(() => this.selectedNano),
      gNowTime: computed(() => this.nowTime),
      gUserSelectedDarkMode: computed(() => this.userSelectedDarkMode),
      sUserSelectedDarkMode: async (userSelectedDarkMode) => {
        this.userSelectedDarkMode = userSelectedDarkMode;
        if (this.parsedData) {
          return Store.setUserPreferredColorMode(
            this.parsedData.username,
            userSelectedDarkMode
          );
        }
      },
      gTimelineHeightVH: computed(() => this.timelineHeightVH),
      gColorSelector: computed(() => this.colorSelector),
      gColor: computed(() => this.color),
      gNumber: computed(() => this.number),
      sThemeColorCSSName: (cssName) => {
        this.themeColorCSSName = cssName;
        this.autoSetBrowserColor();
      },
      gHideEndedExamsInFunctionMenu: computed(
        () => this.hideEndedExamsInFunctionMenu
      ),
      sHideEndedExamsInFunctionMenu: (hide) => {
        this.hideEndedExamsInFunctionMenu = hide;
      },
      gDialog: computed(() => this.dialog),
      gPopup: computed(() => this.popup),
      gIsInternational: computed(() => this.isInternational),
      gDarkMode: computed(() => this.darkMode),
      showSnackBar: (msg, duration) => this.showSnackBar(msg, duration),
      gPageProgressBarShown: computed(() => this.pageProgressBarShown),
      sPageProgressBarShown: (shown) => {
        this.pageProgressBarShown = shown;
      },
      refreshData: async () => this.refreshData(),
      gFinallyUsedTime: computed(() => this.finallyUsedTime),
      gotoNextWeek: () => this.gotoNextWeek(),
      gotoLastWeek: () => this.gotoLastWeek(),
      returnToday: () => this.returnToday(),
      gIsCurrentWeek: computed(() => this.isCurrentWeek),
      gLastSyncText: computed(() => this.lastSyncText),
      sync: () => this.sync(),
      gIsCurrentUserSyncing: computed(() => this.isCurrentUserSyncing),
      gSyncCountdownText: computed(() => this.syncCountDownText),
      onBackPressed: () => this.onBackPressed(),
      sSingleEventListToShow: (sel) => {
        this.singleEventListToShow = sel;
      },
      sReceiptQRCode: (code) => {
        this.receiptQRCode = code;
      },
      sQuickLoginFailReason: (reason) => {
        this.quickLoginFailReason = reason;
      },
      sQuickLoginFailURL: (url) => {
        this.quickLoginFailURL = url;
      },
      sLanURL: (url) => {
        this.lanURL = url;
      },
      sWanURL: (url) => {
        this.wanURL = url;
      },
      gRoleChosen: computed(() => this.roleChosen),
      resetRole: this.resetRole,
      callLogout: () => this.callLogout(),
      sOnHostBackPressed: (f) => {
        this.onHostBackPressed = f;
      },
      askForSMSCode: async (sid, phone, times, counter) => {
        return this.askForSMSCode(sid, phone, times, counter);
      },
    };
  },
  methods: {
    resetRole() {
      if (this.parsedData) {
        this.roleChosen = this.parsedData.role;
      }
    },
    onBackPressed() {
      this.$router.go(-1);
    },
    async refreshData() {
      await this.locateTime(true);
      return Main.refreshRouter();
    },
    updateParsedData(data) {
      this.parsedData = data;
      if (this.parsedData == null) {
        return;
      }
      this.nowTime = Util.nowTimeInfo(this.parsedData.termList);
    },
    async locateTime(force = false) {
      if ((await Store.getActiveUserAndHisData()) == null) {
        if (Page.mustLogin(this.$route.path)) {
          this.$router
            .push(Page.loginPage().path)
            .then(() => (this.parsedData = null));
        }
        return;
      } else if (this.parsedData == null || force) {
        this.parsedData = await Store.parseData();
      }
      this.updateParsedData(this.parsedData);
    },
    /**
     * 自动设置页面大小
     */
    autoSetBodySize() {
      const ww = document.documentElement.clientWidth;
      const wh = document.documentElement.clientHeight;
      document.documentElement.style.setProperty("width", `${ww}px`);
      document.documentElement.style.setProperty("--vw", `${ww / 100}px`);
      this.viewportWidth = ww;
      document.documentElement.style.setProperty("height", `${wh}px`);
      document.documentElement.style.setProperty("--vh", `${wh / 100}px`);
      this.viewportHeight = wh;
      document.documentElement.style.setProperty(
        "--vmin",
        `${Math.min(ww, wh) / 100}px`
      );
      document.documentElement.style.setProperty(
        "--vmax",
        `${Math.max(ww, wh) / 100}px`
      );
    },
    /**
     * 根据 data 中 themeColorCSSName，获取 main 节点中对应名称的 CSS 变量值（即实际颜色值），并设置为浏览器颜色。
     */
    autoSetBrowserColor() {
      let color = window
        .getComputedStyle(this.$refs.main)
        .getPropertyValue(this.themeColorCSSName)
        .trim();
      document
        .querySelector('meta[name="theme-color"]')
        .setAttribute("content", color);
      document.body.style.backgroundColor = color;
      const Android = Util.Android();
      if (Android != null) {
        Android.setThemeColor(color);
      }
      // eslint-disable-next-line no-undef
      if (
        typeof webkit !== "undefined" &&
        // eslint-disable-next-line no-undef
        webkit != null &&
        // eslint-disable-next-line no-undef
        webkit.messageHandlers &&
        // eslint-disable-next-line no-undef
        webkit.messageHandlers.colorTheme &&
        // eslint-disable-next-line no-undef
        webkit.messageHandlers.colorTheme.postMessage
      ) {
        // eslint-disable-next-line no-undef
        webkit.messageHandlers.colorTheme.postMessage(color);
      }
    },
    /**
     * 向上传递 CSS 配置
     */
    passCSSUp() {
      // document.documentElement.style.setProperty('', window.getComputedStyle(this.$refs.main).getPropertyValue(''))
      document.documentElement.style.setProperty(
        "--message-success-background-color",
        window
          .getComputedStyle(this.$refs.main)
          .getPropertyValue("--message-success-background-color")
      );
      document.documentElement.style.setProperty(
        "--message-success-text-color",
        window
          .getComputedStyle(this.$refs.main)
          .getPropertyValue("--message-success-text-color")
      );
      document.documentElement.style.setProperty(
        "--message-success-border-color",
        window
          .getComputedStyle(this.$refs.main)
          .getPropertyValue("--message-success-border-color")
      );
      document.documentElement.style.setProperty(
        "--message-success-icon-color",
        window
          .getComputedStyle(this.$refs.main)
          .getPropertyValue("--message-success-icon-color")
      );
      document.documentElement.style.setProperty(
        "--message-fail-background-color",
        window
          .getComputedStyle(this.$refs.main)
          .getPropertyValue("--message-fail-background-color")
      );
      document.documentElement.style.setProperty(
        "--message-fail-text-color",
        window
          .getComputedStyle(this.$refs.main)
          .getPropertyValue("--message-fail-text-color")
      );
      document.documentElement.style.setProperty(
        "--message-fail-border-color",
        window
          .getComputedStyle(this.$refs.main)
          .getPropertyValue("--message-fail-border-color")
      );
      document.documentElement.style.setProperty(
        "--message-fail-icon-color",
        window
          .getComputedStyle(this.$refs.main)
          .getPropertyValue("--message-fail-icon-color")
      );
      document.documentElement.style.setProperty(
        "--message-warning-background-color",
        window
          .getComputedStyle(this.$refs.main)
          .getPropertyValue("--message-warning-background-color")
      );
      document.documentElement.style.setProperty(
        "--message-warning-text-color",
        window
          .getComputedStyle(this.$refs.main)
          .getPropertyValue("--message-warning-text-color")
      );
      document.documentElement.style.setProperty(
        "--message-warning-border-color",
        window
          .getComputedStyle(this.$refs.main)
          .getPropertyValue("--message-warning-border-color")
      );
      document.documentElement.style.setProperty(
        "--message-warning-icon-color",
        window
          .getComputedStyle(this.$refs.main)
          .getPropertyValue("--message-warning-icon-color")
      );
      document.documentElement.style.setProperty(
        "--message-info-background-color",
        window
          .getComputedStyle(this.$refs.main)
          .getPropertyValue("--message-info-background-color")
      );
      document.documentElement.style.setProperty(
        "--message-info-text-color",
        window
          .getComputedStyle(this.$refs.main)
          .getPropertyValue("--message-info-text-color")
      );
      document.documentElement.style.setProperty(
        "--message-info-border-color",
        window
          .getComputedStyle(this.$refs.main)
          .getPropertyValue("--message-info-border-color")
      );
      document.documentElement.style.setProperty(
        "--message-info-icon-color",
        window
          .getComputedStyle(this.$refs.main)
          .getPropertyValue("--message-info-icon-color")
      );
      document.documentElement.style.setProperty(
        "--snackbar-TransitionDuration",
        this.snackBarTransitionDurationMS + "ms"
      );
    },
    showSnackBar(msg, durationMS = 2000) {
      const msgStr = Require.string(msg);
      this.snackBarSeqNum++;
      const dur = durationMS;
      this.snackBarPromise = this.snackBarPromise.then(() => {
        this.snackBarMsg = msgStr;
        this.snackBarShown = true;
        return (async () => {
          await new Promise((resolve) =>
            setTimeout(resolve, this.snackBarTransitionDurationMS)
          );
          await new Promise((resolve) => setTimeout(resolve, dur));
          this.snackBarShown = false;
          await new Promise((resolve) =>
            setTimeout(resolve, this.snackBarTransitionDurationMS)
          );
          await new Promise((resolve) =>
            setTimeout(resolve, this.snackBarMinIntervalMS)
          );
        })();
      });
    },
    onTermChange(t) {
      if (t != null) {
        this.selectedTermName = t;
      }
    },
    onWeekChange(w) {
      if (w != null) {
        this.selectedWeekNo = w;
      }
    },
    gotoNextWeek() {
      if (this.calWeekIndex < this.weekColumn.length - 1) {
        this.selectedWeekNo = this.weekColumn[this.calWeekIndex + 1];
      }
    },
    gotoLastWeek() {
      if (this.calWeekIndex > 0) {
        this.selectedWeekNo = this.weekColumn[this.calWeekIndex - 1];
      }
    },
    returnToday() {
      this.selectedWeekNo = null;
      this.selectedTermName = null;
    },
    async askForSMSCode(sid, phone, times, counter) {
      return new Promise((resolve) => {
        const judgeMsg =
          times > 1 ? "短信验证码不对，请重新输入" : "请输入短信验证码";
        const commonStyle = {
          hideBorder: true,
          class: "sign-in-input",
          textColor: "var(--sign-in-input-TextColor)",
          activeBorderColor: "var(--sign-in-input-TextColor)",
          inactiveBorderColor: "var(--sign-in-input-TextColor)",
        };
        this.smsCodeValue = new Map([
          [
            sid,
            {
              active: true,
              readonly: true,
              ...commonStyle,
            },
          ],
          [
            phone,
            {
              active: true,
              readonly: true,
              ...commonStyle,
            },
          ],
          [
            judgeMsg,
            {
              active: true,
              ...commonStyle,
            },
          ],
        ]);
        this.smsCodeYesCB = () => {
          const smsValue = Array.from(this.smsCodeValue.values())[2].value;
          if (!smsValue) {
            this.showSnackBar("输入的短信验证码不能为空！");
            return true;
          }
          resolve(smsValue);
        };
        this.smsCodeSubmitCB = () => {
          if (!this.smsCodeYesCB()) {
            this.dialog.smsBlock.dismiss();
          }
        };
        this.smsCodeNoCB = () => resolve();
        this.smsDismissCB = () => {
          counter.resume();
        };
        this.dialog.smsBlock.show();
        counter.pause();
      });
    },
    sync() {
      if (this.parsedData && !this.isCurrentUserSyncing) {
        this.syncingUsername = this.parsedData.username;
        this.syncCounter.start();
        this.$refs.si.show();
        const username = this.parsedData.username;
        const vpnP = this.parsedData.vpnPassword;
        const aawP = this.parsedData.aawPassword;
        const i18n = this.parsedData.isInternational;
        Login.login(username, vpnP, aawP, i18n)
          .then(async (res) => {
            let deepTime = 0;
            while (res instanceof GuetcobHelper.SessionBlocker) {
              switch (res.Blocker.Type) {
                case GuetcobHelper.BlockerTypes.SMSCode:
                  res = await res.Resolve(
                    await this.askForSMSCode(
                      username,
                      res.Blocker.Challenge,
                      ++deepTime,
                      this.syncCounter
                    )
                  );
                  break;
                case GuetcobHelper.BlockerTypes.ConfirmToSendSMSCode:
                  res = await res.Resolve("1");
                  break;
                default:
                  throw new GuetcobHelper.Errors.ErrorInternalServerError(
                    "未知的 Blocker Type"
                  );
              }
            }
            return res;
          })
          .then(
            async (res) => {
              if (this.parsedData) {
                if (
                  username === this.parsedData.username &&
                  res.lastUpdateUnixNanoTimestamp >
                    this.parsedData.lastUpdateUnixNanoTimestamp
                ) {
                  await Store.backup();
                  await Store.loginStore(username, vpnP, aawP, true, res);
                  return this.refreshData();
                }
              }
            },
            (e) => {
              throw e;
            }
          )
          .then(
            () => {
              this.$refs.ss.show();
              new Promise((resolve) => setTimeout(resolve, 1500)).then(() => {
                this.$refs.ss.dismiss();
              });
            },
            async (e) => {
              if (Util.empty(e.tip)) {
                await Store.restore();
                await this.refreshData();
                e.tip = Tip.ErrorParseData;
              }
              this.syncErrorMSGSuffix = e.tip;
              this.$refs.sf.show();
              new Promise((resolve) => setTimeout(resolve, 1500)).then(() => {
                this.$refs.sf.dismiss();
                this.syncErrorMSGSuffix = null;
              });
            }
          )
          .finally(() => {
            this.syncingUsername = null;
            this.$refs.si.dismiss();
            this.syncCounter.stop();
          });
      }
    },
    autoSync() {
      if (this.parsedData && !this.isCurrentUserSyncing) {
        this.syncingUsername = this.parsedData.username;
        this.syncCounter.start();
        const username = this.parsedData.username;
        const vpnP = this.parsedData.vpnPassword;
        const aawP = this.parsedData.aawPassword;
        const i18n = this.parsedData.isInternational;
        Login.login(username, vpnP, aawP, i18n)
          .then(async (res) => {
            while (res instanceof GuetcobHelper.SessionBlocker) {
              switch (res.Blocker.Type) {
                case GuetcobHelper.BlockerTypes.SMSCode:
                  throw new GuetcobHelper.Errors.ErrorNotImplemented();
                case GuetcobHelper.BlockerTypes.ConfirmToSendSMSCode:
                  res = await res.Resolve();
                  break;
                default:
                  throw new GuetcobHelper.Errors.ErrorInternalServerError(
                    "未知的 Blocker Type"
                  );
              }
            }
            return res;
          })
          .then(
            async (res) => {
              if (this.parsedData) {
                if (
                  username === this.parsedData.username &&
                  res.lastUpdateUnixNanoTimestamp >
                    this.parsedData.lastUpdateUnixNanoTimestamp
                ) {
                  await Store.backup();
                  await Store.loginStore(username, vpnP, aawP, true, res);
                  return this.refreshData();
                }
              }
            },
            (e) => {
              throw e;
            }
          )
          .then(
            (res) => res,
            async (e) => {
              if (Util.empty(e.tip)) {
                await Store.restore();
                return this.refreshData();
              }
            }
          )
          .finally(() => {
            this.syncingUsername = null;
            this.syncCounter.stop();
          });
      }
    },
    logout() {
      (async () => {
        const who = this.logoutWho;
        if (who) {
          await Store.logout(who);
        }
        window.location.reload();
      })();
    },
    onRoleChange() {
      this.$refs.roleSelector.dismiss();
    },
    onRoleClick() {
      this.$refs.roleSelector.dismiss();
    },
    callLogout() {
      if (this.logoutWho) {
        this.dialog.logout.show();
      }
    },
    async refreshQQGroup() {
      const jsonRes = await new HTTPRequest()
        .SetMethod(HTTPMethod.GET)
        .SetURL(URLCenter.GUETCourseTableQQGroupInfo)
        .JSON();
      this.guetCourseTableQQGroup.Number = jsonRes.Result.groupNumber;
    },
  },
  mounted() {
    this.isMounted = true;
    this.autoSetBodySize();
    window.addEventListener("resize", this.autoSetBodySize);
    window.addEventListener("orientationchange", this.autoSetBodySize);
    if (screen && screen.orientation) {
      screen.orientation.addEventListener("change", this.autoSetBodySize);
    }
    // 挂载系统颜色监听器
    if (window.matchMedia) {
      // 只有支持此方法，才能监听，否则啥也做不了
      this.systemDarkMediaQueryList = window.matchMedia(
        "(prefers-color-scheme: dark)"
      );
      this.systemDarkMediaQueryList.onchange = (mql) => {
        if (mql.matches) {
          this.systemDarkMode = true;
        }
      };
      if (this.systemDarkMediaQueryList.matches) {
        this.systemDarkMode = true;
      }
      this.systemLightMediaQueryList = window.matchMedia(
        "(prefers-color-scheme: light)"
      );
      this.systemLightMediaQueryList.onchange = (mql) => {
        if (mql.matches) {
          this.systemDarkMode = false;
        }
      };
      if (this.systemLightMediaQueryList.matches) {
        this.systemDarkMode = false;
      }
      this.systemNoColorMediaQueryList = window.matchMedia(
        "(prefers-color-scheme: no-preference)"
      );
      this.systemNoColorMediaQueryList.onchange = (mql) => {
        if (mql.matches) {
          this.systemDarkMode = null;
        }
      };
      if (this.systemNoColorMediaQueryList.matches) {
        this.systemDarkMode = null;
      }
    }
    this.passCSSUp();
  },
  unmounted() {
    this.isMounted = false;
    window.removeEventListener("resize", this.autoSetBodySize);
    window.removeEventListener("orientationchange", this.autoSetBodySize);
    if (screen && screen.orientation) {
      screen.orientation.removeEventListener("change", this.autoSetBodySize);
    }
    window.clearInterval(this.timer);
    window.clearInterval(this.syncTimer);
    if (this.systemDarkMediaQueryList != null) {
      this.systemDarkMediaQueryList.onchange = null;
    }
    if (this.systemLightMediaQueryList != null) {
      this.systemLightMediaQueryList.onchange = null;
    }
    if (this.systemNoColorMediaQueryList != null) {
      this.systemNoColorMediaQueryList.onchange = null;
    }
    delete global.guetcob_onBackPressed;
  },
  beforeCreate() {
    {
      (async () => {
        const lastLoadMS = await Store.lastLoadTimestampMS();
        const nowMS = Date.now();
        if (
          !(
            lastLoadMS != null &&
            lastLoadMS <= nowMS &&
            nowMS - lastLoadMS <= 10000
          )
        ) {
          await Store.load();
          window.location.reload();
          return;
        }
        window.addEventListener("beforeunload", Store.leave);
      })();
    }
  },
  created() {
    //
    this.updateParsedData(Main.data);
    //
    this.timer = window.setInterval(
      this.locateTime,
      this.timeRefreshIntervalMS
    );
    //
    window.setTimeout(this.autoSync, 2000);
    this.syncTimer = window.setInterval(this.autoSync, this.autoSyncIntervalMS);
    //
    global.guetcob_onBackPressed = () => {
      for (const dk in this.dialog) {
        const d = this.dialog[dk];
        if (d && d.isVisible) {
          d.onCancel();
          return true;
        }
      }
      for (const pk in this.popup) {
        const p = this.popup[pk];
        if (p && p.isVisible()) {
          p.hide();
          return true;
        }
      }
      const m = this.onHostBackPressed;
      if (typeof m === "function") {
        try {
          return m();
        } catch (e) {
          return false;
        }
      } else {
        return false;
      }
    };
    //
    this.refreshQQGroup();
  },
  updated() {
    // 自动设置浏览器颜色
    this.autoSetBrowserColor();
    this.passCSSUp();
  },
};
</script>
