<template>
  <div class="day-config day fc w100 h100">
    <DayHead
      class="flex-shrink-0"
      :nowDay="nowDay"
      :nowWeek="!isToday && nowWeekNo === 1 ? nowWeek + 1 : nowWeek"
      :nowWeekNo="nowWeekNo"
      @changeDay="changeDay"
      :isToday="isToday"
      :eventNum="dayEvent.length"
      :isCurrentWeek="gIsCurrentWeek"
    />
    <div
      class="flex-shrink-0 course-has-end unselectable-text"
      @click="changeCardStatus"
      v-if="hasPassEventNum > 0"
    >
      {{ hasPassEventNum }}项日程已结束，点击{{ cardStatus }}
    </div>
    <div class="flex-grow-1">
      <Card
        v-for="(singleEvent, index) in dayEvent"
        :key="singleEvent.id"
        :singleEvent="singleEvent || {}"
        v-show="cardStatus === '收起' || index >= hasPassEventNum"
        :class="{
          'day-card-bottom-line': index < dayEvent.length - 1,
          mask: index < hasPassEventNum,
        }"
      />

      <div
        class="day-course-end fc"
        style="
          font-size: var(--day-course-end-text-size);
          color: var(--day-course-end-text-color);
        "
        v-if="Util.isEmptyArray(dayEvent)"
      >
        <mascot :text="emptyText" />
      </div>
    </div>
    <div id="foot" class="flex-shrink-0 day-tab-bar-wrapper unselectable-text">
      <van-tabbar
        v-model="tabIndex"
        :fixed="false"
        :border="false"
        active-color="var(--day-tab-bar-ActiveTextColor)"
        inactive-color="var(--day-tab-bar-InactiveTextColor)"
        class="day-tab-bar"
      >
        <van-tabbar-item replace :to="Page.Day.path">{{
          Page.Day.name
        }}</van-tabbar-item>
        <van-tabbar-item replace :to="Page.Week.path">{{
          Page.Week.name
        }}</van-tabbar-item>
        <van-tabbar-item replace :to="Page.More.path">{{
          Page.More.name
        }}</van-tabbar-item>
      </van-tabbar>
    </div>
  </div>
</template>

<script>
import { computed, inject, ref } from "vue";
import Card from "./card/Card.vue";
import Util from "@/js/util";
import Page from "@/js/page";
import DayHead from "@/component/day/day_head/DayHead";
import Mascot from "@/component/day/mascot/mascot";

export default {
  name: "Day",
  components: { Card, DayHead, Mascot },
  data() {
    return {
      Util,
      tabIndex: 0,
    };
  },
  computed: {
    emptyText() {
      return (this.isToday ? "今" : "明") + "日日程已全部完成";
    },
  },
  watch: {
    jin(newValue, oldValue) {
      let timeGap = Math.abs(Date.parse(newValue) - Date.parse(oldValue));
      // 当日期发生变化时，修改今天、明天的值（每秒钟调用一次！）
      if (
        (this.isToday &&
          JSON.stringify(this.tomorrow) === JSON.stringify(this.jin)) ||
        (!this.isToday &&
          JSON.stringify(this.today) === JSON.stringify(this.jin))
      ) {
        this.today = this.jin;
        this.tomorrow = this.min;
        this.isToday = true;
      } else if (!this.gIsCurrentWeek && timeGap > 86400000) {
        this.today = this.jin;
        this.tomorrow = this.min;
        this.isToday = true;
      } else if (this.gIsCurrentWeek && timeGap > 86400000) {
        this.today = this.jin;
        this.tomorrow = this.min;
        this.isToday = true;
      }
    },
  },
  setup() {
    let gParsedData = inject("gParsedData");
    let gFinallyUsedTime = inject("gFinallyUsedTime");
    let gIsCurrentWeek = inject("gIsCurrentWeek");

    let isToday = ref(true);
    // jin、min用于计算今天或明天的值是否发生变化
    let jin = computed(() => gFinallyUsedTime.value.today);
    let min = computed(() => gFinallyUsedTime.value.tomorrow);
    // today、tomorrow用于存储今天、明天的标准时间，当点击切换今/名时，改变的是这两个的值
    let today = ref(gFinallyUsedTime.value.today);
    let tomorrow = ref(gFinallyUsedTime.value.tomorrow);
    // 今天、第几周、周几的时间
    let nowDay = computed(() => Util.yearMonthDay(today.value));
    let nowWeek = computed(() => gFinallyUsedTime.value.weekNo);
    let nowWeekNo = computed(() => today.value.getDay());
    // 今天的开始、结束的时间戳
    let start = computed(() => Util.parseTimeToMs(today.value));
    let end = computed(() => Util.parseTimeToMs(tomorrow.value));
    // 当天的所有事件
    const dayEvent = computed(() => {
      const dayEvent = [];
      gParsedData.value.singleEventList.forEach((item) => {
        if (
          Util.ntom(item.nanoTime.start) >= start.value &&
          Util.ntom(item.nanoTime.end) <= end.value
        ) {
          dayEvent.push(item);
        }
      });
      dayEvent.sort((a, b) => {
        if (a.nanoTime.start !== b.nanoTime.start) {
          return a.nanoTime.start - b.nanoTime.start;
        } else {
          return a.nanoTime.end - b.nanoTime.end;
        }
      });
      return dayEvent;
    });

    let hasPassEventNum = computed(() => {
      let cnt = 0;
      dayEvent.value.forEach((item) => {
        if (item.nanoTime.end <= gFinallyUsedTime.value.nanoNow) {
          cnt++;
        }
      });
      return cnt;
    });

    let cardStatus = ref("查看");

    function changeCardStatus() {
      cardStatus.value = cardStatus.value === "收起" ? "查看" : "收起";
    }

    function changeDay(value) {
      isToday.value = value;
      if (value) {
        today.value = new Date(Util.parseTimeToMs(today.value) - 86400000);
        tomorrow.value = new Date(Util.parseTimeToMs(today.value) + 86400000);
      } else {
        today.value = tomorrow.value;
        tomorrow.value = new Date(
          Util.parseTimeToMs(tomorrow.value) + 86400000
        );
      }
    }

    return {
      cardStatus,
      changeCardStatus,
      nowDay,
      nowWeek,
      nowWeekNo,
      dayEvent,
      changeDay,
      hasPassEventNum,
      isToday,
      gIsCurrentWeek,
      jin,
      min,
      today,
      tomorrow,
      Page,
      sThemeColorCSSName: inject("sThemeColorCSSName"),
    };
  },
  mounted() {
    this.sThemeColorCSSName("--day-BackgroundColor");
  },
};
</script>

<style></style>
