import SessionBlockerMap from "../sessionBlockerMap.js";

export default function SessionBlocker(blocker, asyncFunc) {
  this.Blocker = blocker;
  this.AsyncFunc = asyncFunc;
  this.SessionID = SessionBlockerMap.Put(this);
}

SessionBlocker.prototype = {
  async Resolve(response) {
    await this.Blocker.Resolve(response);
    return this.AsyncFunc();
  }
};
