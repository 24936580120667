import Typeof from "../js-typeof/typeof.js";

export default {
  Util: {
    Empty(o, noSemantic) {
      if (o == null) {
        return true;
      } else if (Typeof.String(o)) {
        return (
          o === "" ||
          (!noSemantic &&
            (o.trim().toLowerCase() === "null" ||
              o.trim().toLowerCase() === "undefined"))
        );
      } else if (Typeof.Array(o)) {
        return o.length === 0;
      } else {
        return false;
      }
    },
    NotEmpty(o, noSemantic) {
      return !this.Empty(o, noSemantic);
    },
    GetEmptyStringFromNull(s, noSemantic) {
      return this.Empty(s, noSemantic) ? "" : s;
    },
  },
  String(o) {
    return this.Util.GetEmptyStringFromNull(String(o));
  },
  StringAllowEmpty(o) {
    return String(o);
  },
  Number(o, d = 0) {
    if (Typeof.String(o)) {
      o = o.trim();
    }
    if (o == null) {
      return d;
    } else {
      const num = Number(o);
      return Number.isNaN(num) ? d : num;
    }
  },
  Boolean(o) {
    if (Typeof.String(o)) {
      o = o.trim();
      if (o.toLowerCase() === "true") {
        return true;
      }
      if (o.toLowerCase() === "false") {
        return false;
      }
    }
    return Boolean(o);
  },
  Object(o) {
    return Typeof.Object(o) ? o : {};
  },
  Array(o) {
    return Typeof.Array(o) ? o : [];
  },
  Anything(o) {
    return o;
  },
  Map(o) {
    if (Typeof.Map(o)) {
      return o;
    }
    const m = new Map();
    if (Typeof.Array(o) && o.length > 0 && Typeof.Array(o[0])) {
      for (const [key, value] of o) {
        m.set(key, value);
      }
    }
    return m;
  },
  NonNull(o) {
    if (o == null) {
      throw new TypeError();
    }
    return o;
  },
};
