import HelperError from "./helperError.js";

export default class extends HelperError {
  /**
   * WebVPN 非法的 token
   */
  constructor() {
    super({
      code: "web_vpn_invalid_token",
      tip: "系统坏了，请重试~",
      httpCode: 503,
    });
  }
}
