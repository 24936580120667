<template>
  <div>
    <van-radio-group v-model="checked" v-on="{change: onChange}" class="radio-radio">
      <van-radio v-for="[k,v] in radioMap" :key="(()=>undefined)(k)"
                 :name="k"
                 :checked-color="radioColor"
                 :style="[
                     {'--van-radio-size': '3em', '--van-radio-label-margin': '0.6em'},
                     radioBorderColorStyle,
                     radioTextColorStyle,
                     radioIconColorStyle,
                     ]"
                 v-on="{click: onClick}"
      >
        {{ v }}
      </van-radio>
    </van-radio-group>
  </div>
</template>

<script>

export default {
  name: "Radio",
  props: {
    radioMap: Map,
    modelValue: String,
    radioColor: String,
    radioBorderColor: String,
    radioTextColor: String,
    radioIconColor: String,
    onChange: Function,
    onClick: Function,
  },
  emits: ['update:modelValue'],
  computed: {
    checked: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    },
    radioBorderColorStyle() {
      return this.radioBorderColor ? {
        '--van-radio-border-color': this.radioBorderColor,
      } : null
    },
    radioTextColorStyle() {
      return this.radioTextColor ? {
        '--van-radio-label-color': this.radioTextColor,
      } : null
    },
    radioIconColorStyle() {
      return this.radioIconColor ? {
        '--van-radio-checked-icon-color': this.radioIconColor,
      } : null
    },
  },
}
</script>

<style scoped>

</style>