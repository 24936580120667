<template>
  <div class="card fr">
    <div class="flex-shrink-0 left-bar fr" :class="leftBarColor">
      <div class="align-self-center w100">{{ leftCharMark }}</div>
    </div>
    <div class="align-self-center right-container">
      <div class="time-and-class course-time-margin-bottom">
        <span class="time-color day-time-location-color">{{
          eventStartTime
        }}</span>
        <span v-if="notEmpty(singleEvent.location)">
          <span class="day-card-time-location-delimiter">@</span>
          <span class="location-color day-time-location-color">{{
            singleEvent.location
          }}</span>
        </span>
      </div>
      <div class="course-name">{{ singleEvent.name }}</div>
      <div class="course-time-margin-top">
        <div v-for="[k, v] in detailList" :key="k.id" class="day-card-detail">
          {{ `${k}：${v}` }}
        </div>
      </div>
      <div class="source">
        {{ `来源：${eventSource}\u3000${eventEndTime}结束` }}
      </div>
    </div>
    <div
      class="flex-shrink-0 align-self-center margin-left-auto right-text bold-text"
      :class="leftBarTextColor"
      v-show="status != null"
    >
      {{ status }}
    </div>
  </div>
</template>

<script>
import Util from "@/js/util";
import { computed, inject, toRef } from "vue";
import SingleEvent from "@/js/type/SingleEvent";
import Schedule from "@/js/schedule";
import Typeof from "@/js/typeof";
import Require from "@/js/require";

export default {
  name: "Card",
  props: ["singleEvent"],
  setup(props) {
    let gFinallyUsedTime = inject("gFinallyUsedTime");
    const singleEvent = toRef(props, "singleEvent");

    const turnSingleEvent = computed(() => {
      return new SingleEvent(
        singleEvent.value.id,
        singleEvent.value.nanoTime.start,
        singleEvent.value.nanoTime.end,
        singleEvent.value.location,
        singleEvent.value.name,
        singleEvent.value.participantList,
        singleEvent.value.singleCharMark,
        singleEvent.value.notes,
        singleEvent.value.data
      );
    });
    let isActive = computed(() => {
      return turnSingleEvent.value.isActive(gFinallyUsedTime.value.nanoNow);
    });
    const curClassArr = ["", "一", "二", "三", "四", "五", "六"];
    return {
      gFinallyUsedTime: inject("gFinallyUsedTime"),
      gColorSelector: inject("gColorSelector"),
      gParsedData: inject("gParsedData"),
      isActive,
      turnSingleEvent,
      curClassArr,
    };
  },
  computed: {
    status() {
      const fnn = this.gFinallyUsedTime.nanoNow;
      if (this.singleEvent.isActive(fnn)) {
        return "正在进行";
      } else if (this.singleEvent.isAboutToBegin(fnn)) {
        return "即将开始";
      } else if (this.singleEvent.isJustEnd(fnn)) {
        return "刚刚结束";
      } else {
        return null;
      }
    },
    detailList() {
      const res = [];
      const people = this.singleEvent.participantList.join("，");
      const {
        notes,
        data: {
          termCode,
          batchNo,
          courseNo,
          courseName,
          startWeek,
          endWeek,
          date,
          time,
          week,
          weekday,
          period,
          type,
        },
      } = this.singleEvent;
      const { periodList } = this.gParsedData;
      let timeDes = null;
      if (
        Util.notEmpty(period) &&
        Typeof.array(periodList) &&
        Require.number(period) >= 1 &&
        Require.number(period) < periodList.length
      ) {
        timeDes = periodList[Require.number(period)].description;
      }
      if (this.singleEvent.id.startsWith(Schedule.ID.Prefix.Course)) {
        // 系统课程
        if (Util.notEmpty(people)) {
          res.push(["教师", people]);
        }
        if (Util.notEmpty(timeDes)) {
          res.push(["节次", timeDes]);
        }
        if (Util.notEmpty(courseNo)) {
          res.push(["课号", courseNo]);
        }
        if (Util.notEmpty(startWeek) && Util.notEmpty(endWeek)) {
          res.push(["上课周数", `第 ${startWeek} 周 ~ 第 ${endWeek} 周`]);
        }
        if (Util.notEmpty(notes)) {
          res.push(["备注", notes]);
        }
      } else if (this.singleEvent.id.startsWith(Schedule.ID.Prefix.Exam)) {
        // 系统考试安排
        if (Util.notEmpty(people)) {
          res.push(["教师", people]);
        }
        if (Util.notEmpty(termCode)) {
          res.push(["学期", termCode]);
        }
        if (Util.notEmpty(date)) {
          res.push(["日期", date]);
        }
        if (Util.notEmpty(time)) {
          res.push(["时间", time]);
        }
        if (Util.notEmpty(courseNo)) {
          res.push(["课号", courseNo]);
        }
        if (Util.notEmpty(week) && Require.number(week, 0) > 0) {
          res.push(["周次", `第 ${week} 周`]);
        }
        if (Util.notEmpty(weekday) && Require.number(weekday, 0) > 0) {
          res.push([
            "星期",
            `星期${
              ["零", "一", "二", "三", "四", "五", "六", "日"][
                Require.number(weekday)
              ]
            }`,
          ]);
        }
        if (Util.notEmpty(timeDes)) {
          res.push(["节次", timeDes]);
        }
        if (Util.notEmpty(notes)) {
          res.push(["备注", notes]);
        }
      } else if (
        this.singleEvent.id.startsWith(Schedule.ID.Prefix.MakeUpExam)
      ) {
        // 系统补考/缓考安排
        if (Util.notEmpty(people)) {
          res.push(["教师", people]);
        }
        if (Util.notEmpty(termCode)) {
          res.push(["学期", termCode]);
        }
        if (Util.notEmpty(date)) {
          res.push(["日期", date]);
        }
        if (Util.notEmpty(time)) {
          res.push(["时间", time]);
        }
        if (Util.notEmpty(courseNo)) {
          res.push(["课号", courseNo]);
        }
        if (Util.notEmpty(type)) {
          res.push(["类型", type]);
        }
        if (Util.notEmpty(notes)) {
          res.push(["备注", notes]);
        }
      } else if (
        this.singleEvent.id.startsWith(Schedule.ID.Prefix.Experiment)
      ) {
        // 系统实验安排
        if (Util.notEmpty(people)) {
          res.push(["教师", people]);
        }
        if (Util.notEmpty(timeDes)) {
          res.push(["节次", timeDes]);
        }
        if (Util.notEmpty(batchNo)) {
          res.push(["批次", `第 ${batchNo} 批`]);
        }
        if (Util.notEmpty(courseNo)) {
          res.push(["课号", courseNo]);
        }
        if (Util.notEmpty(courseName)) {
          res.push(["课程名称", courseName]);
        }
        if (Util.notEmpty(notes)) {
          res.push(["备注", notes]);
        }
      } else {
        // 其他
        if (Util.notEmpty(people)) {
          res.push(["参与者", people]);
        }
        if (Util.notEmpty(notes)) {
          res.push(["备注", notes]);
        }
      }
      return res;
    },
    teacher() {
      let ts = "";
      this.singleEvent.participantList.forEach((item) => {
        ts += item + ";";
      });
      if (ts.indexOf(";") !== -1) {
        ts = ts.substring(0, ts.length - 1);
      }
      return ts;
    },
    courseNo() {
      return this.singleEvent.data.courseNo;
    },
    eventStartTime() {
      return Util.rawTimeToHours(Util.ntom(this.singleEvent.nanoTime.start));
    },
    eventEndTime() {
      return Util.rawTimeToHours(Util.ntom(this.singleEvent.nanoTime.end));
    },
    leftCharMark() {
      return this.singleEvent.singleCharMark;
    },
    leftBarColor() {
      const { singleEvent, turnSingleEvent } = this;
      return this.gColorSelector.selectColorClassArray(
        Util.mton(Date.now()),
        [turnSingleEvent],
        singleEvent.id,
        false,
        this.gColorSelector.PredefinedMode.NO_TRANSPARENCY
      );
    },
    leftBarTextColor() {
      const { singleEvent, turnSingleEvent } = this;
      return this.gColorSelector.selectColorClassArray(
        Util.mton(Date.now()),
        [turnSingleEvent],
        singleEvent.id,
        false,
        this.gColorSelector.PredefinedMode.LIGHT
      );
    },
    eventSource() {
      if (this.singleEvent.id.startsWith(Schedule.ID.Prefix.Course)) {
        return Schedule.TypeDescription.Course;
      } else if (this.singleEvent.id.startsWith(Schedule.ID.Prefix.Event)) {
        return Schedule.TypeDescription.Event;
      } else if (
        this.singleEvent.id.startsWith(Schedule.ID.Prefix.Experiment)
      ) {
        return Schedule.TypeDescription.Experiment;
      } else if (this.singleEvent.id.startsWith(Schedule.ID.Prefix.Exam)) {
        return Schedule.TypeDescription.Exam;
      } else if (
        this.singleEvent.id.startsWith(Schedule.ID.Prefix.MakeUpExam)
      ) {
        return Schedule.TypeDescription.MakeUpExam;
      } else {
        return Schedule.TypeDescription.Unknown;
      }
    },
  },
  methods: {
    notEmpty(value) {
      return Util.notEmpty(value);
    },
  },
};
</script>

<style scoped></style>
