export default function JSRWLock() {
  this.readingNum = 0;
  this.writing = false;
  this.waitingReaders = [];
  this.waitingWriters = [];
}

JSRWLock.prototype = {
  async RLock() {
    if (!this.writing && this.waitingWriters.length === 0) {
      this.readingNum++;
      return null;
    }
    return new Promise((rs) => {
      if (!this.writing && this.waitingWriters.length === 0) {
        this.readingNum++;
        return rs();
      }
      this.waitingReaders.push(rs);
    });
  },
  RUnlock() {
    this.readingNum--;
    if (this.readingNum > 0) {
      return;
    }
    if (this.waitingWriters.length === 0) {
      return;
    }
    this.waitingWriters.shift()();
    this.writing = true;
  },
  async WLock() {
    if (!this.writing && this.readingNum === 0) {
      this.writing = true;
      return null;
    }
    return new Promise((rs) => {
      if (!this.writing && this.readingNum === 0) {
        this.writing = true;
        return rs();
      }
      this.waitingWriters.push(rs);
    });
  },
  WUnlock() {
    this.writing = false;
    if (this.waitingWriters.length > 0) {
      this.waitingWriters.shift()();
      this.writing = true;
    } else if (this.waitingReaders.length > 0) {
      while (this.waitingReaders.length > 0) {
        this.waitingReaders.shift()();
        this.readingNum++;
      }
    }
  },
};
