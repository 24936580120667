export const Type = {
  DIRECT: "DIRECT",
  HTTP: "HTTP",
  SOCKS: "SOCKS",
};
export default function NetworkProxy(type, host, port) {
  this.Type = type;
  this.Host = host;
  this.Port = port;
}
