<template>
  <div>
    <div
        ref="groupView"
        class="function-menu-group unselectable-text fr position-relative"
        :class="{'has-top-margin': hasTopMargin}"
    >
      <svg class="alibaba-icon function-menu-group-icon position-absolute" aria-hidden="true">
        <use :xlink:href="'#' + iconID"/>
      </svg>
      <div class="fc function-menu-group-text margin-left-right-auto-in-fr">
        <div class="margin-top-bottom-auto-in-fc">{{ text }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "FunctionMenuGroup",
  props: {
    iconID: String,
    text: String,
    hasTopMargin: {
      type: Boolean,
      default() {
        return true
      },
    },
  },
  methods: {
    getGroupView() {
      return this.$refs.groupView
    },
  },
}
</script>

<style scoped>

</style>