<template>
  <div
    class="dialog-mask-panel"
    :class="[...dpColor]"
    @click="onCancel"
    v-if="isVisible"
  >
    <div class="dialog-background" :class="[...dbColor]" @click.prevent.stop>
      <div class="dialog-head" :class="{ 'display-none': Util.empty(title) }">
        {{ title }}
      </div>
      <div class="dialog-body" :class="{ padding: !removeBodyPadding }">
        <div class="fr dialog-content">
          <slot name="icon" />
          <div>
            {{ msg }}
          </div>
        </div>
        <div class="dialog-view">
          <slot name="view" />
        </div>
        <div
          class="dialog-divider"
          :class="{ 'display-none': removeDivider || (!showNo && !showYes) }"
        />
        <div class="fr justify-content-stretch">
          <div
            class="flex-grow-1 flex-shrink-0 dialog-button no unbreakable-text unselectable-text"
            :class="{ 'display-none': !showNo }"
            @click.prevent.stop="onNo"
          >
            <div :style="{ minWidth: minButtonEM() + 'em' }">
              {{ calNoText() }}
            </div>
          </div>
          <div
            class="flex-shrink-0 min-width-5vmin"
            :class="{ 'display-none': !showYes || !showNo }"
          />
          <div
            class="flex-grow-1 flex-shrink-0 dialog-button yes unbreakable-text unselectable-text"
            :class="{ 'display-none': !showYes }"
            @click.prevent.stop="onYes"
          >
            <div :style="{ minWidth: minButtonEM() + 'em' }">
              {{ calYesText() }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Util from "@/js/util";

export default {
  name: "Dialog",
  props: {
    dpColor: {
      type: Array,
      default() {
        return [];
      },
    },
    dbColor: {
      type: Array,
      default() {
        return [];
      },
    },
    removeBodyPadding: Boolean,
    removeDivider: Boolean,
    title: String,
    msg: String,
    showYes: Boolean,
    showNo: Boolean,
    yesText: String,
    noText: String,
    yesCallback: Function,
    noCallback: Function,
    cancelable: {
      type: Boolean,
      default() {
        return true;
      },
    },
    cancelCallback: Function,
    beforeDismissCallback: Function,
  },
  data() {
    return {
      Util,
      isVisible: false,
    };
  },
  methods: {
    dismiss() {
      if (this.beforeDismissCallback != null) {
        this.beforeDismissCallback();
      }
      this.isVisible = false;
    },
    show() {
      this.isVisible = true;
    },
    onYes() {
      const cb = this.yesCallback == null ? () => false : this.yesCallback;
      if (!cb()) {
        this.dismiss();
      }
    },
    onNo() {
      const cb = this.noCallback == null ? () => false : this.noCallback;
      if (!cb()) {
        this.dismiss();
      }
    },
    onCancel() {
      if (this.cancelable) {
        if (this.cancelCallback != null) {
          this.cancelCallback();
        }
        this.dismiss();
      }
    },
    calYesText() {
      return Util.empty(this.yesText) ? "确定" : this.yesText;
    },
    calNoText() {
      return Util.empty(this.noText) ? "取消" : this.noText;
    },
    minButtonEM() {
      return Math.max(this.calYesText().length, this.calNoText().length);
    },
  },
};
</script>

<style scoped></style>
