import Predefined from "@/js/predefined";
import Typeof from "@/js/typeof";
import Require from "@/js/require";
import { GuetcobHelper } from "@/js/GuetcobHelper.user";
import Callback from "@/js/callback";

export default {
  mton(m) {
    return m * 1000 * 1000;
  },
  ntom(n) {
    return Math.floor(n / (1000 * 1000));
  },
  nullOrEmptyString(s) {
    return s == null || s === "";
  },
  empty(o) {
    if (o == null) {
      return true;
    } else if (Typeof.string(o)) {
      return (
        o === "" ||
        o.trim().toLowerCase() === "null" ||
        o.trim().toLowerCase() === "undefined"
      );
    } else if (Typeof.array(o)) {
      return o.length === 0;
    } else {
      return false;
    }
  },
  notEmpty(s) {
    return !this.empty(s);
  },
  getEmptyStringFromNull(s) {
    return this.empty(s) ? "" : s;
  },
  getEmptyArrayFromNull(a) {
    return a == null ? [] : a;
  },
  isEmptyArray(o, lengthLessThan = 1) {
    return o == null || (Typeof.array(o) && o.length < lengthLessThan);
  },
  randomInt(minInclude, maxExclude) {
    minInclude = Math.ceil(minInclude);
    maxExclude = Math.floor(maxExclude);
    return Math.floor(Math.random() * (maxExclude - minInclude) + minInclude);
  },
  getNormalWeekday_1_7(week_day_1_6_0) {
    return week_day_1_6_0 === 0 ? 7 : week_day_1_6_0;
  },
  isValidYear(year) {
    return Number.isInteger(year) && year >= 1800 && year <= 2100;
  },
  isValidMonth(month) {
    return Number.isInteger(month) && month >= 1 && month <= 12;
  },
  isValidDay(day) {
    return Number.isInteger(day) && day >= 1 && day <= 31;
  },
  isValid24Hour(hour) {
    return Number.isInteger(hour) && hour >= 0 && hour <= 23;
  },
  isValidMinute(minute) {
    return Number.isInteger(minute) && minute >= 0 && minute <= 59;
  },
  isValidSecond(second) {
    return Number.isInteger(second) && second >= 0 && second <= 59;
  },
  isNullOrNaN(object) {
    return (
      (typeof object === "number" && isNaN(object)) ||
      object === undefined ||
      object === null
    );
  },
  convertToNumberArray(x) {
    if (Array.isArray(x)) {
      const res = [];
      for (const m of x) {
        const e = Number(m);
        if (isNaN(e)) {
          return null;
        } else {
          res.push(e);
        }
      }
      return res;
    } else {
      return null;
    }
  },
  pastTimeDescription(nano) {
    if (nano < 0) {
      return "未来";
    } else if (nano < Predefined.Minute.Nano) {
      return "刚刚";
    } else if (nano < Predefined.Hour.Nano) {
      return Math.floor(nano / Predefined.Minute.Nano) + "分钟" + "前";
    } else if (nano < Predefined.Day.Nano) {
      return Math.floor(nano / Predefined.Hour.Nano) + "小时" + "前";
    } else if (nano < Predefined.Month.Nano) {
      return Math.floor(nano / Predefined.Day.Nano) + "天" + "前";
    } else if (nano < Predefined.Year.Nano) {
      return Math.floor(nano / Predefined.Month.Nano) + "个月" + "前";
    } else {
      return Math.floor(nano / Predefined.Year.Nano) + "年" + "前";
    }
  },
  firstUnixNanoTimestampOfMondayOfFirstWeekOfTerm(termStartUnixNano) {
    let m = this.ntom(termStartUnixNano);
    while (new Date(m).getDay() !== 1) {
      m += Predefined.Day.Milli;
    }
    const d = new Date(m);
    return this.mton(
      new Date(d.getFullYear(), d.getMonth(), d.getDate(), 0, 0, 0, 0).getTime()
    );
  },
  lastUnixNanoTimestampOfSundayOfLastWeekOfTerm(termEndUnixNano) {
    let m = this.ntom(termEndUnixNano);
    while (new Date(m).getDay() !== 0) {
      m += Predefined.Day.Milli;
    }
    const d = new Date(m);
    return (
      this.mton(
        new Date(
          d.getFullYear(),
          d.getMonth(),
          d.getDate(),
          23,
          59,
          59,
          999
        ).getTime()
      ) +
      Predefined.Millisecond.Nano -
      1
    );
  },
  nowTimeInfo(termList, nowDate = new Date()) {
    termList = [...termList];
    let now,
      yesterday,
      today,
      tomorrow,
      weekDateList = [];
    let nanoNow;
    // 现在
    now = nowDate;
    // 现在的纳秒 Unix 时间戳
    nanoNow = this.mton(now.getTime());
    // 今天
    today = new Date(
      now.getFullYear(),
      now.getMonth(),
      now.getDate(),
      0,
      0,
      0,
      0
    );
    // 昨天
    yesterday = new Date(today.getTime() - Predefined.Day.Milli);
    // 明天
    tomorrow = new Date(today.getTime() + Predefined.Day.Milli);
    // 星期列表
    weekDateList = this.calWeekDateListFromNowNano(this.mton(now.getTime()));
    // 较晚开始的排前面
    termList.sort((a, b) => b.startNano - a.startNano);
    for (const t of termList) {
      if (nanoNow >= t.startNano && nanoNow < t.endNano) {
        //找到了当前学期
        const absNanoDiffFirst = Math.abs(nanoNow - t.firstMondayNano);
        const absFloatWeekDiffFirst = absNanoDiffFirst / Predefined.Week.Nano;
        // 小于时向上取整，大于等于时向下取整
        const weekDiff =
          nanoNow >= t.firstMondayNano
            ? Math.floor(absFloatWeekDiffFirst)
            : -Math.ceil(absFloatWeekDiffFirst);
        return {
          now: now,
          nanoNow: nanoNow,
          yesterday: yesterday,
          today: today,
          tomorrow: tomorrow,
          weekDateList: weekDateList,
          currentTerm: t,
          // ---额外字段开始
          weekNo: 1 + weekDiff,
          // ---额外字段结束
          nextTerm: null,
        };
      }
    }
    // 没找到当前学期
    // 较早开始的排前面
    termList.sort((a, b) => a.startNano - b.startNano);
    for (const t of termList) {
      if (t.startNano >= nanoNow) {
        //找到了下一个学期
        return {
          now: now,
          nanoNow: nanoNow,
          yesterday: yesterday,
          today: today,
          tomorrow: tomorrow,
          weekDateList: weekDateList,
          currentTerm: null,
          nextTerm: t,
        };
      }
    }
    // 既没找到当前学期，又没找到下一个学期
    return {
      now: now,
      nanoNow: nanoNow,
      yesterday: yesterday,
      today: today,
      tomorrow: tomorrow,
      weekDateList: weekDateList,
      currentTerm: null,
      nextTerm: null,
    };
  },
  calWeekNano(term, week_base_1) {
    return term.firstMondayNano + (week_base_1 - 1) * Predefined.Week.Nano;
  },
  calDayNanoOfWeekNoAndWeekday(term, week_base_1, weekday_1_7) {
    return (
      this.calWeekNano(term, week_base_1) +
      (weekday_1_7 - 1) * Predefined.Day.Nano
    );
  },
  periodToIndex(period_1_6) {
    period_1_6 = Number(period_1_6);
    if (period_1_6 === 56) {
      period_1_6 = 8;
    }
    return period_1_6;
  },
  periodToName(periodList, period_1_6) {
    return periodList[this.periodToIndex(period_1_6)].name;
  },
  calCourseUnixNanoList(
    termList,
    periodList,
    termCode,
    startWeekInclude,
    endWeekInclude,
    oddWeekOnly,
    weekday_1_7,
    seq_1_6
  ) {
    {
      startWeekInclude = Number(startWeekInclude);
      endWeekInclude = Number(endWeekInclude);
      weekday_1_7 = Number(weekday_1_7);
      seq_1_6 = Number(seq_1_6);
    }
    // 【重要】时间段列表前面有个伪时段，所以下标从 1 开始
    const period = periodList[this.periodToIndex(seq_1_6)];
    const term = termList.filter((term) => term.termCode === termCode)[0];
    const res = [];
    for (let i = startWeekInclude; i <= endWeekInclude; i++) {
      if (oddWeekOnly && i % 2 === 0) {
        continue;
      }
      const dayNano = this.calDayNanoOfWeekNoAndWeekday(term, i, weekday_1_7);
      res.push({
        start: dayNano + period.startNanoOffset,
        end: dayNano + period.endNanoOffset,
      });
    }
    return res;
  },
  calExamUnixNanoList(
    termList,
    periodList,
    termCode,
    examdate,
    kssj,
    zc,
    xq,
    ksjc
  ) {
    let startDate = new Date();
    let endDate = startDate;
    {
      // 考试节次
      ksjc = Require.number(ksjc, 5);
      // 学期
      const term = termList.filter((term) => term.termCode === termCode)[0];
      if (term != null) {
        // 设置默认的考试日期和时间
        //
        // 周次
        zc = Require.number(zc, term.weekNum);
        // 星期
        xq = Require.number(xq, 7);
        //
        const dayNano = this.calDayNanoOfWeekNoAndWeekday(term, zc, xq);
        // 【重要】时间段列表前面有个伪时段，所以下标从 1 开始
        const period = periodList[this.periodToIndex(ksjc)];
        startDate = new Date(this.ntom(dayNano + period.startNanoOffset));
        endDate = new Date(this.ntom(dayNano + period.endNanoOffset));
      }
    }
    const examStart = {
      year: startDate.getFullYear(),
      month: startDate.getMonth() + 1,
      day: startDate.getDate(),
      hour: startDate.getHours(),
      minute: startDate.getMinutes(),
    };
    const examEnd = {
      year: endDate.getFullYear(),
      month: endDate.getMonth() + 1,
      day: endDate.getDate(),
      hour: endDate.getHours(),
      minute: endDate.getMinutes(),
    };
    try {
      // 尝试使用给定的日期
      if (this.notEmpty(examdate)) {
        examdate = examdate.replace(/  +/g, " ");
        const ary = examdate.split(/[- ]/);
        const numAry = this.convertToNumberArray(ary);
        if (numAry != null && numAry.length === 3) {
          let year, month, day;
          if (numAry[0] > numAry[1] && numAry[0] > numAry[2]) {
            // 年-月-日
            year = numAry[0];
            month = numAry[1];
            day = numAry[2];
          } else if (numAry[2] > numAry[0] && numAry[2] > numAry[1]) {
            // 月-日-年
            year = numAry[2];
            month = numAry[0];
            day = numAry[1];
          }
          if (
            this.isValidYear(year) &&
            this.isValidMonth(month) &&
            this.isValidDay(day)
          ) {
            examStart.year = examEnd.year = year;
            examStart.month = examEnd.month = month;
            examStart.day = examEnd.day = day;
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
    try {
      // 尝试使用给定的开始时间
      if (this.notEmpty(kssj)) {
        kssj = kssj.replace(/ /g, "");
        const ary = kssj.split(/[-:]/);
        const numAry = this.convertToNumberArray(ary);
        if (numAry != null && numAry.length === 4) {
          let sh, sm, eh, em;
          sh = numAry[0];
          sm = numAry[1];
          eh = numAry[2];
          em = numAry[3];
          if (
            this.isValid24Hour(sh) &&
            this.isValid24Hour(eh) &&
            this.isValidMinute(sm) &&
            this.isValidMinute(em) &&
            sh * 60 + sm < eh * 60 + em
          ) {
            examStart.hour = sh;
            examStart.minute = sm;
            examEnd.hour = eh;
            examEnd.minute = em;
          }
        }
      }
    } catch (e) {
      console.log(e);
    }
    return [
      {
        start: this.mton(
          new Date(
            examStart.year,
            examStart.month - 1,
            examStart.day,
            examStart.hour,
            examStart.minute,
            0,
            0
          ).getTime()
        ),
        end: this.mton(
          new Date(
            examEnd.year,
            examEnd.month - 1,
            examEnd.day,
            examEnd.hour,
            examEnd.minute,
            0,
            0
          ).getTime()
        ),
      },
    ];
  },
  calWeekDateListFromNowNano(nowNano) {
    const nowDate = new Date(this.ntom(nowNano));
    let mondayDate = new Date(
      nowDate.getFullYear(),
      nowDate.getMonth(),
      nowDate.getDate(),
      0,
      0,
      0,
      0
    );
    while (mondayDate.getDay() !== 1) {
      mondayDate = new Date(mondayDate.getTime() - Predefined.Day.Milli);
    }
    const weekDateList = [];
    for (let i = 0; i < 7; i++) {
      weekDateList.push(
        new Date(mondayDate.getTime() + i * Predefined.Day.Milli)
      );
    }
    return weekDateList;
  },
  calDayNano(nanoUnix) {
    const date = new Date(this.ntom(nanoUnix));
    const dayDate = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      0,
      0,
      0,
      0
    );
    return this.mton(dayDate.getTime());
  },
  calNanoOffsetWithinDay(nanoUnix) {
    const dayNano = this.calDayNano(nanoUnix);
    return nanoUnix - dayNano;
  },
  convertNanoOffsetToHourMinuteString(nanoOffset) {
    const hourVal = Math.floor(nanoOffset / Predefined.Hour.Nano);
    const hourNano = hourVal * Predefined.Hour.Nano;
    const minuteNano = nanoOffset - hourNano;
    const minuteVal = Math.floor(minuteNano / Predefined.Minute.Nano);
    const hourStr = hourVal.toString().padStart(2, "0");
    const minuteStr = minuteVal.toString().padStart(2, "0");
    return hourStr + ":" + minuteStr;
  },
  concatOrMergeTwoString(s1, d, s2) {
    s1 = this.getEmptyStringFromNull(s1);
    s2 = this.getEmptyStringFromNull(s2);
    if (s1 === s2) {
      s2 = "";
    }
    if (this.empty(s1) || this.empty(s2)) {
      d = "";
    }
    return s1 + d + s2;
  },
  offsetWidthWithMargin(element) {
    const style = window.getComputedStyle(element);
    return (
      element.offsetWidth +
      parseFloat(style.marginLeft) +
      parseFloat(style.marginRight)
    );
  },
  offsetHeightWithMargin(element) {
    const style = window.getComputedStyle(element);
    return (
      element.offsetHeight +
      parseFloat(style.marginTop) +
      parseFloat(style.marginBottom)
    );
  },
  findTermWithTermName(termList, termName) {
    for (const term of termList) {
      if (term.termName === termName) {
        return term;
      }
    }
    return null;
  },
  chineseWeekday(weekday_base_0) {
    return [7, 1, 2, 3, 4, 5, 6][Require.number(weekday_base_0)];
  },
  yearMonthDay(date) {
    return (
      date.getFullYear() + "/" + (date.getMonth() + 1) + "/" + date.getDate()
    );
  },
  rawTimeToHours(timestamp) {
    const date = new Date(timestamp);
    const h = date.getHours();
    let m = date.getMinutes();
    m = m < 10 ? "0" + m : m;
    return h + ":" + m;
  },
  parseTimeToMs(date) {
    return Date.parse(date);
  },
  getVacationName(date) {
    return date.getMonth() + 1 <= 5 ? "寒假" : "暑假";
  },
  // [min, max)
  getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min);
  },
  isInWechatMiniProgramWebView() {
    return window.__wxjs_environment === "miniprogram";
  },
  Android() {
    // eslint-disable-next-line no-undef
    return typeof Android !== "undefined" && Android != null ? Android : null;
  },
  linkDisabled() {
    return this.isInWechatMiniProgramWebView();
  },
  Helper() {
    const Android = this.Android();
    if (window.GuetcobHelper) {
      return new window.GuetcobHelper();
    } else if (Android != null) {
      if (!global.GM_xmlhttpRequest) {
        global.GM_xmlhttpRequest = (details) => {
          const x = Callback.generateCallbackName(
            Callback.Prefix.GM_xmlhttpRequest
          );
          global[x] = function (res) {
            if (res != null) {
              const resp = JSON.parse(res);
              if (typeof resp.response === "string") {
                let bString = window.atob(resp.response);
                resp.response = new Blob([
                  Uint8Array.from(bString, (element, index) => {
                    return bString.charCodeAt(index);
                  }),
                ]);
              }
              if (details.onload) {
                details.onload(resp);
              }
            } else {
              if (details.onerror) {
                details.onerror(null);
              }
            }
            delete global[x];
          };
          new Promise((resolve) => {
            if (!details.isBlob) {
              resolve(details);
              return;
            }
            if (details.data instanceof Blob) {
              details.data.arrayBuffer().then((ab) => {
                let bString = "";
                new Uint8Array(ab).forEach((byte) => {
                  bString += String.fromCharCode(byte);
                });
                details.data = window.btoa(bString);
                resolve(details);
              });
            } else {
              details.data = "";
              resolve(details);
            }
          }).then((details) => Android.http(JSON.stringify(details), x));
        };
      }
      GuetcobHelper.prototype.storeTGT = async (tgt) => {
        const t = JSON.stringify({
          tgt: tgt,
        });
        return new Promise((resolve) => {
          const x = Callback.generateCallbackName(Callback.Prefix.storeTGT);
          global[x] = () => {
            global[x] = () => {
              global[x] = () => {
                global[x] = () => {
                  resolve();
                  delete global[x];
                };
                Android.setCookie("https://cas.guet.edu.cn", "session=1", x);
              };
              Android.setCookie("https://cas.guet.edu.cn", "loginType=1", x);
            };
            Android.setLocalStorage("https://cas.guet.edu.cn", "TGC", t, x);
          };
          Android.setLocalStorage("https://v.guet.edu.cn", "__1_TGC", t, x);
        });
      };
      return new GuetcobHelper();
    } else {
      return null;
    }
  },
  asciiStringToBytes(s) {
    const res = [];
    for (const ch of s) {
      res.push(ch.charCodeAt(0));
    }
    return res;
  },
  atobToBytes(a) {
    return this.asciiStringToBytes(window.atob(a));
  },
  iSafari() {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
  },
};
