import HelperError from "./helperError.js";

export default class extends HelperError {
  /**
   * CAS: 用户账户被冻结
   */
  constructor() {
    super({
      code: "cas_user_blocked_temporary",
      tip: "用户账户被冻结，请稍后再试~",
      httpCode: 401,
    });
  }
}
