<template>
  <div class="bar-back-color-white bar-weight unselectable-text">
    <div class="bar-display footer-bar-padding bar-text-size">
      <div class="bar-right-display">
        <svg
            class="icon bar-icon-margin-right bar-icon-size"
            aria-hidden="true"
        >
          <use :xlink:href="'#' + icon"></use>
        </svg>
        <div class="bar-text-color bar-text-letter-spacing">{{ text }}</div>
      </div>
      <svg class="icon more-right-arrow" aria-hidden="true">
        <use :xlink:href="'#' + toolIcon"></use>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: "Bar",
  props: ["text", "icon", "toolIcon"],
};
</script>

<style>
</style>