<template>
  <div
    class="fr day-head unselectable-text"
    style="padding: var(--day-head-PaddingShorthand)"
  >
    <div
      class="align-self-center flex-shrink-0 fc"
      @click.prevent.stop="gPopup.tw.show()"
      style="margin-left: var(--day-date-week-time-info-block-margin-left)"
    >
      <div class="align-self-start day-year-month-day">
        {{ nowDay }}
      </div>
      <div
        class="align-self-start fr"
        style="margin-top: var(--day-week-info-margin-top)"
      >
        <div class="day-week-info">
          {{ weekInfo }}
        </div>
        <div class="day-not-current-week" v-show="!isCurrentWeek">
          &nbsp;(非本周)&nbsp;
        </div>
      </div>
    </div>
    <div class="flex-grow-1" />
    <div class="align-self-center fr day-menu">
      <div
        class="flex-shrink-0 align-self-center"
        @click.prevent.stop="returnToday"
        v-show="!isCurrentWeek"
        style="
          --ph: var(--day-menu-item-padding-horizontal);
          padding-left: var(--ph);
          padding-right: var(--ph);
        "
      >
        本周
      </div>
      <div
        class="flex-shrink-0 align-self-center fr"
        @click.prevent.stop="changeDay"
        style="
          --ph: calc(2 * var(--vw));
          padding-left: var(--ph);
          padding-right: var(--ph);
        "
      >
        <div class="align-self-center" :class="{ active: localIsToday }">
          {{ "今" }}
        </div>
        <div class="align-self-center">{{ "/" }}</div>
        <div class="align-self-center" :class="{ active: !localIsToday }">
          {{ "明" }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { inject } from "vue";
import Util from "@/js/util";

export default {
  name: "Head",
  setup() {
    return {
      gPopup: inject("gPopup"),
      returnToday: inject("returnToday"),
      gFinallyUsedTime: inject("gFinallyUsedTime"),
    };
  },
  data() {
    return {
      localIsToday: true,
      dayToWeek: ["日", "一", "二", "三", "四", "五", "六"],
    };
  },
  methods: {
    changeDay() {
      this.localIsToday = !this.isToday;
      this.$emit("changeDay", this.localIsToday);
    },
  },
  props: [
    "nowDay",
    "nowWeek",
    "nowWeekNo",
    "eventNum",
    "isToday",
    "isCurrentWeek",
  ],
  computed: {
    weekNo() {
      return this.dayToWeek[this.nowWeekNo];
    },
    weekDes() {
      return this.gFinallyUsedTime.currentTerm
        ? `第${this.nowWeek}周`
        : Util.getVacationName(this.gFinallyUsedTime.now);
    },
    weekInfo() {
      return (
        `${this.weekDes}，周${this.weekNo}，` +
        (this.eventNum > 0 ? `共 ${this.eventNum} 项日程` : "无日程")
      );
    },
  },
  watch: {
    isToday() {
      this.localIsToday = this.isToday;
    },
  },
};
</script>

<style scoped></style>
