<template>
  <!--  时间线是绝对定位的，所以父容器用 relative 定位-->
  <div class="fc overflow-visible-important position-relative" @click.prevent.stop="calOnClick">
    <div class="flex-grow-1 week-node-padding week-node-radius fc position-relative" :class="[...color.node]">
      <svg v-if="hasDot" class="position-absolute top0 right0 week-node-dot" viewBox="0 0 4 4"
           style="fill: currentColor">
        <circle r="1" cx="2" cy="2"/>
      </svg>
      <div
          class="unbreakable-text week-node-classroom-teacher-time-text-size flex-shrink-0"
          :class="{'week-node-time-margin-bottom': Util.notEmpty(time.start)}">
        {{ time.start }}
      </div>
      <div class="margin-top-auto margin-bottom-auto fc">
        <div class="flex-shrink-0 fc">
          <div class="fc" v-if="!combineClassroomAndCourse">
            <div class="week-node-classroom-teacher-time-text-size">
              {{ content.classroom }}
            </div>
            <div :class="{'week-node-classroom-course-gap': Util.notEmpty(content.classroom)}"/>
          </div>
          <div class="week-node-course-text-size">
            {{
              (combineClassroomAndCourse ? Util.getEmptyStringFromNull(content.classroom) + (Util.empty(content.classroom) ? "" : "#") : "")
              +
              content.course
              +
              (combineCourseAndTeacher ? (Util.empty(content.teacher) ? "" : "@") + Util.getEmptyStringFromNull(content.teacher) : "")
            }}
          </div>
          <div class="fc" v-if="!combineCourseAndTeacher">
            <div :class="{'week-node-course-teacher-gap': Util.notEmpty(content.teacher)}"/>
            <div class="week-node-classroom-teacher-time-text-size">
              {{ content.teacher }}
            </div>
          </div>
        </div>
      </div>
      <div
          class="unbreakable-text week-node-classroom-teacher-time-text-size flex-shrink-0"
          :class="{'week-node-time-margin-top': Util.notEmpty(time.end)}">
        {{ time.end }}
      </div>
    </div>
    <!--    这是用来撑开高度的虚假时间线-->
    <div class="flex-shrink-0" :style="{height: timelineHeight}"/>
    <!--    这是真正的时间线-->
    <div class="flex-shrink-0 position-absolute timeline-location"
         :class="[...color.timeline]"
         style="width: calc(100 * var(--vw))"
         :style="{height: timelineHeight}"
         v-show="showTimeLine"
    />
  </div>
</template>

<script>
import Util from "@/js/util";
import {inject} from "vue";

export default {
  name: "WeekNode",
  setup() {
    return {
      gPopup: inject("gPopup"),
      sSingleEventListToShow: inject("sSingleEventListToShow"),
    }
  },
  props: {
    singleEventList: {
      type: Array,
      default() {
        return []
      },
    },
    color: {
      type: Object,
      default() {
        return {
          node: [],
          timeline: [],
        }
      }
    },
    content: {
      type: Object,
      default() {
        return {
          classroom: "",
          course: "",
          teacher: "",
        }
      }
    },
    time: {
      type: Object,
      default() {
        return {
          start: "",
          end: "",
        }
      }
    },
    timelineHeight: String,
    showTimeLine: Boolean,
    combineCourseAndTeacher: {
      type: Boolean,
      default() {
        return true
      },
    },
    combineClassroomAndCourse: {
      type: Boolean,
      default() {
        return true
      },
    },
    hasDot: Boolean,
  },
  data() {
    return {
      Util,
    }
  },
  computed: {
    calOnClick() {
      return Util.empty(this.singleEventList) ? null : () => {
        this.sSingleEventListToShow(this.singleEventList)
        this.gPopup.wcd.show()
      }
    },
  },
}
</script>

<style scoped>

</style>