import HelperError from "./helperError.js";

export default class extends HelperError {
  /**
   * CAS: 学号或 CAS 密码错误
   */
  constructor(chanceRemaining) {
    super({
      code: "cas_password_error",
      tip: `智慧校园用户名或密码错误${
        chanceRemaining == null
          ? ""
          : `，还剩 ${chanceRemaining} 次机会，机会耗尽账号将临时锁定`
      }，请仔细检查输入~`,
      httpCode: 401,
    });
  }
}
