import HelperError from "./error/helperError.js";
import ErrorAcceptanceFailed from "./error/ErrorAcceptanceFailed.js";
import ErrorBadRequest from "./error/ErrorBadRequest.js";
import ErrorCASAccountNeedsImprovement from "./error/ErrorCASAccountNeedsImprovement.js";
import ErrorCASLoginCredentialNotAvailable from "./error/ErrorCASLoginCredentialNotAvailable.js";
import ErrorCASNeedReAuth from "./error/ErrorCASNeedReAuth.js";
import ErrorCASUserBlockedTemporary from "./error/ErrorCASUserBlockedTemporary.js";
import ErrorCASUserPermissionsInsufficient from "./error/ErrorCASUserPermissionsInsufficient.js";
import ErrorCASWrongCaptcha from "./error/ErrorCASWrongCaptcha.js";
import ErrorGone from "./error/ErrorGone.js";
import ErrorInternalServerError from "./error/ErrorInternalServerError.js";
import ErrorMultipleBlockerResponse from "./error/ErrorMultipleBlockerResponse.js";
import ErrorNotFound from "./error/ErrorNotFound.js";
import ErrorNotImplemented from "./error/ErrorNotImplemented.js";
import ErrorRemoteError from "./error/ErrorRemoteError.js";
import ErrorUnauthorized from "./error/ErrorUnauthorized.js";
import ErrorUnknown from "./error/ErrorUnknown.js";
import ErrorWebVPNInvalidToken from "./error/ErrorWebVPNInvalidToken.js";
import ErrorWrongAAWCheckCode from "./error/ErrorWrongAAWCheckCode.js";
import ErrorWrongAAWPassword from "./error/ErrorWrongAAWPassword.js";
import ErrorWrongCASPassword from "./error/ErrorWrongCASPassword.js";
import ErrorSMSCodeRateLimitReached from "./error/ErrorSMSCodeRateLimitReached.js";
import ErrorUserCanceled from "./error/ErrorUserCanceled.js";

export default {
  HelperError,
  ErrorAcceptanceFailed,
  ErrorBadRequest,
  ErrorCASAccountNeedsImprovement,
  ErrorCASLoginCredentialNotAvailable,
  ErrorCASNeedReAuth,
  ErrorCASUserBlockedTemporary,
  ErrorCASUserPermissionsInsufficient,
  ErrorCASWrongCaptcha,
  ErrorGone,
  ErrorInternalServerError,
  ErrorMultipleBlockerResponse,
  ErrorNotFound,
  ErrorNotImplemented,
  ErrorRemoteError,
  ErrorUnauthorized,
  ErrorUnknown,
  ErrorWebVPNInvalidToken,
  ErrorWrongAAWCheckCode,
  ErrorWrongAAWPassword,
  ErrorWrongCASPassword,
  ErrorSMSCodeRateLimitReached,
  ErrorUserCanceled
};
