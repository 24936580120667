import TimeData from "./timeData.js";

export default function MultilevelMap({
  sizeThreshold = 10_0000n,
  timeThreshold = 600_000,
} = {}) {
  this.SizeThreshold = sizeThreshold;
  this.TimeThreshold = timeThreshold;
  this.map = new Map();
  this.size = 0n;
}
MultilevelMap.prototype = {
  keysToArray(keys) {
    return Array.isArray(keys) ? keys : [keys];
  },
  clean(keyArray, nts, { level = keyArray.length, map = this.map } = {}) {
    for (const [k, v] of map) {
      if (level === 1) {
        if (nts - v.Time > this.TimeThreshold) {
          map.delete(k);
          this.size--;
        }
      } else {
        this.clean(keyArray, nts, { level: level - 1, map: v });
        if (v.size === 0) {
          map.delete(k);
        }
      }
    }
  },
  tryToClean(keys) {
    if (this.size <= this.SizeThreshold) {
      return;
    }
    this.clean(this.keysToArray(keys), Date.now());
  },
  SetIfNotPresentThenGet(keys, generateData) {
    keys = this.keysToArray(keys);
    let last = this.map;
    let newData;
    for (let i = 0; i < keys.length; i++) {
      const isLastKey = i === keys.length - 1;
      const k = keys[i];
      if (!last.has(k)) {
        if (!generateData) {
          return null;
        } else {
          if (newData == null) {
            newData = generateData();
            if (newData == null) {
              return null;
            }
          }
        }
        if (isLastKey) {
          last.set(k, new TimeData(newData));
          this.size++;
          this.tryToClean(keys);
        } else {
          last.set(k, new Map());
        }
      }
      last = last.get(k);
    }
    if (last != null) {
      last.Time = Date.now();
      return last.Data;
    }else {
      return null
    }
  },
  Get(keys) {
    return this.SetIfNotPresentThenGet(keys);
  },
  Has(keys) {
    return this.Get(keys) != null;
  },
  RemoveIfPresent(keys) {
    keys = this.keysToArray(keys);
    let last = this.map;
    for (let i = 0; i < keys.length; i++) {
      const isLastKey = i === keys.length - 1;
      const k = keys[i];
      if (!last.has(k)) {
        break;
      }
      if (isLastKey) {
        last.delete(k);
        this.size--;
      } else {
        last = last.get(k);
      }
    }
  },
  SetThenGet(keys, data) {
    this.RemoveIfPresent(keys);
    return this.SetIfNotPresentThenGet(keys, () => data);
  },
};
