<template>
  <div class="week w100 h100 fc">
    <!--    这段注释应该放在上一行代码的上面，但是由于奇奇怪怪的 BUG 而被迫放在这-->
    <!--    占满空间-->
    <!--    溢出的可以显示（例如：时间线）-->
    <div
      id="head"
      class="flex-shrink-0 fr week-head unselectable-text"
      style="padding: var(--week-head-PaddingShorthand)"
    >
      <div
        class="align-self-center flex-shrink-0 fc"
        @click.prevent.stop="gPopup.tw.show()"
        style="margin-left: var(--week-date-week-time-info-block-margin-left)"
      >
        <div class="align-self-start week-year-month-day">
          {{ yearMonthDay }}
        </div>
        <div
          class="align-self-start fr"
          style="margin-top: var(--week-week-info-margin-top)"
        >
          <div class="week-week-info">
            {{ weekAndSwitch }}
          </div>
          <div class="week-not-current-week" v-show="!gIsCurrentWeek">
            &nbsp;(非本周)&nbsp;
          </div>
        </div>
      </div>
      <div class="flex-grow-1" />
      <div class="align-self-center fr week-menu">
        <div
          class="flex-shrink-0 align-self-center"
          @click.prevent.stop="returnToday"
          v-show="!gIsCurrentWeek"
          style="
            --ph: var(--week-menu-item-padding-horizontal);
            padding-left: var(--ph);
            padding-right: var(--ph);
          "
        >
          本周
        </div>
        <div
          class="flex-shrink-0 align-self-center"
          @click.prevent.stop="gotoLastWeek"
          style="
            --ph: var(--week-menu-item-padding-horizontal);
            padding-left: var(--ph);
            padding-right: var(--ph);
          "
        >
          上周
        </div>
        <div
          class="flex-shrink-0 align-self-center"
          @click.prevent.stop="gotoNextWeek"
          style="
            --ph: var(--week-menu-item-padding-horizontal);
            padding-left: var(--ph);
            padding-right: var(--ph);
          "
        >
          下周
        </div>
      </div>
    </div>
    <div
      id="body"
      class="flex-grow-1 fr justify-content-stretch position-relative z-index0"
      style="padding: var(--week-body-PaddingShorthand)"
    >
      <WeekColumn
        class="flex-shrink-0"
        :weekday="renderedData.timeWeekColumn.weekday"
        :timeline-color="renderedData.timeWeekColumn.timelineColor"
        :timeline-height="renderedData.timeWeekColumn.timelineHeight"
        :node-list="renderedData.timeWeekColumn.nodeList"
      />
      <div
        class="flex-grow-1 flex-basis-0 fr justify-content-stretch position-relative z-index0"
      >
        <mascot
          style="
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          "
          v-if="!renderedData.hasCourse"
        />
        <WeekColumn
          class="flex-grow-1 flex-basis-0 z-index-1"
          v-for="wk in renderedData.weekdayWeekColumnList"
          :key="wk.id"
          :weekday="wk.weekday"
          :timeline-color="wk.timelineColor"
          :timeline-height="wk.timelineHeight"
          :node-list="wk.nodeList"
          :style="{ marginLeft: getTimeLineHeight() }"
        />
      </div>
    </div>
    <div id="foot" class="flex-shrink-0 week-tab-bar-wrapper unselectable-text">
      <van-tabbar
        v-model="tabIndex"
        :fixed="false"
        :border="false"
        active-color="var(--week-tab-bar-ActiveTextColor)"
        inactive-color="var(--week-tab-bar-InactiveTextColor)"
        class="week-tab-bar"
      >
        <van-tabbar-item replace :to="Page.Day.path">{{
          Page.Day.name
        }}</van-tabbar-item>
        <van-tabbar-item replace :to="Page.Week.path">{{
          Page.Week.name
        }}</van-tabbar-item>
        <van-tabbar-item replace :to="Page.More.path">{{
          Page.More.name
        }}</van-tabbar-item>
      </van-tabbar>
    </div>
  </div>
</template>

<script>
import WeekColumn from "@/component/week/element/WeekColumn";
import Util from "@/js/util";
import Predefined from "@/js/predefined";
import SingleEvent from "@/js/type/SingleEvent";
import Config from "@/js/config";
import { inject } from "vue";
import Page from "@/js/page";
import Mascot from "@/component/day/mascot/mascot.vue";

export default {
  name: "Week",
  components: { Mascot, WeekColumn },
  setup() {
    return {
      gParsedData: inject("gParsedData"),
      gSelectedNano: inject("gSelectedNano"),
      gNowTime: inject("gNowTime"),
      gUserSelectedDarkMode: inject("gUserSelectedDarkMode"),
      sUserSelectedDarkMode: inject("sUserSelectedDarkMode"),
      gTimelineHeightVH: inject("gTimelineHeightVH"),
      gColorSelector: inject("gColorSelector"),
      gColor: inject("gColor"),
      gNumber: inject("gNumber"),
      sThemeColorCSSName: inject("sThemeColorCSSName"),
      gPopup: inject("gPopup"),
      gFinallyUsedTime: inject("gFinallyUsedTime"),
      gotoNextWeek: inject("gotoNextWeek"),
      gotoLastWeek: inject("gotoLastWeek"),
      returnToday: inject("returnToday"),
      gIsCurrentWeek: inject("gIsCurrentWeek"),
    };
  },
  computed: {
    weekEventMapList() {
      const weekDateList = Util.calWeekDateListFromNowNano(
        this.gSelectedNano == null ? this.gNowTime.nanoNow : this.gSelectedNano
      );
      const res = [];
      for (let i = 0; i < 7; i++) {
        res.push(new Map());
        const eventMap = res[i];
        const weekdayStartNano = Util.mton(weekDateList[i].getTime());
        const weekdayEndNano = weekdayStartNano + Predefined.Day.Nano;
        for (const [id, e] of this.gParsedData.eventMap) {
          for (const nano of e.unixNanoList) {
            if (nano.start >= weekdayStartNano && nano.end <= weekdayEndNano) {
              eventMap.set(
                id,
                new SingleEvent(
                  id,
                  nano.start,
                  nano.end,
                  e.location,
                  e.name,
                  e.participantList,
                  e.singleCharMark,
                  e.notes,
                  e.data
                )
              );
            }
          }
        }
      }
      return res;
    },
    tableLabelMetaData() {
      const ti = this.gFinallyUsedTime;
      // 构建横轴标签
      const weekdayAxis = [];
      {
        for (let i = 0; i < 7; i++) {
          weekdayAxis.push({
            text:
              Config.WeekdayLabelName[i] + "\n" + ti.weekDateList[i].getDate(),
            active: Util.getNormalWeekday_1_7(ti.now.getDay()) - 1 === i,
          });
        }
      }
      // 构建纵轴标签
      const verticalAxis = [];
      {
        const pList = this.gParsedData.periodList;
        const todayNano = Util.mton(ti.today.getTime());
        const tomorrowNano = Util.mton(ti.tomorrow.getTime());
        const nanoNow = ti.nanoNow;
        // 【重要】这里之所以可以等于，是因为时间结点是从下标 1 开始的，下标 0 是伪时段，也即是月份格子
        const isValidAndVisible = (i) =>
          i < pList.length && i <= Config.MaxShownPeriodNum;
        for (let i = 0; isValidAndVisible(i); i++) {
          const p = pList[i];
          let nextStartNano;
          // 【重要】这里比较的条件应该是用户可见的最后一个格子
          if (isValidAndVisible(i + 1)) {
            const pNext = pList[i + 1];
            nextStartNano = todayNano + pNext.startNanoOffset;
          } else {
            nextStartNano = tomorrowNano;
          }
          const startNano = todayNano + p.startNanoOffset;
          const endNano = todayNano + p.endNanoOffset;
          verticalAxis.push({
            text:
              i === 0
                ? ti.weekDateList[0].getMonth() + 1 + "\n" + "月"
                : p.name +
                  "\n" +
                  p.start.hour.toString().padStart(2, "0") +
                  ":" +
                  p.start.minute.toString().padStart(2, "0") +
                  "\n" +
                  p.end.hour.toString().padStart(2, "0") +
                  ":" +
                  p.end.minute.toString().padStart(2, "0"),
            active: i === 0 || (nanoNow >= startNano && nanoNow < endNano),
            rest: nanoNow >= endNano && nanoNow < nextStartNano,
          });
        }
      }
      return {
        weekdayAxis: weekdayAxis,
        verticalAxis: verticalAxis,
        nanoNow: ti.nanoNow,
      };
    },
    renderedData() {
      const weml = this.weekEventMapList;
      const tlmd = this.tableLabelMetaData;
      let hasCourse = false;
      // 构建边界数组
      const startBorderAry = [];
      const endBorderAry = [];
      {
        // 这里从 1 开始是因为要跳过第一个伪时段
        for (
          let i = 1,
            condition = (index) =>
              index < this.gParsedData.periodList.length &&
              index <= Config.MaxShownPeriodNum;
          condition(i);
          i++
        ) {
          const p = this.gParsedData.periodList[i];
          const startPercent = (i - 1) * Config.eachPeriodPercentage();
          const endPercent = i * Config.eachPeriodPercentage();
          {
            // 推入上边界数组
            startBorderAry.push({
              nanoOffset: null,
              percent:
                startPercent > 0
                  ? startPercent - Config.eachPeriodPercentage() / 2
                  : startPercent,
              maxNanoOffsetExclude:
                i > 1 ? this.gParsedData.periodList[i - 1].endNanoOffset : null,
            });
            startBorderAry.push({
              nanoOffset: p.startNanoOffset,
              percent: startPercent,
            });
          }
          {
            // 推入下边界数组
            endBorderAry.push({
              nanoOffset: p.endNanoOffset,
              percent: endPercent,
            });
            endBorderAry.push({
              nanoOffset: null,
              percent:
                endPercent < Config.TotalPeriodPercentage
                  ? endPercent + Config.eachPeriodPercentage() / 2
                  : endPercent,
              minNanoOffsetExclude: condition(i + 1)
                ? this.gParsedData.periodList[i + 1].startNanoOffset
                : null,
            });
          }
        }
      }
      // 对于一周中的每一天，构建上下事件边界数组
      const weekdayBorderList = [];
      const upperBorderValue = 1;
      const lowerBorderValue = 0 - upperBorderValue;
      for (const eventMap of weml) {
        let borderAry = [];
        for (const [id, e] of eventMap) {
          const startNano = e.nanoTime.start;
          const endNano = e.nanoTime.end;
          const startNanoOffset = Util.calNanoOffsetWithinDay(startNano);
          const endNanoOffset = Util.calNanoOffsetWithinDay(endNano);
          let foundTopIndex = null,
            foundBottomIndex = null;
          // 从上至下扫描上边界数组并选择合适的边界推入事件边界数组
          {
            for (let i = 0; i < startBorderAry.length; i++) {
              const border = startBorderAry[i];
              if (
                border.nanoOffset != null &&
                border.nanoOffset > startNanoOffset
              ) {
                let lastIndex = i > 0 ? i - 1 : i;
                if (startBorderAry[lastIndex].nanoOffset == null) {
                  if (
                    lastIndex > 0 &&
                    startBorderAry[lastIndex - 1].nanoOffset === startNanoOffset
                  ) {
                    lastIndex--;
                  } else if (
                    startBorderAry[lastIndex].maxNanoOffsetExclude != null &&
                    startBorderAry[lastIndex].maxNanoOffsetExclude <=
                      startNanoOffset
                  ) {
                    if (lastIndex + 1 < startBorderAry.length) {
                      lastIndex++;
                    }
                  }
                }
                foundTopIndex = lastIndex;
                break;
              }
            }
            if (foundTopIndex == null) {
              foundTopIndex = startBorderAry.length - 1;
            }
          }
          // 从下至上扫描下边界数组并选择合适的边界推入事件边界数组
          {
            for (let i = endBorderAry.length - 1; i >= 0; i--) {
              const border = endBorderAry[i];
              if (
                border.nanoOffset != null &&
                border.nanoOffset < endNanoOffset
              ) {
                let nextIndex = i + 1 >= endBorderAry.length ? i : i + 1;
                if (endBorderAry[nextIndex].nanoOffset == null) {
                  if (
                    nextIndex + 1 < endBorderAry.length &&
                    endBorderAry[nextIndex + 1].nanoOffset === endNanoOffset
                  ) {
                    nextIndex++;
                  } else if (
                    endBorderAry[nextIndex].minNanoOffsetExclude != null &&
                    endBorderAry[nextIndex].minNanoOffsetExclude >=
                      endNanoOffset
                  ) {
                    if (nextIndex - 1 >= 0) {
                      nextIndex--;
                    }
                  }
                }
                foundBottomIndex = nextIndex;
                break;
              }
            }
            if (foundBottomIndex == null) {
              foundBottomIndex = 0;
            }
          }
          while (
            endBorderAry[foundBottomIndex].percent -
              startBorderAry[foundTopIndex].percent <
            Config.eachPeriodPercentage()
          ) {
            let upvote = null,
              downvote = null;
            if (foundTopIndex > 0) {
              if (startBorderAry[foundTopIndex - 1].nanoOffset != null) {
                upvote =
                  startNanoOffset -
                  startBorderAry[foundTopIndex - 1].nanoOffset;
              }
            }
            if (foundBottomIndex + 1 < endBorderAry.length) {
              if (endBorderAry[foundBottomIndex + 1].nanoOffset != null) {
                downvote =
                  endBorderAry[foundBottomIndex + 1].nanoOffset - endNanoOffset;
              }
            }
            if (
              (upvote != null && downvote == null) ||
              (upvote != null && downvote != null && upvote < downvote) ||
              (upvote == null && downvote == null)
            ) {
              foundTopIndex--;
            } else {
              foundBottomIndex++;
            }
          }
          const upb = startBorderAry[foundTopIndex];
          const downb = endBorderAry[foundBottomIndex];
          borderAry.push({
            borderValue: upperBorderValue,
            percent: upb.percent,
            timeNanoOffset: startNanoOffset,
            id: id,
            borderNanoOffset: upb.nanoOffset,
          });
          borderAry.push({
            borderValue: lowerBorderValue,
            percent: downb.percent,
            timeNanoOffset: endNanoOffset,
            id: id,
            borderNanoOffset: downb.nanoOffset,
          });
        }
        // 合并同类型且同刻度的边界
        const combinedBorderList = [];
        const addBorder = function (border) {
          const existingBorderAry = combinedBorderList.filter(
            (b) =>
              b.percent === border.percent &&
              b.borderValue === border.borderValue
          );
          let selectedBorder;
          if (existingBorderAry.length > 0) {
            selectedBorder = existingBorderAry[0];
          } else {
            combinedBorderList.push({
              borderValue: border.borderValue,
              percent: border.percent,
              timeNanoOffset: null,
              borderNanoOffset: border.borderNanoOffset,
              idList: [],
            });
            selectedBorder = combinedBorderList[combinedBorderList.length - 1];
          }
          selectedBorder.idList.push(border.id);
          if (
            // 未设置
            selectedBorder.timeNanoOffset == null ||
            // 上边界，取最小
            (selectedBorder.borderValue === upperBorderValue &&
              border.timeNanoOffset < selectedBorder.timeNanoOffset) ||
            // 下边界，取最大
            (selectedBorder.borderValue === lowerBorderValue &&
              border.timeNanoOffset > selectedBorder.timeNanoOffset)
          ) {
            selectedBorder.timeNanoOffset = border.timeNanoOffset;
          }
        };
        borderAry.forEach(addBorder);
        // 写回
        borderAry = combinedBorderList;
        weekdayBorderList.push(borderAry);
      }
      // 对于一周中的每一天，构建方块数组
      const weekdayRectList = [];
      for (let i = 0; i < weekdayBorderList.length; i++) {
        const borderAry = weekdayBorderList[i];
        weekdayRectList.push([]);
        const rectAry = weekdayRectList[weekdayRectList.length - 1];
        let sum = 0,
          rect;
        for (const border of borderAry) {
          if (sum === 0 && border.borderValue === upperBorderValue) {
            // 上界
            // 新建
            rect = {
              // 加上界
              upperBorder: border,
              // 加 ID
              idList: new Set(border.idList),
            };
          } else if (sum + border.borderValue === 0) {
            // 下界
            // 加下界
            rect.lowerBorder = border;
            // 加 ID
            for (const id of border.idList) {
              rect.idList.add(id);
            }
            // 附加事件列表
            {
              // 筛选
              const singleEventList = [];
              const currentWeekdayEventMap = weml[i];
              for (const [id, singleEvent] of currentWeekdayEventMap) {
                if (rect.idList.has(id)) {
                  singleEventList.push(singleEvent);
                }
              }
              // 较早开始的排前面
              singleEventList.sort(
                (a, b) => a.nanoTime.start - b.nanoTime.start
              );
              // 设置
              rect.singleEventList = singleEventList;
              // 移除无用的 ID 列表
              delete rect.idList;
              // 生成联合 ID
              rect.id = (() => {
                const idList = [];
                for (const singleEvent of rect.singleEventList) {
                  idList.push(singleEvent.id);
                }
                return idList.join("");
              })();
            }
            // 上色
            rect.colorClassArray = this.gColorSelector.selectColorClassArray(
              tlmd.nanoNow,
              rect.singleEventList,
              rect.id
            );
            // 存储
            rectAry.push(rect);
          } else {
            // 途中
            // 加 ID
            for (const id of border.idList) {
              rect.idList.add(id);
            }
          }
          // 入栈出栈
          sum += border.borderValue;
        }
      }
      // 构建时间轴 WeekColumn
      const timeWeekColumn = {
        weekday: {
          name: tlmd.verticalAxis[0].text,
          color: this.getLabelColorClass(tlmd.verticalAxis[0].active),
          showTimeLine: tlmd.verticalAxis[0].rest,
        },
        timelineColor: this.getTimeLineColorClass(),
        timelineHeight: this.getTimeLineHeight(),
        nodeList: [],
      };
      {
        // 【注意】从 1 开始
        for (let i = 1; i < tlmd.verticalAxis.length; i++) {
          const v = tlmd.verticalAxis[i];
          // 推一个占宽度的（适配 Safari）
          timeWeekColumn.nodeList.push({
            // 透明字体，不可折叠，预留加粗空间
            color: ["transparent-text", "unbreakable-text", "bold-text"],
            content: {
              course: v.text,
            },
            // 不显示时间线
            showTimeLine: false,
            // 相对定位占宽度
            position: "relative",
            // 【重要】这里使用的是相对定位，因此不能指定 top，宽度默认是占 100%
            height: 0,
          });
          // 推一个计算高度的（适配 Safari）
          timeWeekColumn.nodeList.push({
            // 真正的颜色
            color: this.getLabelColorClass(v.active),
            content: {
              course: v.text,
            },
            // 可以显示时间线
            showTimeLine: v.rest,
            // 绝对定位计算高度
            position: "absolute",
            // 【重要】这里使用的是绝对定位，因此要指定 100% 宽度，还要指定 top
            top: Config.eachPeriodPercentage() * (i - 1) + "%",
            width: "100%",
            height: Config.eachPeriodPercentage() + "%",
          });
        }
      }
      // 构建表格内容
      const weekdayWeekColumnList = [];
      for (let i = 0; i < weekdayRectList.length; i++) {
        // 方块列表
        const dayRectList = weekdayRectList[i];
        // 横轴标签
        const label = tlmd.weekdayAxis[i];
        // 列
        const wl = {
          weekday: {
            name: label.text,
            color: this.getLabelColorClass(label.active),
            showTimeLine: false,
          },
          timelineColor: this.getTimeLineColorClass(),
          timelineHeight: this.getTimeLineHeight(),
          nodeList: [],
        };
        // 为每一个方块在列中生成渲染节点
        for (const rect of dayRectList) {
          const singleEventList = rect.singleEventList;
          const upperBorder = rect.upperBorder;
          const lowerBorder = rect.lowerBorder;
          wl.nodeList.push({
            id: rect.id,
            // 事件列表
            singleEventList: rect.singleEventList,
            color: rect.colorClassArray,
            content: {
              classroom: singleEventList[0].location,
              course: singleEventList[0].name,
              teacher: singleEventList[0].participantList.join(","),
            },
            hasDot: singleEventList.length > 1,
            time: {
              start:
                upperBorder.borderNanoOffset != null &&
                upperBorder.borderNanoOffset === upperBorder.timeNanoOffset
                  ? null
                  : Util.convertNanoOffsetToHourMinuteString(
                      upperBorder.timeNanoOffset
                    ),
              end:
                lowerBorder.borderNanoOffset != null &&
                lowerBorder.borderNanoOffset === lowerBorder.timeNanoOffset
                  ? null
                  : Util.convertNanoOffsetToHourMinuteString(
                      lowerBorder.timeNanoOffset
                    ),
            },
            showTimeLine: false,
            position: "absolute",
            // 【重要】这里使用的是绝对定位，因此必须指定宽度为 100%
            top: upperBorder.percent + "%",
            width: "100%",
            height: lowerBorder.percent - upperBorder.percent + "%",
          });
        }
        // 将列推入
        weekdayWeekColumnList.push(wl);
        if (wl.nodeList != null && wl.nodeList.length > 0) {
          hasCourse = true;
        }
      }
      return {
        timeWeekColumn: timeWeekColumn,
        weekdayWeekColumnList: weekdayWeekColumnList,
        hasCourse,
      };
    },
    yearMonthDay() {
      const nd = this.gFinallyUsedTime.now;
      return Util.yearMonthDay(nd);
    },
    week() {
      const ti = this.gFinallyUsedTime;
      if (ti.currentTerm == null) {
        return Util.getVacationName(ti.now);
      } else {
        return `第${ti.weekNo}周`;
      }
    },
    weekAndSwitch() {
      return this.week + "，点击切换";
    },
  },
  data() {
    return {
      Page,
      tabIndex: 1,
    };
  },
  methods: {
    getLabelColorClass(isActive) {
      const res = ["weekday", "unbreakable-text"];
      if (isActive) {
        res.push("active");
      }
      return res;
    },
    getTimeLineColorClass() {
      return ["timeline-color"];
    },
    getTimeLineHeight() {
      return this.gTimelineHeightVH + "vh";
    },
  },
  mounted() {
    this.sThemeColorCSSName("--week-BackgroundColor");
  },
};
</script>

<style scoped></style>
