const GUETCourseTablePrefix = "GUETCourseTable"
const DataType = {
    Key: GUETCourseTablePrefix + "qTbP3MpzT5lRBTQ%",
    Type: {
        Map: "map",
    },
}

export default {
    toJSON(o) {
        return JSON.stringify(o, (key, value) => {
            if (value instanceof Map) {
                return {
                    [DataType.Key]: DataType.Type.Map,
                    value: Array.from(value),
                }
            } else {
                return value
            }
        })
    },
    fromJSON(s) {
        return JSON.parse(s, (key, value) => {
            if (value != null) {
                if (value[DataType.Key]) {
                    if (value[DataType.Key] === DataType.Type.Map) {
                        return new Map(value.value)
                    }
                }
            }
            return value
        })
    },
}