export default function (initVal_S, tickCallback) {
  //
  let value, step, precision, timer, paused;
  //
  this.start = () => {
    value = initVal_S;
    step = 1;
    precision = 0;
    paused = false;
    //
    timer = setInterval(() => {
      if (paused) {
        return;
      }
      while (value <= step) {
        step /= 10;
        precision++;
        step =
          Math.floor(step * Math.pow(10, precision)) / Math.pow(10, precision);
      }
      value -= step;
      value =
        Math.floor(value * Math.pow(10, precision)) / Math.pow(10, precision);
      tickCallback(value);
    }, 1000);
    tickCallback(value);
  };
  this.stop = () => {
    if (timer != null) {
      clearInterval(timer);
      timer = null;
    }
  };
  this.pause = () => {
    paused = true;
  };
  this.resume = () => {
    paused = false;
  };
}
