export default [
  {
    name: "1",
    description: "第一大节",
    start: {
      hour: 8,
      minute: 25,
    },
    end: {
      hour: 10,
      minute: 0,
    },
  },
  {
    name: "2",
    description: "第二大节",
    start: {
      hour: 10,
      minute: 25,
    },
    end: {
      hour: 12,
      minute: 0,
    },
  },
  {
    name: "3",
    description: "第三大节",
    start: {
      hour: 14,
      minute: 30,
    },
    end: {
      hour: 16,
      minute: 5,
    },
  },
  {
    name: "4",
    description: "第四大节",
    start: {
      hour: 16,
      minute: 30,
    },
    end: {
      hour: 18,
      minute: 5,
    },
  },
  {
    name: "晚",
    description: "第五大节/晚上第一大节",
    start: {
      hour: 19,
      minute: 30,
    },
    end: {
      hour: 21,
      minute: 10,
    },
  },
  {
    name: "晚",
    description: "第七大节/晚上第三大节",
    start: {
      hour: 21,
      minute: 20,
    },
    end: {
      hour: 22,
      minute: 5,
    },
  },
  {
    name: "午",
    description: "中午",
    start: {
      hour: 12,
      minute: 30,
    },
    end: {
      hour: 14,
      minute: 0,
    },
  },
  {
    name: "晚",
    description: "第五六七大节/晚上第一二三大节",
    start: {
      hour: 19,
      minute: 30,
    },
    end: {
      hour: 22,
      minute: 5,
    },
  },
  {
    name: "晚",
    description: "第六大节/晚上第二大节",
    start: {
      hour: 20,
      minute: 25,
    },
    end: {
      hour: 21,
      minute: 10,
    },
  },
];
