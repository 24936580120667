const DataType = {
  Map: "map",
  ObjectMap: "object-map",
};

export default {
  toJSON(o) {
    return JSON.stringify(o, (key, value) => {
      if (value instanceof Map) {
        return Array.from(value);
      }
      return value;
    });
  },
  fromJSON(s, dataTypeKey = "") {
    return JSON.parse(s, (key, value) => {
      if (value != null) {
        if (value[dataTypeKey]) {
          if (value[dataTypeKey] === DataType.Map) {
            return new Map(value.value);
          }
          if (value[dataTypeKey] === DataType.ObjectMap) {
            const m = new Map(Object.entries(value));
            m.delete(dataTypeKey);
            return m;
          }
        }
      }
      return value;
    });
  },
};
