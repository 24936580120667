<template>
  <!--  最外面这一层是留给外部添加边距的-->
  <div>
    <div
      class="input fc"
      :class="{
        focus: focused,
        invalid: shouldValidate && invalid,
      }"
      :style="colorStyle"
    >
      <div
        class="input-box flex-shrink-0 fc position-relative"
        :style="{
          minWidth: totalMinWidthString,
        }"
      >
        <input
          ref="input"
          v-if="!isTextArea"
          class="input-content flex-grow-1 outline-none"
          :type="calInputType"
          :placeholder="label"
          v-model="userInput"
          @focus="onInputFocus"
          @blur="onInputBlur"
          @keydown.enter.prevent.stop="onEnter"
          :readonly="readonly"
        />
        <textarea
          ref="input"
          v-if="isTextArea"
          class="input-content text-area flex-grow-1 outline-none"
          :rows="rows"
          :placeholder="label"
          v-model="userInput"
          @focus="onInputFocus"
          @blur="onInputBlur"
          @keydown.enter.ctrl.prevent.stop="onEnter"
          :readonly="readonly"
        />
        <svg
          class="alibaba-icon input-tool-icon position-absolute right0 top50 transform-translate-y--50-percent unselectable-text"
          :class="{ 'visibility-hidden': Util.nullOrEmptyString(userInput) }"
          aria-hidden="true"
          @click.prevent.stop="calToolCallback"
        >
          <use :xlink:href="calToolIcon" />
        </svg>
      </div>
      <div
        class="flex-shrink-0 fr input-sub-block"
        :style="{ marginTop: hasSubBlockSpace ? gapHeightString : 0 }"
      >
        <div class="fc position-relative">
          <div class="visibility-hidden input-validation-text">
            {{ invisibleRuleText }}
          </div>
          <div
            class="input-validation-text-wrapper position-absolute w100 h100"
            :style="{
              visibility: shouldValidate && invalid ? 'visible' : 'hidden',
            }"
            v-if="Util.notEmpty(rule.warning)"
          >
            <div class="input-validation-text">
              {{ rule.warning }}
            </div>
          </div>
        </div>
        <div class="flex-grow-1 input-sub-block-gap" style="min-width: 2em" />
        <div
          class="fc input-counter-wrapper unbreakable-text flex-shrink-0"
          :style="{
            visibility:
              showCounter && !Util.nullOrEmptyString(userInput)
                ? 'visible'
                : 'hidden',
          }"
          v-if="hasCounterSpace"
        >
          <div class="input-counter margin-left-auto align-self-end">
            {{ inputLen + " 字" }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Util from "@/js/util";
import { inject } from "vue";
import Require from "@/js/require";

const PredefinedType = {
  TextArea: "textarea",
  Password: "password",
  Text: "text",
  Number: "number",
};

export default {
  name: "Input",
  PredefinedType,
  setup() {
    return {
      gNumber: inject("gNumber"),
    };
  },
  props: {
    label: String,
    type: String,
    rows: {
      type: Number,
      default() {
        return 1;
      },
    },
    rule: {
      type: Object,
      default() {
        return {
          warning: "",
          contentValidator: null,
        };
      },
    },
    gap: String,
    submitCallback: Function,
    modelValue: String,
    couldBeEmpty: Boolean,
    showCounter: Boolean,
    keepRuleText: String,
    keepCounterSpace: Boolean,
    activeBorderColor: String,
    inactiveBorderColor: String,
    textColor: String,
    toolIconAlibaba: {
      type: Object,
      default() {
        return {
          id: "",
          onClick: () => {},
        };
      },
    },
    hideBorder: Boolean,
    readonly: Boolean,
  },
  emits: ["update:modelValue"],
  data() {
    return {
      Util,
      focused: false,
      showPWD: false,
    };
  },
  computed: {
    userInput: {
      get() {
        if (this.modelValue == null) {
          return "";
        } else {
          return this.modelValue;
        }
      },
      set(value) {
        if (value == null) {
          value = "";
        }
        this.$emit("update:modelValue", Require.stringAllowEmpty(value));
      },
    },
    isPassword() {
      return Util.getEmptyStringFromNull(this.type) === PredefinedType.Password;
    },
    isTextArea() {
      return Util.getEmptyStringFromNull(this.type) === PredefinedType.TextArea;
    },
    calInputType() {
      if (this.isPassword && this.showPWD) {
        return PredefinedType.Text;
      } else {
        return this.type;
      }
    },
    calToolIcon() {
      if (Util.notEmpty(this.toolIconAlibaba.id)) {
        return "#" + this.toolIconAlibaba.id;
      } else {
        if (this.isPassword) {
          return this.showPWD
            ? "#alibaba-icon-invisible"
            : "#alibaba-icon-visible";
        } else {
          return "#alibaba-icon-round-clear";
        }
      }
    },
    calToolCallback() {
      if (Util.notEmpty(this.toolIconAlibaba.id)) {
        return this.toolIconAlibaba.onClick
          ? this.toolIconAlibaba.onClick
          : null;
      } else {
        return this.onToolIconClick;
      }
    },
    totalMinWidthString() {
      const minInputEM = 0;
      const hintEM = this.isTextArea
        ? 0
        : Util.getEmptyStringFromNull(this.label).length;
      const minContentEM = Math.max(minInputEM, hintEM);
      const minContentWidthEM = minContentEM + 3;
      return minContentWidthEM + "em";
    },
    gapHeightString() {
      return Util.empty(this.gap) ? this.gNumber.VH + "px" : this.gap;
    },
    shouldValidate() {
      return !Util.nullOrEmptyString(this.userInput);
    },
    valid() {
      if (Util.nullOrEmptyString(this.userInput)) {
        return this.couldBeEmpty;
      } else {
        return (
          !this.rule.contentValidator ||
          this.rule.contentValidator(
            Util.getEmptyStringFromNull(this.userInput)
          )
        );
      }
    },
    invalid() {
      return !this.valid;
    },
    inputLen() {
      return Util.getEmptyStringFromNull(this.userInput).length;
    },
    colorStyle() {
      const cs = {};
      if (Util.notEmpty(this.inactiveBorderColor)) {
        cs["--inactive-border-color"] = this.inactiveBorderColor;
      }
      if (Util.notEmpty(this.activeBorderColor)) {
        cs["--active-border-color"] = this.activeBorderColor;
      }
      if (Util.notEmpty(this.textColor)) {
        cs["--text-color"] = this.textColor;
      }
      if (this.hideBorder) {
        cs["--border-width"] = "0px";
      }
      return cs;
    },
    invisibleRuleText() {
      const warning = Util.getEmptyStringFromNull(this.rule.warning);
      const keepText = Util.getEmptyStringFromNull(this.keepRuleText);
      return warning.length > keepText.length ? warning : keepText;
    },
    hasValidationTextSpace() {
      return Util.notEmpty(this.invisibleRuleText);
    },
    hasCounterSpace() {
      return this.showCounter || this.keepCounterSpace;
    },
    hasSubBlockSpace() {
      return this.hasValidationTextSpace || this.hasCounterSpace;
    },
  },
  methods: {
    onInputFocus() {
      this.focused = true;
    },
    onInputBlur() {
      this.focused = false;
    },
    focusInput() {
      this.$refs.input.focus();
    },
    clearInput() {
      this.userInput = "";
    },
    isValid() {
      return this.valid;
    },
    name() {
      return Util.getEmptyStringFromNull(this.label);
    },
    inputValue() {
      return Util.getEmptyStringFromNull(this.userInput);
    },
    isFocused() {
      return this.focused;
    },
    hasInput() {
      return this.userInput != null && this.userInput !== "";
    },
    onEnter() {
      if (this.submitCallback) {
        this.submitCallback(this.inputValue(), this.isValid());
      }
    },
    onToolIconClick() {
      if (this.isPassword) {
        this.showPWD = !this.showPWD;
      } else {
        this.clearInput();
      }
      this.focusInput();
    },
  },
};
</script>

<style scoped></style>
