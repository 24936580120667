import BlockableTask from "../../types/blockableTask.js";

const keys = {
  studentInfo: Symbol(),
  personalInfo: Symbol(),
  currentTerm: Symbol(),
  termList: Symbol(),
  financial: Symbol(),
  graduationInfo: Symbol(),
  innovationInfo: Symbol(),
  periodList: Symbol(),
  courseList: Symbol(),
  expList: Symbol(),
  examList: Symbol(),
  validCreditList: Symbol(),
  planCourseGradeList: Symbol(),
  gradeList: Symbol(),
  cetList: Symbol(),
  selectedCourseList: Symbol(),
  makeUpExamList: Symbol(),
  expGradeList: Symbol(),
  graduationRequirementList: Symbol(),
  changingMajorInfo: Symbol(),
  hourList: Symbol(),
  updateGraduationInfo: Symbol(),
  updateInnovationInfo: Symbol(),
};
function calTask(obj, key, job) {
  if (obj[key] == null) {
    obj[key] = new BlockableTask(job);
  }
  return obj[key];
}
export default class {
  constructor(loginResult, isLAN, baseRequest, channel) {
    this.loginResult = loginResult;
    this.isLAN = isLAN;
    this.baseRequest = baseRequest;
    this.channel = channel;
  }
  get LoginResult() {
    return this.loginResult;
  }
  get IsLAN() {
    return this.isLAN;
  }
  get BaseRequest() {
    return this.baseRequest;
  }
  async fetchStudentInfo() {
    return {};
  }
  async StudentInfo(channel = this.channel) {
    return calTask(
      this,
      keys.studentInfo,
      this.fetchStudentInfo.bind(this),
    ).Perform(channel);
  }
  async fetchPersonalInfo() {
    return {};
  }
  async PersonalInfo(channel = this.channel) {
    return calTask(
      this,
      keys.personalInfo,
      this.fetchPersonalInfo.bind(this),
    ).Perform(channel);
  }
  async fetchCurrentTerm() {
    return "";
  }
  async CurrentTerm(channel = this.channel) {
    return calTask(
      this,
      keys.currentTerm,
      this.fetchCurrentTerm.bind(this),
    ).Perform(channel);
  }
  async fetchTermList() {
    return [];
  }
  async TermList(channel = this.channel) {
    return calTask(this, keys.termList, this.fetchTermList.bind(this)).Perform(
      channel,
    );
  }
  async fetchFinancial() {
    return "";
  }
  async Financial(channel = this.channel) {
    return calTask(
      this,
      keys.financial,
      this.fetchFinancial.bind(this),
    ).Perform(channel);
  }
  async fetchGraduationInfo() {
    return {};
  }
  async GraduationInfo(channel = this.channel) {
    return calTask(
      this,
      keys.graduationInfo,
      this.fetchGraduationInfo.bind(this),
    ).Perform(channel);
  }
  async fetchInnovationInfo() {
    return {};
  }
  async InnovationInfo(channel = this.channel) {
    return calTask(
      this,
      keys.innovationInfo,
      this.fetchInnovationInfo.bind(this),
    ).Perform(channel);
  }
  async fetchPeriodList() {
    return [];
  }
  async PeriodList(channel = this.channel) {
    return calTask(
      this,
      keys.periodList,
      this.fetchPeriodList.bind(this),
    ).Perform(channel);
  }
  async fetchCourseList() {
    return [];
  }
  async CourseList(channel = this.channel) {
    return calTask(
      this,
      keys.courseList,
      this.fetchCourseList.bind(this),
    ).Perform(channel);
  }
  async fetchExpList() {
    return [];
  }
  async ExpList(channel = this.channel) {
    return calTask(this, keys.expList, this.fetchExpList.bind(this)).Perform(
      channel,
    );
  }
  async fetchExamList() {
    return [];
  }
  async ExamList(channel = this.channel) {
    return calTask(this, keys.examList, this.fetchExamList.bind(this)).Perform(
      channel,
    );
  }
  async fetchValidCreditList() {
    return [];
  }
  async ValidCreditList(channel = this.channel) {
    return calTask(
      this,
      keys.validCreditList,
      this.fetchValidCreditList.bind(this),
    ).Perform(channel);
  }
  async fetchPlanCourseGradeList() {
    return [];
  }
  async PlanCourseGradeList(channel = this.channel) {
    return calTask(
      this,
      keys.planCourseGradeList,
      this.fetchPlanCourseGradeList.bind(this),
    ).Perform(channel);
  }
  async fetchGradeList() {
    return [];
  }
  async GradeList(channel = this.channel) {
    return calTask(
      this,
      keys.gradeList,
      this.fetchGradeList.bind(this),
    ).Perform(channel);
  }
  async fetchCETList() {
    return [];
  }
  async CETList(channel = this.channel) {
    return calTask(this, keys.cetList, this.fetchCETList.bind(this)).Perform(
      channel,
    );
  }
  async fetchSelectedCourseList() {
    return [];
  }
  async SelectedCourseList(channel = this.channel) {
    return calTask(
      this,
      keys.selectedCourseList,
      this.fetchSelectedCourseList.bind(this),
    ).Perform(channel);
  }
  async fetchMakeUpExamList() {
    return [];
  }
  async MakeUpExamList(channel = this.channel) {
    return calTask(
      this,
      keys.makeUpExamList,
      this.fetchMakeUpExamList.bind(this),
    ).Perform(channel);
  }
  async fetchExpGradeList() {
    return [];
  }
  async ExpGradeList(channel = this.channel) {
    return calTask(
      this,
      keys.expGradeList,
      this.fetchExpGradeList.bind(this),
    ).Perform(channel);
  }
  async fetchGraduationRequirementList() {
    return [];
  }
  async GraduationRequirementList(channel = this.channel) {
    return calTask(
      this,
      keys.graduationRequirementList,
      this.fetchGraduationRequirementList.bind(this),
    ).Perform(channel);
  }
  async fetchChangingMajorInfo() {
    return {};
  }
  async ChangingMajorInfo(channel = this.channel) {
    return calTask(
      this,
      keys.changingMajorInfo,
      this.fetchChangingMajorInfo.bind(this),
    ).Perform(channel);
  }
  async fetchHourList() {
    return [];
  }
  async HourList(channel = this.channel) {
    return calTask(this, keys.hourList, this.fetchHourList.bind(this)).Perform(
      channel,
    );
  }
  async updateGraduationInfo() {
    return undefined;
  }
  async UpdateGraduationInfo(channel = this.channel) {
    return calTask(
      this,
      keys.updateGraduationInfo,
      this.updateGraduationInfo.bind(this),
    ).Perform(channel);
  }
  async updateInnovationInfo() {
    return undefined;
  }
  async UpdateInnovationInfo(channel = this.channel) {
    return calTask(
      this,
      keys.updateInnovationInfo,
      this.updateInnovationInfo.bind(this),
    ).Perform(channel);
  }
}
