import HelperError from "./helperError.js";

export default class extends HelperError {
  /**
   * CAS 登录凭证不可用
   */
  constructor() {
    super({
      code: "cas_login_credential_not_available",
      tip: "系统坏了，请重试~",
      httpCode: 503,
    });
  }
}
