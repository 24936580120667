const Role = {
    Student: 'Student',
    InternationalStudent: 'InternationalStudent',
    Teacher: 'Teacher',
}
const RoleMap = new Map([
    [Role.Student, '学生'],
    [Role.InternationalStudent, '国际学院学生'],
    [Role.Teacher, '教师'],
])

export default {
    Year: {
        Nano: 31556926000000000,
        Milli: 31556926000,
    },
    Month: {
        Nano: 2629743830000000,
        Milli: 2629743830,
    },
    Week: {
        Nano: 604800000000000,
        Milli: 604800000,
    },
    Day: {
        Nano: 86400000000000,
        Milli: 86400000,
    },
    Hour: {
        Nano: 3600000000000,
        Milli: 3600000,
    },
    Minute: {
        Nano: 60000000000,
        Milli: 60000,
    },
    Millisecond: {
        Nano: 1000000,
        Milli: 1,
    },
    HttpMethod: {
        GET: "GET",
    },
    HttpHeader: {
        ContentType: "Content-Type",
    },
    MIMEType: {
        JSON: "application/json",
    },
    UserPreferredColorMode: {
        Light: "light",
        Dark: "dark",
        System: "system",
    },
    Role,
    RoleMap,
}