import Util from "@/js/util";

export default {
    Prefix: {
        GM_xmlhttpRequest: "GM_xmlhttpRequest",
        storeTGT: "storeTGT",
    },
    generateCallbackName(prefix) {
        const s = [
            "0",
            "1",
            "2",
            "3",
            "4",
            "5",
            "6",
            "7",
            "8",
            "9",
            "a",
            "b",
            "c",
            "d",
            "e",
            "f",
        ]
        let random = ""
        for (let i = 0; i < 16; i++) {
            random += s[Util.randomInt(0, s.length)]
        }
        return `${prefix}_callback_${Date.now()}_${random}`
    },
}