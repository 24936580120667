<template>
  <div>
    <div v-for="info in infoList" :key="info.name" class="fc">
      <ChildGap/>
      <div class="fr">
        <div class="flex-basis-0 flex-grow-4 flex-shrink-4 fc function-menu-personal-info-name">
          <div class="margin-top-bottom-auto-in-fc">
            {{ info.name }}
          </div>
        </div>
        <div class="flex-basis-0 flex-grow-6 flex-shrink-6 fc function-menu-personal-info-value">
          <div class="margin-top-bottom-auto-in-fc">
            {{ info.value }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChildGap from "@/component/function_menu/element/ChildGap";

export default {
  name: "PersonalInfo",
  components: {ChildGap},
  props: {
    infoList: {
      type: Array,
      default() {
        return []
      },
    },
  }
}
</script>

<style scoped>

</style>