import { GetHelper, IsLAN } from "./guetHelper.js";
import ErrorBadRequest from "../../types/error/ErrorBadRequest.js";
import Util from "js-util";
import CASEncryptor from "../../crypto/casEncryptor.js";
import Blocker from "../../types/blocker.js";
import ErrorInternalServerError from "../../types/error/ErrorInternalServerError.js";
import SessionBlocker from "../../types/sessionBlocker.js";
import BlockerTypes from "../../constants/blockerTypes.js";
import APIName from "../../constants/APIName.js";
import GUETArg from "./guetArg.js";
import School from "../../types/school.js";

function processAPICallRes(res, helper, apiName) {
  if (res instanceof Blocker) {
    return new SessionBlocker(res, async () => {
      return processAPICallRes(await helper.APICall(apiName), helper, apiName);
    });
  } else {
    return res;
  }
}

async function remoteCall(apiName, argPack) {
  if (argPack.IsVPNPasswordPlain) {
    throw new ErrorBadRequest("请勿上传明文密码");
  }
  const helper = GetHelper(
    argPack.StudentID,
    argPack.VPNPassword,
    argPack.AASPassword,
    argPack.IsInternational,
    await IsLAN()
  );
  return processAPICallRes(await helper.APICall(apiName), helper, apiName);
}

async function localCall(apiName, argPack) {
  if (!argPack.IsVPNPasswordPlain) {
    throw new ErrorBadRequest("请直接传入明文密码");
  }
  const vpnPasswordHash = await Util.SHA512(argPack.VPNPassword);
  let res = await remoteCall(
    apiName,
    new GUETArg(
      argPack.StudentID,
      vpnPasswordHash,
      argPack.AASPassword,
      argPack.IsInternational,
      false
    )
  );
  while (res instanceof SessionBlocker) {
    switch (res.Blocker.Type) {
      case BlockerTypes.SaltedVPNPassword:
        res = await res.Resolve(
          CASEncryptor.EncryptPassword(
            argPack.VPNPassword,
            res.Blocker.Challenge
          )
        );
        break;
      case BlockerTypes.SMSCode:
      case BlockerTypes.ConfirmToSendSMSCode:
        return res;
      default:
        throw new ErrorInternalServerError("未知的 BlockerType");
    }
  }
  return res;
}

export default new School(
  GUETArg,
  async (argPack) => remoteCall(APIName.Fetch, argPack),
  {
    IsLAN,
    async LocalLoginVPN(argPack) {
      return localCall(APIName.LoginVPN, argPack);
    },
    async LocalLoginAAS(argPack) {
      return localCall(APIName.LoginAAS, argPack);
    },
    async LocalLoginICampus(argPack) {
      return localCall(APIName.LoginICampus, argPack);
    },
    async LocalFetch(argPack) {
      return localCall(APIName.Fetch, argPack);
    }
  }
);
