import MultilevelMap from "./types/multilevelMap.js";
import Util from "js-util";
import ErrorNotFound from "./types/error/ErrorNotFound.js";

const sessionBlockerMap = new MultilevelMap({
  sizeThreshold: 1000n,
});

const This = {
  Put(sessionBlocker) {
    const nts = Date.now();
    let sessionID;
    // eslint-disable-next-line no-constant-condition
    while (true) {
      sessionID = [nts, Util.RandomUInt(), Util.RandomUInt()].join("#");
      if (!sessionBlockerMap.Has([sessionID])) {
        break;
      }
    }
    sessionBlockerMap.SetThenGet([sessionID], sessionBlocker);
    return sessionID;
  },
  Get(sessionID) {
    return sessionBlockerMap.Get([sessionID]);
  },
  async Resolve(sessionID, response) {
    const blocker = This.Get(sessionID);
    if (blocker == null) {
      throw new ErrorNotFound("找不到会话");
    }
    return blocker.Resolve(response);
  },
};

export default This;
