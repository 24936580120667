import BlockerChannel from "./blockerChannel.js";
import Blocker from "./blocker.js";

export default function BlockableTask(asyncFunc) {
  this.AsyncFunc = asyncFunc;
  this.blockerChannel = new BlockerChannel();
}
BlockableTask.prototype = {
  async perform() {
    if (this.promise == null) {
      this.promise = this.AsyncFunc(this.blockerChannel);
    }
    return Promise.race([this.blockerChannel.Next(), this.promise]);
  },
  async Perform(nextChannel) {
    if (nextChannel == null) {
      return this.perform();
    }
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const res = await this.perform();
      if (res instanceof Blocker) {
        await nextChannel.Bubble(res);
      } else {
        return res;
      }
    }
  },
};
