<template>
  <div>
    <div v-for="sc in selectedCourseList" :key="sc.termCode + sc.courseCode + sc.courseNo" class="fc">
      <ChildGap/>
      <div class="fr">
        <div class="flex-basis-0 fc function-menu-selected-course" style="flex: 5 5">
          <div class="margin-top-bottom-auto-in-fc margin-left-right-auto-in-fc function-menu-selected-course-name"
               :class="{important: sc.important}"
          >
            {{ sc.name }}
          </div>
        </div>
        <div class="flex-basis-0 fc function-menu-selected-course" style="flex: 2 2">
          <div class="margin-top-bottom-auto-in-fc">
            {{ sc.credit }}
          </div>
        </div>
        <div class="flex-basis-0 fc function-menu-selected-course" style="flex: 3 3">
          <div class="margin-top-bottom-auto-in-fc">
            {{ sc.notes }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ChildGap from "@/component/function_menu/element/ChildGap";

export default {
  name: "SelectedCourse",
  components: {ChildGap},
  props: {
    selectedCourseList: {
      type: Array,
      default() {
        return []
      },
    },
  },
}
</script>

<style scoped>

</style>