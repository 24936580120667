// noinspection ExceptionCaughtLocallyJS

import ContentTypeParser from "content-type";
import * as IConv from "iconv-lite";
import Json from "js-json";
import Require from "js-type-require";
import Util from "js-util";
import HTTPMethod from "./HTTPMethod.js";
import MIMEType from "../mime/MIMEType.js";
import HTTPHeader from "./HTTPHeader.js";
import HTTPResult from "./HTTPResult.js";
import HTTPNode from "./HTTPNode.js";
import Dom from "./Dom.js";

export default function HTTPRequest({
  semaphore = null,
  method = null,
  url = null,
  uri = null,
} = {}) {
  Object.defineProperty(this, "RequestSemaphore", {
    value: semaphore,
  });
  this.Method = method;
  this.URL = url;
  this.URI = uri;
  this.CustomizedHeaderList = null;
  this.RequestBinary = null;
  this.RequestForm = null;
  this.RequestString = null;
  this.RequestContentType = null;
  this.RequestContentTypeHeader = null;
  this.Timeout = null;
  this.ConnectTimeout = 2_000;
  this.ReadTimeout = 20_000;
  this.WriteTimeout = 20_000;
  this.IsQuickTest = false;
  this.FollowRedirect = true;
  this.CookieJarTag = null;
  this.AutoSendCookies = null;
  this.AutoReceiveCookies = null;
  this.ClearCookieJar = null;
  this.SetCookies = null;
  this.Proxy = null;
  this.Gateway = null;
  //
  this.StatusCode = null;
  this.StatusMessage = null;
  this.ResponseHeaderList = null;
  this.ResponseHeaderMap = null;
  //
  this.responseBinary = null;
  //
  this.received = false;
  this.receiveFailedE = null;
  this.loaded = false;
  this.loadFailedE = null;
  //
  this.receivedResolver = null;
  this.receivedRejector = null;
  this.loadedResolver = null;
  this.loadedRejector = null;
}
HTTPRequest.prototype = {
  Clone() {
    const clone = new HTTPRequest({ semaphore: this.RequestSemaphore });
    Object.assign(clone, this);
    if (this.CustomizedHeaderList != null) {
      clone.CustomizedHeaderList = [];
      for (const [k, v] of this.CustomizedHeaderList) {
        clone.CustomizedHeaderList.push([k, v]);
      }
    }
    if (this.RequestBinary != null) {
      clone.RequestBinary = this.RequestBinary.slice(0);
    }
    if (this.RequestForm != null) {
      clone.RequestForm = [];
      for (const [k, v] of this.RequestForm) {
        clone.RequestForm.push([k, v]);
      }
    }
    if (this.SetCookies != null) {
      clone.SetCookies = [];
      for (const [k, v] of this.SetCookies) {
        clone.SetCookies.push([k, v]);
      }
    }
    return clone;
  },
  Deserialize(s) {
    const that = Json.fromJSON(s);
    that.RequestBinary = Util.Base64ToArrayBuffer(that.RequestBinary);
    that.responseBinary = Util.Base64ToArrayBuffer(that.responseBinary);
    that.ResponseHeaderMap = Require.Map(that.ResponseHeaderMap);
    that.URI = that.URL;
    return Object.assign(this, that);
  },
  Serialize() {
    return Json.toJSON({
      Method: this.Method,
      URL: this.encodedURL(),
      CustomizedHeaderList: this.CustomizedHeaderList,
      RequestBinary: Util.ArrayBufferToBase64(this.RequestBinary),
      RequestForm: this.RequestForm,
      RequestString: this.RequestString,
      RequestContentType:
        this.RequestContentType == null ? null : this.RequestContentType.Name,
      RequestContentTypeHeader: this.RequestContentTypeHeader,
      Timeout: this.Timeout,
      ConnectTimeout: this.ConnectTimeout,
      ReadTimeout: this.ReadTimeout,
      WriteTimeout: this.WriteTimeout,
      IsQuickTest: this.IsQuickTest,
      FollowRedirect: this.FollowRedirect,
      CookieJarTag: this.CookieJarTag,
      AutoSendCookies: this.AutoSendCookies,
      AutoReceiveCookies: this.AutoReceiveCookies,
      ClearCookieJar: this.ClearCookieJar,
      SetCookies: this.SetCookies,
      Proxy: this.Proxy,
      //
      StatusCode: this.StatusCode,
      StatusMessage: this.StatusMessage,
      ResponseHeaderList: this.ResponseHeaderList,
      ResponseHeaderMap: this.ResponseHeaderMap,
      //
      responseBinary: Util.ArrayBufferToBase64(this.responseBinary),
    });
  },
  GetResponseHeader(name) {
    return Util.MapGet(this.ResponseHeaderMap, name);
  },
  GetFirstResponseHeader(name) {
    const hs = this.GetResponseHeader(name);
    return Util.NotEmpty(hs) ? hs[0] : null;
  },
  calContentType() {
    return this.RequestContentType == null ||
      this.RequestContentType.length === 0
      ? this.RequestContentTypeHeader == null ||
        this.RequestContentTypeHeader.length === 0
        ? null
        : this.RequestContentTypeHeader
      : this.RequestContentType.Value;
  },
  encodedURL() {
    return (this.URI ? this.URI : encodeURI(this.URL)).replaceAll("+", "%2b");
  },
  async setRequestHeader(request, name, value) {
    if (Util.IsNodeJS()) {
      if (request instanceof HTTPNode.ClientRequest) {
        request.setHeader(name, value);
      }
    } else {
      if (request instanceof XMLHttpRequest) {
        request.setRequestHeader(name, value);
      }
    }
  },
  addRequestHeader(request, name, value) {
    if (!Util.IsNodeJS()) {
      if (request instanceof XMLHttpRequest) {
        request.setRequestHeader(name, value);
      }
    }
  },
  async gatewaySerialize(request) {
    return this.setRequestHeader(
      request,
      "HTTP-Request",
      Util.EncodeRFC2047(this.Serialize()),
    );
  },
  applyRequestHeaders(request) {
    const contentType = this.calContentType();
    if (contentType != null) {
      this.addRequestHeader(request, HTTPHeader.ContentType, contentType);
    }
    //
    if (this.CustomizedHeaderList != null) {
      for (const [k, v] of this.CustomizedHeaderList) {
        this.addRequestHeader(request, k == null ? "" : k, v == null ? "" : v);
      }
    }
  },
  generateResponseHeaderMap() {
    this.ResponseHeaderMap = new Map();
    this.ResponseHeaderList.forEach(([k, v]) => {
      if (!this.ResponseHeaderMap.has(k)) {
        this.ResponseHeaderMap.set(k, []);
      }
      this.ResponseHeaderMap.get(k).push(v);
    });
  },
  generateMethod() {
    if (this.Method == null) {
      this.Method = HTTPMethod.GET;
    }
    return this.Method;
  },
  generateTimeout() {
    if (this.Timeout == null) {
      this.Timeout = this.ConnectTimeout + this.ReadTimeout + this.WriteTimeout;
    }
    if (this.IsQuickTest) {
      this.ConnectTimeout = this.ReadTimeout = this.WriteTimeout = 500;
      this.Timeout = this.ConnectTimeout + this.ReadTimeout + this.WriteTimeout;
    }
  },
  generateRequestBinary() {
    if (this.Gateway != null) {
      return;
    }
    if (this.RequestForm != null && this.RequestForm.length > 0) {
      let str = "";
      for (const [i, kv] of this.RequestForm.entries()) {
        if (kv != null && kv.length > 0) {
          if (i > 0) {
            str += "&";
          }
          str += encodeURIComponent(kv[0] || "");
          if (kv.length > 1) {
            str += "=" + encodeURIComponent(kv[1] || "");
          }
        }
      }
      if (str.length > 0) {
        this.RequestString = str;
        this.RequestContentType = MIMEType.XWWWFormURLEncoded;
      }
    }
    if (this.RequestString != null && this.RequestString.length > 0) {
      if (this.calContentType() == null) {
        this.RequestContentType = MIMEType.TextPlainUTF8;
      }
      const ct = this.calContentType();
      const charset = ContentTypeParser.parse(ct).parameters.charset;
      this.RequestBinary = Util.TypedArrayToArrayBuffer(
        IConv.encode(this.RequestString, charset == null ? "utf8" : charset),
      );
    }
    if (this.RequestBinary != null) {
      if (this.RequestBinary.byteLength === 0) {
        this.RequestBinary = null;
      }
      if (this.RequestBinary != null && this.calContentType() == null) {
        this.RequestContentType = MIMEType.ApplicationOctetStream;
      }
    }
  },
  resolveReceive(request) {
    if (this.received || this.receiveFailedE != null) {
      return;
    }
    this.received = true;
    if (this.receivedResolver != null) {
      this.receivedResolver(this.result(request));
    }
  },
  rejectReceive(e) {
    if (this.received || this.receiveFailedE != null) {
      return;
    }
    if (e == null) {
      e = new Error();
    }
    this.receiveFailedE = e;
    if (this.receivedRejector != null) {
      this.receivedRejector(e);
    }
  },
  resolveLoad(arrayBuffer) {
    if (this.loaded || this.loadFailedE != null) {
      return;
    }
    this.loaded = true;
    if (this.loadedResolver != null) {
      this.loadedResolver(this.result(arrayBuffer));
    }
  },
  rejectLoad(e) {
    if (this.loaded || this.loadFailedE != null) {
      return;
    }
    if (e == null) {
      e = new Error();
    }
    this.loadFailedE = e;
    if (this.loadedRejector != null) {
      this.loadedRejector(e);
    }
  },
  rejectAll(e) {
    this.rejectReceive(e);
    this.rejectLoad(e);
  },
  result(result) {
    return new HTTPResult(this, result);
  },
  release() {
    if (this.released) {
      return;
    } else {
      this.released = true;
    }
    if (this.RequestSemaphore != null && this.acquired) {
      this.RequestSemaphore.Release();
    }
  },
  ErrTimeOut: new Error("time out"),
  nodeJSReq() {
    if (this.request != null) {
      return;
    }
    (async () => {
      try {
        if (this.RequestSemaphore != null && !this.acquired) {
          await this.RequestSemaphore.Acquire();
          this.acquired = true;
        }
        this.request = HTTPNode.request(this.Gateway, (response) => {
          const onLoadErr = (e) => {
            this.rejectAll(e);
            this.release();
            response.destroy();
          };
          try {
            const body = [];
            response.on("error", onLoadErr);
            response.on("end", () => {
              try {
                const s = Buffer.concat(body).toString("utf8");
                if (Util.Empty(s)) {
                  throw new Error(
                    Util.GetHeader(response.headers, "HTTP-Request-Error"),
                  );
                }
                this.Deserialize(s);
                this.resolveReceive(request);
                this.resolveLoad(this.responseBinary);
                this.release();
              } catch (e) {
                onLoadErr(e);
              }
            });
            response.on("data", (chunk) => {
              try {
                body.push(chunk);
              } catch (e) {
                onLoadErr(e);
              }
            });
          } catch (e) {
            onLoadErr(e);
          }
        });
        const request = this.request;
        await this.gatewaySerialize(request);
        request.on("error", (e) => {
          this.rejectAll(e);
          this.release();
        });
        request.on("timeout", () => {
          this.rejectAll(this.ErrTimeOut);
          this.release();
        });
        request.on("close", () => {
          this.rejectAll();
          this.release();
        });
        request.end();
      } catch (e) {
        this.rejectAll(e);
        this.release();
      }
    })();
  },
  browserReq() {
    if (this.request != null) {
      return;
    }
    (async () => {
      try {
        if (this.RequestSemaphore != null && !this.acquired) {
          await this.RequestSemaphore.Acquire();
          this.acquired = true;
        }
        this.request = new XMLHttpRequest();
        const request = this.request;
        request.addEventListener("loadend", () => {
          this.release();
        });
        request.addEventListener("readystatechange", () => {
          if (this.Gateway != null || request.readyState !== 2) {
            return;
          }
          try {
            this.StatusCode = request.status;
            this.StatusMessage = request.statusText;
            this.ResponseHeaderList = (request.getAllResponseHeaders() || "")
              .split("\r\n")
              .map((line) => line.split(":").map((field) => field.trim()))
              .filter((kv) => kv.length === 2);
            this.generateResponseHeaderMap();
            this.resolveReceive(this);
          } catch (e) {
            this.rejectReceive(e);
          }
        });
        request.addEventListener("load", () => {
          try {
            if (this.Gateway != null) {
              if (request.responseText == null || request.responseText === "") {
                // noinspection ExceptionCaughtLocallyJS
                throw new Error();
              }
              this.Deserialize(request.responseText);
            } else {
              this.responseBinary =
                request.response == null
                  ? new ArrayBuffer(0)
                  : request.response;
            }
            if (this.Gateway != null) {
              this.resolveReceive(this);
            }
            this.resolveLoad(this.responseBinary);
          } catch (e) {
            if (this.Gateway != null) {
              this.rejectReceive(e);
            }
            this.rejectLoad(e);
          }
        });
        request.addEventListener("error", () => this.rejectAll());
        request.addEventListener("abort", () => this.rejectAll());
        request.addEventListener("timeout", () =>
          this.rejectAll(this.ErrTimeOut),
        );
        if (this.Gateway != null) {
          request.open(HTTPMethod.GET, this.Gateway, true);
          request.responseType = "text";
          await this.gatewaySerialize(request);
          request.send();
        } else {
          request.open(this.generateMethod(), this.encodedURL(), true);
          request.responseType = "arraybuffer";
          this.generateRequestBinary();
          this.applyRequestHeaders(request);
          this.generateTimeout();
          request.timeout = this.Timeout;
          this.generateRequestBinary();
          request.send(this.RequestBinary);
        }
      } catch (e) {
        this.rejectAll(e);
        this.release();
      }
    })();
  },
  req() {
    if (Util.IsNodeJS()) {
      this.nodeJSReq();
    } else {
      this.browserReq();
    }
  },
  async Send() {
    if (this.send != null) {
      return this.send;
    }
    return (this.send = (async () => {
      if (this.received) {
        return this.result(this);
      } else if (this.receiveFailedE != null) {
        throw this.receiveFailedE;
      } else {
        return new Promise((rs, re) => {
          this.receivedResolver = rs;
          this.receivedRejector = re;
          this.req();
        });
      }
    })());
  },
  async ArrayBuffer() {
    if (this.arrayBuffer != null) {
      return this.arrayBuffer;
    }
    return (this.arrayBuffer = (async () => {
      if (this.loaded) {
        return this.result(this.responseBinary);
      } else if (this.loadFailedE != null) {
        throw this.loadFailedE;
      } else {
        return new Promise((rs, re) => {
          this.loadedResolver = rs;
          this.loadedRejector = re;
          this.req();
        });
      }
    })());
  },
  async String() {
    if (this.string != null) {
      return this.string;
    }
    return (this.string = (async () => {
      const baRes = await this.ArrayBuffer();
      return this.result(
        this.newTextDecoder(this.calCharset(baRes.Request, "utf8")).decode(
          baRes.Result,
        ),
      );
    })());
  },
  async StringUsingEncoding(charset) {
    return (async () => {
      const baRes = await this.ArrayBuffer();
      return this.result(
        this.newTextDecoder(this.calCharset(baRes.Request, charset)).decode(
          baRes.Result,
        ),
      );
    })();
  },
  async JSON() {
    if (this.json != null) {
      return this.json;
    }
    return (this.json = (async () => {
      const strRes = await this.String();
      return this.result(Json.fromJSON(strRes.Result));
    })());
  },
  async HTMLDocument() {
    if (this.htmlDocument != null) {
      return this.htmlDocument;
    }
    return (this.htmlDocument = (async () => {
      const strRes = await this.String();
      return this.result(this.parseHTML(strRes.Result));
    })());
  },
  async HTMLDocumentUsingEncoding(charset) {
    return (async () => {
      const strRes = await this.StringUsingEncoding(charset);
      return this.result(this.parseHTML(strRes.Result));
    })();
  },
  parseHTML(s) {
    return Dom(s);
  },
  newTextDecoder(charset) {
    return new TextDecoder(charset, { fatal: true });
  },
  calCharset(request, defaultCharset) {
    const contentTypeList = request.GetResponseHeader(HTTPHeader.ContentType);
    if (contentTypeList != null && contentTypeList.length > 0) {
      const charset = ContentTypeParser.parse(contentTypeList[0]).parameters
        .charset;
      if (charset != null) {
        defaultCharset = charset;
      }
    }
    return defaultCharset;
  },
  GetHeader(name) {
    return Util.MapGet(this.ResponseHeaderMap, name);
  },
  SetURL(url) {
    this.URL = url;
    return this;
  },
  SetURI(uri) {
    this.URI = uri;
    return this;
  },
  SetMethod(method) {
    this.Method = method;
    return this;
  },
  SetCustomizedHeaderList(customizedHeaderList) {
    this.CustomizedHeaderList = customizedHeaderList;
    return this;
  },
  SetRequestBinary(requestBinary) {
    this.RequestBinary = requestBinary;
    return this;
  },
  SetRequestForm(requestForm) {
    this.RequestForm = requestForm;
    return this;
  },
  SetRequestString(requestString) {
    this.RequestString = requestString;
    return this;
  },
  SetRequestContentType(requestContentType) {
    this.RequestContentType = requestContentType;
    return this;
  },
  SetRequestContentTypeHeader(requestContentTypeHeader) {
    this.RequestContentTypeHeader = requestContentTypeHeader;
    return this;
  },
  SetTimeout(timeout) {
    this.Timeout = timeout;
    return this;
  },
  SetIsQuickTest(isQuickTest) {
    this.IsQuickTest = isQuickTest;
    return this;
  },
  SetConnectTimeout(connectTimeout) {
    this.ConnectTimeout = connectTimeout;
    return this;
  },
  SetReadTimeout(readTimeout) {
    this.ReadTimeout = readTimeout;
    return this;
  },
  SetWriteTimeout(writeTimeout) {
    this.WriteTimeout = writeTimeout;
    return this;
  },
  SetFollowRedirect(followRedirect) {
    this.FollowRedirect = followRedirect;
    return this;
  },
  SetCookieJarTag(cookieJarTag) {
    this.CookieJarTag = cookieJarTag;
    return this;
  },
  SetAutoSendCookies(autoSendCookies) {
    this.AutoSendCookies = autoSendCookies;
    return this;
  },
  SetAutoReceiveCookies(autoReceiveCookies) {
    this.AutoReceiveCookies = autoReceiveCookies;
    return this;
  },
  SetClearCookieJar(clearCookieJar) {
    this.ClearCookieJar = clearCookieJar;
    return this;
  },
  SetSetCookies(setCookies) {
    this.SetCookies = setCookies;
    return this;
  },
  SetProxy(proxy) {
    this.Proxy = proxy;
    return this;
  },
  SetGateway(gateway) {
    this.Gateway = gateway;
    return this;
  },
};
