// ==UserScript==
// @name        GUET课程表小助手
// @description 此脚本用于实现 GUET课程表 的登录、同步完全本地化。使用此脚本能够直接与学校官方服务器通信，从而使得用户名密码、教务数据等敏感信息无需流经开发者服务器。
// @namespace   https://github.com/the-eric-kwok
// @icon        https://s2.loli.net/2022/03/18/OML4G3KnXwo1YQm.png
// @connect     10.0.1.5
// @connect     v.guet.edu.cn
// @connect     cas.guet.edu.cn
// @connect     bkjw.guet.edu.cn
// @version     1.3.9
// @author      EricKwok
// @grant       GM_xmlhttpRequest
// @grant       unsafeWindow
// @run-at      document-start
// ==/UserScript==

import { PromiseSemaphore } from "js-promise";
import { HTTPMethod, HTTPRequest } from "js-http-request";
import Util from "js-util";
import StudentInfo, { Role } from "../../types/studentInfo.js";
import ErrorCASNeedReAuth from "../../types/error/ErrorCASNeedReAuth.js";
import ErrorCASWrongCaptcha from "../../types/error/ErrorCASWrongCaptcha.js";
import ErrorWrongCASPassword from "../../types/error/ErrorWrongCASPassword.js";
import ErrorCASUserBlockedTemporary from "../../types/error/ErrorCASUserBlockedTemporary.js";
import ErrorCASUserPermissionsInsufficient from "../../types/error/ErrorCASUserPermissionsInsufficient.js";
import ErrorCASAccountNeedsImprovement from "../../types/error/ErrorCASAccountNeedsImprovement.js";
import ErrorWebVPNInvalidToken from "../../types/error/ErrorWebVPNInvalidToken.js";
import URLs from "../../constants/urls.js";
import CASApps from "../../constants/casApps.js";
import ErrorUnknown from "../../types/error/ErrorUnknown.js";
import BlockerTypes from "../../constants/blockerTypes.js";
import ErrorAcceptanceFailed from "../../types/error/ErrorAcceptanceFailed.js";
import ErrorCASLoginCredentialNotAvailable from "../../types/error/ErrorCASLoginCredentialNotAvailable.js";
import ErrorInternalServerError from "../../types/error/ErrorInternalServerError.js";
import Blocker from "../../types/blocker.js";
import MultilevelMap from "../../types/multilevelMap.js";
import { JSLock } from "js-lock";
import ErrorRemoteError from "../../types/error/ErrorRemoteError.js";
import BlockableTask from "../../types/blockableTask.js";
import APIName from "../../constants/APIName.js";
import ErrorNotImplemented from "../../types/error/ErrorNotImplemented.js";
import ErrorSMSCodeRateLimitReached from "../../types/error/ErrorSMSCodeRateLimitReached.js";
import ErrorNotFound from "../../types/error/ErrorNotFound.js";
import ErrorUserCanceled from "../../types/error/ErrorUserCanceled.js";
import GUETStore from "./guetStore.js";
import GuetAASCredit from "./guetAASCredit.js";
import LoginResult from "./loginResult.js";
import GuetAASSupWisdom from "./guetAASSupWisdom.js";

const singleUser = Util.IsSingleCookieJar();

const mutex = new JSLock();

async function lock() {
  if (!singleUser) {
    return;
  }
  return mutex.Lock();
}

function unlock() {
  if (!singleUser) {
    return;
  }
  mutex.Unlock();
}

const gateway = Util.HTTPRequestGateway();

const proxy = Util.HTTPRequestProxy();

export async function IsLAN() {
  return gateway == null
    ? true
    : new HTTPRequest()
        .SetMethod(HTTPMethod.GET)
        .SetURL(URLs.System.Get(true))
        .SetGateway(gateway)
        .SetProxy(proxy)
        .SetConnectTimeout(400)
        .SetReadTimeout(600)
        .SetWriteTimeout(600)
        .SetTimeout(1000)
        .Send()
        .then((reqRes) => reqRes.Request.StatusCode === 200)
        .catch(() => false);
}

const helperMap = new MultilevelMap();

function helperKeys(
  studentID,
  vpnPassword,
  aasPassword,
  isInternational,
  isLAN,
) {
  return [studentID, vpnPassword, aasPassword, isInternational, isLAN];
}

class guetHelper {
  constructor(studentInfo, isLAN) {
    this.StudentInfo = studentInfo;
    this.tag = (async () =>
      [
        await Util.SHA256(
          Util.GetEmptyStringFromNull(this.StudentInfo.StudentID, true),
        ),
        await Util.SHA256(
          Util.GetEmptyStringFromNull(this.StudentInfo.VPNPassword, true),
        ),
        await Util.SHA256(
          Util.GetEmptyStringFromNull(this.StudentInfo.AASPassword, true),
        ),
        this.StudentInfo.IsInternational,
        Date.now(),
        Util.RandomUInt(),
        Util.RandomUInt(),
      ].join("#"))();
    this.baseRequest = (async () =>
      new HTTPRequest({
        semaphore: new PromiseSemaphore(1),
      })
        .SetProxy(proxy)
        .SetGateway(gateway)
        .SetCookieJarTag(await this.tag))();
    this.IsLAN = isLAN;
  }

  finish() {
    if (this.finished) {
      return;
    }
    this.finished = true;
    const keys = helperKeys(
      this.StudentInfo.StudentID,
      this.StudentInfo.VPNPassword,
      this.StudentInfo.AASPassword,
      this.StudentInfo.IsInternational,
      this.IsLAN,
    );
    if (this.next == null) {
      helperMap.RemoveIfPresent(keys);
    } else {
      helperMap.SetThenGet(keys, this.next);
    }
    if (singleUser) {
      unlock();
    }
  }

  newAPITask(name) {
    return new BlockableTask(async (channel) => {
      if (singleUser) {
        await lock();
      }
      switch (name) {
        case APIName.LoginVPN:
          return this.loginVPN(channel, true);
        case APIName.LoginAAS:
          return this.loginAAS(channel, true);
        case APIName.LoginAASSupWisdom:
          return this.loginAASSupWisdom(channel, true);
        case APIName.LoginICampus:
          return this.loginICampus(channel, true);
        case APIName.Fetch:
          return this.fetch(channel, true);
        default:
          throw new ErrorInternalServerError("未知的 API 调用");
      }
    });
  }

  async callAPI(name) {
    if (this.task == null) {
      this.task = this.newAPITask(name);
    }
    return this.task
      .Perform()
      .then((value) => {
        if (!(value instanceof Blocker)) {
          this.finish();
        }
        return value;
      })
      .catch((reason) => {
        this.finish();
        throw reason;
      });
  }

  getNext() {
    if (this.next != null) {
      return this.next;
    }
    if (this.finished) {
      return GetHelper(
        this.StudentInfo.StudentID,
        this.StudentInfo.VPNPassword,
        this.StudentInfo.AASPassword,
        this.StudentInfo.IsInternational,
        this.IsLAN,
      );
    }
    return (this.next = new guetHelper(this.StudentInfo, this.IsLAN));
  }

  async APICall(name) {
    if (this.api == null) {
      this.api = name;
    }
    if (this.api === name) {
      return this.callAPI(name);
    }
    return this.getNext().APICall(name);
  }

  async refreshCookies() {
    return (await this.baseRequest)
      .Clone()
      .SetMethod(HTTPMethod.GET)
      .SetURL(`http://119.29.29.29`)
      .SetClearCookieJar(true)
      .Send();
  }

  async acceptLogin(loginResult, url, test = (str) => true) {
    await (
      await this.baseRequest
    )
      .Clone()
      .SetMethod(HTTPMethod.GET)
      .SetURL(url)
      .String()
      .then((strRes) => test(strRes.Result));
    return loginResult;
  }

  async openCASPage(loginResult, casApp) {
    return (await this.baseRequest)
      .Clone()
      .SetMethod(HTTPMethod.GET)
      .SetURL(
        `${URLs.CAS.Get(this.IsLAN)}/authserver/login?service=${casApp.URL}`,
      )
      .String()
      .then((strRes) => {
        let saltMatch = /"pwdEncryptSalt".*?value="(.*?)"/i.exec(strRes.Result);
        let executionMatch = /"execution".*?value="(.*?)"/i.exec(strRes.Result);
        const error = new ErrorUnknown("无法获取盐值与 execution");
        if (Util.Empty(saltMatch) || Util.Empty(executionMatch)) {
          throw error;
        }
        const salt = saltMatch[1];
        const execution = executionMatch[1];
        if (Util.Empty(salt) || Util.Empty(execution)) {
          throw error;
        }
        return {
          SaltExecution: { Salt: salt, Execution: execution },
          LoginResult: loginResult,
        };
      });
  }

  async loginCAS(casApp, nextChannel) {
    if (this.loginCASTask == null) {
      this.loginCASTask = new BlockableTask(async (channel) => {
        let reAuthRetry = 3;
        let captchaRetry = 10;
        let progress = 0;
        let saltExecution, saltedVPNPassword, captcha;
        const loginResult = new LoginResult();
        // eslint-disable-next-line no-constant-condition
        while (true) {
          try {
            switch (progress) {
              case 0:
                progress++;
                ({ SaltExecution: saltExecution } = await this.openCASPage(
                  loginResult,
                  casApp,
                ));
                saltedVPNPassword = await channel.Block(
                  BlockerTypes.SaltedVPNPassword,
                  saltExecution.Salt,
                );
                if (!saltedVPNPassword) {
                  throw new ErrorUserCanceled();
                }
              // eslint-disable-next-line no-fallthrough
              case 1:
                progress++;
                // eslint-disable-next-line no-case-declarations
                const isNeedCaptcha = await this.checkIsNeedCaptcha(
                  this.StudentInfo.StudentID,
                );
                if (isNeedCaptcha) {
                  captcha = await this.resolveCaptcha();
                }
              // eslint-disable-next-line no-fallthrough
              case 2:
                progress++;
                // eslint-disable-next-line no-case-declarations
                const loginURL = `${URLs.CAS.Get(this.IsLAN)}/authserver/login`;
                await GUETStore.GetMultiFactorUserCookie(
                  this.StudentInfo.StudentID,
                )
                  .then(async (cookies) => {
                    if (this.IsLAN) {
                      return cookies;
                    } else {
                      const url = new URL(
                        CASApps.CAS.URL + "/authserver/login",
                      );
                      let stringQuery = [];
                      let query = [
                        ["method", "set"],
                        ["host", url.host],
                        ["scheme", url.protocol.replace(":", "")],
                        ["path", url.pathname],
                        ["ck_data", cookies],
                      ];
                      for (const item of query) {
                        stringQuery.push(item.join("="));
                      }
                      await (
                        await this.baseRequest
                      )
                        .Clone()
                        .SetMethod(HTTPMethod.POST)
                        .SetURL(
                          `${URLs.WebVPNCookie}/wengine-vpn/cookie?` +
                            stringQuery.join("&"),
                        )
                        .Send();
                      return undefined;
                    }
                  })
                  .catch((e) => {
                    console.warn("设置 MultiFactorUser Cookie 失败", e);
                  })
                  .then(async (cookie) => {
                    const req = (await this.baseRequest)
                      .Clone()
                      .SetMethod(HTTPMethod.POST)
                      .SetURL(loginURL)
                      .SetRequestForm([
                        ["username", `${this.StudentInfo.StudentID}`],
                        ["password", saltedVPNPassword],
                        ["_eventId", "submit"],
                        ["cllt", "userNameLogin"],
                        ["dllt", "generalLogin"],
                        ["execution", saltExecution.Execution],
                        ["captcha", captcha],
                      ]);
                    if (cookie) {
                      req.SetSetCookies([[loginURL, cookie]]);
                    }
                    return req
                      .String()
                      .then(async (strRes) => {
                        if (!strRes.Result.includes("踢出会话")) {
                          return strRes;
                        }
                        const execution = (
                          await strRes.Request.HTMLDocument()
                        ).Result.getElementById("continue").querySelector(
                          "[name=execution]",
                        ).value;
                        return (await this.baseRequest)
                          .Clone()
                          .SetMethod(HTTPMethod.POST)
                          .SetURL(loginURL)
                          .SetRequestForm([
                            ["execution", execution],
                            ["_eventId", "continue"],
                          ])
                          .String();
                      })
                      .then((strRes) => {
                        this.checkCASLoginResponse(strRes.Result);
                      });
                  });
            }
            break;
          } catch (e) {
            if (e instanceof ErrorCASNeedReAuth && reAuthRetry-- > 0) {
              await this.reAuthCASByPassword(saltedVPNPassword, nextChannel);
              break;
            } else if (
              e instanceof ErrorCASWrongCaptcha &&
              captchaRetry-- > 0
            ) {
              progress = 1;
              continue;
            } else if (
              e instanceof ErrorWebVPNInvalidToken ||
              e instanceof ErrorCASLoginCredentialNotAvailable ||
              e instanceof ErrorUnknown ||
              e instanceof ErrorAcceptanceFailed
            ) {
              throw e;
            }
            throw e;
          }
        }
        return loginResult;
      });
    }
    return this.loginCASTask.Perform(nextChannel);
  }

  async loginVPN(nextChannel, refresh) {
    if (this.loginVPNTask == null) {
      this.loginVPNTask = new BlockableTask(async (channel) => {
        if (refresh) {
          await this.refreshCookies();
        }
        await (
          await this.baseRequest
        )
          .Clone()
          .SetMethod(HTTPMethod.GET)
          .SetURL(`${URLs.VPN.Get(true)}`)
          .Send();
        const loginResult = await this.loginCAS(CASApps.WebVPN, channel);
        return this.acceptLogin(
          loginResult,
          URLs.VPN.Get(true),
          CASApps.WebVPN.Test,
        );
      });
    }
    return this.loginVPNTask.Perform(nextChannel);
  }

  async loginAAS(nextChannel, refresh) {
    if (this.loginAASTask == null) {
      this.loginAASTask = new BlockableTask(async (channel) => {
        if (refresh) {
          await this.refreshCookies();
        }
        if (this.StudentInfo.Role !== Role.Undergraduate) {
          throw new ErrorNotImplemented("暂不支持本次登录");
        }
        if (!this.IsLAN) {
          await this.loginVPN(channel);
        }
        const loginResult = await this.loginCAS(CASApps.AAS, channel);
        return this.acceptLogin(
          loginResult,
          URLs.System.Get(this.IsLAN) +
            (this.StudentInfo.IsUndergraduate
              ? "?ticket=srv"
              : "/Login/MainDesktop"),
          CASApps.AAS.Test,
        );
      });
    }
    return this.loginAASTask.Perform(nextChannel);
  }

  async loginAASSupWisdom(nextChannel, refresh) {
    if (this.loginAASSupWisdomTask != null) {
      return this.loginAASSupWisdomTask.Perform(nextChannel);
    }
    return (this.loginAASSupWisdomTask = new BlockableTask(async (channel) => {
      if (refresh) {
        await this.refreshCookies();
      }
      if (!this.IsLAN) {
        await this.loginVPN(channel);
      }
      const loginResult = await this.loginCAS(CASApps.AASSupWisdom, channel);
      return this.acceptLogin(
        loginResult,
        `${URLs.SystemSupWisdom.Get(this.IsLAN)}/student/sso/login`,
        CASApps.AASSupWisdom.Test,
      );
    })).Perform(nextChannel);
  }

  async loginICampus(nextChannel, refresh) {
    if (this.loginICampusTask == null) {
      this.loginICampusTask = new BlockableTask(async (channel) => {
        if (refresh) {
          await this.refreshCookies();
        }
        if (!this.IsLAN) {
          await this.loginVPN(channel);
        }
        const loginResult = await this.loginCAS(CASApps.ICampus, channel);
        return this.acceptLogin(
          loginResult,
          URLs.ICampus.Get(this.IsLAN),
          CASApps.ICampus.Test,
        );
      });
    }
    return this.loginICampusTask.Perform(nextChannel);
  }

  async serverFetch(channel) {
    const throwError = (request) => {
      let tip;
      const errWhy = request.GetFirstResponseHeader("GUETCOB-Tip");
      if (Util.NotEmpty(errWhy)) {
        tip = Util.DecodeRFC2047(errWhy);
      }
      throw new ErrorRemoteError(tip);
    };
    const vpnPWDHash = await Util.SHA512(this.StudentInfo.VPNPassword);
    const baseRequest = new HTTPRequest()
      .SetReadTimeout(120_000)
      .SetMethod(HTTPMethod.GET)
      .SetURL(
        `${URLs.Server}/student-data/guet/${
          this.StudentInfo.IsInternational ? "i18n" : "non-i18n"
        }/${this.StudentInfo.StudentID}`,
      );
    const headers = [
      ["VPN-Password", Util.EncodeRFC2047(vpnPWDHash)],
      ["AAS-Password", Util.EncodeRFC2047(this.StudentInfo.AASPassword)],
    ];
    let abRes = await baseRequest
      .Clone()
      .SetCustomizedHeaderList(headers)
      .ArrayBuffer();
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const bts = abRes.Request.GetFirstResponseHeader("Blocker-Type");
      if (abRes.Request.StatusCode === 401 && bts) {
        const bt = BlockerTypes.For(Util.DecodeRFC2047(bts));
        abRes = await baseRequest
          .Clone()
          .SetCustomizedHeaderList([
            ["Session-ID", abRes.Request.GetFirstResponseHeader("Session-ID")],
            [
              "Response",
              Util.EncodeRFC2047(
                await channel.Block(
                  bt,
                  Util.DecodeRFC2047(
                    abRes.Request.GetFirstResponseHeader("Challenge"),
                  ),
                ),
              ),
            ],
          ])
          .ArrayBuffer();
      } else if (abRes.Request.StatusCode === 200) {
        return (await abRes.Request.JSON()).Result;
      } else {
        throwError(abRes.Request);
      }
    }
  }

  async localFetch(channel) {
    let loginResult = null;
    try {
      loginResult = await this.loginAAS(channel);
      loginResult.SetAASCreditSucceeded(true);
    } catch (e) {
      if (this.StudentInfo.Role !== Role.Undergraduate) {
        throw e;
      }
    }
    if (this.StudentInfo.Role === Role.Undergraduate) {
      const supLoginResult = await this.loginAASSupWisdom(channel);
      loginResult = loginResult == null ? supLoginResult : loginResult;
    }
    let aas;
    switch (this.StudentInfo.Role) {
      default:
      case Role.Undergraduate:
        aas = new GuetAASSupWisdom(
          loginResult,
          this.IsLAN,
          await this.baseRequest,
          channel,
        );
        break;
      case Role.UndergraduateI18n:
        aas = new GuetAASCredit(
          loginResult,
          this.IsLAN,
          await this.baseRequest,
          channel,
        );
        break;
    }
    // 触发系统更新数据，忽略错误
    await Promise.allSettled([
      aas.Financial(),
      aas.UpdateGraduationInfo(),
      (await aas.StudentInfo()).grade <= 2018
        ? aas.UpdateInnovationInfo()
        : Promise.resolve(),
    ]);
    return {
      lastUpdateUnixNanoTimestamp: Date.now() * 1_000_000,
      termList: await aas.TermList(),
      periodList: await aas.PeriodList(),
      courseList: await aas.CourseList(),
      expList: await aas.ExpList(),
      examList: await aas.ExamList(),
      personalInfoPerson: await aas.PersonalInfo(),
      personalInfoStu: await aas.StudentInfo(),
      validCreditList: await aas.ValidCreditList(),
      planCourseGradeList: await aas.PlanCourseGradeList(),
      gradeList: await aas.GradeList(),
      cetList: await aas.CETList(),
      selectedCourseList: await aas.SelectedCourseList(),
      makeUpExamList: await aas.MakeUpExamList(),
      expGradeList: await aas.ExpGradeList(),
      graduationInformation: await aas.GraduationInfo(),
      graduationRequirementList: await aas.GraduationRequirementList(),
      innovationPointInformation:
        (await aas.StudentInfo()).grade <= 2018
          ? await aas.InnovationInfo()
          : null,
      changingMajorInformation: await aas.ChangingMajorInfo(),
      currentTerm: await aas.CurrentTerm(),
      isInternational: this.StudentInfo.IsInternational,
      hourList: await aas.HourList(),
      role: this.StudentInfo.Role,
    };
  }

  async fetch(nextChannel, refresh) {
    if (this.fetchTask == null) {
      this.fetchTask =
        gateway == null
          ? new BlockableTask(this.serverFetch.bind(this))
          : new BlockableTask(async () => {
              if (refresh) {
                await this.refreshCookies();
              }
              return this.localFetch(nextChannel);
            });
    }
    return this.fetchTask.Perform(nextChannel);
  }

  async resolveCaptcha() {
    return (await this.baseRequest)
      .Clone()
      .SetMethod(HTTPMethod.GET)
      .SetURL(`${URLs.CAS.Get(this.IsLAN)}/authserver/getCaptcha.htl`)
      .ArrayBuffer()
      .then((baRes) => this.parseCaptcha(baRes.Result));
  }

  async parseCaptcha(data) {
    return (await this.baseRequest)
      .Clone()
      .SetMethod(HTTPMethod.POST)
      .SetURL(`${URLs.Server}/ocr/verification-code/guet/cas`)
      .SetRequestBinary(data)
      .Send()
      .then(
        (reqRes) => Util.MapGet(reqRes.Request.ResponseHeaderMap, "code")[0],
      );
  }

  /**
   * 检查是否需要验证码
   * @param {String} studentID 学号
   * @returns {Promise<Boolean>} 是否需要验证码
   */
  async checkIsNeedCaptcha(studentID) {
    return (await this.baseRequest)
      .Clone()
      .SetMethod(HTTPMethod.GET)
      .SetURL(
        `${URLs.CAS.Get(
          this.IsLAN,
        )}/authserver/checkNeedCaptcha.htl?username=${studentID}`,
      )
      .JSON()
      .then((jsonRes) => jsonRes.Result.isNeed);
  }

  async verifySMSCode(code, channel, mobile) {
    if (!code) {
      throw new ErrorUserCanceled();
    }
    return (await this.baseRequest)
      .Clone()
      .SetMethod(HTTPMethod.POST)
      .SetURL(
        `${URLs.CAS.Get(this.IsLAN)}/authserver/reAuthCheck/reAuthSubmit.do`,
      )
      .SetRequestForm([
        ["reAuthType", "3"],
        ["isMultifactor", "true"],
        ["dynamicCode", code],
        ["skipTmpReAuth", "true"],
      ])
      .JSON()
      .then(async (jsonRes) => {
        const msg = jsonRes.Result.msg;
        const stateCode = jsonRes.Result.code;
        if (msg === "认证成功" || stateCode === "reAuth_success") {
          let getMUCookie;
          const regex = /MULTIFACTOR_USERS=([^;]+)/g;
          if (this.IsLAN) {
            getMUCookie = new Promise((resolve) => {
              const cookies = jsonRes.Request.GetHeader("set-cookie");
              if (cookies != null) {
                for (const item of cookies) {
                  if (item.match(regex)) {
                    resolve(item);
                    return;
                  }
                }
              }
              throw new ErrorNotFound("内网没找到 MultiFactorUser Cookie");
            });
          } else {
            const url = new URL(CASApps.CAS.URL + "/authserver/login");
            const stringQuery = [];
            const query = [
              ["method", "get"],
              ["host", url.host],
              ["scheme", url.protocol.replace(":", "")],
              ["path", url.pathname],
              ["vpn_timestamp", Date.now().toString()],
            ];
            for (const item of query) {
              stringQuery.push(item.join("="));
            }
            getMUCookie = (await this.baseRequest)
              .Clone()
              .SetMethod(HTTPMethod.GET)
              .SetURL(
                `${URLs.WebVPNCookie}/wengine-vpn/cookie?` +
                  stringQuery.join("&"),
              )
              .String()
              .then((strRes) => {
                const matchRes = strRes.Result.matchAll(regex);
                let last = null;
                for (const match of matchRes) {
                  last = match[1];
                }
                if (last != null) {
                  return (
                    "MULTIFACTOR_USERS=" +
                    last +
                    "; Max-Age=2147483647; Path=/; HttpOnly"
                  );
                }
                throw new ErrorNotFound("外网没找到 MultiFactorUser Cookie");
              });
          }
          return getMUCookie
            .then((cookie) => {
              return GUETStore.InsertMultiFactorUserCookie(
                this.StudentInfo.StudentID,
                cookie,
              );
            })
            .catch((err) => {
              console.warn("捕获 MultiFactorUser Cookie 失败", err);
            });
        } else if (msg === "动态码错误") {
          return this.verifySMSCode(
            await channel.Block(BlockerTypes.SMSCode, mobile),
            channel,
            mobile,
          );
        } else {
          throw new ErrorUnknown(null, {
            tip: "短信验证失败" + (msg ? "：" + msg : ""),
          });
        }
      });
  }

  async reAuthCASBySMS(channel) {
    const confirm = await channel.Block(
      BlockerTypes.ConfirmToSendSMSCode,
      "需要短信验证，是否发送短信验证码？",
    );
    if (!confirm) {
      throw new ErrorUserCanceled();
    }
    return (await this.baseRequest)
      .Clone()
      .SetMethod(HTTPMethod.POST)
      .SetURL(
        `${URLs.CAS.Get(
          this.IsLAN,
        )}/authserver/dynamicCode/getDynamicCodeByReauth.do`,
      )
      .SetRequestForm([
        ["userName", this.StudentInfo.StudentID],
        ["authCodeTypeName", "reAuthDynamicCodeType"],
      ])
      .JSON()
      .then((jsonRes) => {
        if (jsonRes.Result.res === "success") {
          return jsonRes.Result.mobile;
        } else if (jsonRes.Result.res === "code_time_fail") {
          const waitFor = jsonRes.Result.codeTime;
          throw new ErrorSMSCodeRateLimitReached(waitFor);
        } else {
          const returnMsg = jsonRes.Result.returnMessage
            ? "：" + jsonRes.Result.returnMessage
            : "";
          throw new ErrorUnknown(null, {
            tip: "短信验证码发送失败" + returnMsg,
          });
        }
      })
      .then(async (mobile) => {
        return this.verifySMSCode(
          await channel.Block(BlockerTypes.SMSCode, mobile),
          channel,
          mobile,
        );
      });
  }

  async reAuthCASByPassword(saltedVPNPassword, channel) {
    return (await this.baseRequest)
      .Clone()
      .SetMethod(HTTPMethod.POST)
      .SetURL(
        `${URLs.CAS.Get(this.IsLAN)}/authserver/reAuthCheck/reAuthSubmit.do`,
      )
      .SetRequestForm([
        ["reAuthType", "2"],
        ["isMultifactor", "true"],
        ["password", saltedVPNPassword],
      ])
      .JSON()
      .then((jsonRes) => {
        if (jsonRes.Result.code !== "reAuth_success") {
          return this.reAuthCASBySMS(channel);
        }
      });
  }

  checkCASLoginResponse(s) {
    if (s.includes("多因子")) {
      throw new ErrorCASNeedReAuth();
    }
    if (s.includes("密码有误")) {
      throw new ErrorWrongCASPassword();
    }
    if (s.includes(">验证码错误") || s.includes("图形动态码错误")) {
      throw new ErrorCASWrongCaptcha();
    }
    if (s.includes("账号已经被冻结")) {
      throw new ErrorCASUserBlockedTemporary();
    }
    if (s.includes("应用没有权限")) {
      throw new ErrorCASUserPermissionsInsufficient();
    }
    if (s.includes("信息待完善")) {
      throw new ErrorCASAccountNeedsImprovement();
    }
    if (s.includes("非法的Token")) {
      throw new ErrorWebVPNInvalidToken();
    }
    if (s.includes("登录凭证不可用")) {
      throw new ErrorCASLoginCredentialNotAvailable();
    }
    let tryMatch = /id="showErrorTip".*?<span>(.*?)<\/span>/i.exec(s);
    if (Util.NotEmpty(tryMatch) && tryMatch[1].length > 0) {
      throw new ErrorUnknown(tryMatch[1], { tip: tryMatch[1] });
    }
  }
}

export function GetHelper(
  studentID,
  vpnPassword,
  aasPassword,
  isInternational,
  isLAN,
) {
  return helperMap.SetIfNotPresentThenGet(
    helperKeys(studentID, vpnPassword, aasPassword, isInternational, isLAN),
    () =>
      new guetHelper(
        new StudentInfo(studentID, vpnPassword, aasPassword, isInternational),
        isLAN,
      ),
  );
}
