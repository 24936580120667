<template>
  <div class="more more-back-color w100 h100 fc">
    <div class="flex-shrink-0 bar-margin-bottom-long-dist">
      <HeadBar />
    </div>
    <div class="flex-grow-1">
      <div
        v-for="(item, index) in barMainText"
        :key="item.id"
        :class="{
          'bar-margin-bottom-line':
            (index + 1) % 2 === 1 && index !== barMainText.length - 1,
          'bar-margin-bottom-long-dist':
            (index + 1) % 2 === 0 || index === barMainText.length - 1,
        }"
        @click.prevent.stop="item.onclick"
      >
        <Bar :text="item.name" :icon="item.icon" :tool-icon="item.toolIcon" />
      </div>
    </div>
    <div id="foot" class="flex-shrink-0 unselectable-text more-tab-bar-wrapper">
      <van-tabbar
        v-model="tabIndex"
        :fixed="false"
        :border="false"
        active-color="var(--more-tab-bar-ActiveTextColor)"
        inactive-color="var(--more-tab-bar-InactiveTextColor)"
        class="more-tab-bar"
      >
        <van-tabbar-item replace :to="Page.Day.path">{{
          Page.Day.name
        }}</van-tabbar-item>
        <van-tabbar-item replace :to="Page.Week.path">{{
          Page.Week.name
        }}</van-tabbar-item>
        <van-tabbar-item replace :to="Page.More.path">{{
          Page.More.name
        }}</van-tabbar-item>
      </van-tabbar>
    </div>
  </div>
</template>

<script>
import Page from "@/js/page";
import Bar from "./bar/Bar";
import HeadBar from "./headBar/HearBar.vue";
import { inject } from "vue";
import ReceiptQRCode from "@/js/receipt_QR_code";
import Util from "@/js/util";
import Url_center from "@/js/url_center";
import Name_center from "@/js/name_center";

export default {
  name: "More",
  components: { Bar, HeadBar },
  data() {
    return {
      tabIndex: 2,
      restartDes: "重启应用",
    };
  },
  computed: {
    darkModeDes() {
      if (this.gUserSelectedDarkMode == null) {
        return "跟随系统";
      } else if (this.gUserSelectedDarkMode) {
        return "深色模式";
      } else {
        return "浅色模式";
      }
    },
    barMainText() {
      const Android = Util.Android();
      return [
        {
          name: this.gIsCurrentUserSyncing ? "同步中..." : "立即同步",
          onclick: this.gIsCurrentUserSyncing ? () => {} : this.sync,
          icon: "alibaba-icon-refresh",
          toolIcon: "alibaba-icon-solid-dot",
        },
        {
          // name: "教务功能",
          name: "教务系统",
          // onclick: () => {
          //   this.$router.push(Page.FunctionMenu.path);
          // },
          // onclick: this.defaultOnClick,
          onclick: () => {
            window.open("https://bkjwtest.guet.edu.cn/student/sso/login");
          },
          icon: "alibaba-icon-xuexi",
          toolIcon: "alibaba-icon-xiangyou1",
        },
        {
          name: "常用工具",
          onclick: this.defaultOnClick,
          icon: "alibaba-icon-gongju",
          toolIcon: "alibaba-icon-xiangyou1",
        },
        {
          name: "设置",
          onclick: this.defaultOnClick,
          icon: "alibaba-icon-weihu",
          toolIcon: "alibaba-icon-xiangyou1",
        },
        {
          name:
            Android == null
              ? "下载 - GUET课程表 · 安卓版"
              : `分享${Android.appName()}`,
          onclick: () => {
            if (Android == null) {
              if (Util.linkDisabled()) {
                this.gDialog.contact.show();
              } else {
                window.open(Url_center.GUETCourseTableDownload);
              }
            } else {
              Android.shareText(`嘿！我发现了一个超好用的${Android.appName()}，快来试试吧~

下载安装：
${Url_center.GUETCourseTableDownload}

QQ交流群（${this.gGUETCourseTableQQGroup.Number}）点击加群：
${Url_center.GUETCourseTableGroup}

微信公众号（${Name_center.GUETCourseTableWeChat}）：微信搜索 “${
                Name_center.GUETCourseTableWeChat
              }”

如果遇到无法下载/访问的问题，请把链接复制到浏览器中打开，即可正常下载/访问
`);
            }
          },
          icon: "alibaba-icon-zhifeiji",
          toolIcon:
            Android == null
              ? "alibaba-icon-xiangyou1"
              : "alibaba-icon-solid-dot",
        },
        {
          name: "捐赠",
          onclick: () => {
            this.sReceiptQRCode(ReceiptQRCode.selectOne());
            this.gDialog.sponsor.show();
          },
          icon: "alibaba-icon-dianzan",
          toolIcon: "alibaba-icon-solid-dot",
        },
        {
          name: `${this.darkModeDes}，点击切换`,
          onclick: () => {
            (async () => {
              if (this.gUserSelectedDarkMode == null) {
                return this.sUserSelectedDarkMode(false);
              } else if (this.gUserSelectedDarkMode) {
                return this.sUserSelectedDarkMode(null);
              } else {
                return this.sUserSelectedDarkMode(true);
              }
            })();
          },
          icon: this.gDarkMode ? "alibaba-icon-moon2" : "alibaba-icon-sun2",
          toolIcon: "alibaba-icon-solid-dot",
        },
        {
          name: process.env.VUE_APP_VERSION_NAME,
          onclick: this.defaultOnClick,
          icon: "alibaba-icon-duck",
          toolIcon: "alibaba-icon-solid-dot",
        },
        {
          name: "关于GUET课程表",
          onclick: this.defaultOnClick,
          icon: "alibaba-icon-wengao",
          toolIcon: "alibaba-icon-xiangyou1",
        },
        {
          name: "联系我们",
          onclick: () => {
            this.gDialog.contact.show();
          },
          icon: "alibaba-icon-bamboo-dragonfly",
          toolIcon: "alibaba-icon-solid-dot",
        },
        {
          name: this.restartDes,
          onclick: () => {
            this.restartDes = "应用重启中，请稍等...";
            window.location.reload();
          },
          icon: "alibaba-icon-game-console",
          toolIcon: "alibaba-icon-solid-dot",
        },
        {
          name: this.gIsCurrentUserSyncing ? "同步中..." : "登出",
          onclick: this.gIsCurrentUserSyncing
            ? () => {}
            : () => {
                this.gDialog.logout.show();
              },
          icon: "alibaba-icon-logout",
          toolIcon: "alibaba-icon-solid-dot",
        },
      ];
    },
  },
  setup() {
    return {
      Page,
      sThemeColorCSSName: inject("sThemeColorCSSName"),
      sync: inject("sync"),
      gDialog: inject("gDialog"),
      gIsCurrentUserSyncing: inject("gIsCurrentUserSyncing"),
      gDarkMode: inject("gDarkMode"),
      gUserSelectedDarkMode: inject("gUserSelectedDarkMode"),
      sUserSelectedDarkMode: inject("sUserSelectedDarkMode"),
      sReceiptQRCode: inject("sReceiptQRCode"),
      showSnackBar: inject("showSnackBar"),
      gGUETCourseTableQQGroup: inject("gGUETCourseTableQQGroup"),
    };
  },
  mounted() {
    this.sThemeColorCSSName("--more-bar-back-color");
  },
  methods: {
    defaultOnClick() {
      this.showSnackBar("开发者正在夜以继日地开发此功能，再等等吧~");
    },
  },
};
</script>

<style></style>
