import HelperError from "./helperError.js";

export default class extends HelperError {
  /** 验收失败 */
  constructor(details) {
    super({
      code: "acceptance_failed",
      tip: "登录未成功，请重试~",
      httpCode: 500,
      details: details,
    });
  }
}
