import HelperError from "./helperError.js";

export default class extends HelperError {
  /** 远程错误 */
  constructor(tip) {
    super({
      code: "remote_error",
      tip: tip,
      httpCode: 500,
    });
  }
}
