import HelperError from "./helperError.js";

export default class extends HelperError {
  /** 请求参数有误 */
  constructor(details) {
    super({
      code: "bad_request",
      tip: undefined,
      httpCode: 400,
      details: details,
    });
  }
}
