import Require from "@/js/require";
import Predefined from "@/js/predefined";

export default function SingleEvent(
    id,
    startUnixNano,
    endUnixNano,
    location,
    name,
    participantList,
    singleCharMark,
    notes,
    data,
) {
    this.isActive = isActive
    this.isAboutToBegin = isAboutToBegin
    this.isJustEnd = isJustEnd

    const myParticipantList = []
    for (const participant of Require.array(participantList)) {
        myParticipantList.push(Require.string(participant))
    }

    this.id = Require.string(id)
    this.nanoTime = Require.object({
        start: Require.number(startUnixNano),
        end: Require.number(endUnixNano),
    })
    this.location = Require.string(location)
    this.name = Require.string(name)
    this.participantList = Require.array(myParticipantList)
    this.singleCharMark = Require.string(singleCharMark)
    this.notes = Require.string(notes)
    this.data = Require.object(data)
}

function isActive(nanoNow) {
    return nanoNow >= this.nanoTime.start && nanoNow < this.nanoTime.end
}

function isAboutToBegin(nanoNow) {
    return nanoNow < this.nanoTime.start && this.nanoTime.start - nanoNow <= Predefined.Minute.Nano * 15
}

function isJustEnd(nanoNow) {
    return nanoNow >= this.nanoTime.end && nanoNow - this.nanoTime.end <= Predefined.Minute.Nano * 15
}