import Require from "@/js/require";

export default function Event(
    unixNanoList,
    location,
    name,
    participantList,
    singleCharMark,
    notes,
    data,
) {
    const myUnixNanoList = []
    for (const unixNano of Require.array(unixNanoList)) {
        myUnixNanoList.push(Require.object({
            start: Require.number(Require.object(unixNano).start),
            end: Require.number(Require.object(unixNano).end),
        }))
    }
    const myParticipantList = []
    for (const participant of Require.array(participantList)) {
        myParticipantList.push(Require.string(participant))
    }

    this.unixNanoList = Require.array(myUnixNanoList)
    this.location = Require.string(location)
    this.name = Require.string(name)
    this.participantList = Require.array(myParticipantList)
    this.singleCharMark = Require.string(singleCharMark)
    this.notes = Require.string(notes)
    this.data = Require.object(data)
}